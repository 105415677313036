import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { post } from '../services/request';
import { apiUrl, url_change_subscription_email } from '../services/constant';
import { get_change_subscription_email_done } from '../actions/change_subscription_email';


function* get_change_subscription_email_saga(action) {
	yield call(
		callApi,
		apiUrl + url_change_subscription_email,
		post,
        action.payload,
        get_change_subscription_email_done,
	);
}
export default function* watch_get_change_subscription_email_saga() {
    yield takeEvery('CHANGE_SUBSCRIPTION_EMAIL', get_change_subscription_email_saga);
}

