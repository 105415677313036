// VALIDATE PCE
export default (state = {}, action) => {
    switch (action.type) {
        case 'PCE_VALIDE_DONE':
            return {
                result: action.payload
            }
        default:
            return state
    }
}