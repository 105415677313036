import React, { Component } from 'react';
import { connect } from 'react-redux';
import { contact } from '../../actions/contact';
import { bindActionCreators } from 'redux';
import { current_step, subscription_id } from '../../services/status';
import Spinner from 'react-bootstrap/Spinner';
import { get_previous } from '../../actions/previous';
import { Container, Row, Col } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2'
import '../../../node_modules/react-phone-input-2/lib/bootstrap.css'
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";
import swal from 'sweetalert';
import icon_help from '../../assets/img/calc.svg';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import fr from 'date-fns/locale/fr';
import moment from 'moment';

class Coordonnees extends Component {
    constructor(props) {
        super(props);
        const newdate = new Date();
        newdate.setDate(newdate.getDate());
        registerLocale('fr', fr)
        this.state = {
            regexp: /^[0-9\b]+$/,
            coordonnees: (localStorage.getItem('next') ? JSON.parse(localStorage.getItem('next')) : JSON.parse(localStorage.getItem('current_step_continue'))),
            frm_data: {},
            last_name: '',
            first_name: '',
            telephone: '',
            email_contact: '',
            pce_input: '',
            isPCERequire: false,
            errorPCE: '',
            siret: '',
            isSiretRequire: false,
            errorSiret: '',
            list_pce: [],
            max_pce: null,
            isLoading: false,
            site_key: process.env.REACT_APP_TAG_RECAPTCHA_V3 ? process.env.REACT_APP_TAG_RECAPTCHA_V3 : '',
            token: '',
            civility: '',
            errorMail: [],
            messageContact: '',
            valideSiret: false,
            validePCE: false,
            commentaire: '',
            commentary_included: true,
            startDate: newdate,
            endDate: newdate,
            currentDate: newdate,
            dateMessage: 'Date fin est supérieur de la date début.',
            showDate: false
        }
        this.handleInput = this.handleInput.bind(this);
    }
    componentWillMount(){
        window.dataLayer.push({
            pageCategory: 'Cordonnées',
        });
        if (localStorage.getItem('current_step_continue')) {
            if(JSON.parse(localStorage.getItem('current_step_continue')).data.step === 'contact'){
                this.setState({
                    messageContact: JSON.parse(localStorage.getItem('current_step_continue')).data.data.messageContact ? JSON.parse(localStorage.getItem('current_step_continue')).data.data.messageContact : '',
                    max_pce: JSON.parse(localStorage.getItem('current_step_continue')).data.data.sites_number ? JSON.parse(localStorage.getItem('current_step_continue')).data.data.sites_number : null,
                    isPCERequire: JSON.parse(localStorage.getItem('current_step_continue')).data.data.is_pce_obligatory ? JSON.parse(localStorage.getItem('current_step_continue')).data.data.is_pce_obligatory : false,
                    isSiretRequire: JSON.parse(localStorage.getItem('current_step_continue')).data.data.is_siren_obligatory ? JSON.parse(localStorage.getItem('current_step_continue')).data.data.is_siren_obligatory : false,
                    commentary_included: JSON.parse(localStorage.getItem('current_step_continue')).data.data.commentary_included ? JSON.parse(localStorage.getItem('current_step_continue')).data.data.commentary_included : false,
                    showDate : JSON.parse(localStorage.getItem('current_step_continue')).data.data.quick_win ? JSON.parse(localStorage.getItem('current_step_continue')).data.data.quick_win : false,
                })
            }
        }
        if (localStorage.getItem('next')) {
            if(JSON.parse(localStorage.getItem('next')).step === 'contact'){
                this.setState({
                    messageContact: JSON.parse(localStorage.getItem('next')).data.messageContact ? JSON.parse(localStorage.getItem('next')).data.messageContact : '',
                    max_pce: JSON.parse(localStorage.getItem('next')).data.sites_number ? JSON.parse(localStorage.getItem('next')).data.sites_number : null,
                    isPCERequire: JSON.parse(localStorage.getItem('next')).data.is_pce_obligatory ? JSON.parse(localStorage.getItem('current_step_continue')).data.is_pce_obligatory : false,
                    isSiretRequire: JSON.parse(localStorage.getItem('next')).data.is_siren_obligatory ? JSON.parse(localStorage.getItem('current_step_continue')).data.is_siren_obligatory : false,
                    commentary_included: JSON.parse(localStorage.getItem('next')).data.commentary_included ? JSON.parse(localStorage.getItem('current_step_continue')).data.commentary_included : false,
                    showDate : JSON.parse(localStorage.getItem('next')).data.quick_win ? JSON.parse(localStorage.getItem('next')).data.quick_win : JSON.parse(localStorage.getItem('next')).data.quick_win,
                })
            }
        }
    }
    componentWillUnmount() {
        localStorage.removeItem('current_step_continue');
    }
    componentDidMount() {
        const { coordonnees, site_key } = this.state;
        this.setState({
            //last_name: coordonnees.data.last_name,
            //first_name: coordonnees.data.first_name,
            //telephone: coordonnees.data.telephone,
            //email_contact: coordonnees.data.email_contact
        });
        loadReCaptcha(site_key, this.verifyCallback);
    }
    updateToken = () => {
        // you will get a new token in verifyCallback
        this.recaptcha.execute()
    };
    verifyCallback = (recaptchaToken) => {
        // Here you will get the final recaptchaToken!!!
        this.setState({
            token: recaptchaToken
        });
    }
    componentDidUpdate(prevProps) {
        const { coordonnees_contact, previous } = this.props;
        // Enable navigation prompt
        window.onbeforeunload = function() {
            return true;
        };
        // Next step
        if (prevProps.coordonnees_contact !== coordonnees_contact) {
            // Remove navigation prompt
            window.onbeforeunload = null;
            //console.log('coordonnees_contact Before : ', coordonnees_contact)
            if (coordonnees_contact.result.status === 200) {
                //console.log('coordonnees_contact True 200 : ', coordonnees_contact)
                localStorage.removeItem('current_step_continue');
                localStorage.removeItem('previous');
                localStorage.setItem('step', coordonnees_contact.result.data.step);
                localStorage.setItem('subscription_id', coordonnees_contact.result.data.subscription_id);
                localStorage.setItem('next', JSON.stringify(coordonnees_contact.result.data));
                localStorage.setItem('current_step_continue', JSON.stringify(coordonnees_contact.result.data));
                localStorage.setItem('type_subscription', coordonnees_contact.result.data.type_subscription);
                window.location.reload(false);
                window.location.replace(coordonnees_contact.result.data.step)
            } else {
                //console.log('coordonnees_contact false : ', coordonnees_contact)
                this.setState({
                    isLoading: false,
                    errorMail: coordonnees_contact.result.response.data.email_contact ? coordonnees_contact.result.response.data.email_contact : '',
                    errorSiret: coordonnees_contact.result.response.data.errorSiret ? coordonnees_contact.result.response.data.errorSiret : ''
                });
                /*
                swal({
                    title: "Erreur dans le formulaire",
                    //text: "Code Erronée",
                    icon: "error",
                }).then(() => {
                    this.setState({
                        isLoading: false,
                        errorMail: coordonnees_contact.result.response.data.email_contact ? coordonnees_contact.result.response.data.email_contact : '',
                        errorSiret: coordonnees_contact.result.response.data.errorSiret ? coordonnees_contact.result.response.data.errorSiret : ''
                    });
                })
                */
            }
        }
        // PREVIOUS STEP
        if (prevProps.previous !== previous) {
            //console.log('prev corddonee : ', previous)
            setTimeout(() => {
                // Remove navigation prompt
                window.onbeforeunload = null;
                localStorage.removeItem('current_step_continue');
                localStorage.removeItem('next');
                localStorage.setItem('step', previous.result.data.step);
                localStorage.setItem('subscription_id', previous.result.data.subscription_id);
                localStorage.setItem('previous', JSON.stringify(previous.result.data));
                localStorage.setItem('current_step_continue', JSON.stringify(previous.result.data));
                // Redirect
                window.location.reload(false);
                window.location.replace(previous.result.data.step)
            }, 2000);
        }
    }
    addPce = (e) => {
        const { list_pce, pce_input } = this.state;
        let new_list = list_pce;
        new_list.push(pce_input);
        this.setState({
            list_pce: new_list,
            pce_input: ''
        });
    };
    // Popup Error help
    togglePopupError = () => {
        const { showPopupError } = this.state
        //window.scrollTo(0, 0)
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.setState({
            showPopupError: !showPopupError
        })
    }
    close_popup = (e) => {
        this.setState({
            showPopupError: false
        })
    }
    removePce = (item) => {
        const { list_pce } = this.state;
        let rest_list_pce = list_pce.filter((cp) => cp !== item);
        this.setState({
            list_pce: rest_list_pce
        });
    };
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    // prevent user enter white space at first position
    handleWhiteSpace = (e) => {
        if (e.key === " " && e.target.value.length===0) {
          e.preventDefault();
        }
    };
    handleCivility = (e) => {
        this.setState({
            civility: e.target.value
        });
    };
    handleSubmit = (e) => {
        e.preventDefault();
        const { contact } = this.props;
        const { frm_data, last_name, first_name, telephone, email_contact, list_pce, token, civility, siret, startDate, endDate,showDate, commentaire } = this.state;
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        frm_data.civility = civility;
        frm_data.last_name = last_name;
        frm_data.first_name = first_name;
        frm_data.telephone = "+" + telephone;
        frm_data.email_contact = email_contact;
        frm_data.pce = list_pce.join();
        frm_data.message = commentaire === "" ? null : commentaire;
        frm_data.siret = siret;
        //frm_data.subscription_id = localStorage.getItem('subscription_id');
        frm_data.subscription_id = path_remove_slash;
        frm_data.recaptcha = token
        frm_data.start_date = showDate ? moment(startDate).format('YYYY-MM-DD') : null
        frm_data.end_date = showDate ? moment(endDate).format('YYYY-MM-DD') : null
        // SUBMIT DATA TO BACKEND
        contact(frm_data);
        //console.log('data : ', frm_data)
        // LOADING
        this.setState({
            isLoading: true
        });
        //console.log('Data form', frm_data);
    };
    // GET PREVIOUS
    go_back = (e) => {
        e.preventDefault();
        const { get_previous } = this.props;
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        let data = {}
        data.type_step = 'previous';
        //data.subscription_id = subscription_id;
        data.subscription_id = path_remove_slash;
        data.current_step = current_step;
        this.setState({
            isLoading: true
        });
        get_previous(data);
    }
    // HANDLE START DATE CHANGE
    handleStartDateChange = (dateStart) => {
        const {endDate, startDate} = this.state
        if(dateStart < endDate){
            this.setState({
                startDate: dateStart,
            });
        }else{
            this.setState({
                startDate: dateStart,
                endDate: dateStart
            });
        }
    };
    // HANDLE END DATE CHANGE
    handleEndDateChange = (dateEnd) => {
        this.setState({
            endDate: dateEnd
        });
    };
    render() {
        const {
            list_pce,
            isLoading,
            first_name,
            last_name,
            telephone,
            pce_input,
            max_pce,
            site_key,
            civility,
            email_contact,
            errorMail,
            messageContact,
            siret,
            errorSiret,
            isPCERequire,
            isSiretRequire,
            commentaire,
            commentary_included,
            startDate,
            endDate,
            currentDate,
            dateMessage,
            showDate
        } = this.state;
        return (
            <React.Fragment>
                {isLoading ?
                    <div className="loadingKey">
                        <Spinner className="spinner-border text-primary" animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                    :
                    <div className="coordone_wrapper">
                        <h1 className="blue-border"><span></span>Mes coordonnées</h1>
                        {
                            messageContact !== '' && messageContact !== undefined ?
                            <div className="etablissement-wrapper">
                                <div className="icon-help">
                                    <img src={icon_help} alt="endesa etablissement" />
                                </div>
                                <div className="etablissement-text">
                                    <p>
                                    {messageContact}
                                    </p>
                                </div>
                            </div>
                            : null
                        }
                        <form onSubmit={this.handleSubmit}>
                            <Row>
                                <Col lg={12}>
                                    <div className="form-group mt-5">
                                        <p>Civilité <span className="red_star">*</span></p>
                                            <label className={(civility === 'Mme' ? 'radio_5 radio_6 active' : 'radio_5 radio_6')} htmlFor="civility_1">
                                                <input id="civility_1" name="civility" value="Mme" type="radio" onChange={(e) => this.handleCivility(e)} />
                                                <span style={{ paddingTop: '12px' }}>Madame</span>
                                            </label>
                                            <label className={(civility === 'Mr' ? 'radio_5 radio_6 active' : 'radio_5 radio_6')} style={{marginLeft: '20px'}} htmlFor="civility_2">
                                                <input id="civility_2" name="civility" value="Mr" type="radio" onChange={(e) => this.handleCivility(e)} />
                                                <span style={{ paddingTop: '12px' }}>Monsieur</span>
                                            </label>
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div className="form_group mt-5">
                                        <label className="simple_label">Nom <span className="red_star">*</span></label>
                                        <input className="simple_input mt-1" value={last_name} name="last_name" type="text" onChange={this.handleInput} />
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div className="form_group mt-5">
                                        <label className="simple_label">Prénom <span className="red_star">*</span></label>
                                        <input className="simple_input mt-1" value={first_name} name="first_name" type="text" onChange={this.handleInput} />
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div className="form_group mt-5">
                                        <label className="simple_label">Numéro de téléphone <span className="red_star">*</span></label>
                                        {/*<input className="simple_input mt-1" name="telephone" type="text" onChange={this.handleInput} />*/}
                                        <PhoneInput
                                            inputProps={{ name: "telephone", className: "simple_input_phone" }}
                                            country={'fr'}
                                            value={telephone}
                                            countryCodeEditable={false}
                                            onChange={telephone => this.setState({ telephone })}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div className="form_group mt-5">
                                        <label className="simple_label">Adresse email <span className="red_star">*</span></label>
                                        <input className="simple_input mt-1" value={email_contact} name="email_contact" type="email" onChange={this.handleInput} />
                                        {
                                            errorMail.length > 0 ?
                                            errorMail.map((message, idx) => {
                                                    return (
                                                        <p key={idx} className="text-danger small-text-help mt-2">{message}</p>
                                                    )
                                                })
                                            : null
                                        }
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div className="form_group mt-5">
                                        <div className="block">
                                            <label className="simple_label">PCE {isPCERequire ? <span className="red_star">*</span> : ''}</label>
                                            <input type="text" name="pce_input" onKeyDown={this.handleWhiteSpace} value={pce_input} onChange={this.handleInput} className="simple_input mt-1" />
                                            {
                                                isPCERequire && max_pce > 0 ?
                                                    <p className="address-text small-text-help mt-2">Merci d'ajouter au moins {max_pce} PCE.</p>
                                                : null
                                            }
                                        </div>
                                        {
                                            pce_input !== '' ?
                                                list_pce.length > 0 ?
                                                    (list_pce.some(pce => pce === pce_input ) ?
                                                        <p className="text-danger small-text-help mt-2">Merci de saisir un PCE différent.</p>
                                                        : <label className="add_coproprietie" onClick={this.addPce}>Ajouter ce PCE <i className="fas fa-plus"></i></label>)
                                                    : <label className="add_coproprietie" onClick={this.addPce}>Ajouter ce PCE <i className="fas fa-plus"></i></label>
                                            : null
                                        }
                                        {/*
                                            pce_input !== '' ?
                                            <label className="add_coproprietie" onClick={this.addPce}>Ajouter ce PCE <i className="fas fa-plus"></i></label>
                                            : null
                                        */}
                                        <div className="block">
                                            {
                                                list_pce.map((item, index) => {
                                                    return <span className="list_CP" key={index}>{item} <i className="fas fa-times" onClick={() => this.removePce(item)}></i></span>;
                                                })
                                            }
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div className="form_group mt-5">
                                        <div className="block">
                                            <label className="simple_label">SIRET/SIREN {isSiretRequire ? <span className="red_star">*</span> : '' }</label>
                                            <input type="text" name="siret" value={siret} onChange={this.handleInput} className="simple_input" />
                                            <p className="text-danger small-text-help mt-2">{errorSiret}</p>
                                        </div>
                                    </div>
                                </Col>
                                {
                                    showDate ?
                                        <React.Fragment>
                                            <Col xs={12} md={6}>
                                                <div className="form_group mt-5">
                                                    <label className="simple_label">Date de début <span className="red_star">*</span></label>
                                                        <DatePicker
                                                            dateFormat="dd/MM/yyyy"
                                                            className="date_calendar_contact"
                                                            selected={startDate}
                                                            onChange={(dateStart) => this.handleStartDateChange(dateStart)}
                                                            minDate={currentDate}
                                                            locale="fr"
                                                        />
                                                </div>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <div className="form_group mt-5">
                                                    <label className="simple_label">Date de fin <span className="red_star">*</span></label>
                                                    <DatePicker
                                                        dateFormat="dd/MM/yyyy"
                                                        className="date_calendar_contact"
                                                        selected={endDate}
                                                        onChange={(dateEnd) => this.handleEndDateChange(dateEnd)}
                                                        minDate={startDate}
                                                        locale="fr"
                                                    />
                                                    {
                                                        endDate < startDate ?
                                                        <p className="text-danger small-text-help mt-2">{dateMessage}</p>
                                                        : null
                                                    }
                                                </div>
                                            </Col>
                                        </React.Fragment>
                                    : null
                                }
                                {
                                    commentary_included ?
                                        <Col xs={12} md={6}>
                                            <div className="form_group mt-5">
                                                <div className="block">
                                                    <label className="simple_label">Commentaire :</label>
                                                    <textarea name="commentaire" value={commentaire} onChange={this.handleInput} className="simple_input mt-1" rows="3"></textarea>
                                                    {/*<input type="text" name="siret" value={siret} onChange={this.handleInput} className="simple_input" />*/}
                                                </div>
                                            </div>
                                        </Col>
                                    : null
                                }
                                <Col xs={10} md={10}>
                                    <div className="form_group mt-5"><p> </p>
                                        <input type="submit" name="btn_submit" id='cord_continue'
                                        disabled =
                                            {
                                                isPCERequire === true && list_pce.length < max_pce || civility === '' || civility === null || last_name === '' || first_name === '' || telephone === '' || telephone.length < 11 || email_contact === '' ? true
                                                    : isSiretRequire === true && siret === '' || civility === '' || civility === null || last_name === '' || first_name === '' || telephone === '' || telephone.length < 11 || email_contact === '' ? true
                                                    : showDate === true && siret === '' || civility === '' || civility === null || last_name === '' || first_name === '' || telephone === '' || telephone.length < 11 || email_contact === '' ? true
                                                : false
                                            }
                                        className="btn_next_continue btn_submit" onSubmit={this.handleSubmit} value="Envoyer" />
                                        <label className="previous" id='cord_previous' onClick={this.go_back}><i className="fas fa-chevron-left"></i>Étape précédente</label>
                                    </div>
                                </Col>
                                <Col xs={2} md={2}></Col>
                            </Row>
                        </form>
                        <ReCaptcha
                            ref={ref => this.recaptcha = ref}
                            sitekey={site_key}
                            action={this.updateToken}
                            verifyCallback={this.verifyCallback}
                        />
                    </div>
                }
            </React.Fragment >
        );
    }
}
function mapStateToProps({ coordonnees_contact, previous }) {
    return { coordonnees_contact, previous };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        contact: contact,
        get_previous: get_previous
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Coordonnees);
