let list_steps = ['identification', 'contact', 'liste_etablissements', 'choix_syndic', 'liste_offres', 'saisie_pce_estimation', 'choix_duree_contrat', 'donnees_client', '404', 'remerciement', 'bienvenue', 'signature_contrat', 'verification_email', 'all_np_step']
export const current_step = (localStorage.getItem('step') && localStorage.getItem('step') !== "undefined" && list_steps.includes(localStorage.getItem('step')) ? localStorage.getItem('step') : "start");
//export const current_step = 'saisie_pce_estimation';
let key_param = window.location.pathname;
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
export const subscription_id = key_param.split('/')[1];
const getRappel = urlParams.get('get_recalled_popup');
//console.log(getRappel)
if(getRappel){
    sessionStorage.setItem('get_recalled_popup',getRappel)
}
export const categorie = 'hôtellerie / restauration';
export const offer_selected = 0;
let previous_steps = [];
switch (current_step) {
    case 'start':
        previous_steps = [];
        break;
    case '404':
        previous_steps = [];
        break;
    case '500':
        previous_steps = [];
        break;
    case 'identification':
        previous_steps = [];
        break;
    case 'choix_syndic':
        previous_steps = [];
        break;
    case 'liste_etablissements':
        previous_steps = [];
        break;
    case 'contact':
        previous_steps = [];
        break;
    case 'liste_offres':
        previous_steps.push('identification');
        break;
    case 'saisie_pce_estimation':
        previous_steps.push('identification', 'liste_offres');
        break;
    case 'choix_duree_contrat':
        previous_steps.push('identification', 'liste_offres', 'saisie_pce_estimation');
        break;
    case 'donnees_client':
        previous_steps.push('identification', 'liste_offres', 'saisie_pce_estimation', 'choix_duree_contrat');
        break;
    case 'verification_email':
        previous_steps.push('identification', 'liste_offres', 'saisie_pce_estimation', 'choix_duree_contrat');
        break;
    case 'signature_contrat':
        previous_steps.push('identification', 'liste_offres', 'saisie_pce_estimation', 'choix_duree_contrat');
        break;
    case 'remerciement':
        previous_steps.push('identification', 'liste_offres', 'saisie_pce_estimation', 'choix_duree_contrat');
        break;
    case 'bienvenue':
        previous_steps.push('identification', 'liste_offres', 'saisie_pce_estimation', 'choix_duree_contrat');
        break;
    case 'all_np_step':
        previous_steps.push('identification', 'liste_offres', 'saisie_pce_estimation', 'choix_duree_contrat');
        break;
    default:
        previous_steps.push('identification');
        break;
}
export const past_step = previous_steps;
