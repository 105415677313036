import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { get } from '../services/request';
import { apiUrl, url_utilisation_type } from '../services/constant';
import { get_estimation_type_done } from '../actions/estimation_type_list';


function* get_estimation_type_saga(action) {
	yield call(
		callApi,
		apiUrl + url_utilisation_type,
		get,
        action.payload,
        get_estimation_type_done,
	);
}
export default function* watch_get_estimation_type_saga() {
    yield takeEvery('ESTIMATION_TYPE_LIST', get_estimation_type_saga);
}

