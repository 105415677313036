import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import swal from 'sweetalert';
import Spinner from 'react-bootstrap/Spinner';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { get_previous } from '../../actions/previous';
import { donnees_client } from '../../actions/donnees_client';
import { get_payeurs_divergents } from '../../actions/payeurs_divergents';
import { get_file_types } from '../../actions/file_types';
import { get_verify_address } from '../../actions/verify_address';
import { get_pre_verified_address } from '../../actions/pre_verified_address';
import { current_step, subscription_id } from '../../services/status';
import { bindActionCreators } from 'redux';
import ReactHtmlParser from 'react-html-parser';
import Site from './site';
import { sites } from './data';
import '../../assets/css/form.css';
import './souscription.css';
import check_2 from '../../assets/img/check_2.png';
import check_2_active from '../../assets/img/check_2_active.png';
import PhoneInput from 'react-phone-input-2'
import '../../../node_modules/react-phone-input-2/lib/bootstrap.css'
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";
import { validateIBAN } from "lc-validator-iban";
import { FieldValidationResult } from "lc-form-validation";
import ReactTooltip from '../../../node_modules/react-tooltip/dist/index';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import DateCountdown from 'react-date-countdown-timer';
import CountdownTimer from "react-component-countdown-timer";
import FileBase64 from 'react-file-base64';
import PopupError from '../aide/PopupError'
import {Modal, Button} from "react-bootstrap"
import { get_numbers_subs } from '../../actions/all_subs';
import icon_help from "../../assets/img/calc.svg";



class Souscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            civility: '',
            last_name: '',
            first_name: '',
            telephone: '',
            email: '',
            code_swift: '',
            files: [],
            fileName: 'Merci de charger votre fichier RIB ici',
            divergent_payer_nom: '',
            divergent_payer_prenom: '',
            divergent_payer_phone: '',
            divergent_payer_email: '',
            divergent_payer_name: '',
            divergent_payer_siret: '',
            sites: [],
            format_facture: '',
            frm_data: {},
            coordonnees: (localStorage.getItem('next') ? JSON.parse(localStorage.getItem('next')) : JSON.parse(localStorage.getItem('current_step_continue'))),
            checkedEnvoi: new Map(),
            is_autorise: false,
            checkedFacturation: new Map(),
            payeurDivergent: false,
            site_key: process.env.REACT_APP_TAG_RECAPTCHA_V3 ? process.env.REACT_APP_TAG_RECAPTCHA_V3 : '',
            token: '',
            iban: "",
            result: "",
            isReadOnly: false,
            validationAddres: false,
            sites_edited: [],
            widthSize: window.innerWidth,
            isLoading: false,
            // State for error message
            errorEmail: [],
            errorPhone: [],
            errorIban: [],
            errorSwift: [],
            errorAddress: [],
            errorSiret: [],
            errorRS: [],
            addressTypeError: '',
            addressProspectError: '',
            addressTextError: 'Veuillez saisir une adresse valide',
            addressCode: 0,
            btnAdresseDisabled: true,
            dateToUpdateEmail: 'December 24, 2020 18:00:00',
            showCounter: true,
            sitesValidation: [],
            errorCounter: 0,
            showPopupError: false,
            timerTyping: 0,
            show: false,
            showDropdown: [],
            type_files: [],
            lists_input: [],
            uploadedFiles: [],
            selectedFile: [],
            sendToBack_selectedFile: [],
            errorRibFile: [],
            errorForeignFiles: [],
            id_file: 1,
            fileMaxSize: 0,
            supportedFiles: [],
            phoneDigits: true,
            phoneDigitsDivergent: true
        }
        this.box = React.createRef();
    }
    handleClose = () => {
        this.setState({
            show: false
        })
    }

    handleShow  = () => {
        this.setState({
            show: true
        })
    }
    componentDidUpdate(prevProps) {
        const { previous, informations_client, payeurs_divergents, verify_address, pre_verified_address, all_file_types, all_subs } = this.props;
        const {sitesValidation, errorCounter} = this.state
        // Enable navigation prompt
        window.onbeforeunload = function() {
            return true;
        };
        // GET PARAMS
        if (prevProps.all_subs !== all_subs) {
            all_subs.result.data.map((itm, indx) => {
                // GET MAX SIZE FILE
                if (itm.param === "UPLOAD_MAXIMUM_FILE_SIZE") {
                    this.setState({
                        fileMaxSize: itm.value
                    })
                }
                // GET EXTENSION FILES
                if (itm.param === "ALLOWED_EXTENSIONS") {
                    this.setState({
                        supportedFiles: itm.value
                    })
                }
            })
        }
        if (prevProps.pre_verified_address !== pre_verified_address) {
            if(pre_verified_address.result.status === 200){
                this.setState({
                    sitesValidation: pre_verified_address.result.data
                })
            }else{
            }
        }
        if (prevProps.all_file_types !== all_file_types) {
            if(all_file_types.result.status === 200){
                this.setState({
                    type_files: all_file_types.result.data
                })
            }else{
            }
        }
        // PAYEURS DIVERGENTS
        if (prevProps.payeurs_divergents !== payeurs_divergents) {
            // Remove navigation prompt
            window.onbeforeunload = null;
            if (payeurs_divergents.result.status === 200) {
                localStorage.removeItem('previous');
                localStorage.setItem('step', payeurs_divergents.result.data.step);
                localStorage.setItem('subscription_id', payeurs_divergents.result.data.subscription_id);
                // Redirect
                this.setState({
                    isLoading: false
                });
                window.location.reload(false);
                //console.log('Returned data', payeurs_divergents)
            }
        }
        // VERIFY ADDRESS
        if (prevProps.verify_address !== verify_address) {
            if (verify_address.result.status === 200) {
                //console.log('YEEEES VALID ADDRESS')
                const siteList = sitesValidation;
                var adressType = verify_address.result.data.address_type;
                siteList[verify_address.result.data.prospect_id][adressType] = true;
                this.setState({
                    addressProspectError: verify_address.result.data.prospect_id ? verify_address.result.data.prospect_id : '',
                    addressTypeError: verify_address.result.data.address_type ? verify_address.result.data.address_type : '',
                    //addressTextError: verify_address.result.data.detail ? verify_address.result.data.detail : '',
                    addressCode: 200,
                    btnAdresseDisabled: false,
                    sitesValidation: siteList
                })
            }else {
                //console.log('Returned Errors : ',verify_address.result.response.data.prospect_id, verify_address.result.response.data.address_type, verify_address.result.response.data.detail )
                const siteList = sitesValidation;
                var adressType = verify_address.result.response.data.address_type;
                siteList[verify_address.result.response.data.prospect_id][adressType] = false;
                if(errorCounter > 2){
                    this.setState({
                        show: true,
                        showPopupError: true,
                        errorCounter: errorCounter + 1,
                        addressProspectError: verify_address.result.response.data.prospect_id ? verify_address.result.response.data.prospect_id : '',
                        addressTypeError: verify_address.result.response.data.address_type ? verify_address.result.response.data.address_type : '',
                        //addressTextError: verify_address.result.response.data.detail ? verify_address.result.response.data.detail : '',
                        addressCode: 400,
                        btnAdresseDisabled: true,
                        sitesValidation: siteList
                    })
                }else {
                    this.setState({
                        show: false,
                        errorCounter: errorCounter + 1,
                        addressProspectError: verify_address.result.response.data.prospect_id ? verify_address.result.response.data.prospect_id : '',
                        addressTypeError: verify_address.result.response.data.address_type ? verify_address.result.response.data.address_type : '',
                        //addressTextError: verify_address.result.response.data.detail ? verify_address.result.response.data.detail : '',
                        addressCode: 400,
                        btnAdresseDisabled: true,
                        sitesValidation: siteList
                    })
                }
            }
        }
        // NEXT STEP
        if (prevProps.informations_client !== informations_client) {
            // Remove navigation prompt
            window.onbeforeunload = null;
            if (informations_client.result.status === 200) {
                localStorage.removeItem('current_step_continue');
                localStorage.removeItem('previous');
                localStorage.setItem('step', informations_client.result.data.step);
                localStorage.setItem('subscription_id', informations_client.result.data.subscription_id);
                localStorage.setItem('type_subscription', informations_client.result.data.type_subscription);
                localStorage.setItem('user_key', informations_client.result.data.user_key);
                localStorage.setItem('next', JSON.stringify(informations_client.result.data));
                localStorage.setItem('current_step_continue', JSON.stringify(informations_client.result.data));
                // Redirect
                this.setState({
                    isLoading: false
                });
                window.location.reload(false);
                window.location.replace(informations_client.result.data.step)
                //console.log('Returned data', informations_client)
            } else {
                //console.log('sites : ', informations_client.result.response.data.sites)
                //console.log('BACK : ', informations_client.result.response.data)
                var divList = document.createElement("div");
                if(informations_client.result.response.data.sites){
                    informations_client.result.response.data.sites.map((item, idx) => {
                        if(item.ErrorAddres){
                            //console.log('ITEM SITE : ', item.ErrorAddres[0], idx)
                        }
                    })
                    divList.innerHTML = "<div class='list_errors'><ul>"+ informations_client.result.response.data.sites.map((item, idx) => {
                        if(item.ErrorAddres){
                            //console.log('ITEM SITE : ', item.ErrorAddres[0], idx)
                            return `<li>Site ${idx+1} : ${item.ErrorAddres[0]}</li>`
                        }
                    }).join('') +"</ul></div>";
                }
                swal({
                    title: "Erreur dans le formulaire",
                    text: "Une ou plusieurs informations saisies ne sont pas correctes",
                    className: "error-message",
                    content: divList,
                    icon: "error",
                }).then(() => {
                    if(errorCounter > 2){
                        this.setState({
                            show: true,
                            showPopupError: true,
                            errorCounter: errorCounter + 1,
                            isLoading: false,
                            errorEmail: (informations_client.result.response.data.email ? informations_client.result.response.data.email : ''),
                            errorPhone: (informations_client.result.response.data.errorPhone ? informations_client.result.response.data.errorPhone : ''),
                            errorIban: (informations_client.result.response.data.errorIban ? informations_client.result.response.data.errorIban : ''),
                            errorSwift: (informations_client.result.response.data.errorSwift ? informations_client.result.response.data.errorSwift : ''),
                            errorSiret: (informations_client.result.response.data.errorSiret ? informations_client.result.response.data.errorSiret : ''),
                            errorRS: (informations_client.result.response.data.errorRS ? informations_client.result.response.data.errorRS : ''),
                            errorRibFile: (informations_client.result.response.data.rib ? informations_client.result.response.data.rib : []),
                            errorForeignFiles: (informations_client.result.response.data.file_extension_errors ? informations_client.result.response.data.file_extension_errors : []),
                        });
                    }else{
                        this.setState({
                            show: false,
                            errorCounter: errorCounter + 1,
                            isLoading: false,
                            errorEmail: (informations_client.result.response.data.email ? informations_client.result.response.data.email : ''),
                            errorPhone: (informations_client.result.response.data.errorPhone ? informations_client.result.response.data.errorPhone : ''),
                            errorIban: (informations_client.result.response.data.errorIban ? informations_client.result.response.data.errorIban : ''),
                            errorSwift: (informations_client.result.response.data.errorSwift ? informations_client.result.response.data.errorSwift : ''),
                            errorSiret: (informations_client.result.response.data.errorSiret ? informations_client.result.response.data.errorSiret : ''),
                            errorRS: (informations_client.result.response.data.errorRS ? informations_client.result.response.data.errorRS : ''),
                            errorRibFile: (informations_client.result.response.data.rib ? informations_client.result.response.data.rib : []),
                            errorForeignFiles: (informations_client.result.response.data.file_extension_errors ? informations_client.result.response.data.file_extension_errors : []),
                        });
                    }
                    var parent = document.querySelector('.upload-file input');
                    parent.setAttribute("accept", "application/pdf")
                    parent.setAttribute("class", "input-upload-file")
                })
            }
        }
        // //ERROR
        // if (prevProps.informations_client !== informations_client && informations_client.result.response.status !== 200) {
        //     swal({
        //         title: 'Erreur' + informations_client.result.response.status,
        //         text: JSON.parse(informations_client.result.response.data).map((error) => { return <p>{error}</p>; }),
        //         icon: "error",
        //     });
        // }
        // PREVIOUS STEP
        if (prevProps.previous !== previous) {
            // Remove navigation prompt
            window.onbeforeunload = null;
            localStorage.removeItem('current_step_continue');
            localStorage.setItem('step', previous.result.data.step);
            localStorage.setItem('subscription_id', previous.result.data.subscription_id);
            localStorage.setItem('previous', JSON.stringify(previous.result.data));
            // Redirect
            this.setState({
                isLoading: false
            });
            window.location.reload(false);
            window.location.replace(previous.result.data.step)
        }
    }
    componentWillMount() {
        window.dataLayer.push({
            pageCategory: 'Souscription',
            event: 'gtm.load.Souscription',
        });
        const {get_file_types, get_numbers_subs} = this.props
        get_file_types()
        get_numbers_subs();
        const { coordonnees, type_files, id_file } = this.state;
        var arrayFiles = []
        arrayFiles.length = 1
        arrayFiles.fill(0)

        let data = []
        let data_selectedFile = []
        data.length = type_files.length
        data_selectedFile.length = arrayFiles.length
        data.fill(false)
        var id_file_copy = id_file
        data_selectedFile[0] = {'type':'Selectionner un type', 'base_64': null, 'file_name': 'Merci de charger votre fichier ici', 'id_type': null, 'id_file': id_file_copy + 1}
        //console.log('Coordonnées', coordonnees);
        this.setState({
            civility: coordonnees.data.civility,
            last_name: coordonnees.data.last_name,
            first_name: coordonnees.data.first_name,
            telephone: coordonnees.data.telephone,
            email: coordonnees.data.email,
            sites: coordonnees.sites,
            isLoading: false,
            uploadedFiles: arrayFiles,
            showDropdown: data,
            selectedFile: data_selectedFile,
            id_file: id_file_copy + 1
        });
        if (localStorage.getItem('current_step_continue')) {
            if(JSON.parse(localStorage.getItem('current_step_continue')).data.step === 'donnees_client'){
                this.setState({
                    civility: JSON.parse(localStorage.getItem('current_step_continue')).data.data.civility,
                    last_name: JSON.parse(localStorage.getItem('current_step_continue')).data.data.last_name,
                    first_name: JSON.parse(localStorage.getItem('current_step_continue')).data.data.first_name,
                    telephone: JSON.parse(localStorage.getItem('current_step_continue')).data.data.telephone,
                    email: JSON.parse(localStorage.getItem('current_step_continue')).data.data.email,
                    sites: JSON.parse(localStorage.getItem('current_step_continue')).data.sites,
                    isLoading: false
                })
            }
        }
    }
    componentDidMount() {
        const { site_key, sites, addressProspectError, addressTypeError, addressTextError, addressCode } = this.state;
        const {get_verify_address, get_pre_verified_address} = this.props;
        // Adding a click event listener
        document.addEventListener('click', this.handleOutsideClick);
        let frm_data = {
            subscription_id: subscription_id,
        }
        get_pre_verified_address(frm_data)
        /*
        for (let index = 0; index < sites.length; index++) {
            const element = sites[index];
            console.log('element : ', element)
            let frm_data = {
                address: element.address_supply, // address name
                subscription_id: subscription_id, // sub id
                prospect_id: element.id, // id site
                address_type: 'address_supply', // copro or etab
            }
            get_verify_address(frm_data)
        }
        */
        sites.map((item,idx) => {
            if(item.address_supply === '' || item.address_supply === null){
                this.setState({
                    btnAdresseDisabled: true
                })
            }
            else {
                this.setState({
                    btnAdresseDisabled: false
                })
            }
        })
        loadReCaptcha(site_key, this.verifyCallback);
        this.setState({
            widthSize: window.innerWidth
        })
        var parent = document.querySelector('.upload-file input');
        parent.setAttribute("accept", "application/pdf")
        parent.setAttribute("class", "input-upload-file")
    }
    handleOutsideClick = (event) => {
        const {showDropdown} = this.state
        var specifiedElement = document.getElementById('js-search-suggest-wrapper');

        if(specifiedElement !== null){
            var isClickInside = specifiedElement.contains(event.target);
            if (!isClickInside) {
                this.setState({
                    showDropdown: showDropdown.fill(false)
                })
            }
        }
    }
    updateToken = () => {
        // you will get a new token in verifyCallback
        this.recaptcha.execute()
    };
    // close popup counter +3
    close_popup = (e) => {
        this.setState({
            showPopupError: false
        })
    }
    // Popup Error help
    togglePopupError = () => {
        const { showPopupError } = this.state
        //window.scrollTo(0, 0)
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.setState({
            showPopupError: !showPopupError
        })
    }
    verifyCallback = (recaptchaToken) => {
        // Here you will get the final recaptchaToken!!!
        //console.log(recaptchaToken, "<= your recaptcha token");
        this.setState({
            token: recaptchaToken
        });
    }
    // GET PREVIOUS
    go_back = (e) => {
        e.preventDefault();
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        swal({
            title: "Vous allez être redirigé vers la vue précédente et perdre les informations entrées, êtes-vous sûr ?",
            //text: "Once deleted, you will not be able to recover this imaginary file!",
            icon: "warning",
            buttons: ["Annuler", "Oui"],
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                const { get_previous } = this.props;
                let data = {};
                data.type_step = 'previous';
                //data.subscription_id = subscription_id;
                data.subscription_id = path_remove_slash;
                data.current_step = current_step;
                this.setState({
                    isLoading: true
                });
                get_previous(data);
            } else {

            }
          });
        //console.log("Previous : ",data)
    }
    // RESET DATA - SOUSSCRIPTION
    resetData = (e) => {
        e.preventDefault();
        swal({
            title: "Êtes vous sur ? vous perdrez toutes les informations renseignées",
            //text: "Once deleted, you will not be able to recover this imaginary file!",
            icon: "warning",
            buttons: ["Non", "Oui"],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    swal("Votre souscription est réinitialisée", {
                        icon: "success",
                    }).then(() => {
                        //console.log('call ws')
                    });
                } else {
                    //swal("Your imaginary file is safe!");
                }
            });

    }
    handleCivility = (e) => {
        this.setState({
            civility: e.target.value
        });
        //console.log('SITES VALIDATION : ', this.state.sitesValidation)
    };
    handleFormatFacture = (e) => {
        this.setState({
            format_facture: e.target.value
        });
    };
    // CHECK ADRESSE ENVOI POUR LES SITES QUI ONT UNE ADRESSE D'ENVOI
    is_envoi = (e) => {
        const item = e.target.name;
        const isChecked = e.target.checked;
        this.setState(prevState => ({
            checkedEnvoi: prevState.checkedEnvoi.set(item, isChecked)
        }))
    };
    // CHECK ADRESSE FACTURATION POUR LES SITES QUI ONT UNE ADRESSE FACTURATION
    is_facturation = (e) => {
        const item = e.target.name;
        const isChecked = e.target.checked;
        this.setState(prevState => ({
            checkedFacturation: prevState.checkedFacturation.set(item, isChecked)
        }))
    };
    checkAutorise = (e) => {
        const { is_autorise } = this.state;
        this.setState({
            is_autorise: !is_autorise
        });
    };
    checkPayeur = (e) => {
        const { payeurDivergent } = this.state;
        this.setState({
            payeurDivergent: !payeurDivergent,
            divergent_payer_nom : '',
            divergent_payer_prenom : '',
            divergent_payer_phone : '',
            divergent_payer_email : '',
            divergent_payer_name : '',
            divergent_payer_siret : ''

        });
    };
    handleSubmit = (e) => {
        e.preventDefault();
        const { donnees_client } = this.props;
        const {
            frm_data,
            civility,
            last_name,
            first_name,
            telephone,
            email,
            format_facture,
            iban,
            code_swift,
            divergent_payer_name,
            payeurDivergent,
            divergent_payer_siret,
            sites,
            is_autorise,
            token,
            divergent_payer_nom,
            divergent_payer_prenom,
            divergent_payer_phone,
            divergent_payer_email,
            files,
            selectedFile,
            sendToBack_selectedFile
        } = this.state;
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        //frm_data.subscription_id = (localStorage.getItem('subscription_id') ? localStorage.getItem('subscription_id') : '');
        frm_data.subscription_id = path_remove_slash;
        frm_data.last_name = last_name;
        frm_data.first_name = first_name;
        frm_data.telephone = "+" + telephone;
        frm_data.email = email;
        frm_data.civility = civility;
        frm_data.receive_billing_by = format_facture;
        frm_data.code_swift = code_swift;
        frm_data.iban = iban;
        frm_data.is_divergent_payer = (payeurDivergent ? true : false);
        frm_data.divergent_payer_last_name = divergent_payer_nom;
        frm_data.divergent_payer_first_name = divergent_payer_prenom;
        frm_data.divergent_payer_telephone = "+" + divergent_payer_phone;
        frm_data.divergent_payer_email = divergent_payer_email;
        frm_data.divergent_payer_name = divergent_payer_name;
        frm_data.divergent_payer_siret = divergent_payer_siret;
        frm_data.sites = sites;
        frm_data.payement_authorization = is_autorise;
        frm_data.recaptcha = token;
        frm_data.rib_base64 = files.base64;
        frm_data.rib_file_name = files.name;
        frm_data.supplementary_files_list = sendToBack_selectedFile.length > 0 ? sendToBack_selectedFile : null
        //frm_data.supplementary_files_list = selectedFile[selectedFile.length-1].base_64 === null || selectedFile[selectedFile.length-1].type === 'Selectionner un type'  ? null : selectedFile
        // SUBMIT DATA TO THE BACKEND
        donnees_client(frm_data);
        //console.log(frm_data)
        // LOADING
        this.setState({
            isLoading: true
        });
    }
    // Callback~
    getFiles(files){
        //document.getElementsByClassName("input-upload-file").value = null;
        const {fileMaxSize} = this.state
        if(files.type === "application/pdf" && parseInt(files.size.substr(0,files.size.indexOf(' '))) <= fileMaxSize){
            //console.log("files 1 : ", this.state.files)
            this.setState({
                files: files,
                fileName: files.name
            })
            //console.log("upload 2 : ", document.getElementsByClassName("input-upload-file")[0].value)
        }else{
            //console.log("files 2 : ", this.state.files)
            swal({
                title: "Erreur format fichier ou taille fichier",
                text: `Format valide .PDF uniquement, Taille limite des pièces jointes ${Math.trunc(fileMaxSize/1024)} Mo.`,
                className: "error-message",
                icon: "error",
            }).then(
                this.setState({
                    files: [],
                    fileName: 'Merci de charger votre fichier RIB ici'
                })
            )
            document.getElementsByClassName("input-upload-file")[0].value = ''
        }
        //console.log("upload 3 : ", document.getElementsByClassName("input-upload-file")[0].value)
        //console.log("files 3 : ", this.state.files)
    }
    // INPUT CHANGE
    handleInput = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value
        });
    };
    handleIbanChange = (e) => {
        this.setState({
            iban: e.target.value
        });
    }
    // HANDLE SITE INPUTS CHANGE BY ID
    handleInputById = (id, e) => {
        const {get_verify_address} = this.props;
        const { sites, btnAdresseDisabled, timerTyping } = this.state;
        if (timerTyping) {
            clearTimeout(timerTyping);
        }
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        let item = sites.findIndex(site => site.id === id);
        let input_name = e.target.name;
        let input_value = e.target.value;
        let sites_edited = sites;
        sites_edited[item][input_name] = input_value;
        //console.log(input_name, input_value, id);
        let frm_data = {
            address: input_value, // address name
            //subscription_id: subscription_id, // sub id
            subscription_id: path_remove_slash, // sub id
            prospect_id: id, // id site
            address_type: input_name, // copro or etab
        }
        this.setState({
            timerTyping: setTimeout(function () {
                get_verify_address(frm_data)
                //console.log('ws called')
            }, 1000)
          })
        sites_edited.map((item,idx) => {
            if(item.address_supply === '' || item.address_supply === null){
                this.setState({
                    btnAdresseDisabled: true
                })
            }
            else {
                this.setState({
                    btnAdresseDisabled: false
                })
            }
        })
        this.setState({
            sites: sites_edited,
        });
        //console.log('test', sites_edited);
    };
    handlePayeurDivergent = (e) => {
        //e.preventDefault()
        const { get_payeurs_divergents } = this.props
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        let frm_data = {}
        //frm_data.subscription_id = localStorage.getItem('subscription_id');
        frm_data.subscription_id = path_remove_slash;
        //console.log('clicked : ', frm_data)
        this.setState({
            isLoading: true
        });
        // CALL WS
        get_payeurs_divergents(frm_data)
    }
    // DOWNLOAD FILE
    downloadFile = (offer_id) => {
        const { offers } = this.state;
        const { get_brochure } = this.props;
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        let data = {};
        //data.subscription_id = offers.subscription_id;
        data.subscription_id = path_remove_slash;
        data.offer_id = offer_id;
        // SUBMIT TO DOWNLOAD FILE
        get_brochure(data);
        this.setState({
            isLoading: true
        });
    };
    // UPLOAD FOREIGN FILES
    trigger_upload_files = (id) => {
        const {showDropdown} = this.state

        let copied = showDropdown
        let value = !copied[id]
        let copied2 = copied
        copied2.fill(false)
        copied2[id] = value
        this.setState({
            showDropdown: copied2
        })
    };
    trigger_selected_file = (item, idx) => {
        const {selectedFile, sendToBack_selectedFile} = this.state
        let copied_selectedFile = selectedFile
        copied_selectedFile[idx].type = item.name
        copied_selectedFile[idx].id_type = item.id
        copied_selectedFile[idx].base_64 = copied_selectedFile[idx].base_64 === undefined ? null : copied_selectedFile[idx].base_64
        copied_selectedFile[idx].file_name = copied_selectedFile[idx].file_name === undefined ? null : copied_selectedFile[idx].file_name
        let rest_sendToBack_selectedFile = copied_selectedFile.filter((item, i) => item.base_64 !== null && item.file_name !== null );
        this.setState({
            sendToBack_selectedFile: rest_sendToBack_selectedFile
        })
        //console.log('TRIGGER rest_sendToBack_selectedFile : ', rest_sendToBack_selectedFile)
    }
    // Callback~
    foreignFiles(idx, files){
        const {selectedFile, sendToBack_selectedFile, fileMaxSize} = this.state
        if(parseInt(files.size.substr(0,files.size.indexOf(' '))) > fileMaxSize){
            swal({
                title: "Taille fichier maximale",
                text: `La taille du fichier est supérieur de ${fileMaxSize/1024}Mo, merci de charger un fichier moins de ${fileMaxSize/1024}Mo`,
                className: "error-message",
                icon: "error",
            })
        }else{
            let copied_selectedFile = selectedFile
            copied_selectedFile[idx].base_64 = files.base64
            copied_selectedFile[idx].file_name = files.name
            let rest_sendToBack_selectedFile = copied_selectedFile.filter((item, i) => item.id_type !== null && item.type !== 'Selectionner un type' );
            this.setState({
                errorForeignFiles: [],
                selectedFile: copied_selectedFile,
                sendToBack_selectedFile: rest_sendToBack_selectedFile
            })
        }
        //console.log('files : ', parseInt(files.size.substr(0,files.size.indexOf(' '))))
        //console.log('UPLOAD rest_sendToBack_selectedFile : ', rest_sendToBack_selectedFile)
    }
    removeFile = (idx) => {
        const { uploadedFiles, selectedFile } = this.state;
        let rest_lists_input = uploadedFiles.filter((_, i) => i !== idx);
        let rest_selectedFile = selectedFile.filter((_, i) => i !== idx);
        let rest_sendToBack_selectedFile = selectedFile.filter((item, i) => item.base_64 !== null &&  i !== idx);
        this.setState({
            errorForeignFiles: [],
            uploadedFiles: rest_lists_input,
            selectedFile: rest_selectedFile,
            sendToBack_selectedFile: rest_sendToBack_selectedFile
        })
        //console.log('REMOVE rest_sendToBack_selectedFile : ', rest_sendToBack_selectedFile)
    }
    removeFirstFile = (idx) => {
        const { uploadedFiles, selectedFile } = this.state;
        //console.log('uploadedFiles : ', uploadedFiles)
        //console.log('selectedFile : ', selectedFile)
        //console.log('idx : ', idx)
        //console.log('parent : ', parent.value)
        var parent = document.querySelector('.upload-file-foreign input');
        if(selectedFile.length > 1){
            let rest_lists_input = uploadedFiles.filter((_, i) => i !== idx);
            let rest_selectedFile = selectedFile.filter((_, i) => i !== idx);
            let rest_sendToBack_selectedFile = selectedFile.filter((item, i) => item.base_64 !== null &&  i !== idx);
            this.setState({
                errorForeignFiles: [],
                uploadedFiles: rest_lists_input,
                selectedFile: rest_selectedFile,
                sendToBack_selectedFile: rest_sendToBack_selectedFile
            })
        }else{
            let copy_selectedFile = selectedFile
            var parent = document.querySelector('.upload-file-foreign input');
            copy_selectedFile[0].file_name = 'Merci de charger votre fichier ici'
            copy_selectedFile[0].base_64 = null
            copy_selectedFile[0].type = 'Selectionner un type'
            copy_selectedFile[0].id_type = null
            parent.value = null
            this.setState({
                errorForeignFiles: [],
                selectedFile: copy_selectedFile,
                sendToBack_selectedFile: []
            })
        }

    }
    incrementFile = () => {
        const {uploadedFiles, selectedFile, id_file} = this.state
        //console.log('id_file : ', id_file)
        var id_file_copy = id_file
        var array_files = []
        var array_files_selectedFile = selectedFile
        array_files.length = uploadedFiles.length + 1
        array_files_selectedFile.length = uploadedFiles.length + 1
        array_files.fill(0)
        array_files_selectedFile[array_files.length-1] = {'type' : 'Selectionner un type', 'file_name' : 'Merci de charger votre fichier ici', 'base_64': null, 'id_type': null, 'id_file': id_file_copy + 1}
        let rest_sendToBack_selectedFile = selectedFile.filter((item, i) => item.base_64 !== null);
        this.setState({
            id_file: id_file_copy + 1,
            uploadedFiles: array_files,
            selectedFile: array_files_selectedFile,
            sendToBack_selectedFile: rest_sendToBack_selectedFile
        })
        //console.log('INCREMENT array_files_selectedFile : ', array_files_selectedFile)

    }

    render() {
        const {
            civility,
            last_name,
            first_name,
            telephone,
            email,
            checkedEnvoi,
            checkedFacturation,
            format_facture,
            is_autorise,
            payeurDivergent,
            divergent_payer_nom,
            divergent_payer_prenom,
            divergent_payer_phone,
            divergent_payer_email,
            divergent_payer_name,
            divergent_payer_siret,
            coordonnees,
            sites,
            site_key,
            iban,
            isReadOnly,
            code_swift,
            errorEmail,
            errorPhone,
            errorSwift,
            errorIban,
            errorSiret,
            errorRS,
            addressTextError,
            addressProspectError,
            addressTypeError,
            addressCode,
            btnAdresseDisabled,
            widthSize,
            isLoading,
            dateToUpdateEmail,
            showCounter,
            files,
            fileName,
            showDropdown,
            type_files,
            selectedFile,
            lists_input,
            uploadedFiles,
            errorRibFile,
            errorForeignFiles,
            supportedFiles,
            fileMaxSize,
            phoneDigits,
            phoneDigitsDivergent
        } = this.state;

        const result: FieldValidationResult = validateIBAN(iban);
        return (
            <React.Fragment>
                {isLoading ?
                    <div className="loadingKey">
                        <Spinner className="spinner-border text-primary" animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                :
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={9}>
                            <h1 className="blue-border"><span></span>Mes coordonnées</h1>
                            {/*
                            <div className="form_group mt-2 mb-3">
                                <div className="box_recommandons_2 payeur_divergent">
                                    <div className="icon"></div>
                                    <div className="txt_link">
                                        <p>
                                            Si vous avez plusieurs payeurs divergents, prière de <span onClick={this.handlePayeurDivergent} className="clickme">cliquer ici</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            */}
                            <form className="souscription-form" onSubmit={this.handleSubmit}>
                                <div className="form-group mt-5">
                                    <p>Civilité <span className="red_star">*</span></p>
                                    <Row>
                                        <Col xs={6} md={6} className="noPadding">
                                            <label className={(civility === 'Mme' ? 'radio_5 radio_6 active' : 'radio_5 radio_6')} htmlFor="civility_1">
                                                <input id="civility_1" name="civility" value="Mme" type="radio" onChange={(e) => this.handleCivility(e)} />
                                                <span style={{ paddingTop: '12px' }}>Madame</span>
                                            </label>
                                        </Col>
                                        <Col xs={6} md={6} className="noPadding">
                                            <label className={(civility === 'Mr' ? 'radio_5 radio_6 active' : 'radio_5 radio_6')} htmlFor="civility_2">
                                                <input id="civility_2" name="civility" value="Mr" type="radio" onChange={(e) => this.handleCivility(e)} />
                                                <span style={{ paddingTop: '12px' }}>Monsieur</span>
                                            </label>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="form-group mt-5">
                                    <Row>
                                        <Col xs={12} md={6} className="noPadding">
                                            <p>Nom du signataire <span className="red_star">*</span></p>
                                            <input type="text" name="last_name" value={last_name} className="simple_input" onChange={this.handleInput} />
                                        </Col>
                                        <Col xs={12} md={6} className="noPadding marg_top_input">
                                            <p>Prénom du signataire <span className="red_star">*</span></p>
                                            <input type="text" name="first_name" value={first_name} className="simple_input" onChange={this.handleInput} />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="form-group mt-5">
                                    <Row>
                                        <Col xs={12} md={6} className="noPadding">
                                            <p>Téléphone portable<span className="red_star">*</span></p>
                                            {/*<input type="text" name="telephone" value={telephone} className="simple_input" onChange={this.handleInput} />*/}
                                            <PhoneInput
                                                inputProps={{ name: 'telephone', className: 'simple_input_phone' }}
                                                masks={{ ma: '. ..-..-..-..' }}
                                                country={'fr'}
                                                countryCodeEditable={false}
                                                value={telephone}
                                                inputStyle={{display: 'inline-block'}}
                                                /*onChange={telephone => this.setState({ telephone })}*/
                                                onChange={(value, country) => {
                                                    const threeDigits = value.slice(0, 3);
                                                    if(country.dialCode === '33'){
                                                        if(threeDigits === '336' || threeDigits === '337'){
                                                            this.setState({ telephone: value, phoneDigits: true });
                                                        }else{
                                                            this.setState({telephone: country.dialCode, phoneDigits: false})
                                                        }
                                                    }else{
                                                        this.setState({ telephone: value, phoneDigits: true });
                                                    }
                                                }}
                                            />
                                            <Tooltip title="Seuls les numéros de téléphones portables sont acceptés. Vous recevrez un code par SMS pour authentifier la signature de votre contrat." position={widthSize > 576 ? "right" : "bottom"} trigger={widthSize > 576 ? "mouseenter" : "click"}>
                                                <span className="info_postal btn_mobile info_addr_envoi infos-phone-divergent" data-class="own_tooltip">i</span>
                                            </Tooltip>
                                            {
                                                !phoneDigits ?
                                                <p className="text-danger small-text-help mt-2">Merci de saisir un numéro de téléphone portable</p>
                                                : null
                                            }
                                            {
                                                errorPhone.length > 0 ?
                                                    errorPhone.map((message, idx) => {
                                                        return (
                                                            <p key={idx} className="text-danger small-text-help mt-2">{message}</p>
                                                        )
                                                    })
                                                    : null
                                            }
                                        </Col>
                                        <Col xs={12} md={6} className="noPadding marg_top_input">
                                            <p>Email <span className="red_star">*</span></p>
                                            <input type="email" name="email" value={email} className="simple_input" onChange={this.handleInput} />
                                            {
                                                errorEmail.length > 0 ?
                                                    errorEmail.map((message, idx) => {
                                                        return (
                                                            <p key={idx} className="text-danger small-text-help mt-2">{message}</p>
                                                        )
                                                    })
                                                    : null
                                            }
                                        </Col>
                                    </Row>
                                </div>
                                {
                                    /*
                                    sites.map((item) => {
                                        return <Site
                                            key={item.id}
                                            id={item.id}
                                            title={(item.type_prospect === 'etablissement' ? item.address_supply : item.name + ' - ' + item.postcode)}
                                            address_supply={item.address_supply}
                                            address_billing={item.address_billing}
                                            // changeBillingAdd={this.billingAdd}
                                            // changeSupplyAdd={this.supplyAdd}
                                            address_send={item.address_send}
                                            is_envoi={this.is_envoi}
                                            is_facturation={this.is_facturation}
                                            checkedEnvoi={checkedEnvoi}
                                            handleInputById={this.handleInputById}
                                            checkedFacturation={checkedFacturation}
                                        />
                                    })
                                    */
                                    sites.map((item, idx) => {
                                        const id_prospect = item.id
                                        return <React.Fragment key={idx} >
                                            <div className="mt-5">
                                            <div className={`form-group site_souscription mt-1 site_${item.id}`}>
                                                <h4>
                                                    { item.name === null ?
                                                        item.displayable_supply_address
                                                    : item.name + ', ' + item.postcode
                                                    }
                                                </h4>
                                                <div className="frm_fourniture">
                                                    <p>Adresse de fourniture <span className="red_star">*</span></p>
                                                    <input required className="simple_input txt_c_p" value={(item.address_supply)} type="text" name="address_supply" onChange={(e) => this.handleInputById(item.id, e)} />
                                                    {/*
                                                    <span className="info_addr_four" data-class="own_tooltip" data-place="right" data-effect="solid" data-tip="L’adresse de fourniture est le lieu de consommation du gaz">i</span>
                                                    <ReactTooltip />
                                                    */}
                                                    <Tooltip title="L’adresse de fourniture est le lieu de consommation du gaz." position={widthSize > 576 ? "right" : "bottom"} trigger={widthSize > 576 ? "mouseenter" : "click"}>
                                                        <span className="info_postal btn_mobile info_addr_envoi" data-class="own_tooltip">i</span>
                                                    </Tooltip>
                                                    <p className="small-text-help mt-2 address-text">Ex : 101 mon adresse, 06110 Lyon ou <span>101 mon adresse, mon complement, 06110 Lyon</span></p>
                                                    {
                                                        this.state.sitesValidation[id_prospect] && this.state.sitesValidation[id_prospect].address_supply ?
                                                        <p className="text-success small-text-help">L'adresse est normalisée.</p>
                                                        :
                                                        <p className="text-danger small-text-help">{addressTextError}</p>
                                                    }
                                                    {
                                                        /*
                                                        addressTypeError === 'address_supply' && item.id === addressProspectError ?
                                                            <p className={addressCode === 200 ? "text-success small-text-help" : "text-danger small-text-help"}>{addressTextError}</p>
                                                        : null
                                                        */
                                                    }
                                                </div>
                                                <div className="frm_envoi">
                                                    <label onClick={this.is_envoi} className={(checkedEnvoi.get(`check_envoi_${item.id}`) ? 'check_2 mt-4 active' : 'check_2 mt-4')} htmlFor={`check_envoi_${item.id}`}>
                                                        {(checkedEnvoi.get(`check_envoi_${item.id}`) ?
                                                            <img src={check_2_active} alt="Endesa S.A." />
                                                            :
                                                            <img src={check_2} alt="Endesa S.A." />
                                                        )}
                                                        <input
                                                            type="checkbox"
                                                            value={item.id}
                                                            name={`check_envoi_${item.id}`}
                                                            id={`check_envoi_${item.id}`}
                                                            onChange={this.is_envoi}
                                                            checked={checkedEnvoi.get(`check_envoi_${item.id}`) || false}
                                                        />
                                                        <p>Cochez cette case si vous avez une adresse d’envoi différente pour vos factures</p>
                                                    </label>
                                                    {(checkedEnvoi.get(`check_envoi_${item.id}`) ?
                                                        <React.Fragment>
                                                            <p>Adresse d’envoi de la facture</p>
                                                            <input className="simple_input txt_c_p" required value={item.address_send ? item.address_send : ''} type="text" name="address_send" onChange={(e) => this.handleInputById(item.id, e)} />
                                                            {/*
                                                            <span className="info_addr_envoi" data-class="own_tooltip" data-place="right" data-effect="solid" data-tip="Il s’agit de l’adresse à laquelle vous souhaitez recevoir la facture papier.">i</span>
                                                            <ReactTooltip />
                                                            */}
                                                            <Tooltip title="Il s’agit de l’adresse à laquelle vous souhaitez recevoir la facture papier." position={widthSize > 576 ? "right" : "bottom"} trigger={widthSize > 576 ? "mouseenter" : "click"}>
                                                                <span className="info_postal btn_mobile info_addr_envoi" data-class="own_tooltip">i</span>
                                                            </Tooltip>
                                                            <p className="small-text-help mt-2 address-text">Ex : 101 mon adresse, 06110 Lyon ou <span>101 mon adresse, mon complement, 06110 Lyon</span></p>
                                                            {
                                                                /*
                                                                item.address_send === '' || item.address_send === null ?
                                                                    <p className="text-danger small-text-help error_test">Ce champs ne doit pas être vide</p>
                                                                : null
                                                                */
                                                            }
                                                            {
                                                                this.state.sitesValidation[id_prospect] && this.state.sitesValidation[id_prospect].address_send ?
                                                                <p className="text-success small-text-help">L'adresse est normalisée.</p>
                                                                :
                                                                <p className="text-danger small-text-help">{addressTextError}</p>
                                                            }
                                                            {
                                                                /*
                                                                addressTypeError === 'address_send' && item.id === addressProspectError ?
                                                                    <p className={addressCode === 200 ? "text-success small-text-help" : "text-danger small-text-help"}>{addressTextError}</p>
                                                                : null
                                                                */
                                                            }
                                                        </React.Fragment>
                                                        : '')}
                                                </div>
                                                <div className="frm_facturation">
                                                    <label onClick={this.is_facturation} className={(checkedFacturation.get(`adresse_facturation_${item.id}`) ? 'check_2 mt-4 active' : 'check_2 mt-4')} htmlFor={`adresse_facturation_${item.id}`}>
                                                        {(checkedFacturation.get(`adresse_facturation_${item.id}`) ?
                                                            <img src={check_2_active} alt="Endesa S.A." />
                                                            :
                                                            <img src={check_2} alt="Endesa S.A." />
                                                        )}
                                                        <input
                                                            type="checkbox"
                                                            value={item.id}
                                                            name={`adresse_facturation_${item.id}`}
                                                            id={`adresse_facturation_${item.id}`}
                                                            onChange={this.is_facturation}
                                                            checked={checkedFacturation.get(`adresse_facturation_${item.id}`) || false}
                                                        />
                                                        <p>Cochez cette case si vous avez une adresse de facturation différente</p>
                                                    </label>
                                                    {(checkedFacturation.get(`adresse_facturation_${item.id}`) ?
                                                        <React.Fragment>
                                                            <p>Adresse de facturation</p>
                                                            <input className="simple_input txt_c_p" required value={item.address_billing ? item.address_billing : ''} type="text" name="address_billing" onChange={(e) => this.handleInputById(item.id, e)} />
                                                            {/*
                                                            <Tooltip title="l'adresse de fourniture est le lieu de consommation du gaz." position={widthSize > 576 ? "right" : "bottom"} trigger={widthSize > 576 ? "mouseenter" : "click"}>
                                                                <span className="info_postal btn_mobile info_addr_envoi" data-class="own_tooltip">i</span>
                                                            </Tooltip>
                                                            */}
                                                            <p className="small-text-help mt-2 address-text">Ex : 101 mon adresse, 06110 Lyon ou <span>101 mon adresse, mon complement, 06110 Lyon</span></p>
                                                            {
                                                                /*
                                                                item.address_billing === '' || item.address_billing === null ?
                                                                    <p className="text-danger small-text-help error_test">Ce champs ne doit pas être vide</p>
                                                                : null
                                                                */
                                                            }
                                                            {
                                                                this.state.sitesValidation[id_prospect] && this.state.sitesValidation[id_prospect].address_billing ?
                                                                <p className="text-success small-text-help">L'adresse est normalisée.</p>
                                                                :
                                                                <p className="text-danger small-text-help">{addressTextError}</p>
                                                            }
                                                            {
                                                                /*
                                                                addressTypeError === 'address_billing' && item.id === addressProspectError ?
                                                                    <p className={addressCode === 200 ? "text-success small-text-help" : "text-danger small-text-help"}>{addressTextError}</p>
                                                                : null
                                                                */
                                                            }
                                                        </React.Fragment>
                                                        : '')}
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                    })
                                }
                                <div className="form-group divergent-infos">
                                    <Row>
                                        <Col xs={12} className="noPadding mb-4">
                                            <label className={(payeurDivergent ? 'check_2 mt-4 active' : 'check_2 mt-4')}>
                                                <input
                                                    type="checkbox"
                                                    name="payeur"
                                                    id="payeur"
                                                    value="payeur"
                                                    onChange={this.checkPayeur}
                                                    checked={payeurDivergent || false}
                                                />
                                                <p>
                                                    {(payeurDivergent ?
                                                        <img src={check_2_active} alt="Endesa S.A." />
                                                        :
                                                        <img src={check_2} alt="Endesa S.A." />
                                                    )}
                                                    Cocher cette case si le payeur est divergent
                                                </p>
                                            </label>
                                            <Tooltip title="Le payeur divergent est également appelé tiers payeur, payeur délégué, ou payeur alternatif. Cela veut dire que le payeur n’est pas le titulaire du contrat. Les factures sont payées par une entreprise tierce." position={widthSize > 576 ? "right" : "bottom"} trigger={widthSize > 576 ? "mouseenter" : "click"}>
                                                <span className="info_postal btn_mobile info" data-class="own_tooltip">i</span>
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                    {
                                        payeurDivergent ?
                                            <React.Fragment>
                                                <Row>
                                                    <div className="infot-text-conso etablissement-wrapper">
                                                        <div className="icon-help">
                                                            <img src={icon_help} alt="endesa etablissement" />
                                                        </div>
                                                        <div className="etablissement-text">
                                                            <p>
                                                            Lorsque vous aurez signé votre contrat, le payeur divergent recevra un email qui lui permettra de signer le mandat de prélèvement via une signature électronique sécurisée.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6} className="noPadding mt-4">
                                                        <p>Nom du payeur divergent <span className="red_star">*</span></p>
                                                        <input type="text" name="divergent_payer_nom" value={divergent_payer_nom} className="simple_input" onChange={this.handleInput} />
                                                    </Col>
                                                    <Col xs={12} md={6} className="noPadding mt-4">
                                                        <p>Prénom du payeur divergent <span className="red_star">*</span></p>
                                                        <input type="text" name="divergent_payer_prenom" value={divergent_payer_prenom} className="simple_input" onChange={this.handleInput} />
                                                    </Col>
                                                    <Col xs={12} md={6} className="noPadding mt-4">
                                                        <p style={{display: 'inline-block'}}>Téléphone portable<span className="red_star">*</span></p>
                                                        <PhoneInput
                                                            inputProps={{ name: 'divergent_payer_phone', className: 'simple_input_phone' }}
                                                            masks={{ ma: '. ..-..-..-..' }}
                                                            country={'fr'}
                                                            countryCodeEditable={false}
                                                            value={divergent_payer_phone}
                                                            /*onChange={divergent_payer_phone => this.setState({ divergent_payer_phone })}*/
                                                            onChange={(value, country) => {
                                                                const threeDigits = value.slice(0, 3);
                                                                if(country.dialCode === '33'){
                                                                    if( threeDigits === '336' || threeDigits === '337'){
                                                                        this.setState({ divergent_payer_phone: value, phoneDigitsDivergent: true });
                                                                    }else{
                                                                        this.setState({divergent_payer_phone: country.dialCode, phoneDigitsDivergent: false})
                                                                    }
                                                                }else{
                                                                    this.setState({ divergent_payer_phone: value, phoneDigitsDivergent: true });
                                                                }
                                                            }}
                                                        />
                                                        <Tooltip title="Seuls les numéros de téléphones portables sont acceptés. Le payeur divergent recevra un code par SMS pour authentifier la signature du mandat de prélèvement." position={widthSize > 576 ? "right" : "bottom"} trigger={widthSize > 576 ? "mouseenter" : "click"}>
                                                            <span className="info_postal btn_mobile info_addr_envoi infos-phone-divergent" data-class="own_tooltip">i</span>
                                                        </Tooltip>
                                                        {
                                                            !phoneDigitsDivergent ?
                                                            <p className="text-danger small-text-help mt-2">Merci de saisir un numéro de téléphone portable</p>
                                                            : null
                                                        }
                                                    </Col>
                                                    <Col xs={12} md={6} className="noPadding mt-4">
                                                        <p>Email <span className="red_star">*</span></p>
                                                        <input type="mail" name="divergent_payer_email" value={divergent_payer_email} className="simple_input" onChange={this.handleInput} />
                                                    </Col>
                                                    <Col xs={12} md={6} className="noPadding mt-4">
                                                        <p>Raison sociale <span className="red_star">*</span></p>
                                                        <input type="text" name="divergent_payer_name" value={divergent_payer_name} className="simple_input" onChange={this.handleInput} />
                                                        {
                                                            errorRS.length > 0 ?
                                                                errorRS.map((message, idx) => {
                                                                    return (
                                                                        <p key={idx} className="text-danger small-text-help mt-2">{message}</p>
                                                                    )
                                                                })
                                                                : null
                                                        }
                                                    </Col>
                                                    <Col xs={12} md={6} className="noPadding marg_top_input mt-4">
                                                        <p>SIRET/SIREN <span className="red_star">*</span></p>
                                                        <input type="text" name="divergent_payer_siret" value={divergent_payer_siret} className="simple_input" onChange={this.handleInput} />
                                                        {
                                                            errorSiret.length > 0 ?
                                                                errorSiret.map((message, idx) => {
                                                                    return (
                                                                        <p key={idx} className="text-danger small-text-help mt-2">{message}</p>
                                                                    )
                                                                })
                                                                : null
                                                        }
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                            : null
                                    }
                                </div>
                                <div className="form-group mt-5">
                                    <p>IBAN <span className="red_star">*</span></p>
                                    {/*<NumberFormat className="simple_input col-xs-12 col-md-7" name="iban" format="#### #### #### #### #### #### ####" mask="X" onChange={this.handleInput} />*/}
                                    <input type="text" name="iban" value={iban} onChange={this.handleIbanChange} className="simple_input" />
                                    <br />
                                    {
                                        errorIban.length > 0 ?
                                            errorIban.map((message, idx) => {
                                                return (
                                                    <p key={idx} className="text-danger small-text-help mt-2">{message}</p>
                                                )
                                            })
                                            : null
                                    }
                                    {/*result.succeeded && iban !== '' ? (
                                        <p className="small-text-help mt-2 text-success">{result.errorMessage} Format de votre IBAN est valide.</p>
                                    ) : (
                                            <p className="small-text-help mt-2 text-danger">Format {result.errorMessage}</p>
                                    )*/}
                                    <Row>
                                        <Col xs={12} md={6} className="noPadding mt-5">
                                            <p>Code SWIFT <span className="red_star">*</span></p>
                                            <input type="text" name="code_swift" value={code_swift} className="simple_input" onChange={this.handleInput} />
                                            {
                                                errorSwift.length > 0 ?
                                                    errorSwift.map((message, idx) => {
                                                        return (
                                                            <p key={idx} className="text-danger small-text-help mt-2">{message}</p>
                                                        )
                                                    })
                                                    : null
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={6} className="noPadding mt-5">
                                            <p>RIB <span className="red_star">*</span></p>
                                            <div className="wrapper-uploaded-files">
                                                <div className="upload-file">
                                                    <FileBase64
                                                    multiple={ false }
                                                    onDone={ this.getFiles.bind(this) } />
                                                    <p className="small-text-help mt-2 upload-text">{fileName}</p>
                                                </div>
                                                <Tooltip title={`Format valide : PDF uniquement.</br>Taille limite de la pièce jointe : ${Math.trunc(fileMaxSize) / 1024} Mo.`} position={widthSize > 576 ? "right" : "bottom"} trigger={widthSize > 576 ? "mouseenter" : "click"}>
                                                    <span className="btn_mobile info_files" data-class="own_tooltip">i</span>
                                                </Tooltip>
                                            </div>
                                            {
                                                errorRibFile.length > 0 ?
                                                errorRibFile.map((message, idx) => {
                                                    return(
                                                        <p className="text-danger small-text-help mt-2">{errorRibFile}</p>
                                                    )
                                                })
                                                : null
                                            }

                                        </Col>
                                    </Row>
                                    {
                                        type_files.length > 0 ?
                                            <Row>
                                                <Col xs={12} md={12} className="noPadding">
                                                    <div className="form-group mt-5">
                                                        <p>Charger un fichier facultatif ?</p>
                                                        <div className="parent-file-upload" id="js-search-suggest-wrapper" >
                                                            {
                                                                uploadedFiles.map((item, idx) => {
                                                                    return (
                                                                        <div key={idx} className="wrapper-postal wrapper-files-upload">
                                                                            <Col xs={12} md={6} className="noPadding">
                                                                                <div className="select dropdown-postal wrapper-file-upload dropdown-type-file" onClick={(e) => this.trigger_upload_files(idx)}>
                                                                                    <input className="toggle" readOnly type="text" />
                                                                                    <div className="arrow">
                                                                                        {(showDropdown[idx] ?
                                                                                            <i className="fas fa-chevron-up"></i>
                                                                                        : <i className="fas fa-chevron-down"></i>)}
                                                                                    </div>
                                                                                    <div className="selected">
                                                                                        {
                                                                                            selectedFile[idx].type === 'Selectionner un type' ?
                                                                                            <div className="value">Sélectionner un type</div>
                                                                                            :
                                                                                            <div className="value">{selectedFile[idx].type}</div>
                                                                                        }
                                                                                    </div>
                                                                                    <div className={`dropdown ${(showDropdown[idx] ? '' : 'hide-drop')}`}>
                                                                                        {
                                                                                            type_files.map((item, index) => {
                                                                                                return <p key={index} className="value" onClick={(e) => this.trigger_selected_file(item, idx)}>{item.name}</p>;
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                            <Col xs={12} md={6} className="noPadding">
                                                                                <div className="wrapper-uploaded-files">
                                                                                    <div className="input_file_upload input_upload_selected_file">
                                                                                        <div className="upload-file upload-file-foreign">
                                                                                            <FileBase64
                                                                                            multiple={ false }
                                                                                            onDone={ this.foreignFiles.bind(this,idx) } />
                                                                                            <p className="small-text-help mt-2 upload-text name-uploaded-text">{selectedFile[idx].file_name}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        /*
                                                                                        idx > 0 ?
                                                                                            <i className="fas fa-times cursor-file close-btn" onClick={(e) => this.removeFile(idx)}></i>
                                                                                        : null
                                                                                        */
                                                                                    }
                                                                                    {
                                                                                        idx > 0 ?
                                                                                            <i className="fas fa-times cursor-file close-btn" onClick={(e) => this.removeFile(idx)}></i>
                                                                                        : idx === 0 && selectedFile[0].base_64 !== null || selectedFile[0].type !== 'Selectionner un type' ?
                                                                                            <i className="fas fa-times cursor-file close-btn" onClick={(e) => this.removeFirstFile(0)}></i>
                                                                                        : null
                                                                                    }
                                                                                    <Tooltip title={`Formats des fichiers acceptés :${supportedFiles.map((extension) => {
                                                                                        return ' ' + extension 
                                                                                    })}.</br>Taille limite des pièces jointes : ${Math.trunc(fileMaxSize) / 1024} Mo.`} position={widthSize > 576 ? "right" : "bottom"} trigger={widthSize > 576 ? "mouseenter" : "click"}>
                                                                                        <span className="btn_mobile info_files" data-class="own_tooltip">i</span>
                                                                                    </Tooltip>
                                                                                </div>
                                                                                {
                                                                                    errorForeignFiles.length > 0 ?
                                                                                        errorForeignFiles.filter((error) => parseInt(error.id_file) === parseInt(selectedFile[idx].id_file)).map((type) => {
                                                                                            return(
                                                                                                <p className="text-danger small-text-help mt-2">{type.message}</p>
                                                                                            )
                                                                                        })
                                                                                    : null
                                                                                }

                                                                            </Col>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            {
                                                                selectedFile[selectedFile.length - 1].type === 'Selectionner un type' || selectedFile[selectedFile.length - 1].base_64 === null ?
                                                                    null
                                                                : <p className="mt-2 cursor-file add-file-btn" onClick={(e) => this.incrementFile()}>Ajouter un nouveau fichier</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        : null
                                    }
                                </div>
                                <div className="form-group mt-5">
                                    <p>Comment souhaitez-vous recevoir vos factures ? <span className="red_star">*</span></p>
                                    <Row>
                                        <Col xs={12} md={6} className="noPadding">
                                            <label className={(format_facture === 'email' ? 'radio_5 radio_6 active' : 'radio_5 radio_6')} htmlFor="format_facture_1">
                                                <input id="format_facture_1" name="format_facture" value="email" type="radio" onChange={(e) => this.handleFormatFacture(e)} />
                                                <span style={{ paddingTop: '12px' }}>Format électronique (email)</span>
                                            </label>
                                        </Col>
                                        <Col xs={12} md={6} className="noPadding">
                                            <label className={(format_facture === 'postal_route' ? 'radio_5 radio_6 active' : 'radio_5 radio_6')} htmlFor="format_facture_2">
                                                <input id="format_facture_2" name="format_facture" value="postal_route" type="radio" onChange={(e) => this.handleFormatFacture(e)} />
                                                <span style={{ paddingTop: '12px' }}>Format papier (voie postale)</span>
                                            </label>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="form-group mt-5">
                                    <Row>
                                        <Col xs={12} md={12} className="noPadding">
                                            <label className={(is_autorise ? 'check_2 mt-4 active' : 'check_2 mt-4')} htmlFor="autorise">
                                                <input
                                                    type="checkbox"
                                                    name="autorise"
                                                    id="autorise"
                                                    value="autorise"
                                                    onChange={this.checkAutorise}
                                                    checked={is_autorise || false}
                                                />
                                                <p>
                                                    {(is_autorise ?
                                                        <img src={check_2_active} alt="Endesa S.A." />
                                                        :
                                                        <img src={check_2} alt="Endesa S.A." />
                                                    )}
                                                    J’autorise ENDESA ENERGÍA S.A. à prélever sur le compte bancaire indiqué ci-dessus les sommes
                                                    dues au titre de mon contrat selon les modalités prévues dans mon contrat. J’autorise ma banque à
                                                    accepter les prélèvements automatiques de ENDESA ENERGÍA S.A. <span className="red_star">*</span>
                                                </p>
                                            </label>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="form-group mt-5"><p> </p>
                                    <input onSubmit={this.handleSubmit}
                                    disabled={ civility !== '' && civility !== null && last_name !== '' && first_name !== '' && telephone !== '' && telephone.length > 10 && email !== '' && iban !== '' && code_swift !== '' && is_autorise !== false
                                     && format_facture !== '' && payeurDivergent !== true && btnAdresseDisabled === false && files.base64 !== undefined ? false
                                    : civility !== '' && civility !== null && last_name !== '' && first_name !== '' && telephone !== '' && telephone.length > 10 && email !== '' && iban !== '' && code_swift !== '' && is_autorise !== false
                                    && format_facture !== '' && payeurDivergent === true && divergent_payer_name !== '' && divergent_payer_siret !== '' && divergent_payer_nom !== '' && divergent_payer_prenom !== '' && divergent_payer_phone.length > 10 && divergent_payer_email !== '' && btnAdresseDisabled === false && files.base64 !== undefined ? false
                                    : true}
                                    type="submit" name="btn_submit" className="btn_next_continue btn_souscription btn_submit" value="Continuer" />
                                    {
                                        isReadOnly ?
                                            <label className="reset-data btn_reset" onClick={this.resetData}><i className="fas fa-undo"></i>Réinitialiser la souscription</label>
                                            :
                                            <label id='souscription_previous' className="previous" onClick={this.go_back}><i className="fas fa-chevron-left"></i>Étape précédente</label>
                                    }
                                </div>
                                <ReCaptcha
                                    ref={ref => this.recaptcha = ref}
                                    sitekey={site_key}
                                    action={this.updateToken}
                                    verifyCallback={this.verifyCallback}
                                />
                            </form>
                        </Col>
                    </Row>
                    {
                        this.state.errorCounter > 3 && this.state.showPopupError ?
                        <React.Fragment>
                            <Modal dialogClassName="modal-90w" size="lg" centered show={this.state.show} onHide={this.handleClose}>
                                <PopupError
                                text='Close Me'
                                classFull= {true}
                                close_popup= {this.close_popup.bind(this)}
                                closePopup={this.togglePopupError.bind(this)}
                                />
                            </Modal>
                        </React.Fragment>
                        : null
                    }
                </Container>
                }
            </React.Fragment>
        );
    }
}
function mapStateToProps({ previous, informations_client, payeurs_divergents, verify_address, pre_verified_address, all_file_types, all_subs}) {
    return { previous, informations_client, payeurs_divergents, verify_address, pre_verified_address, all_file_types, all_subs };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        get_previous: get_previous,
        donnees_client: donnees_client,
        get_payeurs_divergents: get_payeurs_divergents,
        get_verify_address: get_verify_address,
        get_pre_verified_address: get_pre_verified_address,
        get_file_types: get_file_types,
        get_numbers_subs: get_numbers_subs
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Souscription);
