import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { saisie_pce_estimation } from '../../actions/estimation';
import { validatePce } from '../../actions/validate_pce';
import { valider_pce } from '../../actions/valide_pce';
import { get_previous } from '../../actions/previous';
import { get_reset_subscription } from '../../actions/reset_subscription';
import { get_anciennete } from '../../actions/buildings_age';
import { get_type_chaudiere } from '../../actions/type_chaudiere';
import { get_numbers_subs } from '../../actions/all_subs';
import { bindActionCreators } from 'redux';
import { current_step, subscription_id } from '../../services/status';
import { Container, Row, Col } from 'react-bootstrap';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import fr from 'date-fns/locale/fr';
import FactureModal from './facture';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import './consommation.css';
import swal from 'sweetalert';
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";
import { addDays } from 'date-fns';
import PopupError from '../aide/PopupError'
import {Modal, Button} from "react-bootstrap"
import PopupPCE from '../aide/PopupPCE'
import icon_help from "../../assets/img/calc.svg";

class Consommation extends Component {
    constructor(props) {
        super(props);
        const newdate = new Date();
        newdate.setDate(newdate.getDate() + 6);
        registerLocale('fr', fr)
        this.state = {
            consommations: (localStorage.getItem('next') ? JSON.parse(localStorage.getItem('next')) : JSON.parse(localStorage.getItem('current_step_continue'))),
            //minDate: newdate,
            startDate: [],
            current_returned_prospect: [],
            is_ready_only: false,
            show_facture_modal: false,
            edit_pce: false,
            frm_data: {},
            liste_site: [],
            data: [],
            currentDate: newdate,
            minDate: '',
            maxDate: '',
            pce_entered: [],
            estimationList: [],
            current_prospect: '',
            isLoading: false,
            errorPCE: [],
            currentPCE: '',
            currentFacture: '',
            facture_values: {},
            list_anciennete: [],
			list_type_chaudiere: [],
            site_key: process.env.REACT_APP_TAG_RECAPTCHA_V3 ? process.env.REACT_APP_TAG_RECAPTCHA_V3 : '',
            token: '',
            errorCounter: 0,
            showPopupError: false,
            startDateBack: '2021/07/25',
            show: false,
            showBackButton: false,
            showPce: false,
            selected_site_facture: [],
            dateTextError: []
        }
        this.maFacture = this.maFacture.bind(this);
    }
    handleClose = () => {
        this.setState({
            show: false,
            show_facture_modal: false,
            showPopupError: false,
            showPce: false
        })
    }

    handleShow  = () => {
        this.setState({
            show: true
        })
    }
    togglePopup = () => {
        const {showPce} = this.state
        this.setState({
            showPce: !showPce
        })
        console.log('yees')
    };
    componentWillMount(){
        const { current_returned_prospect, startDateBack, currentDate} = this.state;
        const {get_type_chaudiere, get_anciennete, get_numbers_subs} = this.props
        window.dataLayer.push({              
            pageCategory: 'Consommation',
            event: 'gtm.load.Consommation',             
        });
        //const newdate = new Date(startDateBack);
        //newdate.setDate(newdate.getDate() + 6);
        //const startdate = newdate;
        //console.log('newdate : ', newdate)
        let date_array = [];
        let pce_array = [];
		get_type_chaudiere();
		get_anciennete();
        get_numbers_subs();

        if (localStorage.getItem('current_returned_prospect')) {
            let list_prospect = current_returned_prospect;
            list_prospect.push(JSON.parse(localStorage.getItem('current_returned_prospect')));
            current_returned_prospect.push(JSON.parse(localStorage.getItem('current_returned_prospect')));
            this.setState({
                //current_returned_prospect: list_prospect
                is_ready_only: JSON.parse(localStorage.getItem('current_step_continue')).data.is_readonly ? JSON.parse(localStorage.getItem('current_step_continue')).data.is_readonly : JSON.parse(localStorage.getItem('current_step_continue')).is_readonly,
                liste_site: JSON.parse(localStorage.getItem('current_step_continue')).data.data ? JSON.parse(localStorage.getItem('current_step_continue')).data.data : JSON.parse(localStorage.getItem('current_step_continue')).data ,
                current_returned_prospect: JSON.parse(localStorage.getItem('current_step_continue')).data.data ? JSON.parse(localStorage.getItem('current_step_continue')).data.data.filter(item => item.date_start_supply !== null) : JSON.parse(localStorage.getItem('current_step_continue')).data.filter(item => item.date_start_supply !== null),
                consommations: JSON.parse(localStorage.getItem('current_step_continue')).data.data ? JSON.parse(localStorage.getItem('current_step_continue')).data.data : JSON.parse(localStorage.getItem('current_step_continue')).data
            })
            date_array.length = JSON.parse(localStorage.getItem('current_step_continue')).data.data.length;
            for(let i=0;i<JSON.parse(localStorage.getItem('current_step_continue')).data.data.length;i++){
                JSON.parse(localStorage.getItem('current_step_continue')).data.data[i].pce ? pce_array.push(JSON.parse(localStorage.getItem('current_step_continue')).data.data[i].pce) : pce_array.push('')

                date_array[i] = JSON.parse(localStorage.getItem('current_step_continue')).data.data[i].date_start_supply === null ? null : new Date(JSON.parse(localStorage.getItem('current_step_continue')).data.data[i].date_start_supply)
            }
        }
        if (localStorage.getItem('next')) {
            this.setState({
                is_ready_only: JSON.parse(localStorage.getItem('next')).is_readonly,
                liste_site: JSON.parse(localStorage.getItem('next')).data,
                current_returned_prospect: JSON.parse(localStorage.getItem('next')).data.filter(item => item.date_start_supply !== null),
                consommations: JSON.parse(localStorage.getItem('next'))
            })
            date_array.length = JSON.parse(localStorage.getItem('next')).data.length;
            for(let i=0;i<JSON.parse(localStorage.getItem('next')).data.length;i++){
                JSON.parse(localStorage.getItem('next')).data[i].pce ? pce_array.push(JSON.parse(localStorage.getItem('next')).data[i].pce) : pce_array.push('')
                date_array[i] = JSON.parse(localStorage.getItem('next')).data[i].date_start_supply === null ? null : new Date(JSON.parse(localStorage.getItem('next')).data[i].date_start_supply)
            }
        }
        // GET VALUE OF READ ONLY WHEN CLICKED PREVIOUS
        if (localStorage.getItem('previous')) {
            this.setState({
                is_ready_only: JSON.parse(localStorage.getItem('previous')).is_readonly,
                liste_site: JSON.parse(localStorage.getItem('previous')).data,
                current_returned_prospect: JSON.parse(localStorage.getItem('previous')).data.filter(item => item.date_start_supply !== null),
                consommations: JSON.parse(localStorage.getItem('previous'))
            })
            date_array.length = JSON.parse(localStorage.getItem('previous')).data.length;
            for(let i=0;i<JSON.parse(localStorage.getItem('previous')).data.length;i++){
                JSON.parse(localStorage.getItem('previous')).data[i].pce ? pce_array.push(JSON.parse(localStorage.getItem('previous')).data[i].pce) : pce_array.push('')
                date_array[i] = JSON.parse(localStorage.getItem('previous')).data[i].date_start_supply === null ? null : new Date(JSON.parse(localStorage.getItem('previous')).data[i].date_start_supply)
            }
        }
        
        /*if (localStorage.getItem('current_step_continue')) {
            this.setState({
                is_ready_only: JSON.parse(localStorage.getItem('current_step_continue')).is_readonly,
                liste_site: JSON.parse(localStorage.getItem('current_step_continue')).data,
                current_returned_prospect: JSON.parse(localStorage.getItem('current_step_continue')).data.filter(item => item.date_start_supply !== null),
                consommations: JSON.parse(localStorage.getItem('current_step_continue'))
            })
            date_array.length = JSON.parse(localStorage.getItem('current_step_continue')).data.length;
            for(let i=0;i<JSON.parse(localStorage.getItem('current_step_continue')).data.length;i++){
                JSON.parse(localStorage.getItem('current_step_continue')).data[i].pce ? pce_array.push(JSON.parse(localStorage.getItem('current_step_continue')).data[i].pce) : pce_array.push('')
            }
        }*/
        if (localStorage.getItem('current_step_continue')) {
            if(JSON.parse(localStorage.getItem('current_step_continue')).data.step === 'saisie_pce_estimation'){
                this.setState({
                    is_ready_only: JSON.parse(localStorage.getItem('current_step_continue')).data.is_readonly,
                    liste_site: JSON.parse(localStorage.getItem('current_step_continue')).data.data,
                    current_returned_prospect: JSON.parse(localStorage.getItem('current_step_continue')).data.data.filter(item => item.date_start_supply !== null),
                    consommations: JSON.parse(localStorage.getItem('current_step_continue')).data
                })
                date_array.length = JSON.parse(localStorage.getItem('current_step_continue')).data.data.length;
                for(let i=0;i<JSON.parse(localStorage.getItem('current_step_continue')).data.data.length;i++){
                    JSON.parse(localStorage.getItem('current_step_continue')).data.data[i].pce ? pce_array.push(JSON.parse(localStorage.getItem('current_step_continue')).data.data[i].pce) : pce_array.push('')
                    date_array[i] = JSON.parse(localStorage.getItem('current_step_continue')).data.data[i].date_start_supply === null ? null : new Date(JSON.parse(localStorage.getItem('current_step_continue')).data.data[i].date_start_supply)
                }
            }
        }
        //date_array.fill(startdate)
        //console.log('array : ', date_array)
        this.setState({
            startDate : date_array,
            pce_entered : pce_array,
        })
        //console.log('will mount : ', this.state.startDate)
    }
    componentWillUpdate(prevProps) {
        const { previous } = this.props;
        // Loader
        if (prevProps.previous !== previous) {
            this.setState({
                isLoading: true
            });
        }
    }
    componentDidMount(){
        const {site_key, startDate} = this.state;
        loadReCaptcha(site_key, this.verifyCallback);
        //console.log('startDate : ', startDate)
    }
    updateToken = () => {
        // you will get a new token in verifyCallback
        this.recaptcha.execute()
    };
    verifyCallback = (recaptchaToken) => {
        // Here you will get the final recaptchaToken!!!  
        this.setState({
            token: recaptchaToken
        });
    }

    componentDidUpdate(prevProps) {
        // Enable navigation prompt
        window.onbeforeunload = function() {
            return true;
        };
        var errors = this.state.errorPCE;
        //var errors_date = this.state.dateTextError
        var pceEntered = this.state.pce_entered;
        const { current_returned_prospect, edit_pce, errorCounter, currentPCE } = this.state;
        const { previous, estimation, validated_pce, valide_pce, reset_subscription, all_subs } = this.props;
        //console.log('didupdate ; ', this.state.startDate)
        // GET BUILDING AGE AND CHAUDIERE TYPE
        const { building_age, chaudiere_type } = this.props;
		if (prevProps.building_age !== building_age) {
			this.setState({
				list_anciennete: building_age.result.data ? building_age.result.data : []
			})
        }
        // DATE LIMITS
        if (prevProps.all_subs !== all_subs) {
            all_subs.result.data.map((itm, indx) => {
                // MIN DATE
                if (itm.param === "SUBSCRIPTION_PCE_DAYS_LIMIT") {
                    this.setState({
                        minDate: itm.value
                    })
                }
                // MAX DATE
                if (itm.param === "SUBSCRIPTION_PCE_DAYS_MAX_LIMIT") {
                    this.setState({
                        maxDate: itm.value
                    })
                }
            })
        }
		if (prevProps.chaudiere_type !== chaudiere_type) {
			this.setState({
				list_type_chaudiere: chaudiere_type.result.data ? chaudiere_type.result.data : []
			})
        }
        
        // GET NEW KEY WHEN RESET SUBS
        if (prevProps.reset_subscription !== reset_subscription) {
            // Remove navigation prompt
            window.onbeforeunload = null;
            let newKey = reset_subscription.result.data.subscription_id;
            //window.location.assign(newKey)
            window.location.pathname = newKey + '/start'
        }

        // VALIDATION PCE ENTERED
        if (prevProps.valide_pce !== valide_pce) {
            let next = localStorage.getItem('next') ? JSON.parse(localStorage.getItem('next')) : localStorage.getItem('previous') ? JSON.parse(localStorage.getItem('previous')) : JSON.parse(localStorage.getItem('current_step_continue')).data  ;
            let next2 = localStorage.getItem('current_step_continue') ? JSON.parse(localStorage.getItem('current_step_continue')) : null ;
            if (valide_pce.result.status === 200) {
                window.dataLayer.push({
                    event: 'gtm.click.pcevalid',                
                });
                errors[this.state.currentPCE] = ''
                //errors_date[this.state.currentPCE] = ''
                let list_validated = current_returned_prospect;
                if (edit_pce) {
                    let current_index = list_validated.findIndex(item => item.prospect === valide_pce.result.data.data.prospect && item.date_start_supply === null);
                    let current_index2 = next.data.findIndex(item => item.prospect === valide_pce.result.data.data.prospect);
                    next.data[current_index2] = valide_pce.result.data.data;
                    if(localStorage.getItem('current_step_continue')){ next2.data = next };
                    localStorage.getItem('next') ? localStorage.setItem('next', JSON.stringify(next)) : localStorage.getItem('previous') ? localStorage.setItem('previous', JSON.stringify(next)) : localStorage.setItem('current_step_continue', JSON.stringify(next2)) ;

                    //list_validated.splice(current_index, 1);
                    list_validated.push(valide_pce.result.data.data);
                    this.setState({
                        edit_pce: false,
                        isLoading: false,
                        current_returned_prospect: current_returned_prospect,
                        liste_site: localStorage.getItem('next') ? JSON.parse(localStorage.getItem('next')).data : localStorage.getItem('previous') ? JSON.parse(localStorage.getItem('previous')).data : JSON.parse(localStorage.getItem('current_step_continue')).data.data  ,
                    })
                } else {
                    list_validated.push(valide_pce.result.data.data);

                    let current_index2 = next.data.findIndex(item => item.prospect === valide_pce.result.data.data.prospect);
                    next.data[current_index2] = valide_pce.result.data.data;
                    if(localStorage.getItem('current_step_continue')){ next2.data = next };
                    localStorage.getItem('next') ? localStorage.setItem('next', JSON.stringify(next)) : localStorage.getItem('previous') ? localStorage.setItem('previous', JSON.stringify(next)) : localStorage.setItem('current_step_continue', JSON.stringify(next2)) ;

                    this.setState({
                        isLoading: false,
                        current_returned_prospect: list_validated,
                        liste_site: localStorage.getItem('next') ? JSON.parse(localStorage.getItem('next')).data : localStorage.getItem('previous') ? JSON.parse(localStorage.getItem('previous')).data : JSON.parse(localStorage.getItem('current_step_continue')).data.data  ,
                    });
                }

                //return true;
            } else {
                //console.log('YEEES : ', valide_pce)
                errors[this.state.currentPCE] = (valide_pce.result.response.data.errorPCE ? valide_pce.result.response.data.errorPCE : '');
                //errors_date[this.state.currentPCE] = (valide_pce.result.response.data.errorPCE ? 'Merci de sélectionner une date de début de livraison.' : '');
                pceEntered[this.state.currentPCE] = '';
                if(errorCounter > 2){
                    this.setState({
                        showBackButton: true,
                        show: true,
                        currentFacture: currentPCE,
                        showPopupError: true,
                        errorCounter: errorCounter+1,
                        isLoading: false,
                        errorPCE: errors,
                        //errors_date: errors_date,
                        pce_entered: pceEntered,
                        liste_site: localStorage.getItem('next') ? JSON.parse(localStorage.getItem('next')).data : localStorage.getItem('previous') ? JSON.parse(localStorage.getItem('previous')).data : JSON.parse(localStorage.getItem('current_step_continue')).data.data  ,
                    })

                }else{
                    this.setState({
                        showBackButton: false,
                        errorCounter: errorCounter+1,
                        isLoading: false,
                        errorPCE: errors,
                        //errors_date: errors_date,
                        pce_entered: pceEntered,
                        liste_site: localStorage.getItem('next') ? JSON.parse(localStorage.getItem('next')).data : localStorage.getItem('previous') ? JSON.parse(localStorage.getItem('previous')).data : JSON.parse(localStorage.getItem('current_step_continue')).data.data  ,
                    });
                }
                // hide popup error
                /*
                swal({
                    title: "Un ou plusieurs champs sont incorrects. Veuillez vérifier les données saisies",
                    //text: valide_pce.result.response.data.errorPCE,
                    icon: "error",
                }).then(() => {
                    if(errorCounter > 2){
                        this.setState({
                            showBackButton: true,
                            show: true,
                            currentFacture: currentPCE,
                            showPopupError: true,
                            errorCounter: errorCounter+1,
                            isLoading: false,
                            errorPCE: errors,
                            //errors_date: errors_date,
                            pce_entered: pceEntered,
                            liste_site: localStorage.getItem('next') ? JSON.parse(localStorage.getItem('next')).data : localStorage.getItem('previous') ? JSON.parse(localStorage.getItem('previous')).data : JSON.parse(localStorage.getItem('current_step_continue')).data.data  ,
                        })

                    }else{
                        this.setState({
                            showBackButton: false,
                            errorCounter: errorCounter+1,
                            isLoading: false,
                            errorPCE: errors,
                            //errors_date: errors_date,
                            pce_entered: pceEntered,
                            liste_site: localStorage.getItem('next') ? JSON.parse(localStorage.getItem('next')).data : localStorage.getItem('previous') ? JSON.parse(localStorage.getItem('previous')).data : JSON.parse(localStorage.getItem('current_step_continue')).data.data  ,
                        });
                    }
                })
            */
            }
            //console.log(errors)
        }
        // VALIDATION ESTIMATION ENTERED
		if (prevProps.validated_pce !== validated_pce) {
            let next = localStorage.getItem('next') ? JSON.parse(localStorage.getItem('next')) : localStorage.getItem('previous') ? JSON.parse(localStorage.getItem('previous')) : JSON.parse(localStorage.getItem('current_step_continue')).data  ;
            let next2 = localStorage.getItem('current_step_continue') ? JSON.parse(localStorage.getItem('current_step_continue')) : null ;
            if(validated_pce.result.status === 200){
                window.dataLayer.push({
                    event: 'gtm.click.estimationvalid',                
                });
                let list_validated = current_returned_prospect;
                let pce_list = this.state.pce_entered;
                pce_list[this.state.currentFacture] = '';
                errors[this.state.currentFacture] = '';
                //errors_date[this.state.currentFacture] = '';
                this.setState({
                    pce_entered: pce_list,
                    errorPCE: errors,
                    //errors_date: errors_date,
                });
                if (edit_pce) {
                    
                    let current_index = list_validated.findIndex(item => item.prospect === validated_pce.result.data.data.prospect && item.date_start_supply === null);
                    //list_validated.splice(current_index, 1);
                    list_validated.push(validated_pce.result.data.data);
                    let current_index2 = next.data.findIndex(item => item.prospect === validated_pce.result.data.data.prospect);
                    next.data[current_index2] = validated_pce.result.data.data;
                    if(localStorage.getItem('current_step_continue')){ next2.data = next };
                    localStorage.getItem('next') ? localStorage.setItem('next', JSON.stringify(next)) : localStorage.getItem('previous') ? localStorage.setItem('previous', JSON.stringify(next)) : localStorage.setItem('current_step_continue', JSON.stringify(next2)) ;
                    this.setState({
                        edit_pce: false,
                        show_facture_modal: false,
                        isLoading: false,
                        current_returned_prospect: current_returned_prospect,
                        liste_site: localStorage.getItem('next') ? JSON.parse(localStorage.getItem('next')).data : localStorage.getItem('previous') ? JSON.parse(localStorage.getItem('previous')).data : JSON.parse(localStorage.getItem('current_step_continue')).data.data  ,
                    })
                } else {
                    list_validated.push(validated_pce.result.data.data);
                    let current_index2 = next.data.findIndex(item => item.prospect === validated_pce.result.data.data.prospect);
                    next.data[current_index2] = validated_pce.result.data.data;
                    if(localStorage.getItem('current_step_continue')){ next2.data = next };
                    localStorage.getItem('next') ? localStorage.setItem('next', JSON.stringify(next)) : localStorage.getItem('previous') ? localStorage.setItem('previous', JSON.stringify(next)) : localStorage.setItem('current_step_continue', JSON.stringify(next2)) ;
                    this.setState({
                        isLoading: false,
                        show_facture_modal: false,
                        current_returned_prospect: list_validated,
                        liste_site: localStorage.getItem('next') ? JSON.parse(localStorage.getItem('next')).data : localStorage.getItem('previous') ? JSON.parse(localStorage.getItem('previous')).data : JSON.parse(localStorage.getItem('current_step_continue')).data.data  ,
                    });
                }
            } else {
                this.setState({
                    isLoading: false,
                });
                /*
                swal({
                    title: "Errors",
                    icon: "error",
                }).then(() => {
                    this.setState({
                        isLoading: false,
                    });
                })
                */
            }
        }
        
        // NEXT STEP
        if (prevProps.estimation !== estimation) {
            // Remove navigation prompt
            window.onbeforeunload = null;
            if (estimation.result.status === 200) {
                localStorage.removeItem('current_step_continue');
                localStorage.removeItem('previous');
                localStorage.setItem('step', estimation.result.data.step);
                localStorage.setItem('subscription_id', estimation.result.data.subscription_id);
                localStorage.setItem('next', JSON.stringify(estimation.result.data));
                localStorage.setItem('current_step_continue', JSON.stringify(estimation.result.data));
                // Data
                window.location.reload(false);
                window.location.replace(estimation.result.data.step)
            } else {
                //console.log('add alert')
            }
        }
        // PREVIOUS STEP
        if (prevProps.previous !== previous) {
            // Remove navigation prompt
            window.onbeforeunload = null;
            localStorage.setItem('step', previous.result.data.step);
            localStorage.setItem('subscription_id', previous.result.data.subscription_id);
            localStorage.setItem('previous', JSON.stringify(previous.result.data));
            // Redirect
            this.setState({
                isLoading: false
            });
            window.location.reload(false);
            window.location.replace(previous.result.data.step)
        }
        
    }
    // RESET DATA - SOUSSCRIPTION
    resetData = (e) => {
        e.preventDefault();
        const { get_reset_subscription } = this.props;
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        let data = {};
        //data.subscription_id = subscription_id;
        data.subscription_id = path_remove_slash;
        swal({
            title: "Etes-vous sûr ? Vous perdrez toutes les informations renseignées",
            //text: "Votre souscription sera réinitialisé !",
            icon: "warning",
            buttons: ["Non", "Oui"],
            dangerMode: true,
        })
            .then((willDelete) => {
                window.dataLayer.push({
                    event: 'gtm.click.consommation_Reinitialiser',                
                });
                if (willDelete) {
                    swal("Votre souscription est réinitialisée", {
                        icon: "success",
                    }).then(() => {
                        get_reset_subscription(data)
                    });
                } else {
                    //swal("Your imaginary file is safe!");
                }
            });

    }
    // Popup Error help
    togglePopupError = () => {
        const { showPopupError } = this.state
        //window.scrollTo(0, 0)
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.setState({
            showPopupError: !showPopupError
        })
    }
    // GET PREVIOUS
    go_back = (e) => {
        e.preventDefault();
        const { get_previous } = this.props;
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        let data = {};
        data.type_step = 'previous';
        //data.subscription_id = subscription_id;
        data.subscription_id = path_remove_slash;
        data.current_step = current_step;
        this.setState({
            isLoading: true
        });
        get_previous(data);
    }
    // HANDLE DATE CHANGE
    handleDateChange = (date,i) => {
        let date_list = this.state.startDate;
        var errors_date = this.state.dateTextError
        date_list[i] = date;
        errors_date[i] = '';
        this.setState({
            startDate: date_list,
            dateTextError: errors_date
        });
    };


    closeFacture = () => {
        this.setState({
            show_facture_modal: false
        })
    };
    initPopup = () => {
        this.setState({
            show_facture_modal: false,
            showPopupError: true
        })
        console.log('TEEEST 2')
    };
    openFacture = () => {
        this.setState({
            show_facture_modal: true,
            showPopupError: false
        })
        console.log('TRUEEEE')
    };
    close_popup = (e) => {
        this.setState({
            showPopupError: false
        })
    }
    changeLoading = () => {
        this.setState({
            isLoading: true
        });
    };
    validerPce = (e, prospect, i) => {
        e.preventDefault();
        const { pce_entered, startDate } = this.state;
        const { valider_pce } = this.props;
        var errors_date = this.state.dateTextError
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        let validation_site = {};

        if(startDate[i] == null){
            //console.log('non remplis')
            errors_date[i] = 'Merci de sélectionner une date de début de contrat.';
            this.setState({
                isLoading: false,
                errors_date: errors_date,
            })
            /*
            swal({
                title: "Merci de sélectionner une date de début de contrat.",
                icon: "error",
            }).then(() => {
                this.setState({
                    isLoading: false,
                    errors_date: errors_date,
                })
            });
            */
            
        }else{
            //console.log('date remplis')
            errors_date[i] = '';
            validation_site.superficie = null;
            validation_site.anciennete = null;
            validation_site.type_chaudiere = null;
    
            validation_site.uti_four = null
            validation_site.num_couvert = null
            validation_site.num_salarie = null
    
            validation_site.type_consommation = null;
            validation_site.pce_choice = 'pce';
            validation_site.date_start_supply = startDate[i] == null ? null : moment(startDate[i]).format('DD-MM-YYYY');
            validation_site.prospect = prospect;
            validation_site.pce = pce_entered[i];
            //validation_site.subscription_id = subscription_id;
            validation_site.subscription_id = path_remove_slash;
            this.setState({
                current_prospect: prospect,
                isLoading: true,
                currentPCE : i,
            });
            valider_pce(validation_site);
        }
        //console.log('data pce : ', validation_site)
        //console.log('date pce : ', startDate[i])
    }
    // HANDLE PCE CHANGE
    handlePce = (e,i) => {
        let pce = e.target.value;
        let pce_list = this.state.pce_entered;
        pce_list[i] = pce;
        this.setState({
            pce_entered: pce_list
        });
    };
    // MA FACTURE
    maFacture = (site, i) => {
        
        const { show_facture_modal, startDate } = this.state
        let values = {}
        let index_anciennte = this.state.list_anciennete.findIndex(item => item.display_value === site.anciennete);
        let index_chaudiere = this.state.list_type_chaudiere.findIndex(item => item.display_value === site.type_chaudiere);
        var errors_date = this.state.dateTextError

        if(startDate[i] == null){
            //console.log('non remplis')
            errors_date[i] = 'Merci de sélectionner une date de début de contrat.';
            this.setState({
                isLoading: false,
                errors_date: errors_date,
            })
            /*
            swal({
                title: "Merci de sélectionner une date de début de contrat.",
                icon: "error",
            }).then(() => {
                this.setState({
                    isLoading: false,
                    errors_date: errors_date,
                })
            });
            */
            
        }else{
            values.type_consommation = site.type_consommation;
            values.superficie = site.superficie;
            if(site.type_chaudiere){values.type_chaudiere = this.state.list_type_chaudiere[index_chaudiere].value}
            if(site.anciennete){values.anciennete = this.state.list_anciennete[index_anciennte].value}
            values.uti_four = site.uti_four ;
            values.num_couvert = parseInt(site.num_couvert);
            values.num_salarie = parseInt(site.num_salarie);
            this.setState({
                facture_values: values,
                show_facture_modal: !show_facture_modal,
                current_prospect: site.prospect,
                currentFacture : i,
                show: true,
                showBackButton: false,
                selected_site_facture: site
            });
            //document.documentElement.scrollTop = 180;
            window.scrollTo({top: 180, behavior: 'smooth'});
        }
    };

    // EDITER PCE
    editPCE = (site, i) => {
        const { current_returned_prospect, liste_site } = this.state;
        let site_list = liste_site;
        let rest_pce_list = current_returned_prospect;
        let current_row = current_returned_prospect.find(item => item.prospect === site.prospect);
        let current_index = rest_pce_list.findIndex(item => item.prospect === site.prospect);

        //rest_pce_list.splice(current_row, 1);
        current_row.date_start_supply = null;

        rest_pce_list = current_returned_prospect.filter(function(value, index, arr){ return index !== current_index;});
        this.setState({
            current_returned_prospect:rest_pce_list,
            edit_pce: true
        });
        //console.log('edit : ', site)
    };
    // SUBMIT FORM
    handleSubmit = (e) => {
        e.preventDefault();
        const { saisie_pce_estimation } = this.props;
        const { frm_data, token } = this.state;
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        //frm_data.subscription_id = (localStorage.getItem('subscription_id') ? localStorage.getItem('subscription_id') : '');
        frm_data.subscription_id = path_remove_slash;
        frm_data.recaptcha = token
        // SUBMIT DATA TO THE BACKEND
        saisie_pce_estimation(frm_data);
        // LOADING
        this.setState({
            isLoading: true
        });
    };
    render() {
        const {
            show_facture_modal,
            startDate,
            currentDate,
            minDate,
            maxDate,
            edit_pce,
            liste_site,
            pce_entered,
            isLoading,
            current_prospect,
            current_returned_prospect,
            is_ready_only,
            errorPCE,
            currentFacture,
            is_autorise_tech,
            is_autorise_info,
            site_key,
            showBackButton,
            show,
            dateTextError
        } = this.state;
        return (
            <React.Fragment>
                {(isLoading ?
                    <div className="loadingKey"><Spinner className="spinner-border text-primary" animation="border" role="status"><span className="sr-only">Loading...</span></Spinner></div> :
                    <Container>
                        <Row>
                            <Col xs={12} md={12} lg={10}>
                                {is_ready_only ? <div className="blank_div"></div> : null}
                                <h1 className="blue-border"><span></span>Ma consommation</h1>
                                <div className="form-group mt-5">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="sites_container">
                                            <div className="infot-text-conso etablissement-wrapper">
                                                <div className="icon-help">
                                                    <img src={icon_help} alt="endesa etablissement" />
                                                </div>
                                                <div className="etablissement-text">
                                                    <p>
                                                    Pour vous fournir un devis adapté à vos besoins, nous vous demandons ci-dessous quelques renseignements sur votre consommation énergétique.
                                                    Vous trouverez nos meilleurs offres et tarifs dans l'étape suivante !
                                                    </p>
                                                </div>
                                            </div>
                                            {liste_site.map((site, i) => {
                                                var site_returned = current_returned_prospect.find((etab, j) => etab.prospect === site.prospect && etab.date_start_supply !== null) ? current_returned_prospect.find((etab, j) => etab.prospect === site.prospect && etab.date_start_supply !== null) : '';
                                                return (current_returned_prospect.find((etab, j) => etab.prospect === site.prospect && etab.date_start_supply !== null) ?
                                                    <div key={i} className="border-conso site_container valide mb-5" >
                                                        <span className="sep_site"></span>
                                                        {
                                                            site_returned.type_prospect === "etablissement" ?
                                                                <h2>Etablissement -{i + 1}</h2>
                                                                : <h2>{site_returned.name} - {site_returned.postcode}</h2>
                                                        }
                                                        <Row>
                                                            <Col xs={12} md={6} className="noPadding mt-3">
                                                                <h3>Adresse :</h3>
                                                                <p>{site_returned.address}</p>
                                                            </Col>
                                                            <Col xs={12} md={6} className="noPadding mt-3">
                                                                <h3>Date début de fourniture :</h3>
                                                                {/*<p>{site_returned.date_start_supply}</p>*/}
                                                                <p>{moment(site_returned.date_start_supply).format('DD/MM/YYYY')}</p>
                                                            </Col>
                                                            {(site_returned.pce ?
                                                                <>
                                                                <Col xs={12} md={6} className="noPadding mt-3">
                                                                    <h3>PCE :</h3>
                                                                    <p>{site_returned.pce}</p>
                                                                </Col>
                                                                <Col xs={12} md={6} className="noPadding mt-3"></Col>
                                                                </>
                                                            : '')}
                                                            {(site_returned.type_consommation ?
                                                                <>
                                                                    <Col xs={12} md={12} className="noPadding mt-3">
                                                                        <h3>Estimation de consommation :</h3>
                                                                        <Row>
                                                                            {(site_returned.type_consommation.filter(word => word === 'Chaudiere').map((type,i) => {
                                                                                switch (type) {
                                                                                    case 'Chaudiere':
                                                                                        return(<Col key={i} xs={12} md={6} className="noPadding mt-3">
                                                                                            <div className="type">
                                                                                                <p style={{color:'#ffffff'}}>Usage Chauffage</p>
                                                                                            </div>
                                                                                            <div className="type-details" >
                                                                                                <p>Superficie des locaux en m² : {site_returned.superficie} m²</p>
                                                                                                <p>Ancienneté du bâtiment : {site_returned.anciennete}</p>
                                                                                                <p>Type de chaudière : {site_returned.type_chaudiere}</p>
                                                                                            </div>
                                                                                        </Col>);
                                                                                    default:
                                                                                        break;
                                                                                }
                                                                            })
                                                                            )}
                                                                            {
                                                                                site_returned.type_consommation.filter(word => word !== 'Chaudiere').length > 0 ? 
                                                                                    <Col key={i} xs={12} md={6} className="noPadding mt-3">
                                                                                        <div className="type">
                                                                                            <p style={{color:'#ffffff'}}>Process fabrication métier</p>
                                                                                        </div>
                                                                                        <div className="type-details" >
                                                                                            {(site_returned.type_consommation.filter(word => word !== 'Chaudiere').map((type,i) => {
                                                                                                switch (type) {
                                                                                                    case 'Four_a_gaz':
                                                                                                        return(<p>{site_returned.uti_four === true ? 'Four à gaz : Oui' : site_returned.uti_four === false ? 'Four à gaz : Non' : '' }</p>);
                                                                                                    case 'Cuisson_restau':
                                                                                                        return( <p>Cuisson restauration : {site_returned.num_couvert} Couverts par service</p>)
                                                                                                    case 'eau_chaude':
                                                                                                        return(<p>Nombre de salariés sur le site : {site_returned.num_salarie}</p>)
                                                                                                    default:
                                                                                                        break;
                                                                                                }
                                                                                    })
                                                                                    )}
                                                                                    </div>
                                                                                </Col>
                                                                                : ''
                                                                            }
                                                                        </Row>
                                                                    </Col>
                                                                </>
                                                            : '')}
                                                            
                                                            <input style={{ float: 'right', clear: 'both', marginTop: 20 }} type="button" className="btn_edit_site" onClick={() => this.editPCE(site_returned, i)} value="Modifier" />
                                                        </Row>
                                                    </div>

                                                    :
                                                    <div key={i} className="border-conso site_container mb-5">
                                                        <span className="sep_site"></span>
                                                        {
                                                            site.type_prospect === "copro" ?
                                                                <h2>{site.name} - {site.postcode}</h2>
                                                                : <h2>{site.address}</h2>
                                                        }
                                                        <Row>
                                                            <Col xs={12} md={12} className="noPadding mt-3">
                                                                <p>Date de début de contrat <span className="red_star">*</span></p>
                                                                <DatePicker
                                                                    placeholderText='XX-XX-XXXX'
                                                                    dateFormat="dd/MM/yyyy"
                                                                    className={ dateTextError[i] === undefined || dateTextError[i] === '' ? "date_input_pce " : "date_input_pce input_invalid"}
                                                                    selected={startDate[i]}
                                                                    onChange={(date) => this.handleDateChange(date,i)}
                                                                    minDate={currentDate}
                                                                    maxDate={addDays(currentDate, maxDate)}
                                                                    locale="fr"
                                                                />
                                                                {
                                                                    dateTextError[i] != '' ?
                                                                        <p className="text-danger small-text-help">{dateTextError[i]}</p>
                                                                    : null
                                                                }
                                                            </Col>
                                                            <Col xs={12} md={12} className="noPadding mt-3">
                                                                <p className="small-text-help mb-2">Afin de vous proposer le tarif le plus juste, nous vous invitons à saisir le numéro de PCE (Point de Comptage et d’Estimation) indiqué sur votre facture de gaz.</p>
                                                                <p>PCE <span className="red_star">*</span></p>
                                                                <div className="form-group">
                                                                    <input type="text" className={errorPCE[i] === undefined || errorPCE[i] === '' ? "pce_input" : "pce_input input_invalid"} value={pce_entered[i]} onChange={(e) => this.handlePce(e,i)} id={i} />
                                                                    <button type="button" disabled={ (pce_entered[i] === "" || pce_entered[i] === null || pce_entered[i] === undefined) ? true : false} onClick={(e) => this.validerPce(e, site.prospect,i)} className="validate_pce">Valider</button>
                                                                    {/*<p className="small-text-help mt-2">Vous trouverez votre numéro de PCE sur votre facture de gaz.</p>*/}
                                                                    <div className="btn mt-2 btn-show-pce" onClick={this.togglePopup.bind(this)}>Où trouver mon PCE</div>
                                                                    {
                                                                        errorPCE[i] !== '' ?
                                                                            <p className="text-danger small-text-help mt-2">{errorPCE[i]}</p>
                                                                        : null
                                                                    }
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={6} className="noPadding mt-3">
                                                                <h3 className="no_facture" onClick={() => this.maFacture(site , i)}>Je n'ai pas ma facture</h3>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                            }
                                            )}
                                            <div className="site_container mb-3">
                                                <span className="sep_site"></span>
                                                <div className="form_group mt-5"><p> </p>
                                                    <input type="submit" id="allow-navigation consommation_continue" name="btn_submit" disabled={ current_returned_prospect.length === liste_site.length ? false : true} className="btn_next_continue btn_submit allow-navigation" value="Continuer" onSubmit={this.handleSubmit} />
                                                    {/*<label className="previous" onClick={this.go_back}><i className="fas fa-chevron-left"></i>Étape précédente</label>*/}
                                                    {
                                                        is_ready_only ?
                                                            <label className="reset-data btn_reset"  onClick={this.resetData}><i className="fas fa-undo"></i>Réinitialiser la souscription</label>
                                                            :
                                                            <label className="previous allow-navigation" id='consommation_previous' onClick={this.go_back}><i className="fas fa-chevron-left"></i>Étape précédente</label>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <ReCaptcha
                                    ref={ref => this.recaptcha = ref}
                                    sitekey={site_key}
                                    action={this.updateToken}
                                    verifyCallback={this.verifyCallback}
                                />
                            </Col>
                        </Row>
                    </Container>
                )
                }
                {
                    this.state.errorCounter > 3 && this.state.showPopupError ? 
                    <React.Fragment>
                        <Modal dialogClassName="modal-90w" size="lg" centered show={this.state.show} onHide={this.handleClose}>
                            <PopupError
                            text='Close Me'
                            classFull= {true}
                            show_popup_facture= {this.openFacture.bind(this)}
                            close_popup= {this.close_popup.bind(this)}
                            closePopup={this.togglePopupError.bind(this)}
                            initPopup={this.initPopup.bind(this)}
                            showBackButton= {showBackButton}
                            /> 
                        </Modal>
                    </React.Fragment>
                    : null
                }
                {
                    (show_facture_modal ?
                        <React.Fragment>
                            <Modal dialogClassName="modal-90w" size="lg" centered show={this.state.show} onHide={this.handleClose}>
                                <FactureModal
                                    initPopup={this.initPopup}
                                    showBackButton={showBackButton}
                                    values={this.state.facture_values}
                                    numFacture={currentFacture}
                                    changeLoading = {this.changeLoading}
                                    closeFacture={this.closeFacture}
                                    prospect={current_prospect}
                                    edit={edit_pce}
                                    date_start_supply={moment(this.state.startDate[currentFacture]).format('DD-MM-YYYY')}
                                    list_anciennete={this.state.list_anciennete}
                                    list_type_chaudiere={this.state.list_type_chaudiere}
                                    all_data={this.state.selected_site_facture}
                                />
                            </Modal>
                        </React.Fragment>
                        :
                        ''
                    )
                }
                {
                    (this.state.showPce ?
                        <React.Fragment>
                            <Modal size="lg" centered show={this.state.showPce} onHide={this.handleClose}>
                                <PopupPCE closePopup={this.togglePopup.bind(this)} />
                            </Modal>
                        </React.Fragment>
                        :
                        ''
                    )
                }
            </React.Fragment >
        );
    }
}

function mapStateToProps({ estimation, validated_pce, previous, type_estimation_list, valide_pce, reset_subscription,estimation_form, building_age, chaudiere_type, all_subs }) {
    return { estimation, validated_pce, previous, type_estimation_list, valide_pce, reset_subscription,estimation_form, building_age, chaudiere_type, all_subs };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        get_anciennete: get_anciennete,
		get_type_chaudiere: get_type_chaudiere,
        saisie_pce_estimation: saisie_pce_estimation,
        validatePce: validatePce,
        valider_pce: valider_pce,
        get_previous: get_previous,
        get_reset_subscription: get_reset_subscription,
        get_numbers_subs: get_numbers_subs,
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Consommation);