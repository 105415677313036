/*import axios from 'axios';
import { apiUrl, url_saisie_pce_estimation } from '../services/constant';

export const  = (data) => dispatch => {
    const request = axios.post(apiUrl + url_saisie_pce_estimation,data);
    dispatch({
        type: 'ESTIMATION',
        payload: request
    })
}
*/
export function saisie_pce_estimation(payload) {
    return {
        type: 'ESTIMATION',
        payload,
    };
}
export function saisie_pce_estimation_done(request) {
    return {
        type: 'ESTIMATION_DONE',
        payload: request,
    };
}