import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { post } from '../services/request';
import { apiUrl, url_pre_verified_address } from '../services/constant';
import { get_pre_verified_address_done } from '../actions/pre_verified_address';


function* get_pre_verified_address_saga(action) {
	yield call(
		callApi,
		apiUrl + url_pre_verified_address,
		post,
        action.payload,
        get_pre_verified_address_done,
	);
}
export default function* watch_get_pre_verified_address_saga() {
    yield takeEvery('PRE_VERIFIED_ADDRESS', get_pre_verified_address_saga);
}