/*import axios from 'axios';
import { apiUrl, url_parameters } from '../services/constant';

export const get_numbers_subs = (data) => dispatch => {
    const request = axios.get(apiUrl + url_parameters, data);
    dispatch({
        type: 'ALL_SUBS',
        payload: request
    })
}
*/
export function get_numbers_subs(payload) {
    return {
        type: 'ALL_SUBS',
        payload,
    };
}
export function get_numbers_subs_done(request) {
    return {
        type: 'ALL_SUBS_DONE',
        payload: request,
    };
}