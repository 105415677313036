/*import axios from 'axios';
import { apiUrl, url_get_key } from '../services/constant';

export const start = (key) => dispatch => {
    console.log('Key action', key);
    const request = axios.get(apiUrl + url_get_key + '?key=' + key);
    dispatch({
        type: 'START',
        payload: request
    })
}*/

export function start(payload) {
    return {
        type: 'START',
        payload,
    };
}
export function start_done(request) {
    return {
        type: 'START_DONE',
        payload: request,
    };
}