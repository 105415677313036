import React, { Component } from 'react';

// Core viewer
import {Viewer, Worker} from '@react-pdf-viewer/core';

// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';



// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';


function DocumentBrochurePopup(props) {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const { attachmentPluginInstance } = defaultLayoutPluginInstance;
    return (
        <div className="App">
            <div id="pdfviewer">
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                    <Viewer
                        fileUrl={props.fileURL}
                        plugins={[
                            defaultLayoutPluginInstance
                        ]}
                    />
                </Worker>
            </div>
        </div>
    )
}

export default DocumentBrochurePopup