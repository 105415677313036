import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './footer.css';
import Encarts from '../encarts';
import { subscription_id } from '../../services/status';

function Footer(props) {
    return (
        <React.Fragment>
            <Encarts />
            <footer className="footer row">
                <Col xs={12} sm={12} md={12} lg={9}>
                    <Row>
                        <Col md={3}>
                            <p className="copyright">
                                &copy; Endesa Energia S.A.
                            </p>
                        </Col>
                        <Col md={9}>
                            <Row>
                                {/*
                                <Col md={3} className="text_center">
                                    <a href={process.env.REACT_APP_API_URL + "mentions-legales/"} target="_blank" className="footer_link">Mentions légales</a>
                                </Col>
                                <Col md={3} className="text_center">
                                    <a href={process.env.REACT_APP_API_URL + 'politique-confidentialite/'} target="_blank" className="footer_link">Politique de confidentialité</a>
                                </Col>
                                <Col md={3} className="text_center">
                                    <a href={process.env.REACT_APP_API_URL + 'politique-cookies/'} target="_blank" className="footer_link">Politiques de cookies</a>
                                </Col>
                                <Col md={3} className="text_center">
                                    <a href={process.env.REACT_APP_API_URL + 'contact/?subscription_id='+ subscription_id} target="_blank" className="footer_link">Contactez-nous</a>
                                </Col>
                                */}
                                <Col>
                                    <ul className="links-footer">
                                        <li>
                                            <a href={process.env.REACT_APP_API_URL + "mentions-legales/"} target="_blank" className="footer_link">Mentions légales</a>
                                        </li>
                                        <li>
                                            <a href={process.env.REACT_APP_API_URL + 'politique-confidentialite/'} target="_blank" className="footer_link">Politique de confidentialité</a>
                                        </li>
                                        <li>
                                            <a href={process.env.REACT_APP_API_URL + 'politique-cookies/'} target="_blank" className="footer_link">Politiques de cookies</a>
                                        </li>
                                        <li>
                                            <a href={process.env.REACT_APP_API_URL + 'contact/?subscription_id='+ subscription_id} target="_blank" className="footer_link">Contactez-nous</a>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </footer>
        </React.Fragment>
    )
}

export default Footer;