import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { get } from '../services/request';
import { apiUrl, url_buildings_ages } from '../services/constant';
import { get_anciennete_done } from '../actions/buildings_age';


function* get_anciennete_saga(action) {
	yield call(
		callApi,
		apiUrl+url_buildings_ages,
		get,
        action.payload,
        get_anciennete_done,
	);
}
export default function* watch_get_anciennete_saga() {
    yield takeEvery('BUILDINGS_AGE', get_anciennete_saga);
}

