/*import axios from 'axios';
import { apiUrl, url_commune } from '../services/constant';

export const get_communes = (data) => dispatch => {
    const request = axios.post(apiUrl + url_commune, data);
    dispatch({
        type: 'COMMUNES',
        payload: request
    })
}
*/
export function get_communes(payload) {
    return {
        type: 'COMMUNES',
        payload,
    };
}
export function get_communes_done(request) {
    return {
        type: 'COMMUNES_DONE',
        payload: request,
    };
}