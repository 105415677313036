// Etre rappelé
/*import axios from 'axios';
import { apiUrl, url_get_recalled } from '../services/constant';

export const get_recalled = (data) => dispatch => {
    const request = axios.post(apiUrl + url_get_recalled, data);
    dispatch({
        type: 'GET_RECALLED',
        payload: request
    })
}
*/
export function get_recalled(payload) {
    return {
        type: 'GET_RECALLED',
        payload,
    };
}
export function get_recalled_done(request) {
    return {
        type: 'GET_RECALLED_DONE',
        payload: request,
    };
}