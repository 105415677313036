import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { post } from '../services/request';
import { apiUrl, url_contact } from '../services/constant';
import { contact_done } from '../actions/contact';


function* contact_saga(action) {
	yield call(
		callApi,
		apiUrl + url_contact,
		post,
        action.payload,
        contact_done,
	);
}
export default function* watch_contact_saga() {
    yield takeEvery('CONTACT', contact_saga);
}

