console.log('The value of process.env is:', process.env);
//export const apiUrl = process.env.API_URL;
//export const apiUrl = (process.env.NODE_ENV === 'production' ? "https://dev.c985-endesa-vente-en-ligne.wbc-prod.com/" : "https://dev.c985-endesa-vente-en-ligne.wbc-prod.com/");
export const apiUrl = (process.env.REACT_APP_API_URL === null ? "https://dev.c985-endesa-vente-en-ligne.wbc-prod.com/" : process.env.REACT_APP_API_URL);

let url_subscription = 'subscription/api';
export const url_sub_key = url_subscription + "/launch-sbscription/";
export const url_get_key = url_subscription + "/key/";
export const url_identification = url_subscription + "/identify/";
export const url_choix_syndic = url_subscription + "/syndic/";
export const url_liste_etablissements = url_subscription + "/etablissement/";
export const url_contact = url_subscription + "/get-contacted/";
export const url_liste_offres = url_subscription + "/select-offer/";
export const url_saisie_pce_estimation = url_subscription + "/saisie-pce-estimation/";
export const url_validate_pce = url_subscription + "/validate-pce-estimation/";
export const url_choix_duree_contrat = url_subscription + "/choice-duration-contract/";
export const url_donnees_client = url_subscription + "/customer-contact-information/";
export const url_verification_email = url_subscription + "/verify-email/";
export const url_signature_contrat = url_subscription + "/signing_url/";
export const url_brochure = url_subscription + "/get-offer-brochure/";
export const url_previous = url_subscription + "/previous-next-step/";
export const url_check_promotion_code = url_subscription + "/check-promotion-code/";
export const url_get_recalled = url_subscription + "/get-recalled/";
export const url_utilisation_type = url_subscription + "/estimation-type-list/";
export const url_applicate_code = url_subscription + "/apply-code-verification/";
export const url_continue_later = url_subscription + "/continue-later/";
export const url_resend_verification_email = url_subscription + "/resend-verification-email/";
export const url_change_subscription_email = url_subscription + "/change-subscription-email/";
export const url_dynamic_parameters = "parametrage/api/dynamic-parameters/";
export const url_reset_subscription = url_subscription + "/reset-subscription/";
export const url_commune = url_subscription + "/get-communes/";
export const url_payeurs_divergents = url_subscription + "/multiple-payeurs-divergents/";
export const url_parameters = "parametrage/api/parameters/";
export const url_get_offer_category = url_subscription + "/get-offer-category-illustration/"
export const url_verify_address = url_subscription + "/verify-address/"
export const url_boilers_type = url_subscription + "/boilers-types/"
export const url_buildings_ages = url_subscription + "/buildings-ages/"
export const url_verification_delay = url_subscription + "/get-email-verification-delays/"
export const url_docusign_link = url_subscription + "/create-docusign-recipient-view/"
export const url_pre_verified_address = url_subscription + "/verify-pre-filled-addresses/"
export const url_customer_coordinates_file_types = url_subscription + "/customer-coordinates-file-types/"
export const url_devis = url_subscription + "/download-devis/"
