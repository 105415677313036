import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { post } from '../services/request';
import { apiUrl, url_previous } from '../services/constant';
import { get_data_done } from '../actions/get_data';


function* get_data_saga(action) {
	yield call(
		callApi,
		apiUrl + url_previous,
		post,
        action.payload,
        get_data_done,
	);
}
export default function* watch_get_data_saga() {
    yield takeEvery('DATA', get_data_saga);
}