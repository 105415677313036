import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxPromise from 'redux-promise';
import thunk from 'redux-thunk';
import monitorReducersEnhancer from '../enhancers/monitorReducer';
import loggerMiddleware from '../middleware/logger';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas/index'
import rootReducer from '../models';

export default function configureStore(preloadedState) {
    const sagaMiddleware = createSagaMiddleware();
    const middlewares = [sagaMiddleware,thunk, ReduxPromise, loggerMiddleware]
    const middlewareEnhancer = applyMiddleware(...middlewares)
    const enhancers = [middlewareEnhancer, monitorReducersEnhancer]
    const composedEnhancers = composeWithDevTools(...enhancers)
    const store = createStore(rootReducer, preloadedState, composedEnhancers)
    sagaMiddleware.run(rootSaga);
    return store
}
