import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { post } from '../services/request';
import { apiUrl, url_commune } from '../services/constant';
import { get_communes_done } from '../actions/communes';


function* get_communes_sage(action) {
	yield call(
		callApi,
		apiUrl + url_commune,
		post,
        action.payload,
        get_communes_done,
	);
}
export default function* watch_get_communes_sage() {
    yield takeEvery('COMMUNES', get_communes_sage);
}

