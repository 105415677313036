
export function get_devis(payload) {
    return {
        type: 'DEVIS',
        payload,
    };
}
export function get_devis_done(request) {
    return {
        type: 'DEVIS_DONE',
        payload: request,
    };
}