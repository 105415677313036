import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { post } from '../services/request';
import { apiUrl, url_verification_email } from '../services/constant';
import { verification_email_done } from '../actions/verification_email';


function* verification_email_saga(action) {
	yield call(
		callApi,
		apiUrl + url_verification_email,
		post,
        action.payload,
        verification_email_done,
	);
}
export default function* watch_verification_email_saga() {
    yield takeEvery('VERIFICATION_EMAIL', verification_email_saga);
}

