import axios from 'axios';

export const get = (url,data, responseType) => {
  var response
  if(data){
    if(responseType){
      response = axios.get(`${url}`,responseType,data);
    }
    else{
      response = axios.get(`${url}`,data);
    }
  }
  else{
    if(responseType){
      response = axios.get(`${url}`,responseType);
    }
    else{
      response = axios.get(`${url}`);
    }
  }
  
    return response;
  };
  
export const post = (url,data, responseType) => {
  var response
  if(data){
    if(responseType){
      response = axios.post(`${url}`,data,responseType);
    }
    else{
      response = axios.post(`${url}`,data);
    }
  }
  else{
    if(responseType){
      response = axios.post(`${url}`,responseType);
    }
    else{
      response = axios.post(`${url}`);
    }
  }
    return response;
  };
