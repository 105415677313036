import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { post } from '../services/request';
import { apiUrl, url_verification_delay } from '../services/constant';
import { get_verification_delay_done } from '../actions/verification_delay';


function* get_verification_delay_saga(action) {
	yield call(
		callApi,
		apiUrl + url_verification_delay,
		post,
        action.payload,
        get_verification_delay_done,
	);
}
export default function* watch_get_verification_delay_saga() {
    yield takeEvery('VERIFICATION_DELAY', get_verification_delay_saga);
}

