import React, { Component } from 'react';
import { Document, Page } from 'react-pdf';
import closeBtn from '../../assets/img/close-btn.svg';
import downloadBtn from '../../assets/img/download.svg';
import '../../assets/css/form.css';
import './popup.css';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


class BrochureModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
            numPages : null,
            pageNumber : 1,
		};
    }
    onDocumentLoadSuccess({ numPages }) {
        this.setState({
            numPages: numPages,
            pageNumber : 1
        });
      }
    
    changePage(offset) {
        this.setState({
            pageNumber : this.state.pageNumber + offset
        });
      }
    
    previousPage() {
        this.changePage(-1);
      }
    
    nextPage() {
        this.changePage(1);
      }
	
	render() {
        const { 
			downloadFile, 
            CloseBrochure,
            fileURL,
		} = this.props;
		return (
			<React.Fragment>
                <div className="pop_up2 ">
					{/*<div className="bg_pop_up2"  onClick={CloseBrochure} />*/}
					<div className="pop_up2_container popup_inner">
						<button className="btn-close">
							<img src={closeBtn} onClick={CloseBrochure} alt='' />
						</button>
						<button className="btn-download">
                            <img src={downloadBtn} onClick={downloadFile} height="25px" alt='' />
						</button>
                        <Document
                            className="Document"
                            file={fileURL}
                            onLoadSuccess={ (numPages) => this.onDocumentLoadSuccess(numPages)}
                        >
                            <Page pageNumber={this.state.pageNumber} />
                        </Document>

                        <div className="PageButtons">
                            <button
                                type="button"
                                className="btn btn-outline-secondary Left"
                                disabled={this.state.pageNumber <= 1}
                                onClick={this.previousPage.bind(this)}
                            />
                            <p>
                                {this.state.pageNumber || (this.state.numPages ? 1 : '--')} of {this.state.numPages || '--'}
                            </p>
                            <button
                                type="button"
                                className="btn btn-outline-secondary Right"
                                disabled={this.state.pageNumber >= this.state.numPages}
                                onClick={this.nextPage.bind(this)}
                            />
                        </div>
                    </div>
                </div>
			</React.Fragment>
		);
	}
}

export default BrochureModal;
