import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { get } from '../services/request';
import { apiUrl, url_get_key } from '../services/constant';
import { start_done } from '../actions/start';


function* start_saga(action) {
	yield call(
		callApi,
		apiUrl + url_get_key + '?key=' + action.payload,
		get,
        null,
        start_done,
	);
}
export default function* watch_start_saga() {
    yield takeEvery('START', start_saga);
}

