import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import BigSteps from '../../components/bigSteps/bigSteps';
import { current_step, subscription_id } from '../../services/status';
import PopupRappel from '../../components/aide/PopupRappel'
import './header.css';
import logo from '../../assets/img/endesa_logo.svg';
import Aide from '../../components/aide/aide'
import {Modal, Button} from "react-bootstrap"
import Bandeau from '../bandeau/bandeau.js'
import { get_numbers_subs } from '../../actions/all_subs';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Header extends Component {
    constructor(props){
        super(props)
        this.state = {
            toggleHelp: false,
            toggleTips: false,
            show: false,
            checkReminder: false,
            checkRappel: false
        }
    }
    componentDidUpdate(prevProps){
        const { all_subs } = this.props;
        if (prevProps.all_subs !== all_subs) {
            //console.log(all_subs)
            all_subs.result.data.map((itm, indx) => {
                if (itm.param === "CHECK_REMINDER") {
                    //console.log('YEES HEZDER : ', itm.value)
                    this.setState({
                        checkReminder: itm.value,
                        checkRappel: itm.value
                    })
                }
            })
        }
    }
    handleClose = () => {
        this.setState({
            show: false
        })
    }

    handleShow  = () => {
        this.setState({
            show: true
        })
    }
    toggleHelpMobile = () => {
        const {toggleHelp} = this.state
        this.setState({
            toggleHelp: !toggleHelp
        })
    }
    toggleTipsMobile = () => {
        const {toggleTips} = this.state
        this.setState({
            toggleTips: !toggleTips
        })
    }
    togglePopup = () => {
        const { showPopup } = this.state
        this.setState({
            showPopup: !showPopup,
            show: true
        })
    }
    render(){
        const {toggleHelp, checkReminder, checkRappel} = this.state
        return (
            <React.Fragment>
                <Bandeau />
                <header className="header">
                    <Row>
                        <Col xs={12} md={12} lg={3} className="logo_container" id="logo_container">
                            <a className="logo">
                                <img src={logo} alt="Enedesa S.A." />
                            </a>
                            <div className="toggleMobile" >
                                <div className="toggleMobileWrapper" onClick={this.toggleHelpMobile}>
                                    <button className={(toggleHelp ? "aide_toggle active_aide" : "aide_toggle")} ></button>
                                    <span>Besoin d'aide ?</span>
                                </div>
                                {
                                    toggleHelp ?
                                    <div className="mobileToggleLinks" onClick={this.toggleHelpMobile} onBlur={() => this.setState({toggleHelp: false})}>
                                        <div className="linksMobileWrapper">
                                        {
                                            checkReminder ?
                                                <a href="#" className="rappel_link" onClick={this.togglePopup.bind(this)}>Être rappelé</a>
                                            :
                                                <a href="#" className="rappel_link" onClick={this.togglePopup.bind(this)}>Nous appeler</a>
                                        }
                                        <a href={process.env.REACT_APP_API_URL+'contact/?subscription_id='+ subscription_id} target="_blank" className="contact_link">Contact</a>
                                        <a href={process.env.REACT_APP_API_URL+ 'faq'} target="_blank" className="faq_link">FAQ</a>
                                        </div>
                                    </div>
                                    : null
                                }
                            </div>
                        </Col>
                        <Col xs={12} md={12} lg={9} className="noPadding desktopMenu">
                            <BigSteps />
                        </Col>
                    </Row>
                </header>
                {this.state.showPopup ?
                    <React.Fragment>
                        <Modal dialogClassName="modal-90w" size="lg" centered show={this.state.show} onHide={this.handleClose}>
                            <PopupRappel
                                text='Close Me'
                                closePopup={this.togglePopup.bind(this)}
                                checkReminderProp={checkReminder}
                                checkRappelProp={checkRappel}
                            />
                        </Modal>
                    </React.Fragment>
                    : null
                }
            </React.Fragment>
        )
    }
}


function mapStateToProps({ all_subs }) {
    return { all_subs };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        get_numbers_subs: get_numbers_subs
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);