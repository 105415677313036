import Offers from "../components/offers/offers";
import Consommation from "../components/consommation/consommation";
import Key from "./key";
import { current_step, subscription_id } from './status';
import Tarifs from "../components/tarifs/tarifs";
import Souscription from "../components/souscription/souscription";
import Etablissement from "../components/society/etablissement";
import Coordonnees from "../components/society/coordonnees";
import Syndic from "../components/society/syndic";
import Confirmation from "../components/souscription/email_confirmation";
import Welcome from "../components/souscription/welcome";
import Client from "../components/society/client";
import Signature from "../components/souscription/signature_contrat";
import NoMatchPage from "./404";
import Remerciement from "../components/souscription/remerciement";
import all_np_step from "../components/souscription/all_np_step";

let current_component = Key;
let url_key = (localStorage.getItem('subscription_id') ? '/' + localStorage.getItem('subscription_id') : '/' + localStorage.getItem('subscription_id'));
let step = current_step;
if(window.location.pathname.split('/')[2] !== step && window.location.pathname.split('/').length > 1 && subscription_id !== ""){
    console.log('if')
    window.location.replace('/' + subscription_id + '/' + step)
}else{
    console.log('else')
}
if (subscription_id !== localStorage.getItem('subscription_id')) {
    //console.log('route true : ', subscription_id, 'compared : ', url_key)
    window.location.reload();
    localStorage.clear();
    step = 'start';
    localStorage.setItem('subscription_id', subscription_id);
}else{
    //step = 'identification';
}
if (url_key === '404' || localStorage.getItem('step') === '404') {
    localStorage.clear();
    step = '404';
}
switch (step) {
    case 'start':
        current_component = Key;
        break;
    case 'identification':
        current_component = Client
        break;
    case '404':
        current_component = NoMatchPage
        break;
    case '500':
        current_component = NoMatchPage
        break;
    case 'choix_syndic':
        current_component = Syndic
        break;
    case 'liste_etablissements':
        current_component = Etablissement
        break;
    case 'contact':
        current_component = Coordonnees
        break;
    case 'liste_offres':
        current_component = Offers
        break;
    case 'saisie_pce_estimation':
        current_component = Consommation
        break;
    case 'choix_duree_contrat':
        current_component = Tarifs
        break;
    case 'donnees_client':
        current_component = Souscription
        break;
    case 'verification_email':
        current_component = Confirmation
        break;
    case 'signature_contrat':
        current_component = Signature
        break;
    case 'remerciement':
        current_component = Remerciement
        break;
    case 'bienvenue':
        current_component = Welcome
        break;
    case 'all_np_step':
        current_component = all_np_step
        break;
    default:
        current_component = Key
        break;
}

export const routes = [
    {
        path: url_key === '/' || url_key === '' ? url_key : url_key + "/" + step,
        component: current_component
    }
];
export const url_to_go = url_key + "/" + step;
export const step_to_go = step;