/*import axios from 'axios';
import { apiUrl, url_brochure } from '../services/constant';

export const get_brochure = (data) => dispatch => {
    const request = axios.get(apiUrl + url_brochure + '?offer_id=' + data.offer_id, { responseType: 'blob' }, data);
    console.log('dataa :', data)
    dispatch({
        type: 'BROCHURE',
        payload: request
    })
}
*/
export function get_brochure(payload) {
    return {
        type: 'BROCHURE',
        payload,
    };
}
export function get_brochure_done(request) {
    return {
        type: 'BROCHURE_DONE',
        payload: request,
    };
}