export function get_sub_key(payload) {
    return {
        type: 'SUB_KEY',
        payload,
    };
}
export function get_sub_key_done(request) {
    return {
        type: 'SUB_KEY_DONE',
        payload: request,
    };
}