import React from "react"
import { current_step, subscription_id } from '../../services/status';
import swal from 'sweetalert';
import './aide.css';
import shopIcon from "../../assets/img/shop-icon.png"
import shopMobile_svg from '../../assets/img/shopMobile.svg';

class OffreSelectioneePopup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dataOffer: localStorage.getItem('next') ? JSON.parse(localStorage.getItem('next')) : JSON.parse(localStorage.getItem('current_step_continue')).data,
            offreName: 'Offre Hôtellerie',
            offrePriceOriginal: 150,
            offrePricePromo: 120,
            offrePromotion: 15,
            offrePriceMonth: 1340,
            offreConsoMonth: 10254,
            offreDocumentation: '',
            fileURL : null,
        }
    }
    componentDidMount() {
        const { dataOffer } = this.state;
        //console.log('dataOffer : ', dataOffer.data)
        /*
        this.setState({
            offreName: dataOffer.data.chosen_offer_data.offer_name,
            offrePriceOriginal: dataOffer.data.chosen_offer_data.ancient_monthly_price,
            offrePricePromo: dataOffer.data.chosen_offer_data.new_monthly_price,
            offrePromotion: dataOffer.data.chosen_offer_data.reduction_rate,
            offrePriceMonth: dataOffer.data.chosen_offer_data.new_annual_price,
            offreConsoMonth: dataOffer.data.chosen_offer_data.monthly_consumption,
            offreDocumentation: ''
        })
        */
    }
    componentWillMount() {
        window.dataLayer.push({              
            pageCategory: 'Mes Cordonnées',                
        });
        if (localStorage.getItem('current_step_continue')) {
            if(JSON.parse(localStorage.getItem('current_step_continue')).data.step === 'donnees_client'){
                sessionStorage.setItem('offrePricePromo',JSON.parse(localStorage.getItem('current_step_continue')).data.data.chosen_offer_data.new_monthly_price)
                this.setState({
                    offreName: JSON.parse(localStorage.getItem('current_step_continue')).data.data.chosen_offer_data.offer_name,
                    offrePriceOriginal: JSON.parse(localStorage.getItem('current_step_continue')).data.data.chosen_offer_data.ancient_monthly_price,
                    offrePricePromo: JSON.parse(localStorage.getItem('current_step_continue')).data.data.chosen_offer_data.new_monthly_price,
                    offrePromotion: JSON.parse(localStorage.getItem('current_step_continue')).data.data.chosen_offer_data.reduction_rate,
                    offrePriceMonth: JSON.parse(localStorage.getItem('current_step_continue')).data.data.chosen_offer_data.new_annual_price,
                    offreConsoMonth: JSON.parse(localStorage.getItem('current_step_continue')).data.data.chosen_offer_data.monthly_consumption,
                    offreDocumentation: ''
                })
            }
        }
        if (localStorage.getItem('next')) {
            if(JSON.parse(localStorage.getItem('next')).step === 'donnees_client'){
                sessionStorage.setItem('offrePricePromo',JSON.parse(localStorage.getItem('next')).data.chosen_offer_data.new_monthly_price)
                this.setState({
                    offreName: JSON.parse(localStorage.getItem('next')).data.chosen_offer_data.offer_name,
                    offrePriceOriginal: JSON.parse(localStorage.getItem('next')).data.chosen_offer_data.ancient_monthly_price,
                    offrePricePromo: JSON.parse(localStorage.getItem('next')).data.chosen_offer_data.new_monthly_price,
                    offrePromotion: JSON.parse(localStorage.getItem('next')).data.chosen_offer_data.reduction_rate,
                    offrePriceMonth: JSON.parse(localStorage.getItem('next')).data.chosen_offer_data.new_annual_price,
                    offreConsoMonth: JSON.parse(localStorage.getItem('next')).data.chosen_offer_data.monthly_consumption,
                    offreDocumentation: ''
                })
            }
        }
    }

    render() {
        const { offreName, offrePriceOriginal, offrePricePromo, offrePromotion, offrePriceMonth, offreConsoMonth, offreDocumentation, dataOffer } = this.state;
        return (
            <div className="selected-offer-wrapper">
                <div className="selected-offer-aide">
                    <div className="offer-selected-content-title">
                        <h1>Offre sélectionnée :</h1>
                        <h3>{offreName}</h3>
                    </div>
                    <div className="offer-selected-content-details">
                        <h4>estimé à</h4>
                        {
                            offrePriceOriginal !== offrePricePromo ?
                            <p>{offrePriceOriginal}€ <span className="price-new">{offrePricePromo}€</span><span className="text">HT/mois</span></p>
                            :
                            <p><span className="price-new">{offrePricePromo}€</span><span className="text">HT/mois</span></p>
                        }
                        <p className="small-text">Soit {offrePriceMonth}€ HT/an</p>
                        {/*<h4>Code de réduction <span>-{offrePromotion}%</span></h4>*/}
                        <h4>Les frais administratifs et le changement de fournisseur sont offerts</h4>
                    </div>
                    <div className="offer-selected-content-docu">
                        <a href="#" onClick={this.props.closePopup}>Consulter la brochure</a>
                    </div>
                    <div className="offer-selected-icon">
                        <img src={shopMobile_svg} alt="shop endesa icon" />
                    </div>
                </div>
            </div>
        )
    }
}

export default OffreSelectioneePopup