
export function get_anciennete(payload) {
    return {
        type: 'BUILDINGS_AGE',
        payload,
    };
}
export function get_anciennete_done(request) {
    return {
        type: 'BUILDINGS_AGE_DONE',
        payload: request,
    };
}