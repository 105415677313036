import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { post } from '../services/request';
import { apiUrl, url_saisie_pce_estimation } from '../services/constant';
import { saisie_pce_estimation_done } from '../actions/estimation';


function* saisie_pce_estimation_saga(action) {
	yield call(
		callApi,
		apiUrl + url_saisie_pce_estimation,
		post,
        action.payload,
        saisie_pce_estimation_done,
	);
}
export default function* watch_saisie_pce_estimation_saga() {
    yield takeEvery('ESTIMATION', saisie_pce_estimation_saga);
}

