import React from "react"
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Document, Page } from 'react-pdf';
import { bindActionCreators } from 'redux';
import { get_brochure } from '../../actions/brochure';
import './aide.css';
import closeBtn from '../../assets/img/close-btn.svg';
import downloadBtn from '../../assets/img/download.svg';
import { pdfjs } from 'react-pdf';
import DocumentBrochurePopup from "../offers/DocumentBrochurePopup"
import {Modal, Button} from "react-bootstrap"
// Core viewer
import {Viewer, Worker} from '@react-pdf-viewer/core';
// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class PopupBrochure extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            dataOffer: localStorage.getItem('next') ? JSON.parse(localStorage.getItem('next')) : JSON.parse(localStorage.getItem('current_step_continue')).data,
            offreName : '',
            numPages : null,
            pageNumber : 1,
            fileURL : null,
            open : false,
            isLoading : true,
        }
    }
    // SHOW BROCHURE MODAL 

    componentWillMount(){
        if (localStorage.getItem('current_step_continue')) {
            if(JSON.parse(localStorage.getItem('current_step_continue')).data.step === 'donnees_client'){
                this.setState({
                    offreName: JSON.parse(localStorage.getItem('current_step_continue')).data.data.chosen_offer_data.offer_name,
                })
            }
        }
        if (localStorage.getItem('next')) {
            if(JSON.parse(localStorage.getItem('next')).step === 'donnees_client'){
                this.setState({
                    offreName: JSON.parse(localStorage.getItem('next')).data.chosen_offer_data.offer_name,
                })
            }
        }
        const { dataOffer } = this.state;
        const { get_brochure } = this.props;
        let data = {};
        data.subscription_id = dataOffer.subscription_id;
        data.offer_id = dataOffer.offer.id;
        get_brochure(data);
    }
    componentDidUpdate(prevProps) {
        const { brochure_file } = this.props

        // DOWNLOADING FILE
        if (prevProps.brochure_file !== brochure_file) {
            if(this.state.open){
                window.dataLayer.push({
                    event: 'gtm.click.DownloadBochure',                
                    brochure_name: this.state.OpenBrochureTitle,               
                });
                let filename = Object.values(brochure_file.result.headers);
                const blob = new Blob([brochure_file.result.data], { type: filename[2] });
                const data = window.URL.createObjectURL(blob);
                filename = filename[0].split('=');
                filename = filename[1].split('"');
                // IE doesn't allow using a blob object directly as link href
                // instead it is necessary to use msSaveOrOpenBlob
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }
                // For other browsers: 
                var link = document.createElement('a');
                link.href = data;
                link.download = filename[1];
                link.click();
                this.setState({
                    isLoading: false
                });
                setTimeout(function () {
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(data);
                }, 100);
                link.remove();
            }
            else{
                window.dataLayer.push({
                    event: 'gtm.click.ShowBochure',                
                    brochure_name: this.state.OpenBrochureTitle,                
                });
                const blob = new Blob([brochure_file.result.data], { type: 'application/pdf' });
                const fileURL = window.URL.createObjectURL(blob);
                //console.log(fileURL)
                this.setState({
                    isLoading : false,
                    open : true,
                    fileURL : fileURL,
                });
            }
        }
    }
    
    // DOWNLOAD FILE
    downloadFile = () => {
        const { dataOffer } = this.state;
        const { get_brochure } = this.props;
        let data = {};
        data.subscription_id = dataOffer.subscription_id;
        data.offer_id = dataOffer.offer.id;
        // SUBMIT TO DOWNLOAD FILE
        get_brochure(data);
        //console.log('download : ', data)
    };
    closeBrochure(){
        this.setState({
            open : false,
        });
        this.props.closePopup()
    }
    onDocumentLoadSuccess({ numPages }) {
        this.setState({
            numPages: numPages,
            pageNumber : 1
        });
      }
    
    changePage(offset) {
        this.setState({
            pageNumber : this.state.pageNumber + offset
        });
      }
    
    previousPage() {
        this.changePage(-1);
      }
    
    nextPage() {
        this.changePage(1);
      }
    render() {
        return (
            <React.Fragment>
            {this.state.isLoading ?
                <div className="loadingKey">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
                :
                <DocumentBrochurePopup fileURL={this.state.fileURL} />
            }
            </React.Fragment>
        )
    }
}

function mapStateToProps({ brochure_file }) {
    return { brochure_file };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        get_brochure: get_brochure,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupBrochure)