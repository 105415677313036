/*import axios from 'axios';
import { apiUrl, url_liste_etablissements } from '../services/constant';

export const liste_etablissements = (data) => dispatch => {
    const request = axios.post(apiUrl + url_liste_etablissements,data);
    dispatch({
        type: 'LIST_ETABLISSEMENT',
        payload: request
    })
}
*/
export function liste_etablissements(payload) {
    return {
        type: 'LIST_ETABLISSEMENT',
        payload,
    };
}
export function liste_etablissements_done(request) {
    return {
        type: 'LIST_ETABLISSEMENT_DONE',
        payload: request,
    };
}