import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { post } from '../services/request';
import { apiUrl, url_validate_pce } from '../services/constant';
import { validatePce_done } from '../actions/validate_pce';


function* validatePce_saga(action) {
	yield call(
		callApi,
		apiUrl + url_validate_pce,
		post,
        action.payload,
        validatePce_done,
	);
}
export default function* watch_validatePce_saga() {
    yield takeEvery('VALIDATE_PCE', validatePce_saga);
}

