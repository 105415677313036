/*import axios from 'axios';
import { apiUrl, url_verify_address } from '../services/constant';

export const get_verify_address = (data) => dispatch => {
    const request = axios.post(apiUrl + url_verify_address, data);
    dispatch({
        type: 'VERIFY_ADDRESS',
        payload: request
    })
}
*/
export function get_verify_address(payload) {
    return {
        type: 'VERIFY_ADDRESS',
        payload,
    };
}
export function get_verify_address_done(request) {
    return {
        type: 'VERIFY_ADDRESS_DONE',
        payload: request,
    };
}