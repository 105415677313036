/*import axios from 'axios';
import { apiUrl, url_resend_verification_email } from '../services/constant';

export const get_resend_verification_code = (data) => dispatch => {
    const request = axios.post(apiUrl + url_resend_verification_email, data);
    dispatch({
        type: 'RESEND_VERIFICATION_CODE',
        payload: request
    })
}
*/
export function get_resend_verification_code(payload) {
    return {
        type: 'RESEND_VERIFICATION_CODE',
        payload,
    };
}
export function get_resend_verification_code_done(request) {
    return {
        type: 'RESEND_VERIFICATION_CODE_DONE',
        payload: request,
    };
}