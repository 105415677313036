import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { current_step, subscription_id } from '../../services/status';
import aide_circle from '../../assets/img/aide_circle.png';
import bg_aide1 from '../../assets/img/bg_aide1.png';
import bg_aide2 from '../../assets/img/bg_aide2.png';
import astuce from '../../assets/img/Btn astuce.png';
import astuce_svg from '../../assets/img/Btn astuce.svg';
import astuceActive_svg from '../../assets/img/Btn astuce actif.svg';
import astuceActive from '../../assets/img/btn_astuce_active.png';
import closeMobile from '../../assets/img/mobile_close.svg';
import closeShop from '../../assets/img/mobile_close.svg';
import clockMobile from '../../assets/img/clockMobile.png';
import clockMobile_svg from '../../assets/img/Btn Continuer plus tard.svg';
import shopMobile from '../../assets/img/shopMobile.png';
import shopMobile_svg from '../../assets/img/shopMobile.svg';
import clock from '../../assets/img/clock.svg';
import './aide.css';
import PopupRappel from './PopupRappel'
import PopupError from './PopupError'
import PopupContinuer from './PopupContinuer'
import PopupPCE from './PopupPCE'
import PopupBrochure from './PopupBrochure'
import OffreSelectioneePopup from './OffreSelectioneePopup'
import { dynamic_parameters } from '../../actions/settings';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get_offer_category } from '../../actions/get_offer_category';
import { get_numbers_subs } from '../../actions/all_subs';
import {Modal, Button} from "react-bootstrap"
import closeBtn from '../../assets/img/close-btn.svg';

const parentBlock = {
    position: 'absolute',
    top: 170,
    left: 30,
    right:30,
    zIndex: 9
}


const blockInfo = {
    background: '#EFF2F7',
    padding: '20px 15px',
    display: 'block',
    height: 'fit-content',
    borderRadius: 5,
    marginBottom:10
}


const paragraphe = {
    fontFamily: "RoobertEnel-Regular",
    fontSize: 15,
    color: '#0555fa',
    margin: 0,
    clear: 'both',
    width: '100%',
}

const buttonPopup = {
    cursor: "pointer",
    color: "#ffffff",
    background: "#E90657",
    textAlign: "center",
    borderRadius: "5px",
    padding: "2px 5px",
    marginTop: "20px",
    display: "block",
    fontSize: "16px",
    fontFamily: "RoobertEnel-Regular",
    width: "fit-content",
}


const styleLink = {
    color: '#e90657',
    display: 'inline-block',
    fontSize: 16,
    fontFamily: "RoobertEnel-Bold",
    textDecoration: 'none'
}

class Aide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_visible: false,
            visible_info: true,
            showPopup: parseInt(sessionStorage.getItem('get_recalled_popup')) === 1 && (current_step !== 'identification' && current_step !== 'choix_syndic' && current_step !== 'contact' && current_step !== 'remerciement' && current_step !== 'liste_etablissements' && current_step !== 'signature_contrat' && current_step !== 'bienvenue' && current_step !== 'verification_email') ? true : false,
            showPopupError: false,
            showPopupContinuer: false,
            showPopupPCE: false,
            showBlueMobile: false,
            showClockLater: false,
            showOfferSelected: false,
            backgroundBlue: null, 
            showInfo: true,
            widthSize: window.innerWidth,
            ShowBrochure : false,
            show: false,
            checkReminder: false,
            checkRappel: false,
            checkPhone: 'indisponible',
            hideEtreRappele: 'False'
        }
    }
    handleClose = () => {
        this.setState({
            show: false,
            showPopupContinuer: false,
            showPopupPCE: false,
            showPopupError: false,
            showPopup: false,
            ShowBrochure: false
        })
    }

    handleShow  = () => {
        this.setState({
            show: true
        })
    }
    togglePopupBrochure = () => {
        this.setState({
            ShowBrochure: !this.state.ShowBrochure,
            show: true
        });
        console.log('YEES')
    }
    handleClose = () => {
        this.setState({
            show: false,
            showPopupContinuer: false,
            showPopupPCE: false,
            showPopupError: false,
            showPopup: false,
            ShowBrochure: false
        })
    }

    handleShow  = () => {
        this.setState({
            show: true
        })
    }
    togglePopup = () => {
        if (sessionStorage.getItem('get_recalled_popup')) sessionStorage.removeItem('get_recalled_popup');
        const { showPopup, showPopupError } = this.state
        //window.scrollTo(0, 0)
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.setState({
            showPopup: !showPopup,
            showPopupError: false,
            show: true
        })
        //console.log('yees')
    }
    togglePopupError = () => {
        const { showPopupError } = this.state
        //window.scrollTo(0, 0)
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.setState({
            showPopupError: !showPopupError
        })
    }
    toggleContinuerPopup = () => {
        const {showPopupContinuer, showClockLater} = this.state
        //window.scrollTo(0, 0)
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.setState({
            showPopupContinuer: !showPopupContinuer,
            showClockLater: false,
            show: true
        })
    }
    togglePCEPopup = () => {
        const {showPopupPCE} = this.state
        //window.scrollTo(0, 0)
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.setState({
            showPopupPCE: !showPopupPCE,
            show: true
        })
    }
    toggleLinks = () => {
        const { is_visible } = this.state;
        this.setState({
            is_visible: !is_visible
        })
    };
    toggleBulbInfo = () => {
        const { visible_info } = this.state;
        this.setState({
            visible_info: !visible_info
        });
    };
    toggleBlocMobil = () => {
        const { showBlueMobile } = this.state;
        this.setState({
            showBlueMobile: !showBlueMobile,
            showClockLater: false
        });
    };
    toggleClockMobile = () => {
        const { showClockLater } = this.state;
        this.setState({
            showClockLater: !showClockLater,
            showOfferSelected: false,
        });
    };
    toggleShopMobile = () => {
        const { showOfferSelected } = this.state;
        this.setState({
            showOfferSelected: !showOfferSelected,
            showClockLater: false,
            show: true
        });
    };
    componentDidMount(){
        // GET OFFER CATEGORY TYPE LIST
        const { get_offer_category } = this.props;
        let data = {}
        data.subscription_id = subscription_id
        get_offer_category(data);
        
        if(window.innerWidth <= 768){
            this.setState({
                showInfo: true
            })
        }
    }
    componentDidUpdate(prevProps){
        const { offer_category, all_subs } = this.props;
        if(prevProps.offer_category !== offer_category){
            if(offer_category.result.status === 200){
                //console.log('data is : ', offer_category.result.data)
                this.setState({
                    backgroundBlue: offer_category.result.data.background !== null ? offer_category.result.data.background : null
                })
            }
        }
        if (prevProps.all_subs !== all_subs) {
            //console.log(all_subs)
            all_subs.result.data.map((itm, indx) => {
                if (itm.param === "CHECK_REMINDER") {
                    //console.log('YEES : ', itm.value)
                    this.setState({
                        checkReminder: itm.value,
                        checkRappel: itm.value
                    })
                }
                if(itm.param === 'ASSISTANT_PHONE_NUMBER') {
                    this.setState({
                        checkPhone: itm.value
                    })
                }
                if(itm.param === 'CACHER_ETRE_RAPPELE') {
                    this.setState({
                        hideEtreRappele: itm.value
                    })
                }
            })
        }
    }
    toggleBuild = () => {
        const {showInfo} = this.state
        this.setState({
            showInfo: !showInfo
        })
    }
    render() {
        const { is_visible, visible_info, showBlueMobile, showClockLater, showOfferSelected, backgroundBlue, showInfo, widthSize, checkReminder, checkRappel, checkPhone, hideEtreRappele } = this.state;
        let info_buld = <div className={window.innerWidth <= 992 ? "toggleInfo mobileInfos" : "toggleInfo" }>
        <p>
            Vous avez des questions sur l'une de nos offres ? Demandez à être recontacté par l'un de nos conseillers.
        </p>
        {
            checkReminder ?
                <span className="rappeler" onClick={this.togglePopup.bind(this)}>Être rappelé</span>
            :
                <span className="rappeler" onClick={this.togglePopup.bind(this)}>Nous appeler</span>
        }
        </div>;

        let bg_aide = bg_aide1;
        let saved_steps = ['saisie_pce_estimation', 'choix_duree_contrat', 'souscription', 'verification_email', 'donnees_client'];

        switch (current_step) {
            case 'identification':
                info_buld = (
                    <React.Fragment>
                        <div className={(is_visible ? "marge-top-aide" : "")} style={parentBlock}>
                            <div style={blockInfo} >
                                <div style={paragraphe}>
                                Vous <span style={styleLink}>créez votre entreprise</span>, déménagez ou souhaitez changer de fournisseur ? Complétez le formulaire ci-contre pour obtenir des offres adaptées, et souscrire votre contrat de gaz naturel. Vous pourrez <span style={styleLink}>télécharger un devis</span> à l’étape 3 du formulaire.
                                </div>
                            </div>
                            <div style={blockInfo}>
                                <div style={paragraphe}> La souscription en ligne pour plusieurs sites est possible uniquement s’il y a une seule entité en charge du paiement.</div>
                            </div>
                        </div>
                </React.Fragment>);
                bg_aide = bg_aide1;
                break;
            case 'choix_syndic':
                info_buld = ''
                bg_aide = bg_aide1;
                break;
            case 'liste_etablissements':
                info_buld = <React.Fragment><div className={window.innerWidth <= 992 ? "toggleInfo mobileInfos" : "toggleInfo" }>
                    <p>
                        Aidez-nous à identifier votre lieu de livraison en sélectionnant votre établissement dans la liste déroulante.
                    </p>
                </div></React.Fragment>;
                break;
            case 'contact':
                info_buld = ''
                bg_aide = bg_aide1;
                break;
            case 'liste_offres':
                {/*
                info_buld = <React.Fragment><div className={window.innerWidth <= 992 ? "toggleInfo mobileInfos" : "toggleInfo" }>
                    <p>
                        Nous vous proposons les offres les plus adaptées à vos besoins. Sélectionnez l’offre de votre choix et cliquez sur continuer afin de lancer la 
                        simulation de prix.<br/><br/>
                        Besoin d'aide ? <br />
                        Vous avez une question ? Demandez à être recontacté par l'un de nos conseillers.
                    </p>
                    { checkReminder ?
                        <span className="rappeler" onClick={this.togglePopup.bind(this)}>Être rappelé</span>
                        :
                        <span className="rappeler" onClick={this.togglePopup.bind(this)}>Nous appeler</span>
                    }
                </div></React.Fragment>;
                bg_aide = bg_aide1;
                break;
                */}
            case 'saisie_pce_estimation':
                info_buld = <React.Fragment>
                    <div className={(is_visible ? "marge-top-aide" : "")} style={parentBlock}>
                        <div style={blockInfo}>
                            <div style={paragraphe}>Le PCE (Point de Comptage et d'Estimation) est un numéro à 14 chiffres ou un numéro GI suivi de 6 chiffres. Ce numéro nous permet d'identifier rapidement 
                            et précisément votre compteur. Il se trouve sur votre facture de gaz, généralement dans l’emplacement dédié aux données client.</div>
                            <div style={buttonPopup} className="rappeler" onClick={this.togglePCEPopup.bind(this)}>Où trouver mon PCE ?</div>
                        </div>
                        {/*
                        <div style={blockInfo}>
                            <div style={paragraphe}>Besoin d’aide ? Demandez à être rappelé par un de nos conseillers </div>
                            { checkReminder ?
                                <div style={buttonPopup} className="rappeler" onClick={this.togglePopup.bind(this)}>Être rappelé</div>
                                :
                                <div style={buttonPopup} className="rappeler" onClick={this.togglePopup.bind(this)}>Nous appeler</div>
                            }
                        </div>
                        */}
                    </div>
                    </React.Fragment>;
                bg_aide = bg_aide2;
                break;
            case 'choix_duree_contrat':

                info_buld = <React.Fragment>
                    <div className={window.innerWidth <= 992 ? "toggleInfo mobileInfos" : "toggleInfo" }>
                        <p>
                        Votre tarif est estimé à partir du PCE que vous avez indiqué ou des données de consommation que vous avez fournies lors de l’étape précédente.  
                        <br/><br/>
                        Vous pouvez télécharger le devis de plusieurs offres en faisant plusieurs demandes successives, en modifiant les critères souhaités.
                        </p>
                        {/*
                            checkReminder ?
                                <span className="rappeler" onClick={this.togglePopup.bind(this)}>Être rappelé</span>
                            :
                                <span className="rappeler" onClick={this.togglePopup.bind(this)}>Nous appeler</span>
                        */}
                    </div></React.Fragment>;
                bg_aide = bg_aide2;
                break;
            case 'donnees_client':
                //info_buld = '';
                if(window.innerWidth >= 992){
                    info_buld = <OffreSelectioneePopup closePopup={this.togglePopupBrochure.bind(this)} ShowBrochure={this.state.ShowBrochure} />
                    bg_aide = bg_aide1;
                }
                break;
            case 'verification_email':
                info_buld = <React.Fragment><div className={window.innerWidth <= 992 ? "toggleInfo mobileInfos" : "toggleInfo" }>
                    <p>
                        Confirmez votre adresse email pour procéder à la signature électronique de votre contrat.
                    </p>
                </div></React.Fragment>;
                bg_aide = bg_aide1;
                break;
            case 'signature_contrat':
                info_buld = '';
                bg_aide = bg_aide1;
                break;
            case 'remerciement':
                info_buld = '';
                bg_aide = bg_aide1;
                break;
            case 'start':
                info_buld = '';
                bg_aide = bg_aide1;
                break;
            case 'bienvenue':
                info_buld = '';
                bg_aide = bg_aide1;
                break;
            case 'all_np_step':
                info_buld = '';
                bg_aide = bg_aide1;
                break;

            default:
                info_buld = <React.Fragment>
                    <div className={window.innerWidth <= 992 ? "toggleInfo mobileInfos" : "toggleInfo" }>
                        <p>
                            Vous avez une question sur l'une de nos offres ? Demandez à être recontacté par l'un de nos conseillers.
                    </p>
                        <span className="rappeler" onClick={this.togglePopup.bind(this)}>Être rappelé</span>
                    </div></React.Fragment>;
                bg_aide = bg_aide1;
                break;
        }
        return (
            <React.Fragment>
                <Col xs={12} sm={3} className="aide desktopHelpBlocParent" style={{ backgroundColor: '#0555FA', backgroundImage: `url(${backgroundBlue === '' ? bg_aide : backgroundBlue})`, backgroundPosition: 'right 70%', backgroundRepeat: 'no-repeat' }}>

                        <p onClick={this.toggleLinks}>Besoin d'aide ?<button className={(is_visible ? "aide_toggle active_aide" : "aide_toggle")} ></button></p>
                        {(is_visible ?
                            <React.Fragment>
                                <div className="wrapper-help">
                                    <img src={aide_circle} className="circle_aide" alt="Endesa S.A." />
                                    <div className="aide_links">
                                        {
                                            hideEtreRappele === 'False' || hideEtreRappele === 'false' ?
                                                checkReminder ?
                                                    <a href="#" onClick={this.togglePopup.bind(this)} className="rappel_link">Être rappelé</a>
                                                :
                                                    <a href="#" onClick={this.togglePopup.bind(this)} className="rappel_link">Nous appeler</a>
                                            : null
                                        }
                                        <a href={process.env.REACT_APP_API_URL+'contact/?subscription_id='+ subscription_id} target="_blank" className="contact_link">Contact</a>
                                        <a href={process.env.REACT_APP_API_URL+ 'faq'} target="_blank" className="faq_link">FAQ</a>
                                        {/*
                                            current_step !== 'identification' && current_step !== 'choix_syndic' && current_step !== 'contact' && current_step !== 'remerciement' && current_step !== 'liste_etablissements' && current_step !== 'signature_contrat' && current_step !== 'bienvenue' && current_step !== 'verification_email' && current_step !== 'all_np_step'?
                                            <a href="#" onClick={this.togglePopup.bind(this)} className="rappel_link">être rappelé</a>
                                            : null
                                        */}
                                    </div>
                                </div>
                            </React.Fragment>
                            :
                            ''
                        )}
                        {(visible_info && current_step !== 'choix_syndic' && current_step !== 'contact' && current_step !== 'signature_contrat' && current_step !== 'remerciement' && current_step !== 'bienvenue' && current_step !== 'all_np_step' ?
                            <React.Fragment>
                                <div className={(is_visible ? "toggle-info active-marge-toggle" : "toggle-info")}>
                                    {
                                        current_step !== 'donnees_client' ?
                                            <span className={showInfo ? "toggleBuld" : "toggleBuld animate"} onClick={this.toggleBuild} ></span>
                                        : null
                                    }
                                    <div className={showInfo ? 'parent_build' : 'hide-infos-build parent_build'}>
                                        {info_buld}
                                    </div>
                                </div>
                            </React.Fragment>
                            :
                            ''
                        )}
                        {(saved_steps.includes(current_step) ?
                            <button onClick={this.toggleContinuerPopup.bind(this)} className="btn_later">Continuer plus tard</button>
                            :
                            ''
                        )}
                </Col>
                <div className="wrapper-popups">
                {
                    current_step !== 'choix_syndic' && current_step !== 'contact' && current_step !== 'remerciement' && current_step !== 'donnees_client' && current_step !== 'liste_etablissements' && current_step !== 'signature_contrat' && current_step !== 'bienvenue' && current_step !== 'verification_email' && current_step !== 'all_np_step' ?
                    <div className="lightShowBlue">
                        <img alt="astuces endesa" src={astuce_svg} onClick={this.toggleBlocMobil} />
                    </div>
                    : null
                }
                {
                    current_step === 'saisie_pce_estimation' || current_step === 'choix_duree_contrat' || current_step === 'donnees_client' || current_step === 'verification_email' && showClockLater === false ?
                    showClockLater === false ?
                        <div className={showClockLater ? 'clockShow' : 'clockShow'}>
                            <img alt="astuces endesa" src={clockMobile_svg} onClick={this.toggleClockMobile} />
                        </div>
                    :   <div className='clockShow'>
                            <img alt="astuces endesa" src={closeShop} onClick={this.toggleClockMobile} />
                        </div>
                    : null
                }
                {
                    current_step === 'donnees_client' ?
                    <div className="shopShow">
                        <img alt="shop endesa" src={shopMobile_svg} onClick={this.toggleShopMobile} />
                    </div>
                    : null
                }
                {
                    showClockLater ?
                        <button onClick={this.toggleContinuerPopup.bind(this)} className="btn_later btn_later_mobile">Continuer plus tard <img src={clock} alt="endesa continuer plutard" /></button>
                        :
                        null
                }
                </div>
                {
                    showOfferSelected ?
                        <div className="popup_offer_selected_mobile">
                            <div className="wrapper_offer_mobile">
                                <OffreSelectioneePopup closePopup={this.togglePopupBrochure.bind(this)} ShowBrochure={this.state.ShowBrochure} />
                                <div className="shopShow showinside">
                                    <img alt="shop endesa" src={closeShop} onClick={this.toggleShopMobile} />
                                </div>
                            </div>
                        </div>
                        :
                        null
                }
                {
                    showBlueMobile ?
                    <Col xs={12} sm={12} md={12} lg={3} className="aide mobileHelpBlocParent" style={{ backgroundColor: '#0555FA', backgroundImage: `url(${backgroundBlue === null ? bg_aide : backgroundBlue})`, backgroundPosition: 'right bottom', backgroundRepeat: 'no-repeat' }}>
                        <div className="closeMobileHelpBloc">
                            <div className="wrapperCloseMobile">
                                <div className="closeMobileImg">
                                    <img onClick={this.toggleBlocMobil} alt="endesa fermer astuce" src={closeMobile} />
                                </div>
                                <span onClick={this.toggleBlocMobil}>Fermer</span>
                            </div>
                        </div>
                        <div className="mobileHelpBloc">
                            {(visible_info ?
                                <React.Fragment>
                                    <div className={(is_visible ? "toggle-info active-marge-toggle" : "toggle-info")}>
                                        {
                                            current_step !== 'donnees_client' ?
                                                <span className="toggleBuld" onClick={this.toggleBuild}></span>
                                            : null
                                        }
                                        <div className={showInfo ? 'parent_build' : 'hide-infos-build parent_build'}>
                                            {info_buld}
                                        </div>
                                    </div>
                                </React.Fragment>
                                :
                                ''
                            )}
                        </div>
                        <div className="lightShowBlue">
                            <img alt="astuces endesa" src={astuceActive_svg} onClick={this.toggleBlocMobil} />
                        </div>
                    </Col>
                    : null

                }
                {this.state.showPopupError ?
                        <React.Fragment>
                            <Modal dialogClassName="modal-90w" size="lg" centered show={this.state.show} onHide={this.handleClose}>
                                <PopupError
                                    text='Close Me'
                                    closePopup={this.togglePopupError.bind(this)}
                                    checkReminderProp={checkReminder}
                                />
                            </Modal>
                        </React.Fragment>
                    : null
                }
                {this.state.showPopup ?
                        <React.Fragment>
                            <Modal dialogClassName="modal-90w preventPopupInactivityToBeShown" size="lg" centered show={this.state.show} onHide={this.handleClose}>
                                <PopupRappel
                                    text='Close Me'
                                    closePopup={this.togglePopup.bind(this)}
                                    checkReminderProp={checkReminder}
                                    checkRappelProp={checkRappel}
                                    checkPhoneProp={checkPhone}
                                />
                            </Modal>
                        </React.Fragment>
                    : null
                }
                {this.state.showPopupContinuer ?
                        <React.Fragment>
                            <Modal dialogClassName="modal-90w preventPopupInactivityToBeShown" size="lg" centered show={this.state.show} onHide={this.handleClose}>
                                <PopupContinuer text='close me' closePopup={this.toggleContinuerPopup.bind(this)}/>
                            </Modal>
                        </React.Fragment>
                    : null
                }
                {this.state.showPopupPCE ?
                        <React.Fragment>
                            <Modal dialogClassName="modal-90w" size="lg" centered show={this.state.show} onHide={this.handleClose}>
                                <PopupPCE closePopup={this.togglePCEPopup.bind(this)} />
                            </Modal>
                        </React.Fragment>
                        : null
                }
                {this.state.ShowBrochure ?
                        <React.Fragment>
                            <Modal dialogClassName="modal-90w" size="lg" centered show={this.state.show} onHide={this.handleClose}>
                                <span className="close-btn-brochure" onClick={this.togglePopupBrochure.bind(this)}>
                                    <img src={closeBtn} />
                                </span>
                                <PopupBrochure closePopup={this.togglePopupBrochure.bind(this)} />
                            </Modal>
                        </React.Fragment>
                        : null
                }
                
            </React.Fragment>
        );
    }
}

function mapStateToProps({ offer_category, all_subs }) {
    return { offer_category, all_subs };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        get_offer_category: get_offer_category,
        get_numbers_subs: get_numbers_subs
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Aide);