// LISTE_OFFRES
export default (state = {}, action) => {
    switch (action.type) {
        case 'LISTE_OFFRES_DONE':
            return {
                result: action.payload
            }
        default:
            return state
    }
}