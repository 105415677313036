/*import axios from 'axios';
import { apiUrl, url_validate_pce } from '../services/constant';

export const valider_pce = (data) => dispatch => {
    const request = axios.post(apiUrl + url_validate_pce, data);
    dispatch({
        type: 'PCE_VALIDE',
        payload: request
    })
}
*/
export function valider_pce(payload) {
    return {
        type: 'PCE_VALIDE',
        payload,
    };
}
export function valider_pce_done(request) {
    return {
        type: 'PCE_VALIDE_DONE',
        payload: request,
    };
}