import { all } from 'redux-saga/effects';
import watch_get_numbers_subs_saga from './all_subs';
import watch_get_applicate_code_saga from './applicate_code';
import watch_get_brochure_saga from './brochure';
import watch_get_change_subscription_email_saga from './change_subscription_email';
import watch_check_promotion_code_saga from './check_promotion_code';
import watch_choix_duree_contrat_saga from './choix_duree_contrat';
import watch_get_communes_sage from './communes';
import watch_contact_saga from './contact';
import watch_get_continue_later_saga from './continuer_later';
import watch_donnees_client_saga from './donnees_client';
import watch_get_estimation_type_done from './estimation_type_list';
import watch_saisie_pce_estimation_saga from './estimation';
import watch_liste_etablissements_saga from './etablissement';
import watch_get_offer_category_saga from './get_offer_category';
import watch_get_recalled_saga from './get_recalled';
import watch_indentification_saga from './identification';
import watch_liste_offres_Saga from './offers';
import watch_get_payeurs_divergents_saga from './payeurs_divergents';
import watch_get_previous_saga from './previous';
import watch_get_resend_verification_code_saga from './resend_verification_email';
import watch_get_reset_subscription_saga from './reset_subscription';
import watch_dynamic_parameters_saga from './settings';
import watch_signature_contrat_sage from './signature_contrat';
import watch_start_saga from './start';
import watch_choix_syndic_saga from './syndic';
import watch_validatePce_saga from './validate_pce';
import watch_valider_pce_saga from './valide_pce';
import watch_verification_email_saga from './verification_email';
import watch_get_verify_address_saga from './verify_address';
import watch_get_anciennete_saga from './get_anciennete';
import watch_get_type_chaudiere_saga from './get_type_chaudiere';
import watch_validate_estimation_saga from './validate_estimation';
import watch_get_verification_delay_saga from './verification_delay';
import watch_get_docusign_link_saga from './docusign_link';
import watch_get_pre_verified_address_saga from './pre_verified_address';
import watch_get_data_saga from './get_data';
import watch_get_sub_key_saga from './get_sub_key';
import watch_get_file_type_saga from './file_types';
import watch_get_devis_saga from './devis'


export default function* rootSaga() {
    yield all([
        watch_get_numbers_subs_saga(),
        watch_get_applicate_code_saga(),
        watch_get_brochure_saga(),
        watch_get_change_subscription_email_saga(),
        watch_check_promotion_code_saga(),
        watch_choix_duree_contrat_saga(),
        watch_get_communes_sage(),
        watch_contact_saga(),
        watch_get_continue_later_saga(),
        watch_donnees_client_saga(),
        watch_get_estimation_type_done(),
        watch_saisie_pce_estimation_saga(),
        watch_liste_etablissements_saga(),
        watch_get_offer_category_saga(),
        watch_get_recalled_saga(),
        watch_indentification_saga(),
        watch_liste_offres_Saga(),
        watch_get_payeurs_divergents_saga(),
        watch_get_previous_saga(),
        watch_get_resend_verification_code_saga(),
        watch_get_reset_subscription_saga(),
        watch_dynamic_parameters_saga(),
        watch_signature_contrat_sage(),
        watch_start_saga(),
        watch_choix_syndic_saga(),
        watch_validatePce_saga(),
        watch_valider_pce_saga(),
        watch_verification_email_saga(),
        watch_get_verify_address_saga(),
        watch_get_anciennete_saga(),
        watch_get_type_chaudiere_saga(),
        watch_validate_estimation_saga(),
        watch_get_verification_delay_saga(),
        watch_get_docusign_link_saga(),
        watch_get_pre_verified_address_saga(),
        watch_get_data_saga(),
        watch_get_sub_key_saga(),
        watch_get_file_type_saga(),
        watch_get_devis_saga()
    ]);
}
  