export function get_docusign_link(payload) {
    return {
        type: 'DOCUSIGN_LINK',
        payload,
    };
}
export function get_docusign_link_done(request) {
    return {
        type: 'DOCUSIGN_LINK_DONE',
        payload: request,
    };
}