import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { post } from '../services/request';
import { apiUrl, url_choix_duree_contrat } from '../services/constant';
import { choix_duree_contrat_done } from '../actions/choix_duree_contrat';


function* choix_duree_contrat_saga(action) {
	yield call(
		callApi,
		apiUrl + url_choix_duree_contrat,
		post,
        action.payload,
        choix_duree_contrat_done,
	);
}
export default function* watch_choix_duree_contrat_saga() {
    yield takeEvery('CHOIX_DUREE_CONTRAT', choix_duree_contrat_saga);
}

