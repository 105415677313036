import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { post } from '../services/request';
import { apiUrl, url_liste_etablissements } from '../services/constant';
import { liste_etablissements_done } from '../actions/etablissement';


function* liste_etablissements_saga(action) {
	yield call(
		callApi,
		apiUrl + url_liste_etablissements,
		post,
        action.payload,
        liste_etablissements_done,
	);
}
export default function* watch_liste_etablissements_saga() {
    yield takeEvery('LIST_ETABLISSEMENT', liste_etablissements_saga);
}

