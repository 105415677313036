import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { connect } from 'react-redux';
import { choix_syndic } from '../../actions/syndic';
import { bindActionCreators } from 'redux';
import { current_step, subscription_id } from '../../services/status';
import { get_previous } from '../../actions/previous';
import { get_communes } from '../../actions/communes';
import { get_reset_subscription } from '../../actions/reset_subscription';
import { Container, Row, Col } from 'react-bootstrap';
import site_icon from '../../assets/img/icon_site.png';
import site_icon_active from '../../assets/img/icon_site_active.png';
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";
import swal from 'sweetalert';
import { Tooltip } from "react-tippy";
import icon_help from "../../assets/img/calc.svg";


class Syndic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            frm_data: {},
            isLoading: false,
            choix_syndic: (localStorage.getItem('next') ? JSON.parse(localStorage.getItem('next')) : JSON.parse(localStorage.getItem('current_step_continue'))),
            names_copro: [],
            current_name: '',
            current_postal_code: '',
            postalcodes: [],
            name_copro: '',
            list_copropieties: [0],
            list_copropieties_cp: [],
            list_copropieties_communes: [],
            current_type: '',
            current_communes: '',
            communes_list: [],
            cp_input: '',
            client_value: '',
            type_contrat: '',
            site_key: process.env.REACT_APP_TAG_RECAPTCHA_V3 ? process.env.REACT_APP_TAG_RECAPTCHA_V3 : '',
            token: '',
            is_ready_only: false,
            show_list: false,
            show_list_commune: false,
            showAddCopro: false,
            limitSite: 0,
            non_field_errors: [],
            detail: '',
            widthSize: window.innerWidth
        }
    }
    componentWillUnmount() {
        localStorage.removeItem('current_step_continue');
    }
    componentDidMount() {
        const { choix_syndic, site_key, postalcodes, is_ready_only, limitSite, syndic } = this.state;
        loadReCaptcha(site_key, this.verifyCallback);
        if (localStorage.getItem('previous')) {
            this.setState({
                //is_ready_only: JSON.parse(localStorage.getItem('previous')).is_readonly
            })
        }
    }
    componentWillMount() {
        const { choix_syndic } = this.state
        // localstorage next
        if (localStorage.getItem('current_step_continue')) {
            if(JSON.parse(localStorage.getItem('current_step_continue')).data.step === "choix_syndic"){
                this.setState({
                    client_value: JSON.parse(localStorage.getItem('current_step_continue')).data.data.type_subscriber_for_syndic,
                    type_contrat: JSON.parse(localStorage.getItem('current_step_continue')).data.data.type_contract_for_syndic,
                    postalcodes: JSON.parse(localStorage.getItem('current_step_continue')).data.postalcodes,
                    names_copro: JSON.parse(localStorage.getItem('current_step_continue')).data.data.names_copro,
                    is_ready_only: JSON.parse(localStorage.getItem('current_step_continue')).data.is_readonly,
                    limitSite: JSON.parse(localStorage.getItem('current_step_continue')).data.data.sites_number
                })
            }
        }
        if (localStorage.getItem('next')) {
            this.setState({
                client_value: JSON.parse(localStorage.getItem('next')).data.type_subscriber_for_syndic,
                type_contrat: JSON.parse(localStorage.getItem('next')).data.type_contract_for_syndic,
                postalcodes: JSON.parse(localStorage.getItem('next')).postalcodes,
                names_copro: JSON.parse(localStorage.getItem('next')).data.names_copro,
                is_ready_only: JSON.parse(localStorage.getItem('next')).is_readonly,
                limitSite: JSON.parse(localStorage.getItem('next')).data.sites_number
            })
        }
        // GET VALUE OF READ ONLY WHEN CLICKED PREVIOUS
        // localstorage previous
        if (localStorage.getItem('previous')) {
            this.setState({
                client_value: JSON.parse(localStorage.getItem('previous')).data.type_subscriber_for_syndic,
                type_contrat: JSON.parse(localStorage.getItem('previous')).data.type_contract_for_syndic,
                names_copro: JSON.parse(localStorage.getItem('previous')).data.names_copro,
                postalcodes: JSON.parse(localStorage.getItem('previous')).postalcodes,
                is_ready_only: JSON.parse(localStorage.getItem('previous')).is_readonly,
                limitSite: JSON.parse(localStorage.getItem('previous')).data.sites_number
            })
        }
    }
    updateToken = () => {
        // you will get a new token in verifyCallback
        this.recaptcha.execute()
    };
    trigger_list = () => {
        const { show_list } = this.state;
        this.setState({
            show_list: !show_list,
            show_list_commune: false
        })
    };
    trigger_list_commune = () => {
        const { show_list_commune } = this.state;
        this.setState({
            show_list_commune: !show_list_commune,
            show_list: false
        })
    };
    setCurrentType = (item) => {
        const { postalcodes, show_list, current_type } = this.state;
        const { get_communes } = this.props;
        let clicked_type = postalcodes.filter((type) => type === item);
        let frm_data = {
            postal_code: clicked_type[0],
            subscription_id: subscription_id
        }
        this.setState({
            current_type: clicked_type[0],
            current_postal_code: clicked_type[0],
            show_list: !show_list,
            current_communes: ''
        })
        // Send CP to WS get_communes
        get_communes(frm_data)
    };
    setCurrentCommune = (item) => {
        const { list_copropieties_communes, show_list_commune } = this.state;
        let clicked_commune = list_copropieties_communes.filter((type) => type === item);

        this.setState({
            current_communes: clicked_commune[0].town,
            commune_id: clicked_commune[0].id,
            show_list_commune: !show_list_commune
        })

    }
    verifyCallback = (recaptchaToken) => {
        // Here you will get the final recaptchaToken!!!  
        this.setState({
            token: recaptchaToken
        });
    }
    /*
    componentWillReceiveProps(prevProps) {
        const { syndic } = this.props;
        if (prevProps.syndic !== syndic) {
            console.log('receive prop : ', prevProps.syndic.result.response)
            if(prevProps.syndic.result.response.status !== 200){
                this.setState({ 
                    non_field_errors: ['error1', 'error2', 'error3'], 
                    detail: prevProps.syndic.result.response.data.detail ? prevProps.syndic.result.response.data.detail : '' 
                });
            }
        }
    }
    */
    componentDidUpdate(prevProps) {
        const { syndic, previous, communes, reset_subscription } = this.props;
        // Enable navigation prompt
        window.onbeforeunload = function() {
            return true;
        };
        // GET NEW KEY WHEN RESET SUBS
        if (prevProps.reset_subscription !== reset_subscription) {
            //console.log('reset_subscription', reset_subscription);
            // Remove navigation prompt
            window.onbeforeunload = null;
            let newKey = reset_subscription.result.data.subscription_id;
            //console.log('reset_subscription 2 : ', newKey);
            //window.location.assign(newKey)
            window.location.pathname = newKey + '/start'
        }
        // LOAD COMMUNES ON CHANGE CP
        if (prevProps.communes !== communes) {
            this.setState({
                list_copropieties_communes: communes.result.data
            });
            //console.log('data dispatch : ', this.state.list_copropieties_communes)
        }
        // NEXT
        if (prevProps.syndic !== syndic) {
            // Remove navigation prompt
            window.onbeforeunload = null;
            //NEXT STEP
            if (syndic.result.status === 200) {
                localStorage.removeItem('previous');
                localStorage.removeItem('current_step_continue');
                localStorage.setItem('step', syndic.result.data.step);
                localStorage.setItem('subscription_id', syndic.result.data.subscription_id);
                localStorage.setItem('next', JSON.stringify(syndic.result.data));
                localStorage.setItem('current_step_continue', JSON.stringify(syndic.result.data));
                localStorage.setItem('type_subscription', syndic.result.data.type_subscription);
                this.setState({
                    isLoading: true,
                    is_ready_only: choix_syndic.is_readonly
                });
                window.location.reload(false);
                window.location.replace(syndic.result.data.step)
            } else {
                const {non_field_errors, detail} = this.state
                var divList = document.createElement("div");
                if(syndic.result.response.data.non_field_errors){
                    divList.innerHTML = "<div class='list_errors'><ul>"+ syndic.result.response.data.non_field_errors.map((error, idx) => {return `<li>${error}</li>` }).join('') +"</ul></div>";
                }
                swal({
                    title: "Erreur dans le formulaire",
                    text: syndic.result.response.data.detail,
                    content: divList,
                    icon: "error",
                }).then(() => {
                    this.setState({
                        isLoading: false
                    });
                })
            }
        }
        // PREVIOUS STEP
        if (prevProps.previous !== previous) {
            setTimeout(() => {
                // Remove navigation prompt
                window.onbeforeunload = null;
                localStorage.setItem('step', previous.result.data.step);
                localStorage.setItem('subscription_id', previous.result.data.subscription_id);
                localStorage.setItem('previous', JSON.stringify(previous.result.data));
                // Redirect
                window.location.reload(false);
                window.location.replace(previous.result.data.step)
            }, 2000);
        }
    }
    handleClient = (e) => {
        this.setState({
            client_value: e.target.value
        });
    };
    handleTypeContrat = (e) => {
        this.setState({
            type_contrat: e.target.value,
        });
    };
    handleCopro = (e) => {
        this.setState({
            current_name: e.target.value
        });
    };
    addCopro = () => {
        const { current_postal_code, names_copro, current_name, commune_id, current_communes } = this.state;
        let dict_ftm = names_copro;
        let name_obj = { 'name': current_name, 'postalcode': current_postal_code, 'commune': commune_id, 'town_name': current_communes }
        dict_ftm.push(name_obj);
        this.setState({
            names_copro: dict_ftm,
            current_name: '',
            current_type: '',
            current_communes: '',
            commune_id: '',
            list_copropieties_communes: []
        });
        //console.log('clicked')
    }
    // REMOVE NAMES COPRO FROM LIST
    remove_list = (item) => {
        const { names_copro, limitSite } = this.state;
        let rest_list_names_copro = names_copro.filter((cp) => cp !== item);
        //console.log('name copro length : ', names_copro.length)
        //console.log('limitSite : ', limitSite)
        this.setState({
            names_copro: rest_list_names_copro
        });
        //console.log('clicked : ', rest_list_names_copro)
    };
    serializeForm = (form) => {
        var obj = {};
        var formData = new FormData(form);
        for (var key of formData.keys()) {
            obj[key] = formData.get(key);
        }
        return obj;
    };
    handleSubmit = (e) => {
        //input syndic:
        //{
        //    "names_copro": [
        //        { "name": "Tossssssssssssssssssssssms", "postalcode": 25300 ,"commune" :42}       
        //    ],
        //    "subscription_id": "90140288-f062-11ea-9026-3464a9350bfd",
        //    "type_subscriber_for_syndic": "copro",
        //    "type_contract_for_syndic" : "contract_for_copro"
        //}
        e.preventDefault();
        const { choix_syndic } = this.props;
        const { frm_data, names_copro, postalcodes, token, type_contrat, client_value } = this.state;
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        const data_entered_array = Object.entries(this.serializeForm(e.target));
        const data_entered_obj = this.serializeForm(e.target);
        let cps = postalcodes;
        let names = names_copro;
        let frm_keys = Object.keys(data_entered_obj);
        data_entered_array.forEach(([prop, val]) => ((frm_keys.indexOf(prop) > -1) && cps.includes(prop) ?
            names = { ...names, [val]: prop }
            : ''));
        //frm_data.type_subscriber_for_syndic = data_entered_obj.client;
        frm_data.type_subscriber_for_syndic = client_value;
        //frm_data.type_contract_for_syndic = data_entered_obj.type_contrat;
        frm_data.type_contract_for_syndic = type_contrat;
        frm_data.names_copro = names;
        //frm_data.subscription_id = subscription_id;
        frm_data.subscription_id = path_remove_slash;
        frm_data.recaptcha = token
        // SUBMIT DATA TO BACKEND
        choix_syndic(frm_data);
        //console.log(frm_data)
        //console.log('data_entered_obj : ', data_entered_obj)
        // LOADING
        this.setState({
            isLoading: true
        });
    };
    handleSubmitNeither = (e) => {
        e.preventDefault();
        const { choix_syndic } = this.props;
        const { frm_data, names_copro, postalcodes, token, type_contrat, client_value } = this.state;
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        let names = names_copro;
        frm_data.type_subscriber_for_syndic = "neither_syndic_nor_copro";
        //frm_data.type_contract_for_syndic = data_entered_obj.type_contrat;
        frm_data.names_copro = names;
        //frm_data.subscription_id = subscription_id;
        frm_data.subscription_id = path_remove_slash;
        //frm_data.recaptcha = token
        // SUBMIT DATA TO BACKEND
        choix_syndic(frm_data);
        //console.log(frm_data)
        //console.log('data_entered_obj : ', data_entered_obj)
        // LOADING
        this.setState({
            isLoading: true
        });
    };
    // RESET DATA - SOUSSCRIPTION
    resetData = (e) => {
        e.preventDefault();
        const { get_reset_subscription } = this.props;
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        let data = {};
        //data.subscription_id = subscription_id;
        data.subscription_id = path_remove_slash;
        swal({
            title: "Etes-vous sûr ? Vous perdrez toutes les informations renseignées",
            //text: "Once deleted, you will not be able to recover this imaginary file!",
            icon: "warning",
            buttons: ["Non", "Oui"],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    window.dataLayer.push({
                        event: 'gtm.click.syndic_Reinitialiser',                
                    });
                    swal("Votre souscription est réinitialisée", {
                        icon: "success",
                    }).then(() => {
                        //console.log('call ws : ', data);
                        get_reset_subscription(data)
                    });
                } else {
                    //swal("Your imaginary file is safe!");
                }
            });
    }
    // GET PREVIOUS
    go_back = (e) => {
        e.preventDefault();
        const { get_previous } = this.props;
        let data = {}
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        data.type_step = 'previous';
        data.subscription_id = path_remove_slash;
        data.current_step = current_step;
        get_previous(data);
        this.setState({
            isLoading: true
        });
    }
    render() {
        const {
            name_copro,
            client_value,
            type_contrat,
            postalcodes,
            isLoading,
            site_key,
            is_ready_only,
            show_list,
            list_copropieties_cp,
            current_type,
            list_copropieties_communes,
            show_list_commune,
            current_communes,
            names_copro,
            current_name,
            limitSite,
            widthSize
        } = this.state;
        return (
            <React.Fragment>
                {isLoading ?
                    <div className="loadingKey">
                        <Spinner className="spinner-border text-primary" animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                    :
                    <React.Fragment>
                        <Container>
                            <Row>
                                <Col>
                                    {is_ready_only ? <div className="blank_div"></div> : null}
                                    <h1 className="blue-border"><span></span>Je m'identifie</h1>
                                    <div className="etablissement-wrapper mt-4 mb-4">
                                        <div className="icon-help">
                                            <img src={icon_help} alt="endesa etablissement" />
                                        </div>
                                        <div className="etablissement-text">
                                            <p>
                                            Pour vous fournir un devis adapté à vos besoins, nous vous demandons ci-dessous quelques renseignements sur votre consommation énergétique. Vous trouverez nos meilleurs tarifs et vous pourrez télécharger un devis dans l'étape suivante !
                                            </p>
                                        </div>
                                    </div>
                                    <form onSubmit={this.handleSubmit} id="syndic_frm">
                                        <div className="form_group">
                                            <p className="sub-title">Êtes-vous : <span className="red_star">*</span></p>
                                            <label id='Syndic' className={(client_value === 'syndic' ? 'radio_3 oui' : 'radio_3')} htmlFor="radio_3_oui">
                                                <span>Un syndic</span>
                                                {(client_value === 'syndic' ? <i className="fas fa-check"></i> : '')}
                                                <input name="client" id="radio_3_oui" type="radio" value="syndic" onChange={this.handleClient} />
                                            </label>
                                            <label id='Copropriete' className={(client_value === 'copro' ? 'radio_3 non' : 'radio_3')} htmlFor="radio_3_non">
                                                <span>Une copropriété</span>
                                                {(client_value === 'copro' ? <i className="fas fa-check"></i> : '')}
                                                <input name="client" id="radio_3_non" type="radio" value="copro" onChange={this.handleClient} />
                                            </label>
                                        </div>
                                        <div className="form_group">
                                            <div className="box_aucun">
                                                <label className={(client_value === 'neither_syndic_nor_copro' ? 'radio_4 aucun' : 'radio_4')} htmlFor="radio_4_aucun">
                                                    <span onClick={this.handleSubmitNeither} >Aucun de ces deux choix</span>
                                                    <input name="client" id="radio_4_aucun" type="radio" value="neither_syndic_nor_copro" onChange={this.handleClient} />
                                                </label>
                                            </div>
                                        </div>
                                        {((client_value === 'syndic') || (client_value === 'copro') ?
                                            <div className="form_group pt-4">
                                                <p className="mb-3">Choisissez le type de contrat <span className="red_star">*</span></p>
                                                <label className={(type_contrat === 'contract_for_copro' ? 'radio_5 coproprie' : 'radio_5')} htmlFor="contrat_copr">
                                                    <input id="contrat_copr" name="type_contrat" value="contract_for_copro" type="radio" onChange={this.handleTypeContrat} />
                                                    <span>Contrat pour Copropriétés</span>
                                                </label>
                                                <label className={(type_contrat === 'contract_for_syndic' ? 'radio_5 syndic' : 'radio_5')} htmlFor="contrat_syndic">
                                                    <input id="contrat_syndic" name="type_contrat" value="contract_for_syndic" type="radio" onChange={this.handleTypeContrat} />
                                                    <span>Contrat pour Syndic</span>
                                                </label>
                                            </div>
                                            : ''
                                        )}
                                        {(type_contrat === 'contract_for_copro' ?
                                            <Row>
                                                <Col xs={12} md={12}>
                                                    <div className="form_group pt-5">
                                                        <div className="block">
                                                            {limitSite > names_copro.length ?
                                                                <React.Fragment >
                                                                    <div className="form_group"  >
                                                                        <div className="space-right wrapper-input">
                                                                            <p>Nom des Copropriétés <span className="red_star">*</span></p>
                                                                            <input type="text" name={current_type} onChange={this.handleCopro}  value={current_name} className="simple_input copro_text mt-2" />
                                                                            {/*
                                                                                names_copro.map((name, idx) => {
                                                                                    while(true){
                                                                                        if(name.name === current_name){
                                                                                            return <p key={idx} className="text-danger small-text-help mt-2">Merci de saisir un nom copropriété différent.</p>
                                                                                            break;
                                                                                        }
                                                                                        return;
                                                                                        break;
                                                                                    }
                                                                                })
                                                                            */
                                                                            }
                                                                        </div>
                                                                        {/* OLD UI Dropdown 
                                                                        <div className="wrapper-input">
                                                                            <p>Code Postal <span className="red_star">*</span></p>
                                                                            <div className="list_commune list_postal list_box_container mt-2">
                                                                                <div className={`list_box_options${(show_list ? ' show_list' : '')}`}>
                                                                                    <p className="list_box_option">{current_type}</p>
                                                                                    {
                                                                                        postalcodes.map((item, index) => {
                                                                                            return <p key={index} className="list_box_option" onClick={() => this.setCurrentType(item)}>{item}</p>;
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                                <div className="trigger_list_box" onClick={this.trigger_list} >
                                                                                    {(show_list ? <i className="fas fa-chevron-up"></i> : <i className="fas fa-chevron-down"></i>)}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        */}
                                                                        {/* New UI Dropdown */}
                                                                        <div className="space-right wrapper-postal wrapper-input">
                                                                            <p>Code Postal <span className="red_star">*</span></p>
                                                                            <div className="select dropdown-postal mt-2" onClick={this.trigger_list}>
                                                                                <input className="toggle" readOnly type="text" />
                                                                                <div className="arrow">
                                                                                    {(show_list ? 
                                                                                        <i className="fas fa-chevron-up"></i> 
                                                                                    : <i className="fas fa-chevron-down"></i>)}
                                                                                </div>
                                                                                <div className="selected">
                                                                                    <div className="value">{current_type}</div>
                                                                                </div>
                                                                                <div className={`dropdown ${(show_list ? '' : 'hide-drop')}`}>
                                                                                    {
                                                                                        postalcodes.map((item, index) => {
                                                                                            return <p key={index} className="value" onClick={() => this.setCurrentType(item)}>{item}</p>;
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/* End UI Dropdown */}
                                                                        {list_copropieties_communes.length > 0 ?
                                                                            <React.Fragment>
                                                                                {/* OLD UI Dropdown
                                                                                <div className="wrapper-input">
                                                                                    <p>Nom de la commune <span className="red_star">*</span></p>
                                                                                    <div className="list_commune list_box_container mt-2" style={{ minWidth: '130px' }} onClick={this.trigger_list_commune}>
                                                                                        <div className={`list_box_options${(show_list_commune ? ' show_list' : '')}`} style={{ minWidth: '80px' }}>
                                                                                            <p className="list_box_option">{current_communes}</p>
                                                                                            {
                                                                                                list_copropieties_communes.map((item, index) => {
                                                                                                    return <p key={index} className="list_box_option" onClick={(e) => this.setCurrentCommune(item)} >{item.town}</p>;
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                        <div className="trigger_list_box" onClick={this.trigger_list_commune} >
                                                                                            {(show_list_commune ? <i className="fas fa-chevron-up"></i> : <i className="fas fa-chevron-down"></i>)}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                */}
                                                                                <div className="space-right wrapper-postal wrapper-input">
                                                                                    <p>Nom de la commune <span className="red_star">*</span></p>
                                                                                    <div className="select dropdown-postal mt-2" title={current_communes} onClick={this.trigger_list_commune}>
                                                                                        <input className="toggle" readOnly type="text" />
                                                                                        <div className="arrow">
                                                                                            {(show_list_commune ? 
                                                                                                <i className="fas fa-chevron-up"></i> 
                                                                                            : <i className="fas fa-chevron-down"></i>)}
                                                                                        </div>
                                                                                        <div className="selected">
                                                                                            <p className="value">{current_communes}</p>
                                                                                        </div>
                                                                                        <div className={`dropdown ${(show_list_commune ? '' : 'hide-drop')}`}>
                                                                                            {
                                                                                                list_copropieties_communes.map((item, index) => {
                                                                                                    return <p key={index} className="value" onClick={(e) => this.setCurrentCommune(item)}>{item.town}</p>;
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/*
                                                                                    current_name.replace(/\s/g, '').length  && current_name !== '' && current_type !== '' && current_communes !== '' && current_name !== null && current_type !== null && current_communes !== null ? 
                                                                                    names_copro.length > 0 ?    
                                                                                        (names_copro.some(name => name.name === current_name && name.postalcode === current_type && name.town_name === current_communes ) ? 
                                                                                            <p className="text-danger small-text-help mt-2">Merci de saisir un nom copropriété différent.</p> 
                                                                                            : <p className="add_etab_to_list mt-3 add_copro_syndic" onClick={this.addCopro}>Ajouter cette copropriété<i className="fas fa-plus"></i></p>)
                                                                                        : <p className="add_etab_to_list mt-3 add_copro_syndic" onClick={this.addCopro}>Ajouter cette copropriété<i className="fas fa-plus"></i></p>
                                                                                    : null
                                                                                */}
                                                                                {
                                                                                    current_name.replace(/\s/g, '').length  && current_name !== '' && current_type !== '' && current_communes !== '' && current_name !== null && current_type !== null && current_communes !== null ? 
                                                                                    names_copro.length > 0 ?    
                                                                                        (names_copro.some(name => name.name === current_name && name.postalcode === current_type && name.town_name === current_communes ) ? 
                                                                                            <p className="text-danger small-text-help mt-2">Merci de saisir un nom copropriété différent.</p> 
                                                                                            : widthSize > 1024 ?
                                                                                            <Tooltip className='space-right add_etab_to_list mt-3 add_copro_syndic' title="Ajouter cette copropriété" position={widthSize > 576 ? "right" : ""} trigger={widthSize > 576 ? "mouseenter" : "click"}>
                                                                                                    <p className='visible-mobile'>Ajouter une copropriété</p>
                                                                                                    <span onClick={this.addCopro} className="fas fa-plus" data-class="own_tooltip"></span>
                                                                                                </Tooltip>
                                                                                                :   <p className="add_etab_to_list mt-3 add_copro_syndic" onClick={this.addCopro}>Ajouter cette copropriété<i className="fas fa-plus"></i></p> )
                                                                                        : widthSize > 1024 ? 
                                                                                        <Tooltip className='space-right add_etab_to_list mt-3 add_copro_syndic' title="Ajouter cette copropriété" position={widthSize > 576 ? "right" : ""} trigger={widthSize > 576 ? "mouseenter" : "click"}>
                                                                                            <p className='visible-mobile'>Ajouter une copropriété</p>
                                                                                            <span onClick={this.addCopro} className="fas fa-plus" data-class="own_tooltip"></span>
                                                                                        </Tooltip> 
                                                                                        : <p className="add_etab_to_list mt-3 add_copro_syndic" onClick={this.addCopro}>Ajouter cette copropriété<i className="fas fa-plus"></i></p>
                                                                                    : null
                                                                                }
                                                                            </React.Fragment>
                                                                            : null
                                                                        }
                                                                    </div>
                                                                </React.Fragment>
                                                                : null
                                                            }
                                                            <div className="block syndic_infos">
                                                                {
                                                                    names_copro.map((cp, index) => {
                                                                        return <p key={index} className="names-copro">{cp.name} - {cp.postalcode} - {cp.town_name}<i className="fas fa-times" onClick={() => this.remove_list(cp)}></i></p>;
                                                                    })
                                                                }
                                                            </div>

                                                            {/* <p className="add_etab_to_list mt-3" style={{ float: 'none', display: 'inline', clear: 'none' }} onClick={() => this.addCopro(cp)}><i className="fas fa-plus"></i></p> */}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={6}>

                                                </Col>
                                            </Row>
                                            : ''
                                        )}
                                        <div className="form_group mt-5"><p> </p>
                                            <input type="submit" name="btn_submit" 
                                                disabled={ type_contrat === 'contract_for_copro' && names_copro.length === limitSite ? false 
                                                : type_contrat === 'contract_for_syndic' ? false
                                                : true} 
                                                id='syndic_continue' className="btn_next_continue btn_submit" onSubmit={this.handleSubmit} value="Continuer" />
                                            {/*<label className="previous" onClick={this.go_back}><i className="fas fa-chevron-left"></i>Étape précédente</label>*/}
                                            {
                                                is_ready_only ?
                                                    <label className="reset-data btn_reset" onClick={this.resetData}><i className="far fa-trash-alt"></i>Réinitialiser la souscription</label>
                                                    :
                                                    <label className="previous" id='syndic_previous' onClick={this.go_back}><i className="fas fa-chevron-left"></i>Étape précédente</label>
                                            }
                                        </div>
                                        <ReCaptcha
                                            ref={ref => this.recaptcha = ref}
                                            sitekey={site_key}
                                            action={this.updateToken}
                                            verifyCallback={this.verifyCallback}
                                        />
                                    </form>
                                </Col>
                            </Row>
                        </Container>
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}
function mapStateToProps({ syndic, previous, communes, reset_subscription }) {
    return { syndic, previous, communes, reset_subscription };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        choix_syndic: choix_syndic,
        get_previous: get_previous,
        get_reset_subscription: get_reset_subscription,
        get_communes: get_communes
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Syndic);