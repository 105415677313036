/* import axios from 'axios';
import { apiUrl, url_identification } from '../services/constant';

export const identification = (data) => dispatch => {
    const request = axios.post(apiUrl + url_identification, data);
    dispatch({
        type: 'IDENTIFICATION',
        payload: request
    })
}*/

export function identification(payload) {
    return {
        type: 'IDENTIFICATION',
        payload,
    };
}
export function identification_done(request) {
    return {
        type: 'IDENTIFICATION_DONE',
        payload: request,
    };
}