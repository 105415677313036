import React from "react";
import swal from 'sweetalert';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { identification } from '../../actions/identification';
import { current_step, subscription_id } from '../../services/status';
import { get_recalled } from '../../actions/get_recalled';
import './aide.css';
import phone from '../../assets/img/phone.svg';
import closeBtn from '../../assets/img/close-btn.svg';
import closeMobile from '../../assets/img/mobile_close.png';
import PhoneInput from 'react-phone-input-2'
import PopupRappel from './PopupRappel'

class PopupError extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showPopup: parseInt(sessionStorage.getItem('get_recalled_popup')) === 1 && (current_step !== 'identification' && current_step !== 'choix_syndic' && current_step !== 'contact' && current_step !== 'remerciement' && current_step !== 'liste_etablissements' && current_step !== 'signature_contrat' && current_step !== 'bienvenue' && current_step !== 'verification_email') ? true : false,
            showPopupEstimation: false
        }
    }
    togglePopup = () => {
        if (sessionStorage.getItem('get_recalled_popup')) sessionStorage.removeItem('get_recalled_popup');
        const { showPopup } = this.state
        //window.scrollTo(0, 0)
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.setState({
            showPopup: !showPopup
        }, () => {
            if(showPopup === true){
                this.props.close_popup()
            }
        })
    }
    initPopup = () => {
        this.setState({
            showPopup: false
        })
        console.log('TEEEST 1')
    }
    togglePopupEstimation = () => {
        const { showPopupEstimation } = this.state
        //window.scrollTo(0, 0)
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.setState({
            //showPopupEstimation: !showPopupEstimation
        })
        this.props.show_popup_facture();
    }
    componentDidMount(){
        //window.scrollTo(0, 0)
        window.scrollTo({top: 0, behavior: 'smooth'});
    }
    render() {
        return (
            <React.Fragment>
                {
                    this.state.showPopup === false ?
                        this.state.showPopupEstimation ?
                            <PopupRappel
                                text='Close Me'
                                closePopup={this.togglePopup.bind(this)}
                            />
                    :
                    <div className={this.props.classFull ? "pop_up pop_up_facture facture class-full" : "popup-wrapper"}>
                        {/*<div className={this.props.classFull ? "bg_pop_up class-full" : "bg_pop_up2"} onClick={this.props.closePopup} />*/}
                        <div className={this.props.classFull ? "pop_up_container popup_inner class-full" : "popup"}>
                            <div className='popup_inner'>
                                <button className="btn-close" onClick={this.props.closePopup}>
                                    <img className="only_desktop" src={closeBtn} />
                                    <img className="only_mobile" src={closeMobile} />
                                </button>
                                <img src={phone} className={this.props.classFull ? "phone_img_true class-full" : "phone_img"} alt="Endesa S.A." />
                                <h1 className="mt-2">Besoin d’aide ?</h1>
                                {
                                    current_step === 'saisie_pce_estimation' ?
                                        <React.Fragment>
                                            <p className="mt-2">
                                                Le PCE (Point de Comptage et d'Estimation) est un numéro à 14 chiffres ou un numéro GI suivi de 6 chiffres. Ce numéro nous permet d'identifier rapidement et précisément votre compteur. 
                                                Il se trouve sur votre facture de gaz, généralement dans l’emplacement dédié aux données client.<br/><br/>
                                                Vous n’avez pas votre PCE ? Estimez votre consommation ou demandez à être recontacté par l'un de nos conseillers.
                                            </p>
                                            <div className="wrapper-buttons-error">
                                                <button type="submit" name="btn_submit" id='ident_continue' className="btn_rappel_error_conso btn_submit" onClick={this.togglePopup.bind(this)} value="Être rappelé">Être rappelé</button>
                                                <button type="submit" name="btn_submit" id='ident_continue' className="btn_rappel_error_conso conso_width btn_submit" onClick={this.togglePopupEstimation.bind(this)} value="Estimer ma consommation">Estimer ma consommation</button>
                                            </div>
                                        </React.Fragment>
                                    : 
                                    <React.Fragment>
                                        <p className="mt-2">Vous avez une question sur l'une de nos offres ? Vous rencontrez un problème dans votre souscription ? Demandez à être recontacté par l'un de nos conseillers.</p>
                                        <button type="submit" name="btn_submit" id='ident_continue' className="btn_rappel_error btn_submit" onClick={this.togglePopup.bind(this)} value="Être rappelé">Être rappelé</button>
                                    </React.Fragment>
                                }
                                
                            </div>
                        </div>
                    </div>
                    : 
                    <PopupRappel
                        text='Close Me'
                        classFull= {true}
                        closePopup={this.togglePopup.bind(this)}
                        initPopup={this.initPopup.bind(this)}
                        shownByError= {this.props.shownByError}
                        showBackButton={this.props.showBackButton}
                    />
                }
            </React.Fragment>
        );
    }
}

function mapStateToProps({ etre_rappeller, client_identification }) {
    return { etre_rappeller, client_identification };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        get_recalled: get_recalled,
        identification: identification
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(PopupError);