import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import swal from 'sweetalert';
import history from './history';
import { connect } from 'react-redux';
import { start } from '../actions/start';
import { handleHTTPError } from '../actions/errorHandlerActions';
import { bindActionCreators } from 'redux';
import { url_sub_key , apiUrl} from './constant'
import axios from 'axios';

class Key extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: ['404', '500', '405'],
            id: '',
            sub_key:''
        }
    }
    componentDidMount() {
        // GET PARAMS
        const { start } = this.props;
        let key = window.location.pathname;
        console.log('start', key.split('/')[1]);
        if (key.split('/')[1] !== '404') {
            start(key.split('/')[1]);
        } else {
            swal({
                title: "Page not found",
                text: "La page que vous tentez de voir n'existe pas!",
                icon: "error",
            });
        }
    }
    componentDidUpdate(prevProps) {
        console.log('Starting...');
        const { subscription_key, handleHTTPError } = this.props;
        const { errors } = this.state;
        //console.log('Erreur code', subscription_key.result.status);
        if (prevProps.subscription_key !== subscription_key) {
            console.log('Starting', subscription_key);
            let url_redirect = '/';
            if (subscription_key.result.status === 200) {
                // STORING DATA
                localStorage.setItem('step', subscription_key.result.data.step);
                localStorage.setItem('subscription_id', subscription_key.result.data.subscription_id);
                localStorage.setItem('current_step_continue', JSON.stringify(subscription_key.result));
                //console.log('Starting', localStorage.getItem('current_step_continue'));
                window.location = url_redirect + localStorage.getItem('subscription_id') + "/" + subscription_key.result.data.step;
            } else {
                localStorage.clear();
                localStorage.setItem('step', '404');
                let key_param = window.location.pathname;
                const subscription_id = key_param.split('/')[1];
                //console.log('link : ', subscription_id)
                if(subscription_id !== ''){
                    window.location.href = process.env.REACT_APP_API_URL+`/subscription/api/check-subscription/?subscription_id=${subscription_id}`;
                }
                else{
                    var self = this;
                    axios.post(`${apiUrl+url_sub_key}`).then(
                        function (response) {
                            if(response){
                                localStorage.setItem('step', response.data.step);
                                localStorage.setItem('subscription_id', response.data.subscription_id);
                                window.location.href = '/'+response.data.subscription_id + "/" + response.data.step;
                        }
             })
                }
                /*
                swal({
                    title: "Page not found",
                    text: "La page que vous tentez de voir n'existe pas!",
                    icon: "error",
                });
                */
            }
        }
    };


    render() {
        return (
            <React.Fragment>
                <div className="loadingKey">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps({ subscription_key, errorHandler }) {
    return { subscription_key, errorHandler };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        start: start,
        handleHTTPError: handleHTTPError,
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Key);