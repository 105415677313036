import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { post } from '../services/request';
import { apiUrl, url_liste_offres } from '../services/constant';
import { liste_offres_done } from '../actions/offers';


function* liste_offres_saga(action) {
	yield call(
		callApi,
		apiUrl + url_liste_offres,
		post,
        action.payload,
        liste_offres_done,
	);
}
export default function* watch_liste_offres_Saga() {
    yield takeEvery('LISTE_OFFRES', liste_offres_saga);
}

