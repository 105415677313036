import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { post } from '../services/request';
import { apiUrl, url_sub_key } from '../services/constant';
import { get_sub_key_done } from '../actions/get_sub_key';


function* get_sub_key_saga(action) {
	yield call(
		callApi,
		apiUrl + url_sub_key,
		post,
        action.payload,
        get_sub_key_done,
	);
}
export default function* watch_get_sub_key_saga() {
    yield takeEvery('SUB_KEY', get_sub_key_saga);
}

