import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Offer(props) {
    return (
        <React.Fragment>
            <div className={`offer_container${(props.selected === props.id ? ' selected' : '')} ${props.disabledChoose ? 'disable-me' : ''}`}>
                <div className="icon_wrapper" style={{backgroundColor: props.icon_bg_color}}>
                    <img className="offer_icone offer_icone_eco" alt="Offres Endesa S.A." src={props.icone} />
                </div>
                {(props.consulted === props.id ?
                    <span className="offer_selected">Offre consultée</span>
                    : '')}
                <div className="inner_offer tarif_inner_offer">
                    {
                        props.disabledChoose ?
                            <div className="tarif_inner_offer">
                                <div className="begin_bloc">
                                    <h2>{props.title}<br /><em>{props.sub_title}</em></h2>
                                    <p>{props.text}</p>
                                    <div className="form-group mt-4">
                                        <p>Durée du contrat</p>
                                        {/* New UI Dropdown */}
                                        <div className="wrapper-postal">
                                            <div className="select dropdown-postal mt-2" onClick={(e) => props.trigger_list_duree(props.index_offer)}>
                                                <input className="toggle" readOnly type="text" />
                                                <div className="arrow">
                                                    {(props.show_list_duree[props.index_offer] ? 
                                                        <i className="fas fa-chevron-up"></i> 
                                                    : <i className="fas fa-chevron-down"></i>)}
                                                </div>
                                                <div className="selected">
                                                    {
                                                        props.disabledChoose ?
                                                        <div className="value">mois</div>
                                                        : 
                                                        <div className="value">{props.duree_selected} mois</div>
                                                    }
                                                </div>
                                                <div className={`dropdown ${(props.show_list_duree[props.index_offer] ? '' : 'hide-drop')}`}>
                                                    {
                                                        props.simulation.map((item, index) => {
                                                            return <p key={index} className="value" onClick={(e) => props.changeDuree(item, props.id, props.index_offer)}>{item.duree} mois</p>;
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {/* End UI Dropdown */}
                                    </div>
                                    {(props.offeradvantage_set.length ?
                                        <div className="btn-group toggle_avantage">
                                            <p className="title-advantages">
                                                Avantages :
                                            </p>
                                            <div className="list_avantage">
                                                {!props.disabledChoose ? 
                                                <ul id={props.id}>
                                                    {props.offeradvantage_set.slice(0,1).map((av, index) => {
                                                        return <li key={index}>{av.title}</li>;
                                                    })}
                                                </ul>
                                                : null
                                                }
                                                <div className={`dropdown-menu dropdown-menu-right list_avantage ${props.textButtonsOffer[props.index_offer] ? 'show' : null} `}  >
                                                    <ul id={props.id}>
                                                        {props.offeradvantage_set.slice(1,props.offeradvantage_set.length).map((advantage, index) => {
                                                            return <li key={index}>{advantage.title}</li>
                                                        })
                                                        }
                                                    </ul>
                                                </div>
                                                {
                                                    !props.disabledChoose ?
                                                    <button type="button" onClick={(e) => props.toggleTextButton(props.index_offer)} className={`btn btn-secondary dropdown-toggle btn-show-more ${props.textButtonsOffer[props.index_offer] ? 'rotate-me' : null} `}>
                                                        {
                                                            props.textButtonsOffer[props.index_offer] ? 'Voir moins' : 'Voir plus'
                                                        }
                                                    </button>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        :
                                        ''
                                    )}
                                    {
                                        props.disabledChoose ? 
                                        <div className="simulation-wrapper no-offer-available">
                                            <p>{props.ineligibility_message !== null ? props.ineligibility_message : "Non disponible"}</p>
                                        </div>
                                        : null
                                    }
                                </div>
                                <div className="end-bloc">
                                    <div className="download-wrapper">
                                        <a href="#" onClick={props.disabledChoose ? null : () => props.brochure(props.id)} className="download_brochure">Consulter la brochure</a>
                                    </div>
                                </div>
                            </div>
                        : 
                            <div className="tarif_inner_offer">
                                <div className="begin_bloc">
                                <h2>{props.title}<br /><em>{props.sub_title}</em></h2>
                                <p>{props.text}</p>
                                {
                                    props.disabledChoose ? 
                                    <div className="simulation-wrapper no-offer-available">
                                        <p>{props.ineligibility_message !== null ? props.ineligibility_message : "Non disponible"}</p>
                                    </div>
                                    : null
                                }
                            </div>
                            <div className="end-bloc">
                                <div className="form-group mt-4">
                                    <p>Durée du contrat</p>
                                    {/* New UI Dropdown */}
                                    <div className="wrapper-postal">
                                        <div className="select dropdown-postal mt-2" onClick={(e) => props.trigger_list_duree(props.index_offer)}>
                                            <input className="toggle" readOnly type="text" />
                                            <div className="arrow">
                                                {(props.show_list_duree[props.index_offer] ? 
                                                    <i className="fas fa-chevron-up"></i> 
                                                : <i className="fas fa-chevron-down"></i>)}
                                            </div>
                                            <div className="selected">
                                                {
                                                    props.disabledChoose ?
                                                    <div className="value">mois</div>
                                                    : 
                                                    <div className="value">{props.duree_selected} mois</div>
                                                }
                                            </div>
                                            <div className={`dropdown ${(props.show_list_duree[props.index_offer] ? '' : 'hide-drop')}`}>
                                                {
                                                    props.simulation.map((item, index) => {
                                                        return <p key={index} className="value" onClick={(e) => props.changeDuree(item, props.id, props.index_offer)}>{item.duree} mois</p>;
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/* End UI Dropdown */}
                                </div>
                                {(props.offeradvantage_set.length ?
                                    <div className="btn-group toggle_avantage">
                                        <p className="title-advantages">
                                            Avantages :
                                        </p>
                                        <div className={`list_avantage ${props.textButtonsOffer[props.index_offer] ? "" : "tarif-show-one-line"}`}>
                                            {!props.disabledChoose ? 
                                            <ul id={props.id}>
                                                {props.offeradvantage_set.slice(0,1).map((av, index) => {
                                                    return <li key={index}>{av.title}</li>;
                                                })}
                                            </ul>
                                            : null
                                            }
                                            <div className={`dropdown-menu dropdown-menu-right list_avantage ${props.textButtonsOffer[props.index_offer] ? 'show' : null} `}  >
                                                <ul id={props.id}>
                                                    {props.offeradvantage_set.slice(1,props.offeradvantage_set.length).map((advantage, index) => {
                                                        return <li key={index}>{advantage.title}</li>
                                                    })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        {
                                            !props.disabledChoose ?
                                            <button type="button" onClick={(e) => props.toggleTextButton(props.index_offer)} className={`btn btn-secondary dropdown-toggle btn-show-more ${props.textButtonsOffer[props.index_offer] ? 'rotate-me' : null} `}>
                                                {
                                                    props.textButtonsOffer[props.index_offer] ? 'Voir moins' : 'Voir plus'
                                                }
                                            </button>
                                            : null
                                        }
                                    </div>
                                    :
                                    ''
                                )}
                                {props.simulation.map((duree, index) => {
                                    if (parseInt(props.duree_selected) === duree.duree) {
                                        return <div className="simulation-wrapper" key={index}>
                                            <div className="estimation-wrapper">
                                                <span className="estime_text">Estimé à</span>
                                                <div className="price_estimation">
                                                    {
                                                        duree.estime_original === duree.estime ?
                                                        <React.Fragment>
                                                            <p className="pricing">{duree.estime}€ <span>HT/mois</span></p>
                                                            <p className="pricing_annual">soit {(duree.estime * 12).toFixed(2)}€ HT/an</p>
                                                        </React.Fragment>
                                                        : 
                                                        <React.Fragment>
                                                            <p className="pricing">{duree.estime_original > 0 ? <del>{duree.estime_original}€</del> : '' }{duree.estime}€ <span>HT/mois</span></p>
                                                            <p className="pricing_annual">soit {(duree.estime * 12).toFixed(2)}€ HT/an</p>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </div>
                                            <div className="details_wrapper">
                                                <p>Abonnement <span>{duree.abonnement_remise}</span></p>
                                                <p>Consommation <span>{duree.consommation}</span></p>
                                                <p>Prix du MWh <span>{duree.prix_remise}</span></p>
                                            </div>
                                        </div>
                                    }
                                })
                                }
                                <div className="download-wrapper">
                                    <a href="#" onClick={props.disabledChoose ? null : () => props.brochure(props.id)} className="download_brochure">Consulter la brochure</a>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <label onChange={() => props.toggetOffer(props.id, props.index_offer,props.isbusiness)} disabled={props.disabledChoose ? true : false} className="choose_offer" htmlFor={`offer_type_${props.id}`}>
                    <span>Choisir cette offre</span>
                    <input type="radio" name="offer_type" id={`offer_type_${props.id}`} value={props.id} />
                </label>
            </div>
        </React.Fragment >
    );
}

export default Offer;