/*import axios from 'axios';
import { apiUrl, url_validate_pce } from '../services/constant';

export const validatePce = (data) => dispatch => {
    const request = axios.post(apiUrl + url_validate_pce,data);
    dispatch({
        type: 'VALIDATE_PCE',
        payload: request
    })
}
*/
export function validatePce(payload) {
    return {
        type: 'VALIDATE_PCE',
        payload,
    };
}
export function validatePce_done(request) {
    return {
        type: 'VALIDATE_PCE_DONE',
        payload: request,
    };
}