/*import axios from 'axios';
import { apiUrl, url_previous } from '../services/constant';

export const get_previous = (data) => dispatch => {
    const request = axios.post(apiUrl + url_previous, data);
    dispatch({
        type: 'PREVIOUS',
        payload: request
    })
}*/

export function get_previous(payload) {
    return {
        type: 'PREVIOUS',
        payload,
    };
}
export function get_previous_done(request) {
    return {
        type: 'PREVIOUS_DONE',
        payload: request,
    };
}