/*import axios from 'axios';
import { apiUrl, url_change_subscription_email } from '../services/constant';

export const get_change_subscription_email = (data) => dispatch => {
    const request = axios.post(apiUrl + url_change_subscription_email, data);
    dispatch({
        type: 'CHANGE_SUBSCRIPTION_EMAIL',
        payload: request
    })
}
*/
export function get_change_subscription_email(payload) {
    return {
        type: 'CHANGE_SUBSCRIPTION_EMAIL',
        payload,
    };
}
export function get_change_subscription_email_done(request) {
    return {
        type: 'CHANGE_SUBSCRIPTION_EMAIL_DONE',
        payload: request,
    };
}