/*import axios from 'axios';
import { apiUrl, url_verification_email } from '../services/constant';

export const verification_email = (data) => dispatch => {
    const request = axios.post(apiUrl + url_verification_email, data);
    dispatch({
        type: 'VERIFICATION_EMAIL',
        payload: request
    })
}
*/
export function verification_email(payload) {
    return {
        type: 'VERIFICATION_EMAIL',
        payload,
    };
}
export function verification_email_done(request) {
    return {
        type: 'VERIFICATION_EMAIL_DONE',
        payload: request,
    };
}