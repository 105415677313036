/*import axios from 'axios';
import { apiUrl, url_payeurs_divergents } from '../services/constant';

export const get_payeurs_divergents = (data) => dispatch => {
    const request = axios.post(apiUrl + url_payeurs_divergents, data);
    dispatch({
        type: 'PAYEURS_DIVERGENTS',
        payload: request
    })
}
*/
export function get_payeurs_divergents(payload) {
    return {
        type: 'PAYEURS_DIVERGENTS',
        payload,
    };
}
export function get_payeurs_divergents_done(request) {
    return {
        type: 'PAYEURS_DIVERGENTS_DONE',
        payload: request,
    };
}