/*import axios from 'axios';
import { apiUrl, url_parameters } from '../services/constant';

export const get_numbers_subs = (data) => dispatch => {
    const request = axios.get(apiUrl + url_parameters, data);
    dispatch({
        type: 'ALL_SUBS',
        payload: request
    })
}
*/
export function get_file_types(payload) {
    return {
        type: 'FILE_TYPES',
        payload,
    };
}
export function get_file_types_done(request) {
    return {
        type: 'FILE_TYPES_DONE',
        payload: request,
    };
}