import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './bandeau.css'
import PopupRappel from '../../components/aide/PopupRappel'
import {Modal, Button} from "react-bootstrap"
import { get_numbers_subs } from '../../actions/all_subs';

class Bandeau extends Component {
    constructor(props){
        super(props)
        this.state = {
            show: false,
            checkReminder: false,
            checkRappel: false,
            checkPhone: 'indisponible',
            hideEtreRappele: 'False'
        }
    }
    togglePopup = () => {
        const { showPopup } = this.state
        this.setState({
            showPopup: !showPopup,
            show: true
        })
    }
    //COMPONENT WILLMOUNT
    componentWillMount(){
        const { get_numbers_subs } = this.props;
        get_numbers_subs();
    }
    componentDidUpdate(prevProps){
        const { checkReminder } = this.state
        const { all_subs } = this.props;
        if (prevProps.all_subs !== all_subs) {
            //console.log(all_subs)
            all_subs.result.data.map((itm, indx) => {
                if (itm.param === "CHECK_REMINDER") {
                    //console.log('YEES : ', itm.value)
                    this.setState({
                        checkReminder: itm.value,
                        checkRappel: itm.value
                    })
                }
                if (itm.param === "ASSISTANT_PHONE_NUMBER") {
                    this.setState({
                        checkPhone: itm.value
                    })
                }
                if (itm.param === "CACHER_ETRE_RAPPELE") {
                    this.setState({
                        hideEtreRappele: itm.value
                    })
                }
            })
        }
    }
    render(){
        const { checkReminder, checkRappel, checkPhone, hideEtreRappele } = this.state
        return (
            <React.Fragment>
                {
                    hideEtreRappele === 'False' || hideEtreRappele === 'false' ?
                        <div className="bandeauParent">
                            <div className="bandeauWrapper">
                                <p>Vous souhaitez souscrire directement par téléphone</p>
                                { checkReminder ?
                                    <a href="#" onClick={this.togglePopup.bind(this)} className="rappel_link rappelIcone">Être rappelé</a>
                                    :
                                    <a href="#" onClick={this.togglePopup.bind(this)} style={{ width: "190px"}} className="rappel_link appelIcone">Nous appeler</a>
                                }
                            </div>
                        </div>
                    : null

                }
                <div>
                    {this.state.showPopup ?
                        <React.Fragment>
                            <Modal dialogClassName="modal-90w preventPopupInactivityToBeShown" size="lg" centered show={this.state.show} onHide={this.togglePopup.bind(this)}>
                                <PopupRappel
                                    text='Close Me'
                                    closePopup={this.togglePopup.bind(this)}
                                    checkReminderProp={checkReminder}
                                    checkRappelProp={checkRappel}
                                    checkPhoneProp={checkPhone}
                                />
                            </Modal>
                        </React.Fragment>
                        : null
                    }
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps({ all_subs }) {
    return { all_subs };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        get_numbers_subs: get_numbers_subs
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Bandeau);