import React, { Component } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { identification } from "../../actions/identification";
import { subscription_id } from "../../services/status";
import { bindActionCreators } from "redux";
import site_icon from "../../assets/img/icon_site.png";
import site_icon_active from "../../assets/img/icon_site_active.png";
import sites_icon from "../../assets/img/icon_sites.png";
import icon_help from "../../assets/img/calc.svg";
import sites_icon_active from "../../assets/img/icon_sites_active.png";
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";
import swal from "sweetalert";
import { get_reset_subscription } from "../../actions/reset_subscription";
import ReactTooltip from "../../../node_modules/react-tooltip/dist/index";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";
import PopupError from "../aide/PopupError";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Modal, Button } from "react-bootstrap";
import check_2_active from "../../assets/img/check_2_active.png";
import check_2 from "../../assets/img/check_2.png";

class Client extends Component {
  constructor(props) {
    super(props);
    const newdate = new Date();
    newdate.setDate(newdate.getDate() + 6);
    registerLocale("fr", fr);
    this.state = {
      regexp: /^[0-9\b]+$/,
      regexpostal: /\b\d{5}\b/g,
      frm_data: {},
      isLoading: false,
      type_code: "",
      client: JSON.parse(localStorage.getItem("current_step_continue")),
      code: localStorage.getItem("data_code")
        ? localStorage.getItem("data_code")
        : "",
      site: localStorage.getItem("data_type_subscription")
        ? localStorage.getItem("data_type_subscription")
        : "",
      // is_one: (localStorage.getItem('data_type_subscription') === '1_site' ? true : false),
      is_more:
        localStorage.getItem("data_type_subscription") === "many_sites"
          ? true
          : false,
      is_one: false,
      is_two: false,
      is_three: false,
      is_four: false,
      nbr_site: localStorage.getItem("data_number_sites")
        ? localStorage.getItem("data_number_sites")
        : 1,
      list_CP: localStorage.getItem("postcodes")
        ? localStorage.getItem("postcodes").split(",")
        : [],
      client_value: localStorage.getItem("data_is_customer") ? "oui" : "",
      site_key: process.env.REACT_APP_TAG_RECAPTCHA_V3
        ? process.env.REACT_APP_TAG_RECAPTCHA_V3
        : "",
      token: "",
      is_ready_only: false,
      widthSize: window.innerWidth,
      // State for error message
      errorClient: [],
      errorSites: [],
      errorNbrSites: [],
      errorSiren: [],
      errorPostal: [],
      errorRS: [],
      errorCounter: 0,
      showPopupError: false,
      startDate: "",
      currentDate: newdate,
      minDate: "",
      maxDate: "",
      show: false,
      is_autorise_tech: false,
      is_autorise_info: false,
      cp_list_items: ["", "", ""],
      check_cp_list_items: false
    };
    this.handleCP = this.handleCP.bind(this);
    this.remove_cp = this.remove_cp.bind(this);
    this.handleCPList = this.handleCPList.bind(this);
  }
  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  checkEmptyListItems = () => {
    const {cp_list_items, nbr_site} = this.state
    const k = cp_list_items.slice(0, nbr_site)
    let check = true
    console.log('cp list :', k)
    cp_list_items.slice(0, nbr_site).map((item) => item === '' ? check = false : null)
    this.setState({
      check_cp_list_items: check
    })
    console.log('CHECK : ', check)
  }

  handleShow = () => {
    this.setState({
      show: true,
    });
  };

  checkAutorise = (e) => {
      const { is_autorise_tech } = this.state;
      this.setState({
          is_autorise_tech: !is_autorise_tech
      });
  };

  checkAutorise_info = (e) => {
      const { is_autorise_info } = this.state;
      this.setState({
          is_autorise_info: !is_autorise_info
      });
  };

  componentWillUnmount() {
    localStorage.removeItem("current_step_continue");
  }
  componentDidMount() {
    const { client, is_ready_only } = this.state;
    //console.log('Customer', client);
    this.setState({
      widthSize: window.innerWidth,
      //site_key: {%REACT_APP_TAG_RECAPTCHA_V3%}
    });
    //localStorage.clear();
    const { site_key } = this.state;
    loadReCaptcha(site_key, this.verifyCallback);
    //localStorage.setItem('step', client.data.last_step);
    //console.log('data client : ',client)
  }
  updateToken = () => {
    // you will get a new token in verifyCallback
    this.recaptcha.execute();
  };
  verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!
    this.setState({
      token: recaptchaToken,
    });
  };
  componentDidUpdate(prevProps) {
    const { client_identification, reset_subscription } = this.props;
    // Enable navigation prompt
    window.onbeforeunload = function () {
      return true;
    };
    const { errorClient, errorCounter } = this.state;
    // GET NEW KEY WHEN RESET SUBS
    if (prevProps.reset_subscription !== reset_subscription) {
      // Remove navigation prompt
      window.onbeforeunload = null;
      //console.log('reset_subscription', reset_subscription);
      let newKey = reset_subscription.result.data.subscription_id;
      //console.log('reset_subscription 2 : ', newKey);
      //window.location.assign(newKey)
      window.location.pathname = newKey + "/start";
    }
    if (prevProps.client_identification !== client_identification) {
      // Remove navigation prompt
      window.onbeforeunload = null;
      if (client_identification.result.status === 200) {
        localStorage.removeItem("previous");
        localStorage.removeItem("current_step_continue");
        localStorage.setItem("step", client_identification.result.data.step);
        localStorage.setItem(
          "subscription_id",
          client_identification.result.data.subscription_id
        );
        localStorage.setItem(
          "next",
          JSON.stringify(client_identification.result.data)
        );
        localStorage.setItem(
          "current_step_continue",
          JSON.stringify(client_identification.result.data)
        );
        // Data
        window.location.reload(false);
        window.location.replace(client_identification.result.data.step);
      } else if (client_identification.result.response.status === 428) {
        //console.log('ERROR IS : ', client_identification.result)
        var divList = document.createElement("div");
        divList.innerHTML =
          "<div class='list_errors'><ul>" +
          client_identification.result.response.data.non_field_errors
            .map((error, idx) => {
              return `<li>${error}</li>`;
            })
            .join("") +
          "</ul></div>";
        swal({
          title: "Erreur dans le formulaire",
          //text: "Code Erronée",
          content: divList,
          icon: "error",
        }).then(() => {
          if (errorCounter > 2) {
            this.setState({
              showPopupError: true,
              show: true,
              isLoading: false,
              errorCounter: errorCounter + 1,
            });
          } else {
            this.setState({
              isLoading: false,
              errorCounter: errorCounter + 1,
            });
          }
        });
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
        //console.log('Error LOG : ', client_identification.result)
        if (errorCounter > 2) {
          this.setState({
            isLoading: false,
            errorClient: client_identification.result.response.data
              .is_already_customer
              ? client_identification.result.response.data.is_already_customer
              : "",
            errorSites: client_identification.result.response.data
              .type_subscription
              ? client_identification.result.response.data.type_subscription
              : "",
            errorNbrSites: client_identification.result.response.data
              .number_sites
              ? client_identification.result.response.data.number_sites
              : "",
            errorRS: client_identification.result.response.data.errorRS
              ? client_identification.result.response.data.errorRS
              : "",
            errorPostal: client_identification.result.response.data.errorPostal
              ? client_identification.result.response.data.errorPostal
              : "",
            errorCounter: errorCounter + 1,
          });
        } else {
          this.setState({
            isLoading: false,
            errorClient: client_identification.result.response.data
              .is_already_customer
              ? client_identification.result.response.data.is_already_customer
              : "",
            errorSites: client_identification.result.response.data
              .type_subscription
              ? client_identification.result.response.data.type_subscription
              : "",
            errorNbrSites: client_identification.result.response.data
              .number_sites
              ? client_identification.result.response.data.number_sites
              : "",
            errorRS: client_identification.result.response.data.errorRS
              ? client_identification.result.response.data.errorRS
              : "",
            errorPostal: client_identification.result.response.data.errorPostal
              ? client_identification.result.response.data.errorPostal
              : "",
            errorCounter: errorCounter + 1,
          });
        }
        if (errorCounter > 2) {
            this.setState({
              showPopupError: true,
              show: true,
            });
        }
        /*
        swal({
          title: "Erreur dans le formulaire",
          icon: "error",
        }).then((value) => {
          //swal(`The returned value is: ${value}`);
          if (value && errorCounter > 2) {
            this.setState({
              showPopupError: true,
              show: true,
            });
          }
        });
        */
        /*
                swal({
                    title: "Merci de remplire tous les champs",
                    //text: "Code Erronée",
                    content: divList,
                    icon: "error",
                }).then(() => {
                    this.setState({
                        isLoading: false,
                        errorSites: client_identification.result.response.data.type_subscription,
                        errorNbrSites: client_identification.result.response.data.number_sites,
                    });
                })
                */
      }
    }
  }
  componentWillMount() {
    const { client } = this.state;
    const listcp = ["", "", ""]
    if (localStorage.getItem("current_returned_prospect")) {
      this.setState({
        client: JSON.parse(localStorage.getItem("current_returned_prospect"))
          .data,
        client_value: JSON.parse(
          localStorage.getItem("current_returned_prospect")
        ).data.is_already_customer,
        is_one:
          JSON.parse(localStorage.getItem("current_returned_prospect")).data
            .number_sites === 1
            ? JSON.parse(localStorage.getItem("current_returned_prospect")).data
                .number_sites
            : "",
        is_more:
          JSON.parse(localStorage.getItem("current_returned_prospect")).data
            .number_sites > 1
            ? JSON.parse(localStorage.getItem("current_returned_prospect")).data
                .number_sites
            : "",
        code: JSON.parse(localStorage.getItem("current_returned_prospect")).data
          .code,
        list_CP: JSON.parse(localStorage.getItem("current_returned_prospect"))
          .data.postcodes,
        site: JSON.parse(localStorage.getItem("current_returned_prospect"))
          .type_subscription,
        nbr_site: JSON.parse(localStorage.getItem("current_returned_prospect"))
          .data.number_sites,
        is_ready_only: JSON.parse(
          localStorage.getItem("current_returned_prospect")
        ).is_readonly,
      });
    }
    // localstorage previous
    if (localStorage.getItem("previous")) {
      if (
        JSON.parse(localStorage.getItem("previous")).step === "identification"
      ) {
        JSON.parse(localStorage.getItem("previous")).data.postcodes.map((postal, idx) => {
          listcp[idx] = postal
        })
        //console.log('YES : ', JSON.parse(localStorage.getItem('previous')).data.is_already_customer)
        //console.log('list_CP : ', JSON.parse(localStorage.getItem('previous')).data.postcodes)
        this.setState({
          client: JSON.parse(localStorage.getItem("previous")),
          client_value: JSON.parse(localStorage.getItem("previous")).data
            .is_already_customer,
          //is_one: JSON.parse(localStorage.getItem('previous')).data.number_sites === 1 ? JSON.parse(localStorage.getItem('previous')).data.number_sites : '',
          is_one:
            JSON.parse(localStorage.getItem("previous")).data.number_sites === 1
              ? true
              : false,
          is_more:
            JSON.parse(localStorage.getItem("previous")).data.number_sites > 1
              ? true
              : false,
          code: JSON.parse(localStorage.getItem("previous")).data.code,
          list_CP: JSON.parse(localStorage.getItem("previous")).data.postcodes,
          site: JSON.parse(localStorage.getItem("previous")).type_subscription,
          nbr_site: JSON.parse(localStorage.getItem("previous")).data
            .number_sites,
          is_ready_only: JSON.parse(localStorage.getItem("previous"))
            .is_readonly,
          startDate:
            JSON.parse(localStorage.getItem("previous")).data
              .supply_start_date === null
              ? ""
              : new Date(
                  JSON.parse(
                    localStorage.getItem("previous")
                  ).data.supply_start_date
                ),
            is_autorise_tech: true,
            is_autorise_info: true,
            cp_list_items: listcp,
            check_cp_list_items: true
        }, () => {
          //console.log('prev : ', this.state.cp_list_items)
        });

      }
    }
  }
  // HANDLE CLIENT
  handleClient = (e) => {
    this.setState({
      client_value: e.target.value,
    });
  };
  close_popup = (e) => {
    this.setState({
      showPopupError: false,
    });
  };
  // HANDLE LE NOMBRE DU SITE
  handleSiteNbr = (e) => {
    const { is_one, is_more, nbr_site } = this.state;
    let one = is_one;
    let more = is_more;
    if (e.target.value === "1_site") {
      one = true;
      more = false;
      this.setState({
        nbr_site: 1,
      });
    } else {
      one = false;
      more = true;
    }
    this.setState({
      site: e.target.value,
      is_one: one,
      is_more: more,
    });
  };
  // SPÉCIFIER LE NOMBRE DU SITES
  handleNbrSite = (e) => {
    const { is_one, is_two, is_three, is_four, cp_list_items, regexpostal } = this.state;
    let one = is_one;
    let two = is_two;
    let three = is_three;
    let four = is_four;
    //this.checkEmptyListItems()
    let check = true
    const list = cp_list_items
    list.slice(0, e.target.value).map((item) => {
      if(item !== ''){
        if(regexpostal.test(item) === true){
          console.log('IF : ' + regexpostal.test(item) + ' - check : ', check) 
        }else{
          check = false
        }
      }else{
        check = false
      }
    })

    if (e.target.value === "1") {
      one = true;
      two = false;
      three = false;
      four = false;
    } else if (e.target.value === "2") {
      one = false;
      two = true;
      three = false;
      four = false;
    } else if (e.target.value === "3") {
      one = false;
      two = false;
      three = true;
      four = false;
    } else {
      one = false;
      two = false;
      three = false;
      four = true;
    }
    this.setState({
      nbr_site: e.target.value,
      is_one: one,
      is_two: two,
      is_three: three,
      is_four: four,
      check_cp_list_items: check
    });
    //console.log('list : ', this.state.cp_list_items)
  };
  // HANDLE CODE: SIRET SIREN RS
  handlCode = (e) => {
    let entered_value = e.target.value;
    //this.checkEmptyListItems()
    this.setState({
      code: entered_value,
    });
  };
  // Popup Error help
  togglePopupError = () => {
    const { showPopupError } = this.state;
    //window.scrollTo(0, 0)
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.setState({
      showPopupError: !showPopupError,
    });
  };
  // RESET DATA - SOUSSCRIPTION
  resetData = (e) => {
    e.preventDefault();
    const { get_reset_subscription } = this.props;
    /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
    let path = window.location.pathname.match(/([^/]*\/){2}/)[0];
    let path_remove_slash = path.replace(/^\/|\/$/g, "");
    let data = {};
    //data.subscription_id = subscription_id;
    data.subscription_id = path_remove_slash;
    swal({
      title: "Etes-vous sûr ? Vous perdrez toutes les informations renseignées",
      //text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: ["Non", "Oui"],
      dangerMode: true,
    }).then((willDelete) => {
      window.dataLayer.push({
        event: "gtm.click.identi_Reinitialiser",
      });
      if (willDelete) {
        swal("Votre souscription est réinitialisée", {
          icon: "success",
        }).then(() => {
          //console.log('call ws : ', data);
          get_reset_subscription(data);
        });
      } else {
        //swal("Your imaginary file is safe!");
      }
    });
  };
  handleCPList = (item, idx, e) => {
    e.preventDefault()

    const {cp_list_items, regexpostal, nbr_site} = this.state
    let check = true

    //this.checkEmptyListItems()

    //console.log('regex : ', regexpostal.test(e.target.value))
    //console.log('Input : ', e.target.value.length)
    if(e.target.value.length > 5){
      e.target.value = e.target.value.slice(0, 5)
      console.log('test : ', e.target.value)
    }

    const list = cp_list_items
    list[idx] = e.target.value
    list.slice(0, nbr_site).map((item) => {
      if(item !== ''){
        if(regexpostal.test(item) === true){
          console.log('IF : ' + regexpostal.test(item) + ' - check : ', check) 
        }else{
          check = false
        }
      }else{
        check = false
      }
    })

    this.setState({
      cp_list_items: list,
      check_cp_list_items: check
    })
  }
  // ADD CODE POSTAL TO THE LIST
  handleCP = (e) => {
    const { list_CP } = this.state;
    let entered_value = e.target.value;
    if (entered_value === "" || this.state.regexp.test(entered_value)) {
      this.setState({
        cp_input: entered_value,
      });
      if (entered_value.length === 5) {
        let new_list_CP = list_CP;
        new_list_CP.push(entered_value);
        //console.log('list', new_list_CP);
        this.setState({
          list_CP: new_list_CP,
          cp_input: "",
        });
      }
    }
  };
  // REMOVE CODE POSTAL FROM LIST
  remove_cp = (item) => {
    const { list_CP } = this.state;
    let rest_list_cp = list_CP.filter((cp) => cp !== item);
    this.setState({
      list_CP: rest_list_cp,
    });
  };
  // SUBMIT
  handleSubmit = (e) => {
    e.preventDefault();
    const { identification } = this.props;
    const {
      frm_data,
      client_value,
      site,
      nbr_site,
      list_CP,
      code,
      token,
      startDate,
      cp_list_items
    } = this.state;
    let postcodes = list_CP;
    //let path = window.location.pathname.replace(/\/+$/, '');
    let path = window.location.pathname.match(/([^/]*\/){2}/)[0];
    let path_remove_slash = path.replace(/^\/|\/$/g, "");
    path = path[0] == "/" ? path.substr(1) : path;
    // frm_data.type_subscription = site;
    // frm_data.number_sites = (site === '1_site' ? 1 : nbr_site);
    frm_data.number_sites = nbr_site;
    //frm_data.postcodes = nbr_site > 3 ? [] : postcodes;
    frm_data.postcodes = nbr_site > 3 ? [] : cp_list_items.slice(0, nbr_site);
    frm_data.supply_start_date =
      nbr_site > 3 ? null : moment(startDate).format("YYYY-MM-DD");
    frm_data.code = nbr_site > 3 ? "" : code;
    //frm_data.is_already_customer = (client_value === 'oui' ? true : false);
    frm_data.is_already_customer =
      client_value === "" ? null : client_value === "oui" ? true : false;
    //frm_data.subscription_id = localStorage.getItem('subscription_id');
    frm_data.subscription_id = path_remove_slash;
    frm_data.recaptcha = token;
    // submit data identification to backend
    // console.log('frm_data', frm_data);
    identification(frm_data);
    this.setState({
      isLoading: true,
    });
    //console.log('Data form', frm_data);
  };
  // HANDLE DATE CHANGE
  handleDateChange = (date) => {
    let date_list = this.state.startDate;
    this.setState({
      startDate: date,
    });
  };
  render() {
    const {
      list_CP,
      client_value,
      is_one,
      is_more,
      site,
      nbr_site,
      cp_input,
      isLoading,
      code,
      errorClient,
      errorPostal,
      errorSiren,
      errorSites,
      errorRS,
      errorNbrSites,
      is_ready_only,
      widthSize,
      site_key,
      startDate,
      currentDate,
      maxDate,
      is_autorise_tech,
      is_autorise_info,
      cp_list_items,
      check_cp_list_items
    } = this.state;
    return (
      <React.Fragment>
        {isLoading ? (
          <div className="loadingKey">
            <Spinner
              className="spinner-border text-primary"
              animation="border"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Container>
            <Row>
              <Col>
                {is_ready_only ? <div className="blank_div"></div> : null}
                <h1 className="blue-border">
                  <span></span>Souscrivez ou demandez un devis de contrat de gaz en quelques clics
                </h1>
                <div className="etablissement-wrapper">
                  <div className="icon-help">
                    <img src={icon_help} alt="endesa etablissement" />
                  </div>
                  <div className="etablissement-text">
                    <p>
                      Pour faciliter votre démarche, munissez-vous de votre{" "}
                      <b>facture de gaz</b> et de votre <b>RIB</b>.
                    </p>
                  </div>
                </div>

                <form method="POST" onSubmit={this.handleSubmit} className="client mt-4">
                  <div className="form_group pt-5">
                    <p>
                    Pour commencer, combien de sites sont concernés par votre demande ?{" "}
                      <span className="red_star">*</span>
                    </p>
                    <div className={`radio-content ${nbr_site === "1" || nbr_site === 1 ? "one" : nbr_site === "2" || nbr_site === 2 ? "two" : nbr_site === "3" || nbr_site === 3 ? "three" : nbr_site === "4" || nbr_site === 4 ? "four" : ""}`}>
                      <div className={ nbr_site === "1" || nbr_site === 1 ? "radio-site-container active" : "radio-site-container" }>
                        <p>1</p>
                        <label className="radio_site">
                          <input name="sites" id="1sites" type="radio" value="1" onChange={this.handleNbrSite} />
                        </label>
                      </div>
                      <div className={ nbr_site === "2" || nbr_site === 2 ? "radio-site-container active" : "radio-site-container"}>
                        <p>2</p>
                        <label className="radio_site">
                          <input name="sites" id="2sites" type="radio" value="2" onChange={this.handleNbrSite}/>
                        </label>
                      </div>
                      <div className={ nbr_site === "3" || nbr_site === 3 ? "radio-site-container active" : "radio-site-container"}>
                        <p>3</p>
                        <label className="radio_site">
                          <input name="sites" id="3sites" type="radio" value="3" onChange={this.handleNbrSite}/>
                        </label>
                      </div>
                      <div className={ nbr_site === "4" || nbr_site === 4 ? "radio-site-container active" : "radio-site-container"}>
                        <p>4 et plus</p>
                        <label className="radio_site">
                          <input name="sites" id="4sites" type="radio" value="4" onChange={this.handleNbrSite}/>
                        </label>
                      </div>
                    </div>
                    {errorNbrSites.length > 0
                      ? errorNbrSites.map((message, idx) => {
                          return (
                            <p
                              key={idx}
                              className="text-danger small-text-help mt-2"
                            >
                              {message}
                            </p>
                          );
                        })
                      : null}
                  </div>

                  {nbr_site === "4" || nbr_site === 4 ? (
                    ""
                  ) : nbr_site !== 4 ? (
                    <React.Fragment>
                      <div className="form_group pt-5">
                        <p>
                          Quelle est votre raison sociale ou votre numéro de
                          SIRET/SIREN ? <span className="red_star">*</span>
                        </p>
                        <input type="text" name="siret_siren_rs" value={code} onChange={this.handlCode} className={errorRS && errorRS.length > 0 ? 'simple_input input_invalid' : 'simple_input'} />
                        {errorRS.length > 0
                          ? errorRS.map((message, idx) => {
                              return (
                                <p
                                  key={idx}
                                  className="text-danger small-text-help mt-2"
                                >
                                  {message}
                                </p>
                              );
                            })
                          : null}
                      </div>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                  {nbr_site === "4" || nbr_site === 4 ? (
                    ""
                  ) : nbr_site !== 4 ? (
                    <div className='form_group pt-5'>
                      <div className='postal_wrapper'>
                        <h6 className="">Quel est le code postal du lieu (ou des lieux) de fourniture ? <span className="red_star">*</span></h6>
                        <Tooltip title="Le code postal est celui de l’adresse de consommation du gaz" position={widthSize > 576 ? "right" : "bottom"} trigger={widthSize > 576 ? "mouseenter" : "click"}>
                          <span className="info_postal btn_mobile" data-class="own_tooltip">i</span>
                        </Tooltip>
                      </div>
                      {
                        cp_list_items.length > 0 ?
                          cp_list_items.slice(0, parseInt(nbr_site)).map((item, idx) => {
                            return (
                              <>
                              <label>Code postal du site {idx+1} : </label>
                                <input type="number" value={item} name="postal_code" onChange={(e) => this.handleCPList(item, idx, e)} className="simple_input txt_c_p"/>
                                <br/>
                                <br/>
                              </>
                            )
                          })
                        : null
                      }
                      {errorPostal.length > 0
                        ? errorPostal.map((message, idx) => {
                            return (
                              <p
                                key={idx}
                                className="text-danger small-text-help mt-2"
                              >
                                {message}
                              </p>
                            );
                          })
                        : null}
                    </div>
                    /*
                    <React.Fragment>
                      <div className="form_group pt-5">
                        <p>
                          Quel est le code postal du lieu (ou des lieux) de
                          fourniture? <span className="red_star">*</span>
                        </p>
                        <input type="text" name="code_postal" value={cp_input} className="simple_input txt_c_p" onChange={this.handleCP}/>
                        <Tooltip title="Le code postal est celui de l’adresse de consommation du gaz." position={widthSize > 576 ? "right" : "bottom"} trigger={widthSize > 576 ? "mouseenter" : "click"}>
                          <span className="info_postal btn_mobile" data-class="own_tooltip">i</span>
                        </Tooltip>
                      </div>
                    </React.Fragment>
                    */
                  ) : (
                    ""
                  )}


                  {nbr_site == 4 ? null : (
                      <div className="form-group mt-5">
                    <Row>
                        <Col xs={12} md={12} className="noPadding">
                            <label
                                className={(is_autorise_tech ? 'check_2 mt-4 active' : 'check_2 mt-4')}
                                htmlFor="autorise">

                                <input
                                    type="checkbox"
                                    name="autorise"
                                    id="autorise"
                                    value="autorise"
                                    onChange={this.checkAutorise}
                                    checked={is_autorise_tech || false}
                                />
                                <p>
                                    {(is_autorise_tech ?
                                            <img src={check_2_active} alt="Endesa S.A."/>
                                            :
                                            <img src={check_2} alt="Endesa S.A."/>
                                    )}
                                    J'autorise expressément Endesa Energia SA à récupérer les données techniques et de consommation de mon ou mes site(s) auprès du Gestionnaire de Réseau afin d'élaborer une offre commerciale de fourniture d’énergie répondant au mieux à mes besoins. Cette autorisation a une validité de 12 mois ou de la durée du contrat éventuellement souscrit par la suite. J’autorise également Endesa à collecter les informations saisies dans ce formulaire pour le traitement de ma demande. <span className="red_star">*</span>
                                </p>
                            </label>
                        </Col>
                        {/*
                        <Col xs={12} md={12} className="noPadding">
                            <label
                                className={(is_autorise_info ? 'check_2 mt-4 active' : 'check_2 mt-4')}
                                htmlFor="is_autorise_info">

                                <input
                                    type="checkbox"
                                    name="is_autorise_info"
                                    id="is_autorise_info"
                                    value="is_autorise_info"
                                    onChange={this.checkAutorise_info}
                                    checked={is_autorise_info || false}
                                />
                                <p>
                                    {(is_autorise_info ?
                                            <img src={check_2_active} alt="Endesa S.A."/>
                                            :
                                            <img src={check_2} alt="Endesa S.A."/>
                                    )}
                                    J’autorise Endesa à collecter les informations saisies
                                    dans ce formulaire pour le traitement de ma
                                    demande. <span className="red_star">*</span>
                                </p>
                            </label>
                        </Col>
                        */}
                    </Row>
                </div>

                      )}



                  <div className="form_group pt-5">
                    <p> </p>
                    {list_CP.length > parseInt(nbr_site) ? (
                      <div>
                        {/*<p className="text-danger">Nombre de site doit être égal au nombre codes postaux</p>*/}
                      </div>
                    ) : null}
                    <input
                      type="submit"
                      disabled={
                            nbr_site == 4
                          ? false
                          : is_one === true &&
                            code !== "" &&
                            check_cp_list_items &&
                            code.replace(/\s/g, "").length && is_autorise_tech
                          ? false
                          : nbr_site !== "" &&
                            nbr_site !== null &&
                            code !== "" &&
                            check_cp_list_items &&
                            code.replace(/\s/g, "").length && is_autorise_tech
                          ? false
                          : true
                      }
                      name="btn_submit"
                      id="ident_continue"
                      className="btn_next_continue btn_submit"
                      onSubmit={this.handleSubmit}
                      value="Continuer"
                    />
                    {is_ready_only ? (
                      <label
                        className="reset-data btn_reset"
                        onClick={this.resetData}
                      >
                        <i className="far fa-trash-alt"></i>Réinitialiser la
                        souscription
                      </label>
                    ) : null}
                  </div>
                </form>

                <ReCaptcha
                  ref={(ref) => (this.recaptcha = ref)}
                  sitekey={site_key}
                  action={this.updateToken}
                  verifyCallback={this.verifyCallback}
                />
              </Col>
            </Row>
            {this.state.errorCounter > 3 && this.state.showPopupError ? (
              <React.Fragment>
                <Modal
                  dialogClassName="modal-90w"
                  size="lg"
                  centered
                  show={this.state.show}
                  onHide={this.handleClose}
                >
                  <PopupError
                    text="Close Me"
                    classFull={true}
                    close_popup={this.handleClose}
                    closePopup={this.handleClose}
                  />
                </Modal>
              </React.Fragment>
            ) : null}
            {/*
                            this.state.errorCounter > 3 && this.state.showPopupError ? 
                                <PopupError
                                text='Close Me'
                                classFull= {true}
                                close_popup= {this.close_popup.bind(this)}
                                closePopup={this.togglePopupError.bind(this)}
                                /> 
                            : null
                        */}
          </Container>
        )}
      </React.Fragment>
    );
  }
}
function mapStateToProps({ client_identification, reset_subscription }) {
  return { client_identification, reset_subscription };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      identification: identification,
      get_reset_subscription: get_reset_subscription,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(Client);
