import React, { Component } from 'react';
import { connect } from 'react-redux';
import { current_step, subscription_id } from '../../services/status';
import { Container, Row, Col } from 'react-bootstrap';
import welcome from '../../assets/img/welcome.png';

class all_np_step extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //last_step: (localStorage.getItem('signature_contrat') ? JSON.parse(localStorage.getItem('signature_contrat')) : JSON.parse(localStorage.getItem('current_step_continue'))),
            isLoading: false,
        }
    }

    omponentWillUnmount() {
        localStorage.clear();
    }

    componentDidMount() {
        //const { last_step } = this.state;
        //console.log('last_step', last_step);
        //console.log('current step : ',current_step, 'subs id : ',subscription_id )
    }
    render() {
        const { email } = this.state
        return (
            <React.Fragment>
                <Container>
                    <Row>
                        <Col xs={12} md={12} className="txt_center mt-6">
                            {/*<img src={welcome} alt="ENDESA S.A." />*/}
                            <h1 className="h1">Nous sommes désolés, la souscription en ligne est indisponible pour votre site.</h1>
                            <p className="btn_equipe">Pour plus d’informations <a className="link-contact" href={process.env.REACT_APP_API_URL+'contact/?subscription_id='+ subscription_id}>contactez-nous</a></p>
                            <a href={process.env.REACT_APP_API_URL} className="btn_redirect">Retour au site</a>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}
function mapStateToProps({ welcome }) {
    return { welcome };
}
export default connect(mapStateToProps)(all_np_step);