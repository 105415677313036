import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { post } from '../services/request';
import { apiUrl, url_get_recalled } from '../services/constant';
import { get_recalled_done } from '../actions/get_recalled';


function* get_recalled_saga(action) {
	yield call(
		callApi,
		apiUrl + url_get_recalled,
		post,
        action.payload,
        get_recalled_done,
	);
}
export default function* watch_get_recalled_saga() {
    yield takeEvery('GET_RECALLED', get_recalled_saga);
}

