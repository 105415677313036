
export function validate_estimation(payload) {
    return {
        type: 'ESTIMATION_FORM',
        payload,
    };
}
export function validate_estimation_done(request) {
    return {
        type: 'ESTIMATION_FORM_DONE',
        payload: request,
    };
}