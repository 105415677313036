import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { post } from '../services/request';
import { apiUrl, url_identification } from '../services/constant';
import { identification_done } from '../actions/identification';


function* indentification_saga(action) {
	yield call(
		callApi,
		apiUrl + url_identification,
		post,
        action.payload,
        identification_done,
	);
}
export default function* watch_indentification_saga() {
    yield takeEvery('IDENTIFICATION', indentification_saga);
}

