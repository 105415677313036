import React, {useState} from 'react';
import { Col } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import history from './services/history';
import { routes } from './services/routes';

import { Provider } from "react-redux";
import configureStore from "./services/store";

import Aide from './components/aide/aide';
import Footer from './layout/footer/footer';
import Header from './layout/header/header';
import { current_step } from './services/status';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/global.css';
import './assets/css/form.css';


const store = configureStore();
function App() {
  return (
    <div className={`app row ${current_step === "donnees_client" || current_step === "identification" || current_step === "saisie_pce_estimation" || current_step === "verification_email" ? "apply-hide" : ''}`}>
      <Provider store={store}>
        <Col xs={12} md={12} lg={9} className="noPadding">
          <Header />
          <div className="inside_step">
            <Router history={history}>
              {
                routes.map((route, index) => {
                  if(window.location.pathname.split('/').length > 3){
                      return <><Redirect to={route.path} /><Route key={index} exact path={route.path} component={route.component} /></>
                  }
                  return( 
                    <Route key={index} exact path={route.path} component={route.component} />
                  )
              })
              }
            </Router>
          </div>
        </Col>
        <Aide />
        <Footer />
      </Provider>
    </div>
  );
}

export default App;
