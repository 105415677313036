import React from 'react';

function Offer(props) {
    return (
        <React.Fragment>
            <div key={props.key} className={`offer_container${(props.selected === props.id ? ' selected' : '')}`}>
                <div className="icon_wrapper" style={{backgroundColor: props.icon_bg_color}}>
                    <img className="offer_icone offer_icone_eco" alt="Offres Endesa S.A." src={props.icone} />
                </div>
                {(props.consulted_offer === props.id ?
                    <span className="offer_selected">Offre consultée</span>
                    : '')}
                <div className="inner_offer offres_inner_offer">
                    <div className="begin-bloc">
                        <h2>{props.title}<br /><em>{props.sub_title}</em></h2>
                        <p>{props.text}</p>
                    </div>
                    <div className="end-bloc">
                        <div className="btn-group toggle_avantage">
                            <p className="title-advantages">
                                Avantages :
                            </p>
                            <div className={`list_avantage ${props.textButtonsOffer[props.index_offer] ? "" : "offer-show-one-line"}`}>
                                <ul id={props.id}>
                                    {props.avantages.slice(0,1).map((av, index) => {
                                        return <li key={index}>{av.title}</li>;
                                    })}
                                </ul>
                                <div className={`dropdown-menu dropdown-menu-right list_avantage ${props.textButtonsOffer[props.index_offer] ? 'show' : null} `}>
                                    <ul id={props.id}>
                                    {props.avantages.slice(1,props.avantages.length).map((av, index) => {
                                            return <li key={index}>{av.title}</li>;
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <button type="button" onClick={(e) => props.toggleTextButton(props.index_offer)} className={`btn btn-secondary dropdown-toggle btn-show-more ${props.textButtonsOffer[props.index_offer] ? 'rotate-me' : null} `}>
                                {
                                    props.textButtonsOffer[props.index_offer] ? 'Voir moins' : 'Voir plus'
                                }
                            </button>
                        </div>
                        <a href="#" onClick={() => props.brochure(props.offer_id,props.title)} className="download_brochure">Consulter la brochure</a>
                    </div>
                </div>
                <label onChange={() => props.toggetOffer(props.id,props.isbusiness)} className="choose_offer" htmlFor={`offer_type_${props.id}`}>
                    <span>{props.selected === props.id ? 'Offre sélectionnée' : 'Calculer le prix'}</span>
                    <input type="radio" name="offer_type" id={`offer_type_${props.id}`} value={props.id} />
                </label>
            </div>
        </React.Fragment >
    );
}

export default Offer;