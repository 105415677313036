import React from "react";
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";
import { Container, Row, Col } from 'react-bootstrap';
import '../../assets/css/form.css';
import './souscription.css';
import closeBtn from '../../assets/img/close-btn.svg';
import { connect } from 'react-redux';
import { get_change_subscription_email } from '../../actions/change_subscription_email';
import { current_step, subscription_id } from '../../services/status';
import { bindActionCreators } from 'redux';
import swal from 'sweetalert';

class PopupEmail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            newMail: '',
            site_key: '6Lej9eoUAAAAAP14LVkE3Wpgsy1-q7i4Oosur-mk',
            token: ''
        }
    }
    handleMail = (e) => {
        this.setState({
            newMail: e.target.value
        })
    }
    componentDidMount() {
        const { site_key } = this.state;
        loadReCaptcha(site_key, this.verifyCallback);
    }
    componentDidUpdate(prevProps) {
        const { change_subscription_email } = this.props
        if (prevProps.change_subscription_email !== change_subscription_email) {
            if (change_subscription_email.result.status === 200) {
                swal({
                    title: "Modification adresse email",
                    text: "Votre adresse email à été changé avec succès",
                    icon: "success",
                }).then(() => {
                    this.props.closePopup()
                });
            } else {
                swal({
                    title: "Modification adresse email",
                    text: "Tentative de modification email a echoué",
                    icon: "error",
                }).then(() => {
                    this.setState({
                        //codeAPI: '400'
                    })
                });
            }
        }

    };
    updateToken = () => {
        // you will get a new token in verifyCallback
        this.recaptcha.execute()
    };
    verifyCallback = (recaptchaToken) => {
        // Here you will get the final recaptchaToken!!!  
        //console.log(recaptchaToken, "<= your recaptcha token");
        this.setState({
            token: recaptchaToken
        });
    }
    updateEmailSubmit = (e) => {
        e.preventDefault()
        const { get_change_subscription_email } = this.props;
        const { newMail, token } = this.state;
        let frm_data = {
            subscription_id: subscription_id,
            email: newMail,
            recaptcha: token
        };
        this.props.onUpdateMail(newMail);
        get_change_subscription_email(frm_data);
        //console.log('update : ', frm_data)
    }
    render() {
        const { newMail, site_key, recaptchaRef } = this.state
        return (
            <div className="popup-wrapper popup_update_mail">
                <div className='popup'>
                    <div className='popup_inner'>
                        <button className="btn-close" onClick={this.props.closePopup}><img src={closeBtn} /></button>
                        <h1>Modifier votre adresse mail</h1>
                        <form onSubmit={this.updateEmailSubmit} className="confimation-pop">
                            <Row className="d-flex align-items-end">
                                <Col xs={12} md={6} className="noPadding mt-5">
                                    <label>Nouvelle adresse e-mail <span className="red_star">*</span></label>
                                    <input type="email" name="email" className="simple_input" value={newMail} onChange={this.handleMail} />
                                </Col>
                                <Col xs={12} md={6} className="noPadding mt-5">
                                    <button className="btn_rappel" disabled={newMail === '' ? true : false} onClick={this.updateEmailSubmit} >Valider</button>
                                </Col>
                            </Row>
                            <ReCaptcha
                                ref={ref => this.recaptcha = ref}
                                sitekey={site_key}
                                action={this.updateToken}
                                verifyCallback={this.verifyCallback}
                            />
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps({ change_subscription_email }) {
    return { change_subscription_email };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        get_change_subscription_email: get_change_subscription_email
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(PopupEmail)