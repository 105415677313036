import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { post } from '../services/request';
import { apiUrl, url_docusign_link } from '../services/constant';
import { get_docusign_link_done } from '../actions/docusign_link';


function* get_docusign_link_saga(action) {
	yield call(
		callApi,
		apiUrl + url_docusign_link,
		post,
        action.payload,
        get_docusign_link_done,
	);
}
export default function* watch_get_docusign_link_saga() {
    yield takeEvery('DOCUSIGN_LINK', get_docusign_link_saga);
}