/*import axios from 'axios';
import { apiUrl, url_check_promotion_code } from '../services/constant';

export const check_promotion_code = (data) => dispatch => {
    const request = axios.post(apiUrl + url_check_promotion_code, data);
    dispatch({
        type: 'CHECK_PROMOTION_CODE',
        payload: request
    })
}
*/
export function check_promotion_code(payload) {
    return {
        type: 'CHECK_PROMOTION_CODE',
        payload,
    };
}
export function check_promotion_code_done(request) {
    return {
        type: 'CHECK_PROMOTION_CODE_DONE',
        payload: request,
    };
}