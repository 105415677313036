import React from "react"
import { Row, Col } from 'react-bootstrap';
import { current_step, subscription_id } from '../../services/status';
import './aide.css';
import closeBtn from '../../assets/img/close-btn.svg';
import closeMobile from '../../assets/img/mobile_close.png';
import facturePCE from '../../assets/img/facture.jpg';
import PopupRappel from './PopupRappel'

class PopupPCE extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            //pceTypes: ['ENDESA', 'ENI', 'EDF', 'ENGIE'],
            pceTypes: ['ENDESA'],
            pceChoosen: 'ENDESA',
            showPopup: false
        }
    }
    handleRadio(e) {
        this.setState({ 
            pceChoosen: e.target.value 
        })
    }
    togglePopup = () => {
        //console.log('Rappel')
        const { showPopup } = this.state
        //window.scrollTo(0, 0)
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.setState({
            showPopup: !showPopup
        })
    }
    render(){
        const {pceChoosen, pceTypes} = this.state
        const options = pceTypes.map((type, key) => {
            const isCurrent = pceChoosen === type
            return (
                <div key={key} className={
                    isCurrent ? 
                    'radioPad__wrapper radioPad__wrapper--selected' :
                    'radioPad__wrapper'
                    }>
                    <div>
                        <label>
                            <input
                                className="radioPad__radio"
                                type="radio" 
                                name="pceTypes" 
                                id={type} 
                                value={type}
                                onChange={this.handleRadio.bind(this)}
                            />
                        {type}
                        </label>
                    </div>
                </div>
            )
            
        })
        
        return(
            <>
                {
                    this.state.showPopup === true ?
                        <PopupRappel
                            text='Close Me'
                            closePopup={this.togglePopup.bind(this)}
                        />
                    :
                    <div className="popup-wrapper popup_continuer">
                        {/*<div className="bg_pop_up2" onClick={this.props.closePopup} />*/}
                        <div className="popup">
                            <div className="popup_inner">
                                <button className="btn-close" onClick={this.props.closePopup}>
                                    <img className="only_desktop" src={closeBtn} />
                                    <div className="only_mobile only-mobile-bloc">
                                        <img className="only_mobile mobile-close-btn" src={closeMobile} />
                                        <span>Fermer</span>
                                    </div>
                                </button>
                                <h1>Où trouver mon PCE ?</h1>
                                <p className="info-text">Le PCE (Point de Comptage et d'Estimation) est un numéro à 14 chiffres ou un numéro GI suivi de 6 chiffres. Ce numéro nous permet d'identifier rapidement et précisément votre compteur. Il se trouve sur votre facture de gaz, généralement dans l’emplacement dédié aux données client.</p>
                                {/*<div className="wrapper-main">  
                                    {options}
                                </div>*/}
                                <div className="lead">
                                    {/*<strong>Ceci est un texte de test {pceChoosen}</strong>*/}
                                    {
                                        pceChoosen === 'ENDESA' ? <img className="img-facture" src={facturePCE} alt='facture pce endesa' />
                                        : null
                                    }
                                    {
                                        //pceChoosen === 'ENI' ? <img className="img-facture" src={facturePCE} alt='facture pce eni' />
                                        //: null
                                    }
                                    {
                                        //pceChoosen === 'ENGIE' ? <img className="img-facture" src={facturePCE} alt='facture pce engie' />
                                        //: null
                                    }
                                    {
                                        //pceChoosen === 'EDF' ? <img className="img-facture" src={facturePCE} alt='facture pce edf' />
                                        //: null
                                    }
                                    
                                </div>
                                {/*<button type="submit" name="btn_submit" id='ident_continue' className="btn_rappel_error_conso btn-rappel-popin btn_submit mt-3" value="Être rappelé" onClick={this.togglePopup.bind(this)}>Être rappelé</button>*/}
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}

export default PopupPCE