import * as actionTypes from '../actions/type';
import history from '../services/history';

const initialState = {
    showErrorModal: false,
    errorMessage: ''
}

const execute404 = (state, action) => {
    localStorage.setItem('step', '404');
    history.push('/404');
    return { ...state };
}

const execute500 = (state, action) => {
    localStorage.setItem('step', '500');
    history.push('/500');
    return { ...state };
}

const executeOtherError = (state, action) => {
    return {
        ...state,
        showErrorModal: true
    };
}

const error = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.HTTP_404_ERROR:
            return execute404(state, action);
        case actionTypes.HTTP_500_ERROR:
            return execute500(state, action);
        case actionTypes.HTTP_OTHER_ERROR:
            return executeOtherError(state, action);
        default:
            return state;
    }
}

export default error;