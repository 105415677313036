import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { post } from '../services/request';
import { apiUrl, url_previous } from '../services/constant';
import { get_previous_done } from '../actions/previous';


function* get_previous_saga(action) {
	yield call(
		callApi,
		apiUrl + url_previous,
		post,
        action.payload,
        get_previous_done,
	);
}
export default function* watch_get_previous_saga() {
    yield takeEvery('PREVIOUS', get_previous_saga);
}