/*import axios from 'axios';
import { apiUrl, url_signature_contrat } from '../services/constant';

export const signature_contrat = (data) => dispatch => {
    const request = axios.post(apiUrl + url_signature_contrat, data);
    dispatch({
        type: 'SIGNATURE_CONTRAT',
        payload: request
    })
}
*/
export function signature_contrat(payload) {
    return {
        type: 'SIGNATURE_CONTRAT',
        payload,
    };
}
export function signature_contrat_done(request) {
    return {
        type: 'SIGNATURE_CONTRAT_DONE',
        payload: request,
    };
}