import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { current_step, subscription_id } from '../../services/status';
import { validatePce } from '../../actions/validate_pce';
import { bindActionCreators } from 'redux';
import closeBtn from '../../assets/img/close-btn.svg';
import '../../assets/css/form.css';
import './popup.css';
import { Tooltip } from 'react-tippy';

class FactureModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			all_data: props.all_data,
			type_usage_chaudiere: props.values.type_consommation ? props.values.type_consommation.includes("Chaudiere") : false,
			type_fourAGaz : props.values.type_consommation ? props.values.type_consommation.includes("Four_a_gaz") : false,
			type_cuissonRestau : props.values.type_consommation ? props.values.type_consommation.includes("Cuisson_restau") : false,
			type_EauChaude : props.values.type_consommation ? props.values.type_consommation.includes("eau_chaude") : false,
			anciennete: props.values.anciennete ? props.values.anciennete : '' ,
			type_chaudiere: props.values.type_chaudiere ? props.values.type_chaudiere : '',
			uti_four : (props.values.uti_four === true || props.values.uti_four === false ) ? props.values.uti_four : '',
			superficie: props.values.superficie ? props.values.superficie : '',
			num_couvert: props.values.num_couvert ? props.values.num_couvert : '',
			num_salarie: props.values.num_salarie ? props.values.num_salarie : '',
			list_anciennete: props.list_anciennete ? props.list_anciennete : [],
			list_type_chaudiere: props.list_type_chaudiere ? props.list_type_chaudiere : [],
			errorNumCouvert : '',
			errorNumSalarie : '',
            widthSize: window.innerWidth,
		};
	}

	componentDidUpdate(prevProps){
		const { validated_pce } = this.props;
		if (prevProps.validated_pce !== validated_pce) {
            if(validated_pce.result.status !== 200){
				this.setState({
					errorNumCouvert: validated_pce.result.response.data.num_couvert,
					errorNumSalarie: validated_pce.result.response.data.num_salarie,
				})
            }
        }
	}
	componentDidMount(){
        this.setState({
            widthSize: window.innerWidth
        })
	}
	// HANDLE INPUT CHANGES
	handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };
	handleType1 = () => {
		this.setState({
			type_usage_chaudiere: !this.state.type_usage_chaudiere
		});
	};
	handleType2 = () => {
		this.setState({
			type_fourAGaz: !this.state.type_fourAGaz
		});
	};

	handleType3 = () => {
		this.setState({
			type_cuissonRestau: !this.state.type_cuissonRestau
		});
	};
	handleType4 = () => {
		this.setState({
			type_EauChaude: !this.state.type_EauChaude
		});
	};

	handleUtilisationFour1 = () => {
		var value = this.state.uti_four === true ? '' : true 
		this.setState({
			uti_four: value
		});
	};
	handleUtilisationFour2 = () => {
		var value = this.state.uti_four === false ? '' : false 
		this.setState({
			uti_four: value
		});
	};
	handleAnciennete = (e) => {
		this.setState({
			anciennete: e.target.value
		});
	};
	handleTypeChaudiere = (e) => {
		this.setState({
			type_chaudiere: e.target.value
		});
	};
	// SUBMIT DATA
	handleSubmit = (e) => {
		const { date_start_supply,validatePce, prospect } = this.props;        
		e.preventDefault();
        const {
			type_usage_chaudiere,
			type_fourAGaz,
			type_EauChaude,
			type_cuissonRestau,
			uti_four,
			superficie,
			num_couvert,
			num_salarie,
			anciennete,
			type_chaudiere
        } = this.state;
		const type_consommation = []
        const frm_data = {}
        if (type_usage_chaudiere){
			type_consommation.push('Chaudiere')
        }
        if (type_fourAGaz){
			type_consommation.push('Four_a_gaz');
        }
        if (type_cuissonRestau){
			type_consommation.push('Cuisson_restau');
        }
        if (type_EauChaude){
			type_consommation.push('eau_chaude');
        }
		frm_data.superficie = type_usage_chaudiere ? superficie : null;
		frm_data.anciennete = type_usage_chaudiere ? anciennete : null;
		frm_data.type_chaudiere = type_usage_chaudiere ? type_chaudiere : null;
		
		frm_data.uti_four = type_fourAGaz && uti_four !== '' ? uti_four : null;
		frm_data.num_couvert = type_cuissonRestau &&num_couvert !== '' ? num_couvert : null;
		frm_data.num_salarie = type_EauChaude && num_salarie !== ''  ? num_salarie : null;
		
		frm_data.type_consommation = type_consommation;
		frm_data.pce_choice = 'estimation';
        frm_data.prospect = prospect;
        frm_data.pce = null;
        frm_data.subscription_id = subscription_id;
		frm_data.date_start_supply = this.props.date_start_supply;
		validatePce(frm_data)
		//'Data', frm_data);
	};
	render() {
		const {
			type_usage_chaudiere,
			type_fourAGaz,
			type_cuissonRestau,
			type_EauChaude,
			list_anciennete,
			list_type_chaudiere,
			uti_four,
			superficie,
			num_couvert,
			num_salarie,
			anciennete,
			type_chaudiere,
			errorNumCouvert,
			errorNumSalarie,
			widthSize,
			all_data
		} = this.state;
		const { 
			closeFacture, 
			numFacture,  
		} = this.props;
		return (
			<React.Fragment>
				<div className="pop_up pop_up_facture facture">
					{/*<div className="bg_pop_up" onClick={closeFacture} />*/}
					<div className="pop_up_container popup_inner">
						{
							this.props.showBackButton === true ?
								<div className="wrapper-back-popup">
									<span className="retour-popup" onClick={this.props.initPopup}>Retour</span>
								</div>
							: null
						}
						{/*<i className="fas fa-times close_facture" onClick={closeFacture}></i>*/}
						<button className="btn-close" onClick={closeFacture}>
							<img src={closeBtn} alt='' />
						</button>
						<h1>Consommation du site {numFacture+1},<br/> {all_data.address}</h1>
						<form onSubmit={this.handleSubmit}>
							<div className="marginTest">
								<p>Afin d’estimer la consommation de gaz du site situé au {all_data.address}, sélectionnez vos usages de gaz (plusieurs usages sont possibles).</p>
								<label
									onClick={this.handleType1}
									className={type_usage_chaudiere ? 'checkout oui' : 'checkout'}
								>
									<span>Chauffage</span>
									{type_usage_chaudiere ? <i className="fas fa-times" /> : ''}
								</label>
								{type_usage_chaudiere && (
									<div className="form-group mt-4">
										<div className="form-group mt-4">
											<p>
												Superficie des locaux en m² <span className="red_star">*</span>
											</p>
											<input
												type="number"
												name="superficie"
												min="0"
												step="any"
												value={superficie}
												className="simple_input_2"
												onChange={this.handleInput}
											/>
										</div>
										<div className="form-group mt-4">
											<p>
												Ancienneté du bâtiment <span className="red_star">*</span>
											</p>
											<select
												name="anciennete"
												className="simple_input_2"
												value={anciennete}
												onChange={this.handleAnciennete}
											>
												<option value="" />
												{list_anciennete.map((item, index) => (
													<option key={index} value={item.value}>
														{item.display_value}
													</option>
												))}
											</select>
										</div>
										<div className="form-group mt-4">
											<p>
												Type de chaudière <span className="red_star">*</span>
											</p>
											<select
												name="type_chaudiere"
												value={type_chaudiere}
												className="simple_input_2"
												onChange={this.handleTypeChaudiere}
											>
												<option value="" />
												{list_type_chaudiere.map((item, index) => (
													<option key={index} value={item.value}>
														{item.display_value}
													</option>
												))}
											</select>
											<Tooltip title="Un autocollant qui indique le type de chaudière est généralement collé sur les chaudières à condensation. Vous pouvez également vérifier la documentation fournie avec l’appareil, notamment la déclaration de conformité du fabricant." position={widthSize > 576 ? "right" : "bottom"} trigger={widthSize > 576 ? "mouseenter" : "click"}>
												<span className="info_postal btn_mobile info_addr_envoi info" data-class="own_tooltip">i</span>
											</Tooltip>
										</div>
									</div>
								)}
								<label
									onClick={this.handleType2}
									className={type_fourAGaz ? 'checkout oui' : 'checkout'}
								>
									<span>Four à gaz (traiteur, industriel, boulangerie)</span>
									{type_fourAGaz ? <i className="fas fa-times" /> : ''}
								</label>
								{type_fourAGaz && (
									<div className="form-group mt-4">
										<div className="form-group mt-4">
											<p>
												<span style={{fontWeight: 'bold'}}>
													Si vous utilisez un four à gaz dans vos processus de fabrication,
												</span>{' '}
												(traiteur, industriel, boulangerie), veuillez préciser si vous êtes
												soumis à une saisonnalité
											</p>
											<div className="form-group mt-4">
												<Row>
													<Col xs={12} md={6} className="noPadding">
														<label
															onClick={this.handleUtilisationFour1}
															className={uti_four ? 'checkout2 oui' : 'checkout2 '}
														>
															<span>Oui</span>
														</label>
													</Col>
													<Col xs={12} md={6} className="noPadding">
														<label
															onClick={this.handleUtilisationFour2}
															className={uti_four === false ? 'checkout2  oui' : 'checkout2 '}
														>
															<span>Non</span>
														</label>
													</Col>
												</Row>
											</div>
										</div>
									</div>
								)}
								<label
									onClick={this.handleType3}
									className={type_cuissonRestau ? 'checkout oui' : 'checkout'}
								>
									<span>Cuisson / restauration</span>
									{type_cuissonRestau ? <i className="fas fa-times" /> : ''}
								</label>
								{type_cuissonRestau && (
									<div className="form-group mt-4">
										<div className="form-group mt-4">
											<p>
												<span style={{fontWeight: 'bold'}}>Si vous opérez dans la restauration,</span> veuillez
												indiquer le nombre maximal de couverts par service
											</p>
											<input
												type="number"
												name="num_couvert"
												min="0"
												value={num_couvert}
												className="simple_input_2"
												onChange={this.handleInput}
											/>
										</div>
											{
												errorNumCouvert !== '' ?
													<p className="text-danger">{errorNumCouvert}</p>
												: null
											}
									</div>
								)}
								<label
									onClick={this.handleType4}
									className={type_EauChaude ? 'checkout oui' : 'checkout'}
								>
									<span>Eau chaude</span>
									{type_EauChaude ? <i className="fas fa-times" /> : ''}
								</label>
								{type_EauChaude && (
									<div className="form-group mt-4">
										<div className="form-group mt-4">
											<p>
												<span style={{fontWeight: 'bold'}}>Si vous utilisez le gaz pour l'eau sanitaire,</span> veuillez
												indiquer le nombre de salariés sur le site
											</p>
											<input
												type="number"
												name="num_salarie"
												min="0"
												value={num_salarie}
												className="simple_input_2"
												onChange={this.handleInput}
											/>
										</div>
											{
												errorNumCouvert !== '' ?
													<p className="text-danger">{errorNumSalarie}</p>
												: null
											}
									</div>
								)}
							</div>
							<div className="form_group mt-5">
								<input
									disabled={
										(
											(
												// Chauffage,not FourAGaz,not Cuisson,not eauchaude
												!type_fourAGaz && !type_cuissonRestau && !type_EauChaude && type_usage_chaudiere && (superficie !== '' && anciennete !== '' && type_chaudiere !== '')
											) ||
											(
												// not Chauffage,FourAGaz,not Cuisson,not eauchaude
												!type_usage_chaudiere && type_fourAGaz && !type_cuissonRestau && !type_EauChaude && uti_four !== ''
											) ||
											(
												// not Chauffage,not FourAGaz,Cuisson,not eauchaude
												!type_usage_chaudiere && !type_fourAGaz && type_cuissonRestau && !type_EauChaude && num_couvert !== ''
											) ||
											(
												// not Chauffage,not FourAGaz,not Cuisson,eauchaude
												!type_usage_chaudiere && !type_fourAGaz && !type_cuissonRestau && type_EauChaude && num_salarie !== ''
											) ||
											(
												// Chauffage,FourAGaz,Cuisson,eauchaude
												type_fourAGaz && type_cuissonRestau && type_EauChaude && type_usage_chaudiere && (superficie !== '' && anciennete !== '' && type_chaudiere !== '') && uti_four !== '' && num_couvert !== '' &&  num_salarie !== ''
											) ||
											(
												// Chauffage,not FourAGaz,Cuisson,eauchaude
												!type_fourAGaz && type_cuissonRestau && type_EauChaude && type_usage_chaudiere && (superficie !== '' && anciennete !== '' && type_chaudiere !== '') && num_couvert !== '' &&  num_salarie !== ''
											) ||
											(
												// Chauffage,FourAGaz,not Cuisson,eauchaude
												type_fourAGaz && !type_cuissonRestau && type_EauChaude && type_usage_chaudiere && (superficie !== '' && anciennete !== '' && type_chaudiere !== '') && uti_four !== '' &&  num_salarie !== ''
											) ||
											(
												// Chauffage,FourAGaz,Cuisson,not eauchaude
												type_fourAGaz && type_cuissonRestau && !type_EauChaude && type_usage_chaudiere && (superficie !== '' && anciennete !== '' && type_chaudiere !== '') && uti_four !== '' && num_couvert !== '' 
											) ||
											(
												// Chauffage,not FourAGaz,not Cuisson,eauchaude
												!type_fourAGaz && !type_cuissonRestau && type_EauChaude && type_usage_chaudiere && (superficie !== '' && anciennete !== '' && type_chaudiere !== '') &&  num_salarie !== ''
											) ||
											(
												// Chauffage,not FourAGaz,Cuisson,not eauchaude
												!type_fourAGaz && type_cuissonRestau && !type_EauChaude && type_usage_chaudiere && (superficie !== '' && anciennete !== '' && type_chaudiere !== '') && num_couvert !== '' 
											) ||
											(
												// Chauffage,FourAGaz,not Cuisson,not eauchaude
												type_fourAGaz && !type_cuissonRestau && !type_EauChaude && type_usage_chaudiere && (superficie !== '' && anciennete !== '' && type_chaudiere !== '') && uti_four !== ''
											) ||
											(
												// not Chauffage,FourAGaz,Cuisson,eauchaude
												type_fourAGaz && type_cuissonRestau && type_EauChaude && !type_usage_chaudiere && uti_four !== '' && num_couvert !== '' &&  num_salarie !== ''
											) ||
											(
												// not Chauffage,FourAGaz,not Cuisson,eauchaude
												type_fourAGaz && !type_cuissonRestau && type_EauChaude && !type_usage_chaudiere && uti_four !== '' &&  num_salarie !== ''
											) ||
											(
												// not Chauffage,FourAGaz,Cuisson,not eauchaude
												type_fourAGaz && type_cuissonRestau && !type_EauChaude && !type_usage_chaudiere && uti_four !== '' && num_couvert !== ''
											) ||
											(
												// not Chauffage,not FourAGaz,Cuisson,eauchaude
												!type_fourAGaz && type_cuissonRestau && type_EauChaude && !type_usage_chaudiere && num_couvert !== '' &&  num_salarie !== ''
											)
										) ? (
											false
										) : (
											true
										)
									}
									type="submit"
									name="btn_submit"
									className="valider"
									value="Valider mes informations"
									onSubmit={this.handleSubmit}
								/>
							</div>
						</form>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
function mapStateToProps({ validated_pce }) {
	return { validated_pce};
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			validatePce: validatePce,
		},
		dispatch
	);
}
export default connect(mapStateToProps, mapDispatchToProps)(FactureModal);
