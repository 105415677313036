/*import axios from 'axios';
import { apiUrl, url_donnees_client } from '../services/constant';

export const DONNEES_CLIENT = (data) => dispatch => {
    const request = axios.post(apiUrl + url_donnees_client, data);
    dispatch({
        type: 'DONNEES_CLIENT',
        payload: request
    })
}
*/
export function donnees_client(payload) {
    return {
        type: 'DONNEES_CLIENT',
        payload,
    };
}
export function donnees_client_done(request) {
    return {
        type: 'DONNEES_CLIENT_DONE',
        payload: request,
    };
}