import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { post } from '../services/request';
import { apiUrl, url_devis } from '../services/constant';
import { get_devis_done } from '../actions/devis';


function* get_devis_saga(action) {
	yield call(
		callApi,
		apiUrl + url_devis,
		post,
        action.payload,
		get_devis_done,
		{responseType: 'blob'}
	);
}
export default function* watch_get_devis_saga() {
    yield takeEvery('DEVIS', get_devis_saga);
}

