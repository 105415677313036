import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { post } from '../services/request';
import { apiUrl, url_check_promotion_code } from '../services/constant';
import { check_promotion_code_done } from '../actions/check_promotion_code';


function* check_promotion_code_saga(action) {
	yield call(
		callApi,
		apiUrl + url_check_promotion_code,
		post,
        action.payload,
        check_promotion_code_done,
	);
}
export default function* watch_check_promotion_code_saga() {
    yield takeEvery('CHECK_PROMOTION_CODE', check_promotion_code_saga);
}

