export function get_verification_delay(payload) {
    return {
        type: 'VERIFICATION_DELAY',
        payload,
    };
}
export function get_verification_delay_done(request) {
    return {
        type: 'VERIFICATION_DELAY_DONE',
        payload: request,
    };
}