import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { post } from '../services/request';
import { apiUrl, url_donnees_client } from '../services/constant';
import { donnees_client_done } from '../actions/donnees_client';


function* donnees_client_saga(action) {
	yield call(
		callApi,
		apiUrl + url_donnees_client,
		post,
        action.payload,
        donnees_client_done,
	);
}
export default function* watch_donnees_client_saga() {
    yield takeEvery('DONNEES_CLIENT', donnees_client_saga);
}

