import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { get } from '../services/request';
import { apiUrl, url_dynamic_parameters } from '../services/constant';
import { dynamic_parameters_done } from '../actions/settings';


function* dynamic_parameters_saga(action) {
	yield call(
		callApi,
		apiUrl + url_dynamic_parameters,
		get,
        action.payload,
        dynamic_parameters_done,
	);
}
export default function* watch_dynamic_parameters_saga() {
    yield takeEvery('PARAMETRES', dynamic_parameters_saga);
}