import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { post } from '../services/request';
import { apiUrl, url_choix_syndic } from '../services/constant';
import { choix_syndic_done } from '../actions/syndic';


function* choix_syndic_saga(action) {
	yield call(
		callApi,
		apiUrl + url_choix_syndic,
		post,
        action.payload,
        choix_syndic_done,
	);
}
export default function* watch_choix_syndic_saga() {
    yield takeEvery('CHOIX_SYNDIC', choix_syndic_saga);
}

