import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get_offer_category } from '../../actions/get_offer_category';
import { Row, Col } from 'react-bootstrap';
import { current_step, past_step, subscription_id } from '../../services/status';
//import { steps } from './steps';
import icon_step1 from '../../assets/img/icon_society.svg';
import icon_step2 from '../../assets/img/icon_offres.png';
import icon_step3 from '../../assets/img/icon_conso.svg';
import icon_step4 from '../../assets/img/icon_tarifs.svg';
import icon_step1_current from '../../assets/img/icon_societe_current.svg';
import icon_step2_current from '../../assets/img/icon_offres_current.png';
import icon_step3_current from '../../assets/img/icon_conso_current.svg';
import icon_step4_current from '../../assets/img/icon_tarifs_current.svg';
import icon_step5_current from '../../assets/img/icon_end_current.svg';


class BigSteps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active_step: 'identification',
            leftPosition: '',
            leftPositionMini: '',
            icon_active: null,
            icon: null,
            steps: [
                {
                    id: 1,
                    name: 'identification',
                    title: 'Société',
                    className: 'head_step_society',
                    icon_step: icon_step1,
                    icon_step_current: icon_step1_current
                },
                {
                    id: 2,
                    name: 'saisie_pce_estimation',
                    title: 'Consommation',
                    className: 'head_step_conso',
                    icon_step: icon_step3,
                    icon_step_current: icon_step3_current
                },
                {
                    id: 3,
                    name: 'choix_duree_contrat',
                    title: 'Tarifs',
                    className: 'head_step_tarifs',
                    icon_step: icon_step4,
                    icon_step_current: icon_step4_current
                },
                {
                    id: 4,
                    name: 'souscription',
                    title: 'Souscription',
                    className: 'head_step_souscription',
                    icon_step: icon_step5_current,
                    icon_step_current: icon_step5_current
                }
            ]
        }
    }

    componentWillMount() {
        let active = current_step;
        switch (current_step) {
            case 'identification':
                active = 'identification';
                break;
            case 'choix_syndic':
                active = 'identification';
                break;
            case 'liste_etablissements':
                active = 'identification';
                break;
            case 'contact':
                active = 'identification';
                break;
            case 'liste_offres':
                active = 'liste_offres';
                break;
            case 'signature_contrat':
                active = 'souscription';
                break;
            case 'verification_email':
                active = 'souscription';
                break;
            case 'donnees_client':
                active = 'souscription';
                break;
            case 'remerciement':
                active = 'souscription';
                break;
            case 'bienvenue':
                active = 'souscription';
                break;
            case 'all_np_step':
                active = 'souscription';
                break;

            default:
                active = current_step;
                break;
        }
        this.setState({
            active_step: active
        })
    }
    // GET LEFT POSITION OF CURRENT STEP
    offset = () => {
        let div = (document.getElementById("current_head_step") ? document.getElementById("current_head_step") : document.getElementById("logo_container"));
        let rect = div.getBoundingClientRect();
        let x = rect.left;
        return x;
    }
    // GET WIDTH POSITION OF CURRENT STEP
    widthCurrent = () => {
        let div = (document.getElementById("current_head_step") ? document.getElementById("current_head_step") : document.getElementById("logo_container"));
        let rect = div.getBoundingClientRect();
        let width = rect.width;
        return width;
    }
    // GET LEFT POSITION OF CURRENT STEP
    offset_mobile = () => {
        let div = (document.getElementById("current_head_step_mobile") ? document.getElementById("current_head_step_mobile") : document.getElementById("logo_container"));
        let rect = div.getBoundingClientRect();
        let x = rect.left;
        return x;
    }
    // GET WIDTH POSITION OF CURRENT STEP
    widthCurrent_mobile = () => {
        let div = (document.getElementById("current_head_step_mobile") ? document.getElementById("current_head_step_mobile") : document.getElementById("logo_container"));
        let rect = div.getBoundingClientRect();
        let width = rect.width;
        return width;
    }
    // CHANGING WIDTH FOR FINISHED AND CURRENT STEP
    changingStatus = () => {
        if(window.innerWidth > 992){
            this.setState({
                leftPosition: (this.offset() + (this.widthCurrent() / 2)) + 'px',
                leftPositionMini: (this.offset() - (this.widthCurrent() / 2)) + 'px'
            });
        }else{
            this.setState({
                leftPosition: (this.offset_mobile() + (this.widthCurrent_mobile() / 2)) + 'px',
                leftPositionMini: (this.offset_mobile() - (this.widthCurrent_mobile() / 2)) + 'px'
            });
        }
    };
    componentDidMount() {
        this.changingStatus();
        // ON RISIZE UPDATING VALUES OF WIDTH FOR FINISHED AND CURRENT STEP
        window.addEventListener("resize", this.changingStatus.bind(this));
        // GET OFFER CATEGORY TYPE LIST
        const { get_offer_category } = this.props;
        let data = {}
        data.subscription_id = subscription_id
        get_offer_category(data);
    }
    componentDidUpdate(prevProps){
        const { steps } = this.state;
        const { offer_category } = this.props;
        if(prevProps.offer_category !== offer_category){
            if(offer_category.result.status === 200){
                //console.log('data is : ', offer_category.result.data)
                this.setState({
                    icon_active: offer_category.result.data.active_icon !== null ? offer_category.result.data.active_icon : null,
                    icon_grey: offer_category.result.data.icon !== null ? offer_category.result.data.icon : null
                })
                // Get a copy of steps 
                let stepsCopy = JSON.parse(JSON.stringify(steps))
                //console.log('stepsCopy ', stepsCopy)
                //make changes to steps specific step
                stepsCopy[1].icon_step = offer_category.result.data.icon !== '' ? offer_category.result.data.icon : icon_step2
                stepsCopy[1].icon_step_current = offer_category.result.data.active_icon !== '' ? offer_category.result.data.active_icon : icon_step2_current
                //console.log('stepsCopy[0] ', stepsCopy[0].icon_step)
                //console.log('stepsCopy[0] ', stepsCopy[0].active_icon)
                //overide previous data with updated data
                this.setState({
                    steps:stepsCopy 
                }) 
                //console.log('steps ', steps)
            }
        }
    }
    render() {
        const { active_step, leftPosition, leftPositionMini, icon_active, icon_grey, steps } = this.state;
        return (
            <React.Fragment>
                <div className="desktopMenuContent">
                    <Row>
                        <Col xs={12} className="noPadding">
                            {
                                steps.map((item, idx) => {
                                    return <div key={idx} id={(active_step === item.name ? 'current_head_step' : '')} key={item.id} className={`head_step${(active_step === item.name ? ' current_head_step ' + item.className : '')}`}>
                                        <div className="img_step_container">
                                            {(past_step.includes(item.name) || active_step === item.name ?
                                                <img className="step_icon" src={(active_step === item.name ? item.icon_step_current : item.icon_step)} alt="Endesa S.A." />
                                                :
                                                ''
                                            )}
                                        </div>
                                        <p className="step_title">{item.title}</p>
                                        {(past_step.includes(item.name) || active_step === item.name ?
                                            <span className={`step_number${(active_step === item.name ? ' step_current' : '')}`}>{item.id}</span>
                                            :
                                            <span className={`step_bullet`}></span>
                                        )}
                                    </div>;
                                })
                            }
                        </Col>
                    </Row>
                    <span className="status_done" style={{ width: (active_step === 'identification' ? '0' : leftPositionMini) }}></span>
                    <span className="encours status" style={{ width: leftPosition }}></span>
                </div>
                <div className="mobileMenuContent">
                    <Row>
                        <Col xs={12} className="noPadding">
                            {
                                steps.map((item) => {
                                    return <div id={(active_step === item.name ? 'current_head_step' : '')} key={item.id} className={`head_step_${(active_step === item.name ? ' current_head_step ' + item.className : '')}`}>
                                        {
                                            active_step === item.name ?
                                            <div className='active_step_section'>
                                                <img className="step_icon" src={item.icon_step_current} alt="Endesa S.A." />
                                                <p>{item.title}</p>
                                            </div>
                                            : null
                                        }
                                    </div>;
                                })
                            }
                            {
                                steps.map((item, idx) => {
                                    return <div key={idx} id={(active_step === item.name ? 'current_head_step_mobile' : '')} className="steps_pins">
                                        {(past_step.includes(item.name) || active_step === item.name ?
                                            <span className={`step_number${(active_step === item.name ? ' step_current' : '')}`}>{item.id}</span>
                                            :
                                            <span className={`step_bullet`}></span>
                                        )}
                                    </div>
                                })
                            }
                        </Col>
                    </Row>
                    <span className="status_done" style={{ width: (active_step === 'identification' ? '0' : leftPositionMini) }}></span>
                    <span className="encours status" style={{ width: leftPosition }}></span>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps({ offer_category }) {
    return { offer_category };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        get_offer_category: get_offer_category
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BigSteps);


