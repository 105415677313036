import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { get } from '../services/request';
import { apiUrl, url_boilers_type } from '../services/constant';
import { get_type_chaudiere_done } from '../actions/type_chaudiere';


function* get_type_chaudiere_saga(action) {
	yield call(
		callApi,
		apiUrl+url_boilers_type,
		get,
        action.payload,
        get_type_chaudiere_done,
	);
}
export default function* watch_get_type_chaudiere_saga() {
    yield takeEvery('TYPE_CHAUDIERE', get_type_chaudiere_saga);
}

