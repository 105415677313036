// PRE VERIFIED ADDRESS
export default (state = {}, action) => {
    switch (action.type) {
        case 'PRE_VERIFIED_ADDRESS_DONE':
            return {
                result: action.payload
            }
        default:
            return state
    }
}