/*import axios from 'axios';
import { apiUrl, url_dynamic_parameters } from '../services/constant';

export const dynamic_parameters = () => dispatch => {
    const request = axios.get(apiUrl + url_dynamic_parameters);
    dispatch({
        type: 'PARAMETRES',
        payload: request
    })
}
*/
export function dynamic_parameters(payload) {
    return {
        type: 'PARAMETRES',
        payload,
    };
}
export function dynamic_parameters_done(request) {
    return {
        type: 'PARAMETRES_DONE',
        payload: request,
    };
}