import React from "react"
import swal from 'sweetalert';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { current_step, subscription_id } from '../../services/status';
import { get_continue_later } from '../../actions/continuer_later';
import './aide.css';
import closeBtn from '../../assets/img/close-btn.svg';
import closeMobile from '../../assets/img/mobile_close.png';
import PhoneInput from 'react-phone-input-2'

class PopupContinuer extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            email: '',
            telephone: '',
            frm_data: {},
            errorPhone: ''
        }
    }
    handleEmail = (e) =>{
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value
        })
    }
    // SUBMIT DATA
    handleSubmit = (e) => {
        const { email, telephone, frm_data } = this.state;
        const { get_continue_later } = this.props;
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        e.preventDefault();
        frm_data.email = email;
        frm_data.telephone = telephone;
        frm_data.subscription_id = path_remove_slash;
        // CALL WS BACKEND
        get_continue_later(frm_data);
        //console.log('Data', frm_data);
    }
    //COMPONENT CHANGE
    componentDidUpdate(prevProps) {
        const { continue_later } = this.props;
        //console.log('email prev: ', continue_later )
        if (prevProps.continue_later !== continue_later) {
            if (continue_later.result.status === 200) {
                window.dataLayer.push({
                    event: 'gtm.click.continuerPlusTard',                
                });
                swal({
                    //title: "Continuer plus tard",
                    text: "Votre souscription a été sauvegardée. Un mail a été envoyé à votre adresse",
                    icon: "success",
                }).then(() => {
                    this.setState({
                        errorPhone: ''
                    })
                    this.props.closePopup()
                });
            } else {
                this.setState({
                    errorPhone: 'Veuillez saisir un numéro telephone valide'
                })
                /*
                swal({
                    title: "Erreur dans le formulaire",
                    text: "Vos informations ne sont pas valides",
                    icon: "error",
                });
                */
            }
        }
    }
    render() {
        const {email, telephone, errorPhone} = this.state
        return (
            <div className="popup-wrapper">
                {/*<div className="bg_pop_up2" onClick={this.props.closePopup} />*/}
                <div className="popup">
                    <div className="popup_inner">
                        <button className="btn-close" onClick={this.props.closePopup}>
                            <img className="only_desktop" src={closeBtn} />
                            <div className="only_mobile only-mobile-bloc">
                                <img className="only_mobile mobile-close-btn" src={closeMobile} />
                                <span>Fermer</span>
                            </div>
                        </button>
                        <h1>Je continue plus tard</h1>
                        <p className="popup_continuer_p">Vous ne pouvez pas terminer votre souscription maintenant ?<br/>
                        Pas de soucis, renseignez votre email et recevez un lien pour reprendre la procédure là où vous vous êtes arrêté.
                        </p>
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group mt-2">
                                <Row>
                                    <Col xs={12} sm={6} md={6} className="noPadding">
                                        <label>Email <span className="red_star">*</span></label>
                                        <input type="email" name="email" value={email} onChange={(e) => this.handleEmail(e)} className="email-input simple_input" />
                                    </Col>
                                    <Col xs={12} sm={6} md={6} className="noPadding">
                                        <label>Téléphone <span className="red_star">*</span></label>
                                        <PhoneInput
                                            inputProps={{ name: "telephone", className: "simple_input_phone" }}
                                            masks={{ ma: '. ..-..-..-..' }}
                                            country={'fr'}
                                            value={telephone}
                                            countryCodeEditable={false}
                                            onChange={telephone => this.setState({ telephone })}
                                        />
                                        {   errorPhone != '' ?
                                                <p className="text-danger small-text-help mt-2">{errorPhone}</p>
                                            : null
                                        }
                                    </Col>
                                </Row>
                            </div>
                            <div className="form-group mt-5">
                                <Row>
                                    <Col xs={12} className="noPadding">
                                        <button type="submit" disabled={email === '' ? true : false} className="btn_simulation">Continuer plus tard</button>
                                    </Col>
                                </Row>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps({ continue_later }) {
    //var from index.js
    return { continue_later };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        get_continue_later: get_continue_later
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupContinuer)