import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { post } from '../services/request';
//import { apiUrl, url_saisie_pce_estimation } from '../services/constant';
import { validate_estimation_done } from '../actions/validate_estimation';


function* validate_estimation_saga(action) {
	yield call(
		callApi,
		'https://3703788d2fdc.ngrok.io/subscription/api/estimation-form/',
		post,
        action.payload,
        validate_estimation_done,
	);
}
export default function* watch_validate_estimation_saga() {
    yield takeEvery('ESTIMATION_FORM', validate_estimation_saga);
}

