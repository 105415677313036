/*import axios from 'axios';
import { apiUrl, url_continue_later } from '../services/constant';

export const get_continue_later = (data) => dispatch => {
    const request = axios.post(apiUrl + url_continue_later, data);
    dispatch({
        type: 'CONTINUE_LATER',
        payload: request
    })
}
*/
export function get_continue_later(payload) {
    return {
        type: 'CONTINUE_LATER',
        payload,
    };
}
export function get_continue_later_done(request) {
    return {
        type: 'CONTINUE_LATER_DONE',
        payload: request,
    };
}