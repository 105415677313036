/*import axios from 'axios';
import { apiUrl, url_liste_offres } from '../services/constant';

export const liste_offres = (data) => dispatch => {
    const request = axios.post(apiUrl + url_liste_offres, data);
    dispatch({
        type: 'LISTE_OFFRES',
        payload: request
    })
}*/

export function liste_offres(payload) {
    return {
        type: 'LISTE_OFFRES',
        payload,
    };
}
export function liste_offres_done(request) {
    return {
        type: 'LISTE_OFFRES_DONE',
        payload: request,
    };
}