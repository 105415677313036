
export function get_type_chaudiere(payload) {
    return {
        type: 'TYPE_CHAUDIERE',
        payload,
    };
}
export function get_type_chaudiere_done(request) {
    return {
        type: 'TYPE_CHAUDIERE_DONE',
        payload: request,
    };
}