import { combineReducers } from 'redux';
import start from './start';
import client from './identification';
import syndic from './syndic';
import etablissement from './etablissement';
import contact from './contact';
import offers from './offers';
import saisie_pce_estimation from './estimation';
import choix_duree_contrat from './choix_duree_contrat';
import donnees_client from './donnees_client';
import returned_verification_email from './verification_email';
import signature_contrat from './signature_contrat';
import validate_pce from './validate_pce';
import error from './error';
import brochure from './brochure';
import previous from './previous';
import code_promo from './check_promotion_code';
import recalled from './get_recalled';
import estimation_type_list from './estimation_type_list';
import applicate_code from './applicate_code';
import continue_later from './continue_later';
import resend_verification_code from './resend_verification_email';
import change_subscription_email from './change_subscription_email';
import reset_subscription from './reset_subscription';
import communes from './communes';
import payeurs_divergents from './payeurs_divergents';
import settings from './settings';
import all_subs from './all_subs';
import pce from './pce_valide';
import get_offer_category from './get_offer_category';
import verify_address from './verify_address';
import building_age from './building_age';
import chaudiere_type from './chaudiere_type';
import estimation_form from './estimation_form';
import verification_delay from './verification_delay';
import docusign_link from './docusign_link';
import pre_verified_address from './pre_verified_address';
import get_data from './get_data';
import get_sub_key from './get_sub_key';
import file_types from './file_types';
import devis from "./devis"

export default combineReducers({
    subscription_key: start,
    client_identification: client,
    syndic: syndic,
    etablissement: etablissement,
    coordonnees_contact: contact,
    my_offers: offers,
    estimation: saisie_pce_estimation,
    validated_pce: validate_pce,
    duree_contrat: choix_duree_contrat,
    informations_client: donnees_client,
    returned_verification_email: returned_verification_email,
    signature_contrat: signature_contrat,
    errorHandler: error,
    brochure_file: brochure,
    previous: previous,
    code_promo: code_promo,
    etre_rappeller: recalled,
    type_estimation_list: estimation_type_list,
    continue_later: continue_later,
    applicate_code: applicate_code,
    resend_verification_code: resend_verification_code,
    change_subscription_email: change_subscription_email,
    parametres: settings,
    reset_subscription: reset_subscription,
    payeurs_divergents: payeurs_divergents,
    communes: communes,
    all_subs: all_subs,
    valide_pce: pce,
    offer_category: get_offer_category,
    verify_address: verify_address,
    building_age: building_age,
    chaudiere_type: chaudiere_type,
    estimation_form : estimation_form,
    verification_delay : verification_delay,
    docusign_link : docusign_link,
    pre_verified_address : pre_verified_address,
    data : get_data,
    sub_key: get_sub_key,
    all_file_types: file_types,
    devis_file: devis
});