import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import swal from 'sweetalert';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { get_applicate_code } from '../../actions/applicate_code';
import { verification_email } from '../../actions/verification_email';
import { get_resend_verification_code } from '../../actions/resend_verification_email';
import { get_verification_delay } from '../../actions/verification_delay';
import { current_step, subscription_id } from '../../services/status';
import { bindActionCreators } from 'redux';
import '../../assets/css/form.css';
import './souscription.css';
import PopupEmail from "./PopupEmail"
import CountdownTimer from "react-component-countdown-timer";
import "../../../node_modules/react-component-countdown-timer/lib/styles.css";
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";
import PopupError from '../aide/PopupError'
import {Modal, Button} from "react-bootstrap"

class Confirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            frm_data: {},
            isLoading: false,
            civility: 'Mr',
            current_email: '',
            format_facture: 'postal_code',
            checkedEnvoi: new Map(),
            is_autorise: false,
            checkedFacturation: new Map(),
            showPopup: false,
            site_key: process.env.REACT_APP_TAG_RECAPTCHA_V3 ? process.env.REACT_APP_TAG_RECAPTCHA_V3 : '',
            token: '',
            hash_code: '',
            code: '',
            codeAPI: '400',
            hideWhenCodeValid: false,
            informations_client: (localStorage.getItem('next') ? JSON.parse(localStorage.getItem('next')) : JSON.parse(localStorage.getItem('current_step_continue'))),
            valideCodeMessage: '',
            showEmailCounter: false,
            dateToUpdateEmail: null, // duration for modifier l'adresse mail
            showResendCounter: false,
            dateToResendCode: null, // duration for renvoyer le code
            showCodeCounter: false,
            dateToValidateCode: null, // duration for valider le code
            errorCounter: 0,
            showPopupError: false,
            show: false
        }
    }
    handleClose = () => {
        this.setState({
            show: false
        })
    }

    handleShow  = () => {
        this.setState({
            show: true
        })
    }
    componentWillMount() {
        window.dataLayer.push({              
            pageCategory: 'ConfirmationMail',
            event: 'gtm.load.ConfirmationMail',                
        });
        const { informations_client, hash_code, dateToUpdateEmail, dateToResendCode, dateToValidateCode } = this.state;
        const {get_verification_delay} = this.props;
        let frm_data = {};
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        frm_data.subscription_id = path_remove_slash;
        get_verification_delay(frm_data);
        //console.log('informations_client : ', informations_client)
        this.setState({
            current_email: (localStorage.getItem('next') ? informations_client.data.email : informations_client.data.data.email),
            //hash_code: informations_client.data.hash_code
            hash_code: (localStorage.getItem('next') ? informations_client.data.hash_code : informations_client.data.data.hash_code),
            dateToUpdateEmail: informations_client.data.data ? informations_client.data.data.dateToUpdateEmail : informations_client.data.dateToUpdateEmail,
            dateToResendCode: informations_client.data.data ? informations_client.data.data.dateToResendCode : informations_client.data.dateToResendCode,
            dateToValidateCode: informations_client.data.data ? informations_client.data.data.dateToValidateCode : informations_client.data.dateToValidateCode,
            showEmailCounter: dateToUpdateEmail !== null ? true : false,
            showResendCounter: dateToResendCode !== null ? true : false,
            showCodeCounter: dateToValidateCode !== null ? true : false
        })
        //console.log('hash code : ', informations_client)
    }
    componentDidUpdate(prevProps) {
        const { applicate_code, returned_verification_email, resend_verification_code, change_subscription_email, verification_delay } = this.props;
        const {valideCodeMessage, errorCounter} = this.state;
        // POPUP VALIDATION CODE EMAIL
        if (prevProps.applicate_code !== applicate_code) {
            if (applicate_code.result.status === 200) {
                window.dataLayer.push({
                    event: 'gtm.click.codeMailValide',                
                });
                this.setState({
                    codeAPI: applicate_code.result.status,
                    hideWhenCodeValid: true,
                    valideCodeMessage: 'Code valide'
                })
                /*
                swal({
                    title: "Code vérification email",
                    text: "Code Validé",
                    icon: "success",
                }).then(() => {
                    this.setState({
                        codeAPI: applicate_code.result.status,
                        hideWhenCodeValid: true,
                    })
                });
                */
            } else {
                if(errorCounter > 2){
                    this.setState({
                        show: true,
                        showPopupError: true,
                        errorCounter: errorCounter+1,
                        codeAPI: '400',
                        hideWhenCodeValid: false,
                        valideCodeMessage: applicate_code.result.response.data.valideCodeMessage ? applicate_code.result.response.data.valideCodeMessage : 'Code invalide. Veuillez vérifier le code qui vous a été envoyé par email.',
                        dateToValidateCode: applicate_code.result.response.data.dateToValidateCode ? parseInt(applicate_code.result.response.data.dateToValidateCode[0]) : null,
                        showCodeCounter: applicate_code.result.response.data.dateToValidateCode ? parseInt(applicate_code.result.response.data.dateToValidateCode[0]) !== null ? true : false : null
                    })
                }else{
                    this.setState({
                        show: false,
                        codeAPI: '400',
                        hideWhenCodeValid: false,
                        errorCounter: errorCounter+1,
                        valideCodeMessage: applicate_code.result.response.data.valideCodeMessage ? applicate_code.result.response.data.valideCodeMessage : 'Code invalide. Veuillez vérifier le code qui vous a été envoyé par email.',
                        dateToValidateCode: applicate_code.result.response.data.dateToValidateCode ? parseInt(applicate_code.result.response.data.dateToValidateCode[0]) : null,
                        showCodeCounter: applicate_code.result.response.data.dateToValidateCode ? parseInt(applicate_code.result.response.data.dateToValidateCode[0]) !== null ? true : false : null
                    })
                }
                /*
                swal({
                    title: "Code vérification email",
                    text: "Code Erronée",
                    icon: "error",
                }).then(() => {
                    this.setState({
                        codeAPI: '400',
                        hideWhenCodeValid: false
                    })
                });
                */
            }
        }
        // UPLOAD EMAIL
        if (prevProps.change_subscription_email !== change_subscription_email) {
            if (change_subscription_email.result.status === 200) {
                this.setState({
                    dateToUpdateEmail: change_subscription_email.result.data.dateToUpdateEmail,
                    dateToResendCode: change_subscription_email.result.data.dateToResendCode,
                    dateToValidateCode: change_subscription_email.result.data.dateToValidateCode,
                    showEmailCounter: change_subscription_email.result.data.dateToUpdateEmail !== null ? true : false,
                    showResendCounter: change_subscription_email.result.data.dateToResendCode !== null ? true : false,
                    showCodeCounter: change_subscription_email.result.data.dateToValidateCode !== null ? true : false,
                })
            }
        }
        // POPUP VALIDATION CODE EMAIL
        if (prevProps.resend_verification_code !== resend_verification_code) {
            if (resend_verification_code.result.status === 200) {
                //console.log('resend_verification_code.result : ', resend_verification_code)
                this.setState({
                    dateToResendCode: resend_verification_code.result.data.dateToResendCode,
                    dateToUpdateEmail: resend_verification_code.result.data.dateToUpdateEmail,
                    dateToValidateCode: resend_verification_code.result.data.dateToValidateCode,
                    showResendCounter: resend_verification_code.result.data.dateToResendCode !== null ? true : false,
                    showCodeCounter: resend_verification_code.result.data.dateToValidateCode !== null ? true : false,
                    showEmailCounter: resend_verification_code.result.data.dateToUpdateEmail !== null ? true : false
                })
                swal({
                    title: "Le code a été renvoyé",
                    //text: "Code Validée",
                    icon: "success",
                })
            } else {
                swal({
                    title: "Echec d'envoi code",
                    //text: "Code Erronée",
                    icon: "error",
                })
            }
        }
        // APPLICATION DU CODE
        {/*
        if ((prevProps.applicate_code !== applicate_code) && (applicate_code.result.status === 200)) {
            // LOADING
            this.setState({
                isLoading: true
            });
            setTimeout(() => {
                localStorage.setItem('verification_email', JSON.stringify(applicate_code.result.data));
                this.setState({
                    isLoading: false
                });
                swal({
                    title: 'Code vérifié',
                    text: 'Votre code est valide',
                    icon: "success",
                });
            }, 2000);
        }
        */}
        // NEXT STEP
        if (prevProps.returned_verification_email !== returned_verification_email) {
            // LOADING
            this.setState({
                isLoading: true
            });
            setTimeout(() => {
                localStorage.removeItem('previous');
                localStorage.setItem('step', returned_verification_email.result.data.step);
                localStorage.setItem('subscription_id', returned_verification_email.result.data.subscription_id);
                localStorage.setItem('next', JSON.stringify(returned_verification_email.result.data));
                // Redirect
                window.location.reload(false);
                window.location.replace(returned_verification_email.result.data.data.signing_url)
                localStorage.clear();
                //window.location.replace(returned_verification_email.result.data.step)
            }, 2000);
        }
        // COUNTDOWN VARIABLES
        if (prevProps.verification_delay !== verification_delay) {
            if (verification_delay.result.status === 200) {
                this.setState({
                    dateToUpdateEmail: verification_delay.result.data.dateToUpdateEmail ? verification_delay.result.data.dateToUpdateEmail : null,
                    dateToResendCode: verification_delay.result.data.dateToResendCode ? verification_delay.result.data.dateToResendCode : null,
                    dateToValidateCode: verification_delay.result.data.dateToValidateCode ? verification_delay.result.data.dateToValidateCode : null,
                    showEmailCounter: verification_delay.result.data.dateToUpdateEmail !== null ? true : false,
                    showResendCounter: verification_delay.result.data.dateToResendCode !== null ? true : false,
                    showCodeCounter: verification_delay.result.data.dateToValidateCode !== null ? true : false
                })
            }
        }
    }
    componentDidMount(){
        const {site_key} = this.state
        loadReCaptcha(site_key, this.verifyCallback);
    }
    updateToken = () => {
        // you will get a new token in verifyCallback
        this.recaptcha.execute()
    };
    verifyCallback = (recaptchaToken) => {
        // Here you will get the final recaptchaToken!!!
        this.setState({
            token: recaptchaToken
        });
    }
    handleCivility = (e) => {
        this.setState({
            civility: e.target.value
        });
    };
    handleFormatFacture = (e) => {
        this.setState({
            format_facture: e.target.value
        });
    };
    close_popup = (e) => {
        this.setState({
            showPopupError: false
        })
    }
    // Popup Error help
    togglePopupError = () => {
        const { showPopupError } = this.state
        //window.scrollTo(0, 0)
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.setState({
            showPopupError: !showPopupError
        })
    }
    // CHECK ADRESSE ENVOI POUR LES SITES QUI ONT UNE ADRESSE D'ENVOI
    is_envoi = (e) => {
        const item = e.target.name;
        const isChecked = e.target.checked;
        this.setState(prevState => ({
            checkedEnvoi: prevState.checkedEnvoi.set(item, isChecked)
        }))
    };
    // CHECK ADRESSE FACTURATION POUR LES SITES QUI ONT UNE ADRESSE FACTURATION
    is_facturation = (e) => {
        const item = e.target.name;
        const isChecked = e.target.checked;
        this.setState(prevState => ({
            checkedFacturation: prevState.checkedFacturation.set(item, isChecked)
        }))
    };
    checkAutorise = (e) => {
        const { is_autorise } = this.state;
        this.setState({
            is_autorise: !is_autorise
        });
    };
    togglePopup = () => {
        const { showPopup } = this.state
        //window.scrollTo(0, 0)
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.setState({
            showPopup: !showPopup,
            show: true
        })
    };
    handleSubmit = (e) => {
        e.preventDefault();
        const { verification_email } = this.props;
        const { frm_data, hash_code, token } = this.state;
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        frm_data.hash_code = hash_code;
        //frm_data.subscription_id = subscription_id;
        frm_data.subscription_id = path_remove_slash;
        frm_data.recaptcha = token
        // SUBMIT DATA TO BACKEND
        verification_email(frm_data);
        //console.log('data form : ', frm_data)
        this.setState({
            isLoading: true
        });
    }
    // APPLIQUER CODE
    applicateCode = (e) => {
        e.preventDefault();
        const { get_applicate_code } = this.props;
        const { hash_code, code } = this.state;
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        let data = {};
        //data.subscription_id = subscription_id;
        data.subscription_id = path_remove_slash;
        data.code = code;
        data.hash_code = hash_code;
        // SUBMIT TO BACKEND
        get_applicate_code(data);
        //console.log('Applicate', data);

    }
    // RESEND VERIFICATION CODE
    resendCode = (e) => {
        e.preventDefault();
        const { get_resend_verification_code } = this.props;
        const { current_email } = this.state;
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        //console.log('get_resend_verification_code : ', get_resend_verification_code)
        let data = {}
        //data.subscription_id = subscription_id;
        data.subscription_id = path_remove_slash;
        data.email = current_email;
        //console.log('resendCode is : ', data)
        //console.log('resendCode current_email is : ', current_email)
        get_resend_verification_code(data)
    }
    // HANDLE INPUT CHANGE
    handleInput = (e) => {
        let name = e.target.name;
        this.setState({
            [name]: e.target.value
        })
    }
    // Handle on update mail POPUP
    handleUpdateMail = (newMail) => {
        this.setState({
            current_email: newMail
        })
        //console.log('handleUpdateMail : ', this.state.current_email)
    }
    render() {
        const { site_key, current_email, code, isLoading, codeAPI, hideWhenCodeValid, valideCodeMessage, showEmailCounter, dateToUpdateEmail, showResendCounter, dateToResendCode, showCodeCounter, dateToValidateCode } = this.state;
        return (
            <React.Fragment>
                <Container>
                    <Row>
                        {isLoading ?
                            <div className="loadingKey">
                                <Spinner className="spinner-border text-primary" animation="border" role="status"></Spinner>
                                <span className="loader-text">Nous allons vérifier votre numéro de téléphone pour passer à la signature électronique de votre contrat.</span>
                            </div>
                            :
                            <Col xs={12} md={12} lg={9}>
                                <h1 className="blue-border"><span></span>Confirmation email</h1>
                                <p className="classic_txt sub-title">Un email vient de vous être envoyé à l’adresse <span>{current_email}</span>  avec le code de validation</p>
                                <div className="wrapper-email-update">
                                    <button className={hideWhenCodeValid ? "btn_edit_email hide-me" : "btn_edit_email"} disabled={showEmailCounter ? true : false} onClick={this.togglePopup}>Modifier mon adresse email</button>
                                    {
                                        //https://www.npmjs.com/package/react-component-countdown-timer
                                        codeAPI !== 200 && showEmailCounter && dateToUpdateEmail !== null ?
                                            <div className="countdown-email-update">
                                                <CountdownTimer count={dateToUpdateEmail} showTitle hideDay hideHours onEnd={() => this.setState({showEmailCounter: false})} />
                                            </div>
                                        : null
                                    }
                                </div>
                                <form onSubmit={this.handleSubmit} className="frm_code_validation">
                                    <div className="form-group mt-5">
                                            <p>Saisissez le code validation que vous avez reçu par email et cliquez sur « Appliquer » <span className="red_star">*</span></p>
                                            <div className="wrapper-code-resend">
                                            <input disabled={ codeAPI === 200 ? true : false} type="text" name="code" className="simple_input" value={code} onChange={this.handleInput} />
                                            {
                                                codeAPI !== 200 ?
                                                    <button className="btn_appliquer" disabled={showCodeCounter ? true : false} onClick={this.applicateCode}>Appliquer</button>
                                                : null
                                            }
                                            {
                                                //https://www.npmjs.com/package/react-component-countdown-timer
                                                codeAPI !== 200 && showCodeCounter && dateToValidateCode !== null ?
                                                    <div className="countdown-email-update">
                                                        <CountdownTimer count={dateToValidateCode} showTitle hideDay hideHours onEnd={() => this.setState({showCodeCounter: false})} />
                                                    </div>
                                                : null
                                            }
                                            </div>
                                            {
                                                codeAPI === 200 && valideCodeMessage !== '' ?
                                                <p className="text-success small-text-help mt-2">{valideCodeMessage}</p>
                                                :
                                                <p className="text-danger small-text-help mt-2">{valideCodeMessage}</p>
                                            }
                                    </div>
                                    <div className="wrapper-code-resend">
                                        <button className={hideWhenCodeValid ? "btn_resend_mail hide-me" : "btn_resend_mail"} disabled={ showResendCounter ? true : false } onClick={this.resendCode}>Je n’ai pas reçu de mail</button>
                                        {
                                            //https://www.npmjs.com/package/react-component-countdown-timer
                                            codeAPI !== 200 && showResendCounter && dateToResendCode !== null ?
                                                <div className="countdown-email-update">
                                                    <CountdownTimer count={dateToResendCode} showTitle hideDay hideHours onEnd={() => this.setState({showResendCounter: false})} />
                                                </div>
                                            : null
                                        }
                                    </div>
                                    <div className="form-group mt-5"><p> </p>
                                        <input type="submit" id='validationMail_continue' disabled={codeAPI === 200 ? null : true} name="btn_submit" className="btn_next_continue btn_submit" value="Continuer" onSubmit={this.handleSubmit} />
                                    </div>
                                </form>
                                <ReCaptcha
                                    ref={ref => this.recaptcha = ref}
                                    sitekey={site_key}
                                    action={this.updateToken}
                                    verifyCallback={this.verifyCallback}
                                />
                            </Col>
                        }
                    </Row>
                </Container>
                {
                    this.state.errorCounter > 3 && this.state.showPopupError ? 
                    <React.Fragment>
                        <Modal dialogClassName="modal-90w" size="lg" centered show={this.state.show} onHide={this.handleClose}>
                            <PopupError
                            text='Close Me'
                            classFull= {true}
                            close_popup= {this.close_popup.bind(this)}
                            closePopup={this.togglePopupError.bind(this)}
                            /> 
                        </Modal>
                    </React.Fragment>
                    : null
                }
                {this.state.showPopup ?
                    <React.Fragment>
                        <Modal size="lg" centered show={this.state.show} onHide={this.handleClose}>
                            <PopupEmail
                                text='Close Me'
                                closePopup={this.togglePopup.bind(this)}
                                onUpdateMail={this.handleUpdateMail}
                            />
                        </Modal>
                    </React.Fragment>
                    : null
                }
            </React.Fragment>
        );
    }
}

function mapStateToProps({ applicate_code, returned_verification_email, resend_verification_code, change_subscription_email, verification_delay }) {
    return { applicate_code, returned_verification_email, resend_verification_code, change_subscription_email, verification_delay };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        get_applicate_code: get_applicate_code,
        verification_email: verification_email,
        get_resend_verification_code: get_resend_verification_code,
        get_verification_delay: get_verification_delay
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);