import React from 'react';
import { Row, Col } from 'react-bootstrap';
import encart1 from '../assets/img/icon_eco.svg';
import encart2 from '../assets/img/icon_garanti.svg';
import encart3 from '../assets/img/icon_contrat.svg';

function Encarts(props) {
    return (
        <React.Fragment>
            <div className="col-12 col-sm-12 col-md-12 col-lg-9 encarts">
                <Col xs={12} md={12}>
                    <Row>
                        <Col xs={12} md={4}>
                            <img src={encart1} alt="Économies garanties" />
                            <h2>Économies<br />garanties</h2>
                            <p>
                            Une offre adaptée à vos besoins, sans engagement de consommation.
                            </p>
                        </Col>
                        <Col xs={12} md={4}>
                            <img src={encart2} alt="Garanti sans coupure" />
                            <h2>Garanti<br />sans coupure</h2>
                            <p>
                            Nous nous occupons de toutes les démarches de changement de fournisseur.
                            </p>
                        </Col>
                        <Col xs={12} md={4}>
                            <img src={encart3} alt="Contrat sans engagement" />
                            <h2>Service client</h2>
                            <p>
                            Notre équipe service client est à votre écoute depuis votre espace dédié en ligne.
                            </p>
                        </Col>
                    </Row>
                </Col>
            </div>
        </React.Fragment>
    )
}

export default Encarts;