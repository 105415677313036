import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { post } from '../services/request';
import { apiUrl, url_resend_verification_email } from '../services/constant';
import { get_resend_verification_code_done } from '../actions/resend_verification_email';


function* get_resend_verification_code_saga(action) {
	yield call(
		callApi,
		apiUrl + url_resend_verification_email,
		post,
        action.payload,
        get_resend_verification_code_done,
	);
}
export default function* watch_get_resend_verification_code_saga() {
    yield takeEvery('RESEND_VERIFICATION_CODE', get_resend_verification_code_saga);
}

