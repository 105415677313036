import React, { Component } from 'react';
import { connect } from 'react-redux';
import { current_step, subscription_id } from '../../services/status';
import { Container, Row, Col } from 'react-bootstrap';
import welcome from '../../assets/img/welcome.svg';
import good from '../../assets/img/good.svg';

class Welcome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            last_step: (localStorage.getItem('signature_contrat') ? JSON.parse(localStorage.getItem('signature_contrat')) : JSON.parse(localStorage.getItem('current_step_continue'))),
            isLoading: false,
            email: ''
        }
    }
    componentWillMount(){
        window.dataLayer.push({
            event: 'gtm.load.Signature',
            budget_souscription : parseFloat(sessionStorage.getItem('offrePricePromo'))
        });
    }
    componentWillUnmount() {
        localStorage.clear();
        if (sessionStorage.getItem('offrePricePromo')) sessionStorage.removeItem('offrePricePromo');
    }

    componentDidMount() {
        const { last_step } = this.state;
        //console.log('last_step', last_step);
        //console.log('current step : ',current_step, 'subs id : ',subscription_id )
        this.setState({
            email: last_step.data.data.email
        })
    }
    componentDidUpdate(prevProps){
        // Enable navigation prompt
        /*
        window.onbeforeunload = function() {
            return true;
        };
        */
    }
    render() {
        const { email } = this.state
        return (
            <React.Fragment>
                <Container>
                    <Row>
                        <Col xs={12} md={12} className="txt_center mt-6">
                            <img src={good} alt="ENDESA S.A." />
                            <h1 className="h1">Bienvenue chez Endesa !</h1>
                            <p className="classic_txt mt-3 txt_welcome">
                                Merci d'avoir choisi Endesa. <br />
                                Nous sommes heureux de vous compter parmi nos clients ! <br />
                                Un email récapitulatif vous a été envoyé à votre adresse <span>{email}</span>
                            </p>
                            <p className="btn_equipe">L'équipe Endesa</p>
                            <a href={process.env.REACT_APP_API_URL} className="btn_redirect">Retour au site</a>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}
function mapStateToProps({ welcome }) {
    return { welcome };
}
export default connect(mapStateToProps)(Welcome);