import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { get } from '../services/request';
import { apiUrl, url_brochure } from '../services/constant';
import { get_brochure_done } from '../actions/brochure';


function* get_brochure_saga(action) {
	yield call(
		callApi,
		apiUrl + url_brochure + '?offer_id=' + action.payload.offer_id,
		get,
        action.payload,
		get_brochure_done,
		{responseType: 'blob'}
	);
}
export default function* watch_get_brochure_saga() {
    yield takeEvery('BROCHURE', get_brochure_saga);
}

