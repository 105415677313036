/*import axios from 'axios';
import { apiUrl, url_reset_subscription } from '../services/constant';

export const get_reset_subscription = (data) => dispatch => {
    const request = axios.post(apiUrl + url_reset_subscription, data);
    dispatch({
        type: 'RESET_SUBSCRIPTION',
        payload: request
    })
}*/

export function get_reset_subscription(payload) {
    return {
        type: 'RESET_SUBSCRIPTION',
        payload,
    };
}
export function get_reset_subscription_done(request) {
    return {
        type: 'RESET_SUBSCRIPTION_DONE',
        payload: request,
    };
}