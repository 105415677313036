import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import swal from 'sweetalert';
import { get_brochure } from '../../actions/brochure';
import { choix_duree_contrat } from '../../actions/choix_duree_contrat';
import { dynamic_parameters } from '../../actions/settings';
import { get_numbers_subs } from '../../actions/all_subs';
import { bindActionCreators } from 'redux';
import { current_step, categorie, offer_selected, subscription_id } from '../../services/status';
import { get_previous } from '../../actions/previous';
import { get_data } from '../../actions/get_data';
import BrochureModal from '../offers/BrochurePopUp'
import { Container, Row, Col } from 'react-bootstrap';
import { get_reset_subscription } from '../../actions/reset_subscription';
import ReactHtmlParser from 'react-html-parser';
import { list_offers } from './data';
import Offer from './single';
import '../offers/offers.css';
import './tarifs.css';
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";
import icon_help from '../../assets/img/calc.svg';
import closeBtn from '../../assets/img/close-btn.svg';
import DocumentBrochurePopup from "../offers/DocumentBrochurePopup"
import {Modal, Button} from "react-bootstrap"
import Devis from "./devis"
import PopupRappel from '../aide/PopupRappel'
import { IdleTimerProvider, IdleTimerConsumer } from 'react-idle-timer/dist/index.legacy.cjs.js'


class Tarifs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: list_offers.filter(item => item.selected === 'selected'),
            offers: (localStorage.getItem('next') ? JSON.parse(localStorage.getItem('next')) : JSON.parse(localStorage.getItem('current_step_continue'))),
            selected_id: '',
            finished_subscriptions_number: 0,
            all_subscriptions_number: 5,
            //selected_duree: 24,
            selected_duree: [],
            selected_id_duree: 0,
            id_simulation_selected: 0,
            simulation_selected: [],
            offer_id_to_change: '',
            chosen_offer: '',
            consulted_offer: '',
            categorie_type: '',
            code: '',
            code_saisie: '',
            isLoading: false,
            getPromotion: 0,
            is_ready_only: false,
            show_list_duree: [],
            textButtonsOffer: [],
            frm_data: {},
            ShowBrochure : false,
            fileURL : null,
            OpenBrochureTitle : '',
            OpenBrochureId : '',
            site_key: process.env.REACT_APP_TAG_RECAPTCHA_V3 ? process.env.REACT_APP_TAG_RECAPTCHA_V3 : '',
            token: '',
            show: false,
            showDevis: false,
            subscriptionDeadline: '',
            dataDevis: {},
            showRappel: false,
            showRappelFromButtonRappel: false,
            alreadyShown: false,
            checkReminder: false,
            checkPhone: 'indisponible',
            hideEtreRappele: 'False'
        }
        this.onPresenceChange = this.onPresenceChange.bind(this)
        this.onPrompt = this.onPrompt.bind(this)
        this.onIdle = this.onIdle.bind(this)
        this.onActive = this.onActive.bind(this)
        this.onAction = this.onAction.bind(this)
    }
    onPresenceChange (presence) {
        const {showRappel, showRappelFromButtonRappel, alreadyShown} = this.state;
        // Handle state changes in one function
        //console.log('onPresenceChange')
        // test if popup was opened by button rappel click or inactivity
        if(document.getElementsByClassName('preventPopupInactivityToBeShown').length == 0 && !alreadyShown){
            this.setState({
                showRappel: true
            })
        }else{
            this.setState({
                showRappel: false
            })
        }
    }

    onPrompt () {
        // Fire a Modal Prompt
        //console.log('onPrompt')
    }

    onIdle () {
        //console.log('onIdle')
        // Close Modal Prompt
        // Do some idle action like log out your user
    }

    onActive (event) {
        // Close Modal Prompt
        // Do some active action
        //console.log('onActive')
    }

    onAction (event) {
    //console.log('onAction')
    // Do something when a user triggers a watched event
    }
    handleCloseDevis = () => {
        this.setState({
            showDevis: false
        })
    }
    handleCloseRappel = () => {
        this.setState({
            showRappel: false,
            alreadyShown: true
        })
    }
    dataDevisSent = (data) => {
        this.setState({
            dataDevis: data
        })
    }
    handleShowDevis = () => {
        this.setState({
            showDevis: true
        })
    }
    handleClose = () => {
        this.setState({
            show: false,
            ShowBrochure: false
        })
        //console.log('close')
    }

    handleShow  = () => {
        this.setState({
            show: true,
            ShowBrochure: true
        })
        //console.log('open')
    }
    toggleBrochure = () => {
        this.setState({
            ShowBrochure: !this.state.ShowBrochure,
            show: false
        });
        //console.log('YEES')
    }
    toggleDevis = (e) => {
        e.preventDefault()
        this.setState({
            showDevis: !this.state.showDevis
        })
    }
    componentWillUnmount() {
        localStorage.removeItem('current_step_continue');
    }
    componentWillMount() {
        window.dataLayer.push({              
            pageCategory: 'Tarifs',
            event: 'gtm.load.Tarifs',                
        });
        const { offers } = this.state;
        const { dynamic_parameters, get_numbers_subs, get_data } = this.props;
        this.setState({
            isLoading: true
        })
        dynamic_parameters();
        get_numbers_subs();
        // GET DATA WHEN LOAD
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        let data = {};
        //data.subscription_id = offers.subscription_id;
        data.subscription_id = path_remove_slash;
        data.type_step="current"
        data.current_step= "choix_duree_contrat"
        // SUBMIT TO DOWNLOAD FILE
        get_data(data);
        {/*
        if (localStorage.getItem('current_step_continue')) {
            if(JSON.parse(localStorage.getItem('current_step_continue')).data.step === 'choix_duree_contrat'){
                let data = []
                data.length = JSON.parse(localStorage.getItem('current_step_continue')).data.data.length
                data.fill(false)
                let data_duree = []
                let id_simulation_duree
                let id_selected_duree
                JSON.parse(localStorage.getItem('current_step_continue')).data.data.map((duree, idx) => {
                    if(duree.offer_simulations[0]){
                        data_duree.push(duree.offer_simulations[0].duree)
                        id_simulation_duree = duree.offer_simulations[0].id
                        id_selected_duree = duree.offer_simulations[0].duree
                    }else{
                        data_duree.push('')
                    }
                })
                console.log('data_duree inside : ', data_duree)
                let current_index = JSON.parse(localStorage.getItem('current_step_continue')).data.data.findIndex(item => item.id === JSON.parse(localStorage.getItem('current_step_continue')).data.chosen_offer);
                JSON.parse(localStorage.getItem('current_step_continue')).data.data.map((offer, idx) => {
                    while(true){
                        if(offer.id === JSON.parse(localStorage.getItem('current_step_continue')).data.chosen_offer){
                            console.log('YEEES EQUAL : ', offer.id, ' - ', JSON.parse(localStorage.getItem('current_step_continue')).data.chosen_offer)
                            this.setState({
                                chosen_offer: JSON.parse(localStorage.getItem('current_step_continue')).data.chosen_offer
                            })
                            break;
                        }
                        return;
                        break;
                    }
                })
                this.setState({
                    offers: JSON.parse(localStorage.getItem('current_step_continue')).data,
                    is_ready_only: JSON.parse(localStorage.getItem('current_step_continue')).data.is_readonly,
                    //chosen_offer: JSON.parse(localStorage.getItem('current_step_continue')).data.chosen_offer,
                    consulted_offer: JSON.parse(localStorage.getItem('current_step_continue')).data.consulted_offer,
                    selected_id: JSON.parse(localStorage.getItem('current_step_continue')).data.chosen_offer,
                    categorie_type: JSON.parse(localStorage.getItem('current_step_continue')).data.secteurs,
                    getPromotion: JSON.parse(localStorage.getItem('current_step_continue')).data.promotion_rate,
                    list_offers: JSON.parse(localStorage.getItem('current_step_continue')).data.data,
                    selected_duree: data_duree,
                    //selected_id_duree: JSON.parse(localStorage.getItem('current_step_continue')).data.data[0].offer_simulations[0].duree,
                    selected_id_duree: id_selected_duree,
                    //id_simulation_selected: JSON.parse(localStorage.getItem('current_step_continue')).data.data[0].offer_simulations[0].id,
                    id_simulation_selected: id_simulation_duree,
                    show_list_duree: data
                })
            }
        }
        if (localStorage.getItem('next')) {
            if(JSON.parse(localStorage.getItem('next')).step === 'choix_duree_contrat'){
                console.log('TEST 1')
                let data = []
                data.length = JSON.parse(localStorage.getItem('next')).data.length
                data.fill(false)
                let data_duree = []
                let id_simulation_duree
                let id_selected_duree
                JSON.parse(localStorage.getItem('next')).data.map((duree, idx) => {
                   if(duree.offer_simulations[0]){
                    console.log('TEST 2')
                        if(duree.id === JSON.parse(localStorage.getItem('next')).chosen_offer){
                            //id_simulation_duree = JSON.parse(localStorage.getItem('next')).simulation_chosen
                            console.log('TEST 3')
                            
                            if(duree.offer_simulations[0].id === JSON.parse(localStorage.getItem('next')).simulation_chosen){
                                id_simulation_duree = duree.offer_simulations[0].id
                                id_selected_duree = duree.offer_simulations[0].duree
                                data_duree.push(duree.offer_simulations[0].duree)
                            }else if (JSON.parse(localStorage.getItem('next')).simulation_chosen === null){
                                id_simulation_duree = duree.offer_simulations[0].id
                                id_selected_duree = duree.offer_simulations[0].duree
                                data_duree.push(duree.offer_simulations[0].duree)
                            }
                        }else{
                            console.log('TEST 6')
                            data_duree.push(duree.offer_simulations[0].duree)
                        }
                    }else{
                        console.log('TEST 7')
                        data_duree.push('')
                    }
                })
                console.log('data_duree next : ', data_duree)
                let current_index = JSON.parse(localStorage.getItem('next')).data.findIndex(item => item.id === JSON.parse(localStorage.getItem('next')).chosen_offer);
                JSON.parse(localStorage.getItem('next')).data.map((offer, idx) => {
                    while(true){
                        if(offer.id === JSON.parse(localStorage.getItem('next')).chosen_offer){
                            console.log('YEEES EQUAL : ', offer.id, ' - ', JSON.parse(localStorage.getItem('next')).chosen_offer)
                            if( offer.offer_simulations[0]){
                                this.setState({
                                    chosen_offer: JSON.parse(localStorage.getItem('next')).chosen_offer
                                })
                                break;
                            }
                            break;
                        }
                        return;
                        break;
                    }
                })
                this.setState({
                    offers: JSON.parse(localStorage.getItem('next')),
                    is_ready_only: JSON.parse(localStorage.getItem('next')).is_readonly,
                    //chosen_offer: JSON.parse(localStorage.getItem('next')).chosen_offer,
                    consulted_offer: JSON.parse(localStorage.getItem('next')).consulted_offer,
                    selected_id: JSON.parse(localStorage.getItem('next')).chosen_offer,
                    categorie_type: JSON.parse(localStorage.getItem('next')).secteurs,
                    getPromotion: JSON.parse(localStorage.getItem('next')).promotion_rate,
                    list_offers: JSON.parse(localStorage.getItem('next')).data,
                    selected_duree: data_duree,
                    //selected_id_duree: JSON.parse(localStorage.getItem('next')).data[0].offer_simulations.length > 0 ? JSON.parse(localStorage.getItem('next')).data[0].offer_simulations[0].duree : null,
                    selected_id_duree: id_selected_duree,
                    //id_simulation_selected: JSON.parse(localStorage.getItem('next')).data[0].offer_simulations.length > 0 ? JSON.parse(localStorage.getItem('next')).data[0].offer_simulations[0].id : null,
                    id_simulation_selected: id_simulation_duree,
                    show_list_duree: data
                })
            }
        }
        */}
        // WHEN REFRESH RETURN TO LAST STEP
        if (localStorage.getItem('previous')) {
            if(JSON.parse(localStorage.getItem('previous')).step === 'choix_duree_contrat'){
                let data = []
                data.length = JSON.parse(localStorage.getItem('previous')).data.length
                data.fill(false)
                // Buttons text offers
                let textButtons= []
                textButtons.length = JSON.parse(localStorage.getItem('previous')).data.length
                textButtons.fill(false)
                let data_duree = []
                let id_simulation_duree
                let id_selected_duree
                JSON.parse(localStorage.getItem('previous')).data.map((duree, idx) => {
                    if(duree.offer_simulations[0]){
                        if(duree.id === JSON.parse(localStorage.getItem('previous')).chosen_offer){
                            id_simulation_duree = JSON.parse(localStorage.getItem('previous')).simulation_chosen
                            duree.offer_simulations.map((simulation, idx) => {
                                if(simulation.id === JSON.parse(localStorage.getItem('previous')).simulation_chosen){
                                    //data_duree.push(duree.offer_simulations[0].duree)
                                    data_duree.push(simulation.duree)
                                    id_selected_duree = simulation.duree
                                    //console.log('simulation ', simulation)
                                }
                            })
                            //id_selected_duree = duree.offer_simulations[0].duree
                        }else{
                            data_duree.push(duree.offer_simulations[0].duree)
                        }
                    }else{
                        data_duree.push('')
                    }
                })
                //console.log('data_duree prev : ', data_duree)
                let current_index = JSON.parse(localStorage.getItem('previous')).data.findIndex(item => item.id === JSON.parse(localStorage.getItem('previous')).chosen_offer);
                this.setState({
                    offers: JSON.parse(localStorage.getItem('previous')),
                    is_ready_only: JSON.parse(localStorage.getItem('previous')).is_readonly,
                    chosen_offer: JSON.parse(localStorage.getItem('previous')).chosen_offer,
                    consulted_offer: JSON.parse(localStorage.getItem('previous')).consulted_offer,
                    selected_id: JSON.parse(localStorage.getItem('previous')).chosen_offer,
                    categorie_type: JSON.parse(localStorage.getItem('previous')).secteurs,
                    getPromotion: JSON.parse(localStorage.getItem('previous')).promotion_rate,
                    list_offers: JSON.parse(localStorage.getItem('previous')).data,
                    selected_duree: data_duree,
                    //selected_id_duree: JSON.parse(localStorage.getItem('previous')).data[0].offer_simulations.length > 0 ? JSON.parse(localStorage.getItem('previous')).data[0].offer_simulations[0].duree : null,
                    selected_id_duree: id_selected_duree,
                    //id_simulation_selected: JSON.parse(localStorage.getItem('previous')).data[0].offer_simulations.length > 0 ? JSON.parse(localStorage.getItem('previous')).data[0].offer_simulations[0].id : null,
                    id_simulation_selected: id_simulation_duree,
                    show_list_duree: data,
                    textButtonsOffer: textButtons,
                    subscriptionDeadline: JSON.parse(localStorage.getItem('previous')).subscription_deadline
                })
            }
        }
    }
    componentWillUpdate(prevProps) {
        const { previous } = this.props;
        // Loader
        if (prevProps.previous !== previous) {
            this.setState({
                isLoading: true
            });
        }
    }
    componentDidMount() {
        const { offers, site_key } = this.state;
        loadReCaptcha(site_key, this.verifyCallback);
        this.setState({
            categorie_type: offers.secteurs
        })
    }
    updateToken = () => {
        // you will get a new token in verifyCallback
        this.recaptcha.execute()
    };
    verifyCallback = (recaptchaToken) => {
        // Here you will get the final recaptchaToken!!!  
        this.setState({
            token: recaptchaToken
        });
    }
    componentDidUpdate(prevProps) {
        const { duree_contrat, previous, parametres, brochure_file, all_subs, reset_subscription, data } = this.props;
        // Enable navigation prompt
        window.onbeforeunload = function() {
            return true;
        };
        // GET NEW KEY WHEN RESET SUBS
        if (prevProps.reset_subscription !== reset_subscription) {
            // Remove navigation prompt
            window.onbeforeunload = null;
            //console.log('reset_subscription', reset_subscription);
            let newKey = reset_subscription.result.data.subscription_id;
            //console.log('reset_subscription 2 : ', newKey);
            //window.location.assign(newKey)
            window.location.pathname = newKey + '/start'
        }
        if(prevProps.data !== data){
            // Remove navigation prompt
            window.onbeforeunload = null;
            if (data.result.status === 200) {
                //console.log('DATA RETURNED : ', data)
                localStorage.setItem('next', JSON.stringify(data.result.data));
                localStorage.setItem('current_step_continue', JSON.stringify(data.result.data));
                //localStorage.setItem('previous', JSON.stringify(data.result.data));
                let dataOffers = []
                dataOffers.length = data.result.data.data.length
                dataOffers.fill(false)
                // Buttons text offers
                let textButtons= []
                textButtons.length = data.result.data.data.length
                textButtons.fill(false)
                let data_duree = []
                let id_simulation_duree
                let id_selected_duree
                data.result.data.data.map((duree, idx) => {
                   if(duree.offer_simulations[0]){
                    //console.log('TEST 2')
                        if(duree.id === data.result.data.chosen_offer){
                            //console.log('TEST 3')
                            if(duree.offer_simulations[0].id === data.result.data.simulation_chosen){
                                id_simulation_duree = duree.offer_simulations[0].id
                                id_selected_duree = duree.offer_simulations[0].duree
                                data_duree.push(duree.offer_simulations[0].duree)
                                //console.log('TEST 4 ')
                            }else if (data.result.data.simulation_chosen === null){
                                id_simulation_duree = duree.offer_simulations[0].id
                                id_selected_duree = duree.offer_simulations[0].duree
                                data_duree.push(duree.offer_simulations[0].duree)
                                //console.log('TEST 5 : ')
                            }else{
                                duree.offer_simulations.map((duree_offer, idx) => {
                                    if(duree_offer.id === data.result.data.simulation_chosen ){
                                        id_simulation_duree = duree_offer.id
                                        id_selected_duree = duree_offer.duree
                                        data_duree.push(duree_offer.duree)
                                        //console.log('TEST 6 : ')
                                    }
                                })
                            }
                        }else{
                            //console.log('TEST 7')
                            data_duree.push(duree.offer_simulations[0].duree)
                        }
                    }else{
                        //console.log('TEST 8')
                        data_duree.push('')
                    }
                })
                this.setState({
                    offers: data.result.data,
                    is_ready_only: data.result.data.is_readonly,
                    chosen_offer: data.result.data.chosen_offer,
                    consulted_offer: data.result.data.consulted_offer,
                    selected_id: data.result.data.chosen_offer,
                    categorie_type: data.result.data.secteurs,
                    getPromotion: data.result.data.promotion_rate,
                    list_offers: data.result.data.data,
                    selected_duree: data_duree,
                    selected_id_duree: id_selected_duree,
                    id_simulation_selected: id_simulation_duree,
                    show_list_duree: dataOffers,
                    textButtonsOffer: textButtons,
                    isLoading: false,
                    subscriptionDeadline: data.result.data.subscription_deadline
                });
            }
        }
        // DOWNLOADING FILE
        if (prevProps.brochure_file !== brochure_file) {
            if(this.state.ShowBrochure){
                window.dataLayer.push({
                    event: 'gtm.click.DownloadBochure',                
                    brochure_name: this.state.OpenBrochureTitle,               
                });
                let filename = Object.values(brochure_file.result.headers);
                const blob = new Blob([brochure_file.result.data], { type: filename[2] });
                const data = window.URL.createObjectURL(blob);
                filename = filename[0].split('=');
                filename = filename[1].split('"');
                // IE doesn't allow using a blob object directly as link href
                // instead it is necessary to use msSaveOrOpenBlob
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }
                // For other browsers: 
                var link = document.createElement('a');
                link.href = data;
                link.download = filename[1];
                link.click();
                this.setState({
                    isLoading: false
                });
                setTimeout(function () {
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(data);
                }, 100);
                link.remove();
            }
            else{
                window.dataLayer.push({
                    event: 'gtm.click.ShowBochure',                
                    brochure_name: this.state.OpenBrochureTitle,                
                });
                const blob = new Blob([brochure_file.result.data], { type: 'application/pdf' });
                const fileURL = window.URL.createObjectURL(blob);
                //console.log(fileURL)
                this.setState({
                    isLoading : false,
                    ShowBrochure : true,
                    fileURL : fileURL,
                    show: true
                });
            }
        }
        // NEXT
        if (prevProps.duree_contrat !== duree_contrat) {
            // Remove navigation prompt
            window.onbeforeunload = null;
            if (duree_contrat.result.status === 200) {
                localStorage.removeItem('current_step_continue');
                localStorage.removeItem('previous');
                localStorage.setItem('step', duree_contrat.result.data.step);
                localStorage.setItem('next', JSON.stringify(duree_contrat.result.data));
                localStorage.setItem('current_step_continue', JSON.stringify(duree_contrat.result.data));
                // Data
                window.location.reload(false);
                window.location.replace(duree_contrat.result.data.step)
            } else {
                swal({
                    title: "Veuillez choisir une offre avant de continuer",
                    text: duree_contrat.result.response.data.errorDuree ? duree_contrat.result.response.data.errorDuree : '',
                    icon: "error",
                }).then(() => {
                    this.setState({
                        isLoading: false
                    });
                })
            }
        }
        // PREVIOUS STEP
        if (prevProps.previous !== previous) {
            // Remove navigation prompt
            window.onbeforeunload = null;
            //console.log('Data', previous);
            localStorage.removeItem('next');
            localStorage.removeItem('current_step_continue');
            localStorage.removeItem('current_returned_prospect');
            localStorage.setItem('step', previous.result.data.step);
            localStorage.setItem('subscription_id', previous.result.data.subscription_id);
            localStorage.setItem('previous', JSON.stringify(previous.result.data));
            // Redirect
            this.setState({
                isLoading: false
            });
            window.location.reload(false);
            window.location.replace(previous.result.data.step)
        }
        // PARAMETRES
        if (prevProps.parametres !== parametres) {
            this.setState({
                finished_subscriptions_number: parametres.finished_subscriptions_number
            });
        }
        // NUMBERS OF SUBS
        if (prevProps.all_subs !== all_subs) {
            all_subs.result.data.map((itm, indx) => {
                if (itm.param === "CHECK_REMINDER") {
                    //console.log('YEES : ', itm.value)
                    this.setState({
                        checkReminder: itm.value,
                    })
                }
                if (itm.param === "NUMBER_SUBSCRIPTIONS_ENDESA") {
                    //console.log('YEES : ', itm.value)
                    this.setState({
                        all_subscriptions_number: itm.value
                    })
                }
                //
                if(itm.param === 'CACHER_ETRE_RAPPELE') {
                    this.setState({
                        hideEtreRappele: itm.value
                    })
                }
                if(itm.param === 'ASSISTANT_PHONE_NUMBER') {
                    this.setState({
                        checkPhone: itm.value
                    })
                }
            })
        }
    }
    toggetOffer = (id, idx_offer,business) => {
        if(id === this.state.consulted_offer){
            window.dataLayer.push({              
                event: 'gtm.click.OffreConsultée',                
            });
        }
        if(business){
            window.dataLayer.push({              
                event: 'gtm.click.OffreMétier',                
            });
        }
        const {selected_duree, chosen_offer, offers, selected_id_duree} = this.state
        //let item_id = offers.data[idx_offer].offer_simulations.find(item => item.duree === selected_id_duree)
        let item_id = offers.data[idx_offer].offer_simulations.find(item => item.duree === selected_duree[idx_offer])
        if(item_id === undefined){
            swal({
                title: "Cette offre n'est pas disponible",
                //text: "Merci de choisir une offre",
                icon: "error",
            })
        }else{
            this.setState({
                chosen_offer: id,
                selected_id_duree: selected_duree[idx_offer],
                id_simulation_selected: item_id.id
            });
        }
    };
    toggleTextButton = (id) => {
        const { textButtonsOffer } = this.state;
        let copied = textButtonsOffer
        let value = !copied[id]
        let copied2 = copied
        //copied2.fill(false)
        copied2[id] = value
        this.setState({
            textButtonsOffer: copied2
        })
    }
    trigger_list_duree = (id) => {
        const { show_list_duree } = this.state;
        let copied = show_list_duree
        let value = !copied[id]
        let copied2 = copied
        copied2.fill(false)
        copied2[id] = value
        this.setState({
            show_list_duree: copied2
        })
    };
    // RESET DATA - SOUSSCRIPTION
    resetData = (e) => {
        e.preventDefault();
        const { get_reset_subscription } = this.props;
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        let data = {};
        //data.subscription_id = subscription_id;
        data.subscription_id = path_remove_slash;
        swal({
            title: "Etes-vous sûr ? Vous perdrez toutes les informations renseignées",
            //text: "Once deleted, you will not be able to recover this imaginary file!",
            icon: "warning",
            buttons: ["Non", "Oui"],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    window.dataLayer.push({
                        event: 'gtm.click.tarif_Reinitialiser',                
                    });
                    swal("Votre souscription est réinitialisée", {
                        icon: "success",
                    }).then(() => {
                        //console.log('call ws : ', data);
                        get_reset_subscription(data)
                    });
                } else {
                    //swal("Your imaginary file is safe!");
                }
            });
    }
    // SHOW BROCHURE MODAL 

    OpenBrochure = (id,title) => {
        const { offers } = this.state;
        const { get_brochure } = this.props;
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        this.setState({
            OpenBrochureTitle: title,
            OpenBrochureId: id,
            isLoading : true,
            show: true
        });
        let data = {};
        //data.subscription_id = offers.subscription_id;
        data.subscription_id = path_remove_slash;
        data.offer_id = id;
        get_brochure(data);
    }
    CloseBrochure = () => {
        this.setState({
            ShowBrochure: false,
            show: false
        });
    }
    togglePopup = () => {
        const { showPopup } = this.state
        this.setState({
            showPopup: !showPopup,
            showRappel: true
        })
    }
    // DOWNLOAD FILE
    downloadFile = () => {
        const { offers } = this.state;
        const { get_brochure } = this.props;
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        let data = {};
        //data.subscription_id = offers.subscription_id;
        data.subscription_id = path_remove_slash;
        data.offer_id = this.state.OpenBrochureId;
        // SUBMIT TO DOWNLOAD FILE
        get_brochure(data);
        this.setState({
            isLoading: true
        });
    };
    // GET PREVIOUS
    go_back = (e) => {
        e.preventDefault();
        const { get_previous } = this.props;
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        let data = {};
        data.type_step = 'previous';
        //data.subscription_id = subscription_id;
        data.subscription_id = path_remove_slash;
        data.current_step = current_step;
        this.setState({
            isLoading: true
        });
        get_previous(data);
    }
    handleSubmit = (e) => {
        e.preventDefault();
        const { choix_duree_contrat } = this.props;
        const { frm_data, chosen_offer, selected_duree, simulation_selected, selected_id_duree, id_simulation_selected, token } = this.state;
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        frm_data.subscription_id = path_remove_slash;
        //frm_data.subscription_id = (localStorage.getItem('subscription_id') ? localStorage.getItem('subscription_id') : '');
        frm_data.offer_id = chosen_offer;
        frm_data.selected_duree = selected_id_duree;
        frm_data.simulation_id = id_simulation_selected;
        frm_data.recaptcha = token
        //frm_data.simulation_selected = simulation_selected;
        //SUBMIT DATA TO THE BACKEND
        choix_duree_contrat(frm_data);
        // LOADING
        this.setState({
            isLoading: true
        });
        //console.log('Data', frm_data);
    };
    //CHANGE DUREE
    changeDuree = (simulations, id, index, e) => {
        const {show_list_duree, selected_duree, chosen_offer} = this.state
        //let copied = show_list_duree
        //copied[index] = !copied[index]
        //copied.fill(false)
        let data_selected = selected_duree
        data_selected[index] = simulations.duree
        //let selected_duration = e.target.value;
        //let simulation_to_show = simulations.filter(item => item.duree === parseInt(selected_duration));
        let selected_duration = simulations.duree;
        let simulation_to_show = simulations.duree === parseInt(selected_duration);
        //console.log('Simulation : ', simulations)
        //console.log('selected_duration : ', selected_duration)
        //console.log('index : ', index)
        //console.log('data_selected : ', data_selected)
        //console.log('copied : ', copied)
        if(id === chosen_offer){
            this.setState({
                selected_id_duree: simulations.duree,
                id_simulation_selected: simulations.id
            })
        }
        this.setState({
            selected_duree: data_selected,
            simulation_selected: simulation_to_show
            //show_list_duree: copied
        })

        //OLD CODE
        // let selected_duree = e.target.value;
        // let selected = offers.filter(offer => offer.id === id);
        // console.log('durée', selected.simulation.filter(duree => duree.duree === selected_duree));

        // NEW TEST CODE
        // console.log("id : ", id, "+ e : ", e)
        // let selected = offers.data.filter(offer => offer.id === id);
        // let selectedSimulation = selected.map(function (item) {
        //     return item.simulation.map(item2 => {
        //         return item2.duree
        //     })
        // })
        // console.log("selectedSimulation", selectedSimulation)
    };
    render() {
        const {
            selected_id,
            offers,
            consulted_offer,
            chosen_offer,
            categorie_type,
            selected_duree,
            offer_id_to_change,
            simulation_selected,
            finished_subscriptions_number,
            getPromotion,
            isLoading,
            is_ready_only,
            all_subscriptions_number,
            show_list_duree,
            site_key,
            textButtonsOffer,
            showDevis,
            id_simulation_selected,
            subscriptionDeadline,
            showRappel,
            showRappelFromButtonRappel,
            checkReminder,
            checkPhone,
            hideEtreRappele
        } = this.state;
        let list_offers = (Array.isArray(offers.data) ? offers.data : offers.data.data);
        return (
            <React.Fragment>
                {isLoading ?
                    <div className="loadingKey">
                        <Spinner className="spinner-border text-primary" animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                    :
                    <Container>
                        <Row>
                            <Col>
                                {is_ready_only ? <div className="blank_div"></div> : null}
                                <h1 className="blue-border"><span></span>Je choisis mon offre</h1>
                                <div className="form-group mt-5 offers_container">
                                    {/*<p className="classic_txt">Voici nos offres adaptées pour votre secteur <span className="secteur_offer">{categorie_type}</span> :</p>*/}
                                    <p className="classic_txt">Nous vous proposons les offres suivantes, adaptées à votre secteur <span className="secteur_offer">{categorie_type}</span> et à votre niveau de consommation : </p>
                                    {/*<p className="promotion-text">Votre code promo ou parrainage <span className="promotion-rate">{getPromotion}%</span> a bien été prix en compte.</p>*/}
                                    {
                                        getPromotion > 0 ?
                                            <p className="promotion-text">Votre code promo ou parrainage <span className="promotion-rate">{getPromotion}%</span> a bien été prix en compte.</p>
                                            :
                                            null
                                    }
                                    <Row>
                                        <Col xs={12} md={12} lg={12} className="noPadding">
                                            <form onSubmit={this.handleSubmit} className="tarif-wrapper">
                                                {
                                                    list_offers.map((item, idx) => {
                                                        return <Offer
                                                            disabledChoose= {item.offer_simulations.length > 0 ? false : true}
                                                            simulation={item.offer_simulations}
                                                            consulted={consulted_offer}
                                                            duree_selected={selected_duree[idx]}
                                                            simulation_selected={simulation_selected}
                                                            icon_bg_color={item.icon_bg_color}
                                                            selected={item.offer_simulations.length > 0 ? chosen_offer : null}
                                                            changeDuree={this.changeDuree}
                                                            trigger_list_duree={this.trigger_list_duree}
                                                            toggleTextButton={this.toggleTextButton}
                                                            textButtonsOffer = {textButtonsOffer}
                                                            show_list_duree={show_list_duree}
                                                            key={item.id}
                                                            id={item.id}
                                                            icone={item.icon}
                                                            title={item.title}
                                                            offer_id_to_change={offer_id_to_change}
                                                            sub_title={item.subtitle}
                                                            text={ReactHtmlParser(item.description)}
                                                            offeradvantage_set={item.offeradvantage_set}
                                                            brochure={this.OpenBrochure}
                                                            isReadOnly={is_ready_only}
                                                            toggetOffer={this.toggetOffer}
                                                            index_offer = {idx}
                                                            isbusiness={item.is_business_offer}
                                                            ineligibility_message={item.ineligibility_display_message} />
                                                    })
                                                }

                                                <div className="form_group">
                                                    <div className="box_recommandons_3">
                                                        <div className="icon"></div>
                                                        <div className="txt_link">
                                                            <p>
                                                                Plus de <span className="pink-text">{all_subscriptions_number}</span> de clients dans le monde ont déjà choisi Endesa !
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="info-text-wrapper mt-2">
                                                    <div className="text-info">
                                                        <ul>
                                                            <li>Les prix n'incluent pas la TVA</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="form_group mt-5"><p> </p>
                                                    <input type="submit" id='tarif_continue' name="btn_submit" disabled={ chosen_offer === null || chosen_offer === '' || this.state.id_simulation_selected === undefined || this.state.selected_id_duree === undefined ? false : false} className="btn_next_continue btn_submit" value="Continuer" onSubmit={this.handleSubmit} />                                                
                                                    <button onClick={(e) => this.toggleDevis(e)} disabled={ chosen_offer === null || chosen_offer === '' || this.state.id_simulation_selected === undefined || this.state.selected_id_duree === undefined ? true : false}  id='tarif_devis' name="btn_devis" className="btn_devis">Télécharger mon devis</button>    
                                                    {/*<label className="previous" onClick={this.go_back}><i className="fas fa-chevron-left"></i>Étape précédente</label>*/}
                                                    {
                                                        is_ready_only ?
                                                            <label className="reset-data btn_reset" onClick={this.resetData}><i className="far fa-trash-alt"></i>Réinitialiser la souscription</label>
                                                            :
                                                            <label className="previous" id='tarif_previous' onClick={this.go_back}><i className="fas fa-chevron-left"></i>Étape précédente</label>
                                                    }
                                                </div>
                                            </form>
                                        </Col>
                                    </Row>
                                </div>
                                <ReCaptcha
                                    ref={ref => this.recaptcha = ref}
                                    sitekey={site_key}
                                    action={this.updateToken}
                                    verifyCallback={this.verifyCallback}
                                />
                            </Col>
                        </Row>
                    </Container>
                }
                {/*
                    (this.state.ShowBrochure ?
                        <React.Fragment>
                            <Modal dialogClassName="modal-90w" size="lg" centered show={this.state.show} onHide={this.handleClose}>
                                <BrochureModal
                                    downloadFile = {this.downloadFile}
                                    CloseBrochure = {this.CloseBrochure.bind(this)}
                                    fileURL = {this.state.fileURL}
                                />
                            </Modal>
                        </React.Fragment>
                        :
                        ''
                    )
                */}
                {
                    this.state.ShowBrochure && this.state.show ?
                        <React.Fragment>
                            <Modal dialogClassName="modal-90w preventPopupInactivityToBeShown" size="lg" centered show={this.state.show} onHide={this.handleClose}>
                                <span className="close-btn-brochure" onClick={this.toggleBrochure.bind(this)}>
                                    <img src={closeBtn} />
                                </span>
                                <DocumentBrochurePopup fileURL={this.state.fileURL} />
                            </Modal>
                        </React.Fragment>
                    : null
                }
                {
                    showDevis ?
                    <React.Fragment>
                        <Modal dialogClassName="modal-90w preventPopupInactivityToBeShown" size="lg" centered show={this.state.showDevis} onHide={this.handleCloseDevis}>
                            <span className="close-btn-brochure close-btn-devis" onClick={this.toggleDevis.bind(this)}>
                                <img src={closeBtn} />
                            </span>
                            <Devis 
                                offersDevis={this.state.list_offers}
                                selectedIdOffer = {chosen_offer}
                                selectedIdDuree = {id_simulation_selected}
                                closeDevis = {this.handleCloseDevis}
                                subscriptionDeadline = {subscriptionDeadline}
                                data={this.dataDevisSent}
                                devisInfo = {this.state.dataDevis}
                            />
                        </Modal>
                    </React.Fragment>
                    : null
                }
                {
                    hideEtreRappele === 'False' || hideEtreRappele === 'false' ?
                        <IdleTimerProvider
                            timeout={100 * 60}
                            onPresenceChange={this.onPresenceChange}
                            /*onPrompt={this.onPrompt}
                            onIdle={this.onIdle}
                            onActive={this.onActive}*/
                            onAction={this.onAction}
                          >
                          <React.Fragment>
                            <Modal dialogClassName="modal-90w" size="lg" centered show={this.state.showRappel} onHide={this.handleCloseRappel}>
                                <PopupRappel
                                    text='Close Me'
                                    closePopup={this.handleCloseRappel}
                                    checkReminderProp={checkReminder}
                                    checkPhoneProp={checkPhone}
                                />
                            </Modal>
                          </React.Fragment>
                        </IdleTimerProvider>
                    : null
                }
                {/*
                    showRappel ?
                        <React.Fragment>
                            <Modal dialogClassName="modal-90w" size="lg" centered show={this.state.showRappel} onHide={this.handleCloseRappel}>
                                <PopupRappel
                                    text='Close Me'
                                    closePopup={this.handleCloseRappel}
                                />
                            </Modal>
                        </React.Fragment>
                    : null
                */}
            </React.Fragment>
        );
    }
}
function mapStateToProps({ duree_contrat, previous, parametres, brochure_file, all_subs, reset_subscription, data }) {
    return { duree_contrat, previous, parametres, brochure_file, all_subs, reset_subscription, data };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        choix_duree_contrat: choix_duree_contrat,
        get_brochure: get_brochure,
        get_previous: get_previous,
        dynamic_parameters: dynamic_parameters,
        get_reset_subscription: get_reset_subscription,
        get_numbers_subs: get_numbers_subs,
        get_data: get_data
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Tarifs);