import icone_eco from '../../assets/img/icone_eco.png';
import icone_bio from '../../assets/img/icone_bio.png';
import icone_hotel from '../../assets/img/icone_hotel.png';

export const list_offers = [
    {
        id: 0,
        icone: icone_eco,
        title: 'Offre économique',
        sub_title: "L'offre budget optimisé",
        text: "Maitrisez votre budget et faites des<br/>économies avec une offre de gaz<br/>naturel à prix fixe conçue pour les<br/>professionnels.",
        brochure: '#',
        avantage: ['Budget optimisé', 'Sans engagement de consommation'],
        selected: 'selected'
    },
    {
        id: 1,
        icone: icone_bio,
        title: 'Offre Biogaz',
        sub_title: "L'offre verte",
        text: "Maitrisez votre budget et faites des<br/>économies avec une offre de gaz<br/>naturel à prix fixe conçue pour les<br/>professionnels.",
        brochure: '#',
        avantage: ['Budget optimisé', 'Sans engagement de consommation'],
        selected: ''
    },
    {
        id: 2,
        icone: icone_hotel,
        title: 'Offre Hôtellerie',
        sub_title: "L'offre métier",
        text: "Maitrisez votre budget et faites des<br/>économies avec une offre de gaz<br/>naturel à prix fixe conçue pour les<br/>professionnels.",
        brochure: '#',
        avantage: ['Budget optimisé', 'Sans engagement de consommation'],
        selected: ''
    },
];