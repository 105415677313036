import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { post } from '../services/request';
import { apiUrl, url_signature_contrat } from '../services/constant';
import { signature_contrat_done } from '../actions/signature_contrat';


function* signature_contrat_sage(action) {
	yield call(
		callApi,
		apiUrl + url_signature_contrat,
		post,
        action.payload,
        signature_contrat_done,
	);
}
export default function* watch_signature_contrat_sage() {
    yield takeEvery('SIGNATURE_CONTRAT', signature_contrat_sage);
}

