import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { get } from '../services/request';
import { apiUrl, url_customer_coordinates_file_types } from '../services/constant';
import { get_file_types_done } from '../actions/file_types';


function* get_file_type_saga(action) {
	yield call(
		callApi,
		apiUrl + url_customer_coordinates_file_types,
		get,
        action.payload,
        get_file_types_done,
	);
}
export default function* watch_get_file_type_saga() {
    yield takeEvery('FILE_TYPES', get_file_type_saga);
}