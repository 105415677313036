import React from 'react';
import check_2 from '../../assets/img/check_2.png';
import check_2_active from '../../assets/img/check_2_active.png';
import ReactTooltip from '../../../node_modules/react-tooltip/dist/index';

function Site(props) {
    return (
        <React.Fragment>
            <div className="mt-5">
                <div className={`form-group site_souscription mt-1 site_${props.id}`}>
                    <h4>{props.title}</h4>
                    <div className="frm_fourniture">
                        <p>Adresse de fourniture <span className="red_star">*</span></p>
                        <input className="simple_input txt_c_p" defaultValue={(props.address_supply)} type="text" name="address_supply" onChange={(e) => props.handleInputById(props.id, e)} />
                        <span className="info_addr_four" data-class="own_tooltip" data-place="right" data-effect="solid" data-tip="l'adresse de fourniture est le lieu de consommation du gaz">i</span>
                        <ReactTooltip />
                        <p className="small-text-help mt-2">Ex : 101 Chemin St Joseph Les Pins Parasols 06110 lyon</p>
                    </div>
                    <div className="frm_envoi">
                        <label onClick={props.is_envoi} className={(props.checkedEnvoi.get(`check_envoi_${props.id}`) ? 'check_2 mt-4 active' : 'check_2 mt-4')} htmlFor={`check_envoi_${props.id}`}>
                            {(props.checkedEnvoi.get(`check_envoi_${props.id}`) ?
                                <img src={check_2_active} alt="Endesa S.A." />
                                :
                                <img src={check_2} alt="Endesa S.A." />
                            )}
                            <input
                                type="checkbox"
                                value={props.id}
                                name={`check_envoi_${props.id}`}
                                id={`check_envoi_${props.id}`}
                                onChange={props.is_envoi}
                                checked={props.checkedEnvoi.get(`check_envoi_${props.id}`) || false}
                            />
                            <p>Cocher cette case si vous disposez d'une adresse d'envoi différente</p>
                        </label>
                        {(props.checkedEnvoi.get(`check_envoi_${props.id}`) ?
                            <React.Fragment>
                                <p>Adresse d'envoi</p>
                                <input className="simple_input txt_c_p" value={props.address_send} type="text" name="address_send" onChange={(e) => props.handleInputById(props.id, e)} />
                                <span className="info_addr_envoi" data-class="own_tooltip" data-place="right" data-effect="solid" data-tip="l'adresse de fourniture est le lieu de consommation du gaz">i</span>
                                <ReactTooltip />
                                <p className="small-text-help mt-2">Ex : 101 Chemin St Joseph Les Pins Parasols 06110 lyon</p>
                            </React.Fragment>
                            : '')}
                    </div>
                    <div className="frm_facturation">
                        <label onClick={props.is_facturation} className={(props.checkedFacturation.get(`adresse_facturation_${props.id}`) ? 'check_2 mt-4 active' : 'check_2 mt-4')} htmlFor={`adresse_facturation_${props.id}`}>
                            {(props.checkedFacturation.get(`adresse_facturation_${props.id}`) ?
                                <img src={check_2_active} alt="Endesa S.A." />
                                :
                                <img src={check_2} alt="Endesa S.A." />
                            )}
                            <input
                                type="checkbox"
                                value={props.id}
                                name={`adresse_facturation_${props.id}`}
                                id={`adresse_facturation_${props.id}`}
                                onChange={props.is_facturation}
                                checked={props.checkedFacturation.get(`adresse_facturation_${props.id}`) || false}
                            />
                            <p>Cocher cette case si vous disposez d'une adresse de facturation différente</p>
                        </label>
                        {(props.checkedFacturation.get(`adresse_facturation_${props.id}`) ?
                            <React.Fragment>
                                <p>Adresse de facturation</p>
                                <input className="simple_input txt_c_p" value={props.address_billing} type="text" name="address_billing" onChange={(e) => props.handleInputById(props.id, e)} />
                                <span className="info_addr_facturation" data-class="own_tooltip" data-place="right" data-effect="solid" data-tip="l'adresse de fourniture est le lieu de consommation du gaz">i</span>
                                <ReactTooltip />
                                <p className="small-text-help mt-2">Ex : 101 Chemin St Joseph Les Pins Parasols 06110 lyon</p>
                            </React.Fragment>
                            : '')}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Site;