import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { get } from '../services/request';
import { apiUrl, url_parameters } from '../services/constant';
import { get_numbers_subs_done } from '../actions/all_subs';


function* get_numbers_subs_saga(action) {
	yield call(
		callApi,
		apiUrl + url_parameters,
		get,
        action.payload,
        get_numbers_subs_done,
	);
}
export default function* watch_get_numbers_subs_saga() {
    yield takeEvery('ALL_SUBS', get_numbers_subs_saga);
}

