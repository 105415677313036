import React, { Component } from 'react';
import swal from 'sweetalert';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Row, Col } from 'react-bootstrap';
import { current_step, subscription_id } from '../../services/status';
import { get_previous } from '../../actions/previous';
import welcome from '../../assets/img/welcome.svg';
import { get_docusign_link } from '../../actions/docusign_link';
import Spinner from 'react-bootstrap/Spinner';
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";
import good from '../../assets/img/good.svg';

class Signature extends Component {
    constructor(props) {
        super(props);
        this.state = {
            verified_email: (localStorage.getItem('next') ? JSON.parse(localStorage.getItem('next')) : JSON.parse(localStorage.getItem('current_step_continue'))),
            isLoading: false,
            url_signing_contract: '',
            isDocusign: false,
            site_key: process.env.REACT_APP_TAG_RECAPTCHA_V3 ? process.env.REACT_APP_TAG_RECAPTCHA_V3 : '',
            token: '',
            disableGenerate: false // disable btn when generat link ws response 200
        }
    }
    componentWillMount(){
        const { verified_email } = this.state;
        //console.log('verified_email willmount : ', verified_email)
        /*
        if (localStorage.getItem('current_step_continue')) {
            if(JSON.parse(localStorage.getItem('current_step_continue')).data.step === "signature_contrat" ){
                console.log('will moun OK', verified_email.data.data.signing_url)
                this.setState({
                    url_signing_contract: JSON.parse(localStorage.getItem('current_step_continue')).data.data.signing_url
                })
            }
        }
        */
        if (localStorage.getItem('next')) {
            // Remove navigation prompt
            window.onbeforeunload = null;
            if(JSON.parse(localStorage.getItem('next')).data.step === "signature_contrat" ){
                //console.log('will moun OK returned', verified_email)
                this.setState({
                    url_signing_contract: JSON.parse(localStorage.getItem('next')).data.signing_url
                })
            }
        }

    }
    componentDidMount() {
        const { verified_email, site_key } = this.state;
        loadReCaptcha(site_key, this.verifyCallback);
        this.setState({
            url_signing_contract: verified_email.data.signing_url ? verified_email.data.signing_url : verified_email.data.data.signing_url
        })
        //console.log('verified_email', verified_email, 'current step : ', current_step);
    }
    updateToken = () => {
        // you will get a new token in verifyCallback
        this.recaptcha.execute()
    };
    verifyCallback = (recaptchaToken) => {
        // Here you will get the final recaptchaToken!!!
        this.setState({
            token: recaptchaToken
        });
    }
    componentDidUpdate(prevProps) {
        const { previous, docusign_link } = this.props;
        // Enable navigation prompt
        window.onbeforeunload = function() {
            return true;
        };
        // PREVIOUS STEP
        if (prevProps.previous !== previous) {
            // Remove navigation prompt
            window.onbeforeunload = null;
            localStorage.setItem('step', previous.result.data.step);
            localStorage.setItem('subscription_id', previous.result.data.subscription_id);
            localStorage.setItem('previous', JSON.stringify(previous.result.data));
            // Redirect
            window.location.reload(false);
        }
        if(prevProps.docusign_link !== docusign_link){
            if (docusign_link.result.status === 200) {
                window.onbeforeunload = null;
                this.setState({
                    isDocusign: true,
                    disableGenerate: true
                })
                let docusign_link_generated = docusign_link.result.data.signing_url;
                //console.log('docusign_link_generated : ', docusign_link_generated)
                window.location = docusign_link_generated;
                localStorage.clear()
            }else {
                //console.log('ERROR')
            }
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();
        const { signer_contrat } = this.props;
        const { url_signing_contract } = this.state;
        // SUBMIT DATA TO BACKEND
        // Remove navigation prompt
        window.onbeforeunload = null;
        window.location = url_signing_contract;
        localStorage.clear()
    }
    // GET PREVIOUS
    go_back = (e) => {
        e.preventDefault();
        const { get_previous } = this.props;
        let data = {}
        data.type_step = 'previous';
        data.subscription_id = subscription_id;
        data.current_step = current_step;
        get_previous(data);
    }
    generate_link = (e) => {
        e.preventDefault();
        window.onbeforeunload = null;
        const {get_docusign_link} = this.props;
        const {token} = this.state
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        let frm_data = {
            //subscription_id: subscription_id,
            subscription_id: path_remove_slash,
            recaptcha: token
        }
        get_docusign_link(frm_data)
        this.setState({
            isDocusign: true
        })
    }
    render() {
        const {isDocusign, site_key, disableGenerate} = this.state
        return (
            <React.Fragment>
                {isDocusign ?
                    <div className="loadingKey">
                        <Spinner className="spinner-border text-primary" animation="border" role="status"></Spinner>
                        <span className="loader-text">Vous serez redirigé automatiquement pour signer votre contrat.</span>
                    </div>
                :
                <Container>
                    <Row>
                        <Col xs={12} md={12} className="txt_center mt-6">
                            <img src={good} alt="ENDESA S.A." />
                            <h1 className="h1">Il vous reste une étape avant de valider votre souscription</h1>
                            <p className="classic_txt mt-3 txt_welcome"></p>
                            {/*<label className="previous" onClick={this.go_back}><i className="fas fa-chevron-left"></i>Étape précédente</label>*/}
                            <button type="button" disabled={disableGenerate ? true : false} onClick={this.generate_link} className="btn_end mt-3">Signer mon contrat</button>
                            {/*<button type="button" onClick={this.generate_link} className="btn_end mt-3">generate link</button>*/}
                            <ReCaptcha
                                ref={ref => this.recaptcha = ref}
                                sitekey={site_key}
                                action={this.updateToken}
                                verifyCallback={this.verifyCallback}
                            />
                        </Col>
                    </Row>
                </Container>
                }
            </React.Fragment>
        )
    }
}
function mapStateToProps({ previous, docusign_link }) {
    return { previous, docusign_link };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        get_previous: get_previous,
        get_docusign_link: get_docusign_link
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Signature);