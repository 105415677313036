/*import axios from 'axios';
import { apiUrl, url_choix_duree_contrat } from '../services/constant';

export const choix_duree_contrat = (data) => dispatch => {
    const request = axios.post(apiUrl + url_choix_duree_contrat,data);
    dispatch({
        type: 'CHOIX_DUREE_CONTRAT',
        payload: request
    })
}
*/
export function choix_duree_contrat(payload) {
    return {
        type: 'CHOIX_DUREE_CONTRAT',
        payload,
    };
}
export function choix_duree_contrat_done(request) {
    return {
        type: 'CHOIX_DUREE_CONTRAT_DONE',
        payload: request,
    };
}