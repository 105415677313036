/*import axios from 'axios';
import { apiUrl, url_applicate_code } from '../services/constant';

export const get_applicate_code = (data) => dispatch => {
    const request = axios.post(apiUrl + url_applicate_code, data);
    dispatch({
        type: 'APPLICATE_CODE',
        payload: request
    })
}*/

export function get_applicate_code(payload) {
    return {
        type: 'APPLICATE_CODE',
        payload,
    };
}
export function get_applicate_code_done(request) {
    return {
        type: 'APPLICATE_CODE_DONE',
        payload: request,
    };
}