/*import axios from 'axios';
import { apiUrl, url_utilisation_type } from '../services/constant';

export const get_estimation_type = () => dispatch => {
    const request = axios.get(apiUrl + url_utilisation_type);
    dispatch({
        type: 'ESTIMATION_TYPE_LIST',
        payload: request
    })
}
*/
export function get_estimation_type(payload) {
    return {
        type: 'ESTIMATION_TYPE_LIST',
        payload,
    };
}
export function get_estimation_type_done(request) {
    return {
        type: 'ESTIMATION_TYPE_LIST_DONE',
        payload: request,
    };
}