import React, {Component} from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PhoneInput from 'react-phone-input-2'
import Spinner from 'react-bootstrap/Spinner';
import swal from 'sweetalert';
import { Tooltip } from 'react-tippy';
import { get_devis } from '../../actions/devis';

class devis extends Component {
  constructor(props) {
    super(props)
    this.state = {
      devisOffers : [],
      dropDownOffer : false,
      dropDownDuree : false,
      selectedOffer : [],
      selectedDuree : [],
      last_name: Object.keys(this.props.devisInfo).length > 0 ? this.props.devisInfo.last_name : '',
      first_name: Object.keys(this.props.devisInfo).length > 0 ? this.props.devisInfo.first_name : '',
      telephone: Object.keys(this.props.devisInfo).length > 0 ? this.props.devisInfo.telephone.substring(1) : '',
      email: Object.keys(this.props.devisInfo).length > 0 ? this.props.devisInfo.email : '',
      errorEmail: "",
      errorPhone: [],
      isLoading: false,
      widthSize: window.innerWidth,
      dataDevis: {}
    }
  }
  componentDidMount() {
    // Adding a click event listener
    document.addEventListener('click', this.handleOutsideClick);
  }
  componentWillMount(){
    const {selectedOffer, selectedDuree} = this.state
    if(this.props.offersDevis){
      if(this.props.offersDevis.length > 0){
        let initializeSelectedOffer = selectedOffer
        let initializeSelectedDuree = selectedDuree
        this.props.offersDevis.filter(offer => offer.id === this.props.selectedIdOffer).map((offerId) => {
          initializeSelectedOffer.idOffer = offerId.id
          initializeSelectedOffer.titleOffer = offerId.title
          
          offerId.offer_simulations.filter(duree => duree.id === this.props.selectedIdDuree).map((dureeId) => {
            initializeSelectedDuree.idDuree = dureeId.id
            initializeSelectedDuree.duree = dureeId.duree
          })
        })
        //console.log('initializeSelectedDuree : ', initializeSelectedDuree)
        this.setState({
          devisOffers : this.props.offersDevis,
          selectedOffer : initializeSelectedOffer,
          selectedDuree: initializeSelectedDuree
        })
      }
    }
  }
  componentDidUpdate(prevProps){
    const { devis_file } = this.props
    // DOWNLOADING FILE
    if (prevProps.devis_file !== devis_file) {

      if (devis_file.result.status === 200) {
        let filename = Object.values(devis_file.result.headers);
        const blob = new Blob([devis_file.result.data], { type: filename[2] });
        const data = window.URL.createObjectURL(blob);
        filename = filename[0].split('=');
        filename = filename[1].split('"');
        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob);
            return;
        }
        // For other browsers: 
        var link = document.createElement('a');
        link.href = data;
        link.download = filename[1];
        link.click();
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
        }, 100);
        link.remove();
        swal({
            text: `Un mail contenant le devis vient de vous être transmis, ainsi qu'un lien pour reprendre votre souscription avec le prix indiqué sur votre estimation valable jusqu'au ${this.props.subscriptionDeadline}`,
            icon: "success",
            button: "Reprendre la souscription"
        }).then(() => {
          this.props.closeDevis()
          this.setState({
              isLoading: false
          });
        })
      }else{
        swal({
          text: "Erreur dans le formulaire",
          icon: "error",
        }).then(() => {
            this.setState({
                isLoading: false,
            });
        })
      }
    }
  }
  toggleOffer = (e) => {
    const {dropDownOffer} = this.state
    e.preventDefault();
    this.setState({
      dropDownDuree : false,
      dropDownOffer : !dropDownOffer
    })
  }
  toggleDuree = (e) => {
    const {dropDownDuree} = this.state
    e.preventDefault();
    this.setState({
      dropDownOffer : false,
      dropDownDuree : !dropDownDuree
    })
  }
  changeOffer = (offer) => {
    const {selectedOffer, devisOffers, selectedDuree} = this.state
    let customizedOffer = selectedOffer
    customizedOffer.idOffer = offer.id
    customizedOffer.titleOffer = offer.title
      if(devisOffers.length > 0){
        let initializeSelectedDuree = selectedDuree
        devisOffers.filter(offerDevis => offerDevis.id === offer.id ).map((offerId) => {
          if(offerId.offer_simulations){
            if(offerId.offer_simulations.length > 0){
              initializeSelectedDuree.idDuree = offerId.offer_simulations[0].id
              initializeSelectedDuree.duree = offerId.offer_simulations[0].duree
              this.setState({
                selectedDuree: initializeSelectedDuree
              })
            }
          }
        })
        //console.log('initializeSelectedDuree : ', initializeSelectedDuree)
      }
    this.setState({
      selectedOffer : customizedOffer
    })
  }
  changeDuree = (duree) => {
    const {selectedDuree} = this.state
    let customizedDuree = selectedDuree
    customizedDuree.idDuree = duree.id
    customizedDuree.duree = duree.duree
    this.setState({
      selectedDuree : customizedDuree
    })
  }
  handleOutsideClick = (event) => {
      const {dropDownOffer, dropDownDuree} = this.state

      var specifiedElementDuree = document.getElementById('selectDuree');
      var specifiedElementOffer = document.getElementById('selectOffre');

      if(specifiedElementDuree !== null){
          var isClickInside = specifiedElementDuree.contains(event.target);
          if (!isClickInside) {
              this.setState({
                dropDownDuree: false
              })
          }
      }
      if(specifiedElementOffer !== null){
          var isClickInside = specifiedElementOffer.contains(event.target);
          if (!isClickInside) {
              this.setState({
                dropDownOffer: false
              })
          }
      }
  }
  // INPUT CHANGE
  handleInput = (e) => {
      let name = e.target.name;
      let value = e.target.value;
      this.setState({
          [name]: value
      });
  };
  handleEmail = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{1,3}$/g;
    if(!value || regex.test(value) === false){
      this.setState({
          errorEmail: "Veillez saisir une adresse mail valide.",
          [name]: value
      });
      return false;
    }else{
      this.setState({
        [name]: value,
        errorEmail: ""
    });
    }
  }
  handleSubmit = (e) => {
    e.preventDefault();
    const {
      last_name, 
      first_name, 
      telephone, 
      email,
      selectedOffer,
      selectedDuree
    } = this.state;
    const {get_devis} = this.props

    let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
    let path_remove_slash = path.replace(/^\/|\/$/g, '');
    let data = {}

    data.subscription_uuid = path_remove_slash;
    data.last_name = last_name
    data.first_name = first_name
    data.telephone = "+" + telephone
    data.email = email
    data.offer = selectedOffer.idOffer
    data.simulation = selectedDuree.idDuree

    get_devis(data)
    // send data to tarif component
    this.props.data(data)
    this.setState({
      isLoading: true
    })

  }
  render() {
    const {
      devisOffers, 
      dropDownOffer, 
      selectedOffer, 
      dropDownDuree, 
      selectedDuree, 
      last_name, 
      first_name, 
      telephone, 
      email,
      isLoading,
      widthSize,
      errorEmail
    } = this.state;
    return (
      <React.Fragment>
        <div className="devis-container">
          <div className="devis-wrapper">
            <div className="title-devis">
              <h3>Télécharger mon devis</h3>
              <p>Pour télécharger votre devis, veuillez remplir le formulaire ci-dessous.<br/>Votre devis vous sera envoyé par email.</p>
            </div>
            {
              devisOffers.filter(offer => offer.id === selectedOffer.idOffer ).map(filteredOffer => {
                return (
                  <div className="details-devis">
                    <div className="left-devis">
                      <h3>{filteredOffer.title}</h3>
                      <p>{filteredOffer.subtitle}</p>
                    </div>
                    {
                      filteredOffer.offer_simulations.filter(duree => duree.id === selectedDuree.idDuree).map((offerSimulation, idx) => {
                        return (
                          <div className="right-devis">
                            <div className="duree-devis">
                              <h4>Durée du contrat</h4>
                              <p><span>{offerSimulation.duree} Mois</span></p>
                            </div>
                            <div className="estimation-devis">
                              <div className="blocs estime">
                                <h5>Estimé à</h5>
                                <div className="price">
                                  <p>{offerSimulation.estime}€ <span>HT/mois</span></p>
                                  <span className="ht-an">soit {(offerSimulation.estime*12).toFixed(2)}€ HT/an</span>
                                </div>
                              </div>
                              <div className="blocs abonnement">
                                <h5>Abonnement</h5>
                                <p>{offerSimulation.abonnement_remise}</p>
                              </div>
                              <div className="blocs conso">
                                <h5>Consommation</h5>
                                <p>{offerSimulation.consommation}</p>
                              </div>
                              <div className="blocs prix">
                                <h5>Prix du MWh</h5>
                                <p>{offerSimulation.prix_remise}</p>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                )
              })
            }
            <div className="form-devis">
              <div className="frm-input wrapper-postal select-offer mb-4">
                <p>Offre <span className="red_star">*</span></p>
                <div id="selectOffre" className="select dropdown-postal" onClick={(e) => this.toggleOffer(e)}>
                    <input className="toggle" readOnly type="text" />
                    {
                    <div className="arrow">
                        {(dropDownOffer ? 
                            <i className="fas fa-chevron-up"></i> 
                        : <i className="fas fa-chevron-down"></i>)}
                    </div>
                    }
                    <div className="selected">
                      <div className="value">{selectedOffer.titleOffer}</div>
                    </div>
                    <div className={`dropdown ${(dropDownOffer ? '' : 'hide-drop')}`}>
                      {
                          devisOffers.map((item, index) => {
                              return <p key={index} className="value" onClick={(e) => this.changeOffer(item)}>{item.title}</p>;
                          })
                      }
                    </div>
                </div>
              </div>
              
              <div className="frm-input wrapper-postal select-duree mb-4">
                <p>Durrée <span className="red_star">*</span></p>
                <div id="selectDuree" className="select dropdown-postal dropdown-duree" onClick={(e) => this.toggleDuree(e)}>
                    <input className="toggle" readOnly type="text" />
                    {
                    <div className="arrow">
                        {(dropDownDuree ? 
                            <i className="fas fa-chevron-up"></i> 
                        : <i className="fas fa-chevron-down"></i>)}
                    </div>
                    }
                    <div className="selected">
                      <div className="value">{selectedDuree.duree} mois</div>
                    </div>
                    <div className={`dropdown ${(dropDownDuree ? '' : 'hide-drop')}`}>
                      {
                        devisOffers.filter(offer => offer.id === selectedOffer.idOffer ).map(filteredOffer => {
                          return (
                            filteredOffer.offer_simulations.map((offerSimulation, idx) => {
                              return <p key={idx} className="value"  onClick={(e) => this.changeDuree(offerSimulation)}>{offerSimulation.duree} mois</p>
                            })
                          )
                        })
                      }
                    </div>
                </div>
                <Tooltip className="tooltip-devis" title="Vous pouvez télécharger le devis pour une seule offre à la fois. Vous avez la possibilité de faire plusieurs téléchargements de devis successifs en modifiant les critères de l’offre" position={widthSize > 576 ? "right" : "bottom"} trigger={widthSize > 576 ? "mouseenter" : "click"}>
                  <span className="info_postal btn_mobile info_addr_envoi info" data-class="own_tooltip">i</span>
                </Tooltip>
              </div>
    
              <div className="frm-input input-nom mb-4">
                <p>Nom <span className="red_star">*</span></p>
                <input type="text" name="last_name" value={last_name} className="simple_input" onChange={this.handleInput}/>
              </div>
              
              <div className="frm-input input-prenom mb-4">
                <p>Prénom <span className="red_star">*</span></p>
                <input type="text" name="first_name" value={first_name} className="simple_input" onChange={this.handleInput} />
              </div>
              
              <div className="frm-input input-prenom mb-4">
                <p>Email <span className="red_star">*</span></p>
                <input type="email" name="email" value={email} className="simple_input" onChange={this.handleEmail} />
                {
                  errorEmail != "" ?
                    <p className="text-danger small-text-help mt-2">{errorEmail}</p>
                  : null
                }
                            
              </div>
    
              <div className="frm-input input-phone mb-4">
                <p>Téléphone <span className="red_star">*</span></p>
                <PhoneInput
                    inputProps={{ name: 'telephone', className: 'simple_input_phone' }}
                    masks={{ ma: '. ..-..-..-..' }}
                    country={'fr'}
                    countryCodeEditable={false}
                    value={telephone}
                    onChange={telephone => this.setState({ telephone })}
                />
              </div>
    
              <button disabled={first_name != '' && last_name != '' && email != '' && telephone != '' && telephone.length > 10 && errorEmail === "" ? false : true} id='tarif_devis_download' name="btn_devis_download" className="btn_devis" onClick={(e) => this.handleSubmit(e)}>
                Télécharger le devis
                </button>    
    
            </div>
          </div>
        </div>
        {
          isLoading ?
            <div className="loadingKey">
                <Spinner className="spinner-border text-primary" animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
          :
          null
        }
      </React.Fragment>
    )
  }
}


function mapStateToProps({ devis_file }) {
  return { devis_file };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
      get_devis: get_devis,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(devis)