import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import swal from 'sweetalert';
import { Row, Col, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { liste_etablissements } from '../../actions/etablissement';
import { get_previous } from '../../actions/previous';
import { bindActionCreators } from 'redux';
import { handleHTTPError } from '../../actions/errorHandlerActions';
import { current_step, subscription_id } from '../../services/status';
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";
import { get_reset_subscription } from '../../actions/reset_subscription';
import icon_help from '../../assets/img/calc.svg';

class Etablissement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            regexp: /^[0-9\b]+$/,
            client_identification: (localStorage.getItem('next') ? JSON.parse(localStorage.getItem('next')) : JSON.parse(localStorage.getItem('current_step_continue'))),
            frm_data: {},
            show_list: false,
            list_etablissement: [],
            selected_etab: [],
            selected_etab_list: {},
            current_etab: '',
            previous: (localStorage.getItem('previous') ? JSON.parse(localStorage.getItem('previous')) : {}),
            sirets: {},
            showAdd: true,
            is_readonly: false,
            isLoading: false,
            site_key: process.env.REACT_APP_TAG_RECAPTCHA_V3 ? process.env.REACT_APP_TAG_RECAPTCHA_V3 : '',
            token: '',
            autoEtablissement: []
        }
    }
    componentWillUnmount() {
        localStorage.removeItem('current_step_continue');
    }
    componentWillMount() {
        window.dataLayer.push({              
            pageCategory: 'Etablissement',                
        });
        const { previous, selected_etab, client_identification } = this.state;
        /*
        if (localStorage.getItem('previous')) {
            this.setState({
                selected_etab: previous.chosen_etbalissements,
                list_etablissement: previous.data,
                is_readonly: previous.is_readonly
            });
        } else {
            this.setState({
                selected_etab: client_identification.chosen_etbalissements,
                list_etablissement: client_identification.data,
                is_readonly: client_identification.is_readonly
            });
        }
        */
        if (localStorage.getItem('current_step_continue')) {
            if(JSON.parse(localStorage.getItem('current_step_continue')).data.step === 'liste_etablissements'){
                this.setState({
                    selected_etab: JSON.parse(localStorage.getItem('current_step_continue')).data.chosen_etbalissements,
                    list_etablissement: JSON.parse(localStorage.getItem('current_step_continue')).data.data,
                    is_readonly: JSON.parse(localStorage.getItem('current_step_continue')).data.is_readonly,
                    autoEtablissement: JSON.parse(localStorage.getItem('current_step_continue')).data.autoEtablissement ? JSON.parse(localStorage.getItem('current_step_continue')).data.autoEtablissement : [],
                    client_identification: JSON.parse(localStorage.getItem('current_step_continue')).data
                })
            }
        }
        if (localStorage.getItem('next')) {
            if(JSON.parse(localStorage.getItem("next")).step === "liste_etablissements"){
                let rest_list_etab = JSON.parse(localStorage.getItem('next')).data.filter((etab) => etab.slug !== JSON.parse(localStorage.getItem('next')).chosen_etbalissements[0]);
                //console.log('rest_list_etab : ', rest_list_etab)
                this.setState({
                    client_identification: JSON.parse(localStorage.getItem('next')),
                    selected_etab: JSON.parse(localStorage.getItem('next')).chosen_etbalissements,
                    list_etablissement: rest_list_etab.length === JSON.parse(localStorage.getItem('next')).data.length ? JSON.parse(localStorage.getItem('next')).data : rest_list_etab,
                    is_readonly: JSON.parse(localStorage.getItem('next')).is_readonly,
                    autoEtablissement: JSON.parse(localStorage.getItem('next')).autoEtablissement ? JSON.parse(localStorage.getItem('next')).autoEtablissement : [],
                    selected_etab_list: JSON.parse(localStorage.getItem('next')).previously_chosen_buildings_list,
                    sirets: JSON.parse(localStorage.getItem('next')).previously_chosen_buildings,
                    is_readonly: JSON.parse(localStorage.getItem('next')).is_readonly,
                    showAdd: JSON.parse(localStorage.getItem('next')).previously_chosen_buildings_list.length >= JSON.parse(localStorage.getItem('next')).number_allowed ? false : true,
                })
            }
        }
        if (localStorage.getItem('previous')) {
            if (JSON.parse(localStorage.getItem('previous')).step === "liste_etablissements") {
                const { list_etablissement } = this.state
                //console.log('log : ', JSON.parse(localStorage.getItem('previous')))
                let rest_list_etab = JSON.parse(localStorage.getItem('previous')).data.filter((etab) => etab.slug !== JSON.parse(localStorage.getItem('previous')).chosen_etbalissements[0]);
                this.setState({
                    client_identification: JSON.parse(localStorage.getItem('previous')),
                    //selected_etab: JSON.parse(localStorage.getItem('previous')).previously_chosen_buildings_list,
                    selected_etab_list: JSON.parse(localStorage.getItem('previous')).previously_chosen_buildings_list,
                    //list_etablissement: JSON.parse(localStorage.getItem('previous')).data,
                    list_etablissement: rest_list_etab,
                    is_readonly: JSON.parse(localStorage.getItem('previous')).is_readonly,
                    showAdd: false,
                    sirets: JSON.parse(localStorage.getItem('previous')).previously_chosen_buildings,
                    autoEtablissement: JSON.parse(localStorage.getItem('previous')).autoEtablissement ? JSON.parse(localStorage.getItem('previous')).autoEtablissement : []
                })
            }
        }
    }
    componentDidMount() {
        const { client_identification, selected_etab, site_key, previous } = this.state;
        loadReCaptcha(site_key, this.verifyCallback);
    }
    updateToken = () => {
        // you will get a new token in verifyCallback
        this.recaptcha.execute()
    };
    verifyCallback = (recaptchaToken) => {
        // Here you will get the final recaptchaToken!!!  
        this.setState({
            token: recaptchaToken
        });
    }
    componentDidUpdate(prevProps) {
        const { etablissement, handleHTTPError, previous, reset_subscription } = this.props;
        // Enable navigation prompt
        window.onbeforeunload = function() {
            return true;
        };
        // GET NEW KEY WHEN RESET SUBS
        if (prevProps.reset_subscription !== reset_subscription) {
            // Remove navigation prompt
            window.onbeforeunload = null;
            //console.log('reset_subscription', reset_subscription);
            let newKey = reset_subscription.result.data.subscription_id;
            //console.log('reset_subscription 2 : ', newKey);
            //window.location.assign(newKey)
            window.location.pathname = newKey + '/start'
        }
        if (prevProps.etablissement !== etablissement) {
            // Remove navigation prompt
            window.onbeforeunload = null;
            if(etablissement.result.status === 200){
                localStorage.removeItem('previous');
                localStorage.removeItem('current_returned_prospect');
                localStorage.setItem('step', etablissement.result.data.step);
                localStorage.setItem('subscription_id', etablissement.result.data.subscription_id);
                localStorage.setItem('next', JSON.stringify(etablissement.result.data));
                // Data
                window.location.reload(false);
                window.location.replace(etablissement.result.data.step)
            }
        }
        // }else{
        //     handleHTTPError(etablissement.result.response.status);
        // }
        // PREVIOUS STEP
        if (prevProps.previous !== previous) {
            // Remove navigation prompt
            window.onbeforeunload = null;
            if(previous.result.status === 200){
                localStorage.removeItem('next');
                localStorage.removeItem('current_returned_prospect');
                localStorage.setItem('step', previous.result.data.step);
                localStorage.setItem('subscription_id', previous.result.data.subscription_id);
                localStorage.setItem('previous', JSON.stringify(previous.result.data));
                // Redirect
                window.location.reload(false);
                window.location.replace(previous.result.data.step)
            }
        }
        // WHEN REFRESH RETURN TO LAST STEP
        // if (localStorage.getItem('previous')) {
        //     if (JSON.parse(localStorage.getItem('previous')).step === "liste_etablissements") {
        //         //localStorage.setItem('step', etablissement.last_step);
        //     }
        // }
    }
    trigger_list = () => {
        const { show_list } = this.state;
        this.setState({
            show_list: !show_list
        })
    };
    // GET PREVIOUS
    go_back = (e) => {
        e.preventDefault();
        const { get_previous } = this.props;
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        let data = {}
        data.type_step = 'previous';
        //data.subscription_id = subscription_id;
        data.subscription_id = path_remove_slash;
        data.current_step = current_step;
        get_previous(data);
    }
    setCurrentEtab = (item) => {
        const { list_etablissement, show_list } = this.state;
        let clicked_etab = list_etablissement.filter((etab) => etab.slug === item.slug);
        this.setState({
            current_etab: clicked_etab[0],
            show_list: !show_list
        })
    };
    addEtab = (current_etab) => {
        /* OLD CODE
        const { list_etablissement, client_identification, selected_etab, sirets, selected_etab_list } = this.state;
        // REMOVE FROM LEFT LIST
        let rest_list_etab = list_etablissement.filter((etab) => etab.slug !== current_etab.slug);
        let new_list = selected_etab_list;

        // ADDING TO RIGHT LIST
        new_list = selected_etab.concat(list_etablissement.filter((etab) => etab.slug === current_etab.slug));
        console.log('new list : ', new_list)
        console.log('selected_etab : ', selected_etab)
        console.log('list_etablissement : ', list_etablissement)

        // ADDING SIRET AS KEYS
        let added_sirets = sirets;
        let siret = current_etab.siret;
        added_sirets = { [siret]: Object.assign({}, list_etablissement.filter((etab) => etab.slug === current_etab.slug)[0]), ...added_sirets };
        
        this.setState({
            list_etablissement: rest_list_etab,
            selected_etab: Object.assign({}, new_list),
            selected_etab_list: new_list,
            sirets: added_sirets,
            current_etab: '',
            showAdd: (client_identification.number_allowed === new_list.length ? true : false) // NUMBER ALLOWED
            //showAdd: false // NUMBER ALLOWED
        });
        */
       // NEW CODE
       const { list_etablissement, client_identification, selected_etab, sirets } = this.state;
        // REMOVE FROM LEFT LIST
        let rest_list_etab = list_etablissement.filter((etab) => etab.slug !== current_etab.slug);
        let new_list = [];
        // ADDING TO RIGHT LIST
        new_list = selected_etab.concat(list_etablissement.filter((etab) => etab.slug === current_etab.slug));
        //console.log('new list ; ', new_list)
        // ADDING SIRET AS KEYS
        let added_sirets = sirets;
        let siret = current_etab.siret;
        added_sirets = { [siret]: Object.assign({}, list_etablissement.filter((etab) => etab.slug === current_etab.slug)[0]), ...added_sirets };
        //console.log('added_sirets ; ', added_sirets)
        this.setState({
            list_etablissement: rest_list_etab,
            selected_etab: new_list,
            selected_etab_list: new_list,
            sirets: added_sirets,
            current_etab: '',
            showAdd: (client_identification.number_allowed === new_list.length ? false : true) // NUMBER ALLOWED
        });
    };
    removeEtab = (item) => {
        const { selected_etab, list_etablissement, client_identification, selected_etab_list, sirets } = this.state;
        let rest_etab = selected_etab_list.filter((cp) => cp !== item);
        let rest_list_etab = list_etablissement;
        rest_list_etab.push(item);

        let added_sirets = sirets;
        let siret = item.siret;
        delete added_sirets[item.siret]
        //added_sirets = { [siret]: Object.assign({}, rest_etab)[0] };
        this.setState({
            selected_etab: rest_etab,
            selected_etab_list: rest_etab,
            list_etablissement: rest_list_etab,
            showAdd: (client_identification.number_allowed === rest_etab.length ? false : true), // NUMBER ALLOWED
            sirets: added_sirets
        });
    };
    deleteAll = () => {
        const { selected_etab_list, list_etablissement } = this.state;
        let current_t = list_etablissement.concat(selected_etab_list);
        this.setState({
            list_etablissement: current_t,
            selected_etab: [],
            selected_etab_list: {},
            sirets: {},
            showAdd: true // NUMBER ALLOWED
        });
    }
    // RESET DATA - SOUSSCRIPTION
    resetData = (e) => {
        e.preventDefault();
        const { get_reset_subscription } = this.props;
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        let data = {};
        //data.subscription_id = subscription_id;
        data.subscription_id = path_remove_slash;
        swal({
            title: "Etes-vous sûr ? Vous perdrez toutes les informations renseignées",
            //text: "Once deleted, you will not be able to recover this imaginary file!",
            icon: "warning",
            buttons: ["Non", "Oui"],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    window.dataLayer.push({
                        event: 'gtm.click.etabli_Reinitialiser',                
                    });
                    swal("Votre souscription est réinitialisée", {
                        icon: "success",
                    }).then(() => {
                        //console.log('call ws : ', data);
                        get_reset_subscription(data)
                    });
                } else {
                    //swal("Your imaginary file is safe!");
                }
            });
    }
    handleSubmit = (e) => {
        const { liste_etablissements } = this.props;
        e.preventDefault();
        const { frm_data, client_identification, selected_etab, sirets, token } = this.state;
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        // SUBMIT DATA TO BACKEND
        //frm_data.subscription_id = client_identification.subscription_id;
        frm_data.subscription_id = path_remove_slash;
        frm_data.liste_etablissements = sirets;
        frm_data.recaptcha = token
        liste_etablissements(frm_data);
        this.setState({
            isLoading: true
        });
        //console.log('submit : ', frm_data)
    };
    render() {
        const {
            client_identification,
            show_list,
            list_etablissement,
            selected_etab,
            current_etab,
            showAdd,
            site_key,
            is_readonly,
            isLoading,
            selected_etab_list,
            previous
        } = this.state;
        return (
            <React.Fragment>
                {isLoading ?
                    <div className="loadingKey">
                        <Spinner className="spinner-border text-primary" animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                    :
                    <Container>
                        {is_readonly ? <div className="blank_div"></div> : null}
                        <h1 className="blue-border"><span></span>Mes établissements</h1>
                        {
                            this.state.autoEtablissement.length > 0 ?
                            <div className="etablissement-wrapper">
                                <div className="icon-help">
                                    <img src={icon_help} alt="endesa etablissement" />
                                </div>
                                <div className="etablissement-text">
                                    <p>
                                        Les établissements suivants ont été automatiquement détéctés :
                                    </p>
                                    {
                                        this.state.autoEtablissement.map((etab, idx) => {
                                        return <p className="infos" key={idx}>{etab}</p>
                                        })
                                    }
                                </div>
                            </div>
                            : null
                        }
                        <form onSubmit={this.handleSubmit}>
                            <Row>
                                <Col xs={12} md={8}>
                                    <div className="form_group mt-5">
                                        {
                                            client_identification.number_allowed > 1 ?
                                                <p>Ajoutez vos établissements <span className="red_star">*</span></p>
                                            : <p>Ajoutez votre établissement <span className="red_star">*</span></p>
                                        }
                                        {(showAdd ?
                                            <div className="wrapper-postal wrapper-input etab-list-wrapper">
                                                <div className="select select-etablissement dropdown-postal mt-2" 
                                                    title= { current_etab.ligne_adresse !== undefined ? `(SIRET : ${current_etab.siret}) ${current_etab.ligne_adresse}, ${current_etab.code_postal} ${current_etab.ville}`  : null}
                                                    onClick={this.trigger_list}>
                                                    <input className="toggle" readOnly type="text" />
                                                    <div className="arrow">
                                                        {(show_list ? 
                                                            <i className="fas fa-chevron-up"></i> 
                                                        : <i className="fas fa-chevron-down"></i>)}
                                                    </div>
                                                    <div className="selected">
                                                        {
                                                            current_etab.ligne_adresse !== undefined ?
                                                            <p className="value">
                                                                (SIRET : {current_etab.siret}) {current_etab.ligne_adresse}, {current_etab.code_postal} {current_etab.ville}
                                                            </p>
                                                            : 
                                                            <p className="value">Choisissez votre etablissement</p>
                                                        }
                                                    </div>
                                                    <div className={`dropdown ${(show_list ? '' : 'hide-drop')}`}>
                                                        {
                                                            list_etablissement.map((item, index) => {
                                                                return <p key={index} className="value" onClick={(e) => this.setCurrentEtab(item)}>(SIRET : {item.siret}) {item.ligne_adresse},{item.code_postal} {item.ville}</p>;
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            : ''
                                        )}
                                        {/*
                                            Object.keys(selected_etab).map((item, idx) => {
                                            console.log('selected : ', selected_etab)
                                            return <p key={idx} className="etab_list" onClick={() => this.removeEtab(selected_etab[item])}>{selected_etab[item].ligne_adresse} {selected_etab[item].code_postal} {selected_etab[item].ville}<i className="fas fa-times"></i></p>
                                        })
                                        */}
                                        {(previous.chosen_etbalissements ?
                                            <div className="choosen_etab">
                                                {
                                                    selected_etab_list.length > 0 ?
                                                        selected_etab_list.length > 0 ?
                                                            selected_etab_list.map((itm, idx) => {
                                                                return <p key={idx} className="etab_list" onClick={() => this.removeEtab(itm)}>(SIRET : {itm.siret})<br/>{itm.ligne_adresse} {itm.code_postal} {itm.ville} <i className="fas fa-times"></i></p>
                                                            })
                                                            : null
                                                        : null
                                                }
                                            </div>
                                            :
                                            <div className="choosen_etab">
                                                {
                                                    selected_etab_list.length > 0 ?
                                                        selected_etab_list.map((itm, idx) => {
                                                            return <p key={idx} className="etab_list" onClick={() => this.removeEtab(itm)}>(SIRET : {itm.siret})<br/>{itm.ligne_adresse} {itm.code_postal} {itm.ville} <i className="fas fa-times"></i></p>
                                                        })
                                                        : null
                                                }
                                            </div>
                                        )}
                                        {
                                            (current_etab === '') ?
                                                '' :
                                                <p className="add_etab_to_list mt-3" onClick={() => this.addEtab(current_etab)}>Ajouter cet établissement <i className="fas fa-plus"></i></p>
                                        }
                                    </div>
                                    <div className="block left full_width mt-4">
                                        {/*<p className="add_all left" onClick={this.addAll}>Tout choisir</p>*/}
                                        {
                                            selected_etab_list.length > 0 ?
                                            <p className="delete_all right" onClick={this.deleteAll}>Tout supprimer</p>
                                            : null
                                        }
                                    </div>
                                </Col>
                                {/*
                        <Col xs={12} md={3}>
                            <div className="choosen_etab pt-5">
                                {
                                    selected_etab.map((item, index) => {
                                        return <p key={index} className="etab_list" onClick={() => this.removeEtab(item)}>{item.ligne_adresse},{item.code_postal} {item.ville} <i className="fas fa-times"></i></p>;
                                    })
                                }
                            </div>
                        </Col>
                        */}
                                <Col xs={10} md={10}>
                                    <div className="form_group mt-5"><p> </p>
                                        {
                                            is_readonly ?
                                                <label className="reset-data btn_reset" onClick={this.resetData}><i className="far fa-trash-alt"></i>Réinitialiser la souscription</label>
                                                :
                                                <label className="previous" id='etabli_previous' onClick={this.go_back}><i className="fas fa-chevron-left"></i>Étape précédente</label>
                                        }
                                        <input type="submit" id='etabli_continue' name="btn_submit" disabled={selected_etab_list.length >= client_identification.number_allowed || selected_etab.length >= client_identification.number_allowed ? false : true} className="btn_next_continue btn_submit" onSubmit={this.handleSubmit} value="Continuer" />
                                    </div>
                                </Col>
                                <Col xs={2} md={2}></Col>
                            </Row>
                            <ReCaptcha
                                ref={ref => this.recaptcha = ref}
                                sitekey={site_key}
                                action={this.updateToken}
                                verifyCallback={this.verifyCallback}
                            />
                        </form>
                    </Container>
                }
            </React.Fragment>
        );
    }
}
function mapStateToProps({ etablissement, previous, reset_subscription }) {
    return { etablissement, previous, reset_subscription };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        liste_etablissements: liste_etablissements,
        handleHTTPError: handleHTTPError,
        get_previous: get_previous,
        get_reset_subscription: get_reset_subscription
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Etablissement);