import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import swal from 'sweetalert';
import { connect } from 'react-redux';
import { liste_offres } from '../../actions/offers';
import { get_brochure } from '../../actions/brochure';
import { get_previous } from '../../actions/previous';
import { dynamic_parameters } from '../../actions/settings';
import { get_numbers_subs } from '../../actions/all_subs';
import { get_reset_subscription } from '../../actions/reset_subscription';
import { check_promotion_code } from '../../actions/check_promotion_code';
import { bindActionCreators } from 'redux';
import { current_step, subscription_id, categorie } from '../../services/status';
import { Container, Row, Col } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import Offer from './single';
import './offers.css';
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";
import check_2_active from '../../assets/img/check_2_active.png';
import check_2 from '../../assets/img/check_2.png';
import icon_espace from "../../assets/img/icon_espace.svg"
import {Modal, Button} from "react-bootstrap"
import closeBtn from '../../assets/img/close-btn.svg';
import DocumentBrochurePopup from "./DocumentBrochurePopup"



class Offers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            frm_data: {},
            isLoading: false,
            is_readonly: false,
            categorie_type: categorie,
            offers: (localStorage.getItem('next') ? JSON.parse(localStorage.getItem('next')) : JSON.parse(localStorage.getItem('list_etablissement'))),
            list_of_offers: [],
            textButtonsOffer: [],
            selected_id: '',
            consulted_offer: '',
            chosen_offer: '',
            code: '',
            code_saisie: '',
            type_subscription: '',
            finished_subscriptions_number: 0,
            all_subscriptions_number: null,
            codePromoReduction: 0,
            site_key: process.env.REACT_APP_TAG_RECAPTCHA_V3 ? process.env.REACT_APP_TAG_RECAPTCHA_V3 : '',
            token: '',
            ShowBrochure : false,
            fileURL : null,
            OpenBrochureTitle : '',
            OpenBrochureId : '',
            show: true,
            is_autorise_tech: false,
            is_autorise_info: false,
        }
    }
    handleClose = () => {
        this.setState({
            show: false,
            ShowBrochure: false
        })
        console.log('close')
    }

    handleShow  = () => {
        this.setState({
            show: true,
            ShowBrochure: true
        })
        console.log('open')
    }
    toggleBrochure = () => {
        this.setState({
            ShowBrochure: !this.state.ShowBrochure,
            show: false
        });
        console.log('YEES')
    }
    componentDidUpdate(prevProps) {
        const { offers } = this.state;
        const { brochure_file, previous, my_offers, code_promo, parametres, all_subs, reset_subscription } = this.props;
        // Enable navigation prompt
        window.onbeforeunload = function() {
            return true;
        };
        // GET NEW KEY WHEN RESET SUBS
        if (prevProps.reset_subscription !== reset_subscription) {
            localStorage.removeItem('previous');
            //console.log('reset_subscription', reset_subscription);
            // Remove navigation prompt
            window.onbeforeunload = null;
            let newKey = reset_subscription.result.data.subscription_id;
            //console.log('reset_subscription 2 : ', newKey);
            //window.location.assign(newKey)
            window.location.pathname = newKey + '/start'
        }
        // NUMBERS OF SUBS
        if (prevProps.all_subs !== all_subs) {
            all_subs.result.data.map((itm, indx) => {
                if (itm.param === "NUMBER_SUBSCRIPTIONS_ENDESA") {
                    //console.log('YEES : ', itm.value)
                    this.setState({
                        all_subscriptions_number: itm.value
                    })
                }
            })
        }
        // POPUP VALIDATION CODE PROMO
        if (prevProps.code_promo !== code_promo) {
            if (code_promo.result.status === 200) {
                swal({
                    title: "code promo ou un code de parrainage",
                    text: code_promo.result.data.message,
                    icon: "success",
                }).then(() => {
                    this.setState({
                        codePromoReduction: code_promo.result.data.reduction
                    })
                })
            } else {
                swal({
                    title: "code promo ou un code de parrainage",
                    text: "Code erronée ou Expiré.",
                    icon: "error",
                }).then(() => {
                    this.setState({
                        codePromoReduction: 0
                    })
                })
            }
        }
        // NEXT STEP
        if (prevProps.my_offers !== my_offers) {
            // Remove navigation prompt
            window.onbeforeunload = null;
            if (my_offers.result.status === 200) {
                localStorage.removeItem('current_step_continue');
                localStorage.removeItem('previous');
                localStorage.setItem('step', my_offers.result.data.step);
                localStorage.setItem('subscription_id', my_offers.result.data.subscription_id);
                localStorage.setItem('type_subscription', my_offers.result.data.subscription_id);
                localStorage.setItem('next', JSON.stringify(my_offers.result.data));
                localStorage.setItem('current_step_continue', JSON.stringify(my_offers.result.data));
                // Redirect
                window.location.reload(false);
                window.location.replace(my_offers.result.data.step)
            } else {
                swal({
                    title: "Merci de choisir une offre",
                    //text: "Merci de choisir une offre",
                    icon: "error",
                }).then(() => {
                    this.setState({
                        isLoading: false,
                    })
                })
            }
        }
        // DOWNLOADING FILE
        if (prevProps.brochure_file !== brochure_file) {
            if(this.state.ShowBrochure){
                window.dataLayer.push({
                    event: 'gtm.click.DownloadBochure',                
                    brochure_name: this.state.OpenBrochureTitle,               
                });
                let filename = Object.values(brochure_file.result.headers);
                const blob = new Blob([brochure_file.result.data], { type: filename[2] });
                const data = window.URL.createObjectURL(blob);
                filename = filename[0].split('=');
                filename = filename[1].split('"');
                // IE doesn't allow using a blob object directly as link href
                // instead it is necessary to use msSaveOrOpenBlob
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }
                // For other browsers: 
                var link = document.createElement('a');
                link.href = data;
                link.download = filename[1];
                link.click();
                this.setState({
                    isLoading: false
                });
                setTimeout(function () {
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(data);
                }, 100);
                link.remove();
            }
            else{
                window.dataLayer.push({
                    event: 'gtm.click.ShowBochure',                
                    brochure_name: this.state.OpenBrochureTitle,                
                });
                const blob = new Blob([brochure_file.result.data], { type: 'application/pdf' });
                const fileURL = window.URL.createObjectURL(blob);
                //console.log(fileURL)
                this.setState({
                    isLoading : false,
                    ShowBrochure : true,
                    fileURL : fileURL,
                });
            }
        }
        // PREVIOUS STEP
        if (prevProps.previous !== previous) {
            window.onbeforeunload = null;
            setTimeout(() => {
                localStorage.removeItem('next');
                localStorage.removeItem('current_step_continue');
                localStorage.removeItem('current_returned_prospect');
                localStorage.setItem('step', previous.result.data.step);
                localStorage.setItem('subscription_id', previous.result.data.subscription_id);
                localStorage.setItem('previous', JSON.stringify(previous.result.data));
                // Redirect
                window.location.reload(false);
                window.location.replace(previous.result.data.step)
            }, 2000);
        }
        // CODE PROMO
        if (prevProps.code_promo !== code_promo) {
            //console.log('Data', code_promo);
        }
        // PARAMETRES
        if (prevProps.parametres !== parametres) {
            this.setState({
                finished_subscriptions_number: parametres.finished_subscriptions_number
            });
        }
        // WHEN REFRESH RETURN TO LAST STEP
        if (localStorage.getItem('previous')) {
            if (JSON.parse(localStorage.getItem('previous')).step === "liste_offres") {
                //localStorage.setItem('step', offers.last_step);
            }
        }

    }
    componentWillUnmount() {
        localStorage.removeItem('current_step_continue');
    }
    componentWillMount() {
        window.dataLayer.push({              
            pageCategory: 'Offres',
            event: 'gtm.load.Offres',                 
        });
        const { offers, list_of_offers } = this.state;
        const { dynamic_parameters, get_numbers_subs } = this.props;
        dynamic_parameters();
        get_numbers_subs();
        if (localStorage.getItem('current_step_continue') && JSON.parse(localStorage.getItem('current_step_continue')).data.step === 'liste_offres') {
            // Buttons text offers
            let textButtons= []
            textButtons.length = JSON.parse(localStorage.getItem('current_step_continue')).data.data.length
            textButtons.fill(false)
            this.setState({
                offers: JSON.parse(localStorage.getItem('current_step_continue')).data,
                list_of_offers: JSON.parse(localStorage.getItem('current_step_continue')).data.data,
                is_readonly: JSON.parse(localStorage.getItem('current_step_continue')).is_readonly,
                textButtonsOffer: textButtons
            });
        }
        if (localStorage.getItem('previous')) {
            if (JSON.parse(localStorage.getItem('previous')).step === "liste_offres") {
                //console.log('previous step')
                //localStorage.setItem('step', offers.last_step);
                // Buttons text offers
                let textButtons= []
                textButtons.length = JSON.parse(localStorage.getItem('previous')).data.length
                textButtons.fill(false)
                this.setState({
                    is_readonly: JSON.parse(localStorage.getItem('previous')).is_readonly,
                    offers: JSON.parse(localStorage.getItem('previous')),
                    list_of_offers: JSON.parse(localStorage.getItem('previous')).data,
                    chosen_offer: JSON.parse(localStorage.getItem('previous')).chosen_offer,
                    textButtonsOffer: textButtons,
                    is_autorise_tech: true,
                    is_autorise_info: true
                })
            }
        }
        if (localStorage.getItem('next')) {
            if (JSON.parse(localStorage.getItem('next')).step === "liste_offres") {
                //console.log('previous step', JSON.parse(localStorage.getItem('next')))
                //localStorage.setItem('step', offers.last_step);
                // Buttons text offers
                let textButtons= []
                textButtons.length = JSON.parse(localStorage.getItem('next')).data.length
                textButtons.fill(false)
                this.setState({
                    is_readonly: JSON.parse(localStorage.getItem('next')).is_readonly,
                    offers: JSON.parse(localStorage.getItem('next')),
                    list_of_offers: JSON.parse(localStorage.getItem('next')).data,
                    chosen_offer: JSON.parse(localStorage.getItem('next')).chosen_offer,
                    textButtonsOffer: textButtons
                })
            }
        }
    }
    componentDidMount() {
        const { offers, site_key, list_of_offers } = this.state;
        this.setState({
            consulted_offer: offers.consulted_offer,
            chosen_offer: offers.chosen_offer,
            categorie_type: offers.secteurs,
            list_of_offers: offers.data
        });
        loadReCaptcha(site_key, this.verifyCallback);
        //console.log('step : ', offers)
        //console.log('list of offers : ', list_of_offers)
    }
    updateToken = () => {
        // you will get a new token in verifyCallback
        this.recaptcha.execute()
    };
    verifyCallback = (recaptchaToken) => {
        // Here you will get the final recaptchaToken!!!  
        this.setState({
            token: recaptchaToken
        });
    }
    // RESET DATA - SOUSSCRIPTION
    resetData = (e) => {
        e.preventDefault();
        const { get_reset_subscription } = this.props;
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        let data = {};
        //data.subscription_id = subscription_id;
        data.subscription_id = path_remove_slash;
        swal({
            title: "Etes-vous sûr ? Vous perdrez toutes les informations renseignées",
            //text: "Once deleted, you will not be able to recover this imaginary file!",
            icon: "warning",
            buttons: ["Non", "Oui"],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    window.dataLayer.push({
                        event: 'gtm.click.offre_Reinitialiser',                
                    });
                    swal("Votre souscription est réinitialisée", {
                        icon: "success",
                    }).then(() => {
                        //console.log('call ws');
                        get_reset_subscription(data)
                    });
                } else {
                    //swal("Your imaginary file is safe!");
                }
            });

    }
    // SELECT OFFER
    toggetOffer = (id,business) => {
        const { offers } = this.state;
        if(id === this.state.consulted_offer){
            window.dataLayer.push({              
                event: 'gtm.click.OffreConsultée',                
            });
        }
        if(business){
            window.dataLayer.push({              
                event: 'gtm.click.OffreMétier',                
            });
        }
        this.setState({
            chosen_offer: id
        });
    }
    // SHOW BROCHURE MODAL 

    OpenBrochure = (id,title) => {
        const { offers } = this.state;
        const { get_brochure } = this.props;
        this.setState({
            OpenBrochureTitle: title,
            OpenBrochureId: id,
            isLoading : true,
            show: true
        });
        let data = {};
        data.subscription_id = offers.subscription_id;
        data.offer_id = id;
        get_brochure(data);
        
    }
    CloseBrochure = () => {
        this.setState({
            ShowBrochure: false,
            show: false
        });
    }
    // DOWNLOAD FILE
    downloadFile = () => {
        const { offers } = this.state;
        const { get_brochure } = this.props;
        let data = {};
        data.subscription_id = offers.subscription_id;
        data.offer_id = this.state.OpenBrochureId;
        // SUBMIT TO DOWNLOAD FILE
        get_brochure(data);
        this.setState({
            isLoading: true
        });
    };
    // GET PREVIOUS
    go_back = (e) => {
        e.preventDefault();
        const { get_previous } = this.props;
        const { offers } = this.state;
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        let data = {};
        data.type_step = 'previous';
        //data.subscription_id = offers.subscription_id;
        data.subscription_id = path_remove_slash;
        data.current_step = offers.step;
        this.setState({
            isLoading: true
        });
        get_previous(data);
    }
    //handlePromotion
    handlePromotion = (e) => {
        this.setState({
            code: e.target.value
        })
    };
    //TEXT BUTTONS
    toggleTextButton = (id) => {
        const { textButtonsOffer } = this.state;
        let copied = textButtonsOffer
        let value = !copied[id]
        let copied2 = copied
        //copied2.fill(false)
        copied2[id] = value
        this.setState({
            textButtonsOffer: copied2
        })
        //console.log('DATA IS : ', copied2 )
    }
    // CHECK PROMOTION
    checkPromo = (e) => {
        e.preventDefault();
        let promotion = this.state.code;
        const { check_promotion_code } = this.props;
        let data = {}
        data.code = promotion;
        check_promotion_code(data);
        //console.log('promo : ', data)
    };
    checkAutorise = (e) => {
        const { is_autorise_tech } = this.state;
        this.setState({
            is_autorise_tech: !is_autorise_tech
        });
    };
    checkAutorise_info = (e) => {
        const { is_autorise_info } = this.state;
        this.setState({
            is_autorise_info: !is_autorise_info
        });
    };
    // SUBMIT
    handleSubmit = (e) => {
        e.preventDefault();
        //window.onbeforeunload = null;
        const { liste_offres } = this.props;
        const { frm_data, chosen_offer, code, token } = this.state;
        /*
        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        */
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        frm_data.promotion_code = code;
        frm_data.offer_id = chosen_offer;
        //frm_data.subscription_id = subscription_id;
        frm_data.subscription_id = path_remove_slash;
        frm_data.recaptcha = token
        // SUBMIT DATA TO BACKEND
        liste_offres(frm_data);
        // LOADING
        this.setState({
            isLoading: true
        });
    };
    render() {
        const {
            chosen_offer,
            offers,
            icons,
            code,
            consulted_offer,
            isLoading,
            codePromoReduction,
            categorie_type,
            list_of_offers,
            finished_subscriptions_number,
            is_readonly,
            all_subscriptions_number,
            site_key,
            textButtonsOffer,
            is_autorise_tech,
            is_autorise_info,
        } = this.state;
        return (
            <React.Fragment>
                {isLoading ?
                    <div className="loadingKey">
                        <Spinner className="spinner-border text-primary" animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                    :
                    <Container>
                        <Row>
                            <Col>
                                {is_readonly ? <div className="blank_div"></div> : null}
                                {
                                    offers.raison_sociale !== null ?
                                        <h1 className="blue-border"><span></span>Bonjour société {offers.raison_sociale}</h1>
                                        :
                                        <h1 className="blue-border"><span></span>Bonjour</h1>
                                }

                                <div className="form-group mt-5 offers_container offers-wrapper">
                                    <p className="classic_txt">Voici nos offres adaptées pour <span className="secteur_offer">{categorie_type}</span> :</p>
                                    <Row>
                                        <Col xs={12} md={12} className="noPadding">
                                            <form onSubmit={this.handleSubmit}>
                                                {
                                                    list_of_offers.map((item, idx) => {
                                                        return <Offer
                                                            key={item.id}
                                                            id={item.id}
                                                            offer_id={item.id}
                                                            icon_bg_color={item.icon_bg_color}
                                                            icone={item.icon}
                                                            title={item.title}
                                                            sub_title={item.subtitle}
                                                            text={ReactHtmlParser(item.description)}
                                                            avantages={item.offeradvantage_set}
                                                            toggleTextButton={this.toggleTextButton}
                                                            textButtonsOffer = {textButtonsOffer}
                                                            toggetOffer={this.toggetOffer}
                                                            brochure={this.OpenBrochure}
                                                            consulted_offer={consulted_offer}
                                                            selected={chosen_offer}
                                                            index_offer = {idx}
                                                            isbusiness={item.is_business_offer} />
                                                    })
                                                }
                                                {/*
                                                <div className="etablissement-wrapper">
                                                    <div className="icon-help icon">
                                                        <img src={icon_espace} alt="endesa etablissement" />
                                                    </div>
                                                    <div className="etablissement-text">
                                                        <p>Plus de <span className="pink-text">{all_subscriptions_number}</span> de clients dans le monde ont déjà choisi Endesa !<br />
                                                            Afin de lancer la simulation du prix de votre offre cliquez sur “continuer”.</p>
                                                    </div>
                                                </div>
                                                */}
                                                <div className="form-group mt-5">
                                                    <Row>
                                                        <Col xs={12} md={12} className="noPadding">
                                                            <label className={(is_autorise_tech ? 'check_2 mt-4 active' : 'check_2 mt-4')} htmlFor="autorise">
    
                                                                <input
                                                                    type="checkbox"
                                                                    name="autorise"
                                                                    id="autorise"
                                                                    value="autorise"
                                                                    onChange={this.checkAutorise}
                                                                    checked={is_autorise_tech || false}
                                                                />
                                                                <p>
                                                                    {(is_autorise_tech ?
                                                                        <img src={check_2_active} alt="Endesa S.A." />
                                                                        :
                                                                        <img src={check_2} alt="Endesa S.A." />
                                                                    )}
                                                                    J'autorise expressément Endesa à récupérer les données techniques de mon ou mes site(s) auprès du Gestionnaire de Réseau afin d'estimer ma consommation, à partir des données fournies. Ces données sont collectées uniquement dans le but de souscrire à nos offres ainsi que dans le cadre de la gestion d’un contrat éventuellement souscrit. <span className="red_star">*</span>
                                                                </p>
                                                            </label>
                                                        </Col>
                                                        <Col xs={12} md={12} className="noPadding">
                                                            <label className={(is_autorise_info ? 'check_2 mt-4 active' : 'check_2 mt-4')} htmlFor="is_autorise_info">
    
                                                                <input
                                                                    type="checkbox"
                                                                    name="is_autorise_info"
                                                                    id="is_autorise_info"
                                                                    value="is_autorise_info"
                                                                    onChange={this.checkAutorise_info}
                                                                    checked={is_autorise_info || false}
                                                                />
                                                                <p>
                                                                    {(is_autorise_info ?
                                                                        <img src={check_2_active} alt="Endesa S.A." />
                                                                        :
                                                                        <img src={check_2} alt="Endesa S.A." />
                                                                    )}
                                                                    J’autorise Endesa à collecter les informations saisies dans ce formulaire pour le traitement de ma demande. <span className="red_star">*</span>
                                                                </p>
                                                            </label>
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className="etablissement-wrapper">
                                                    <div className="icon-help icon">
                                                        <img src={icon_espace} alt="endesa etablissement" />
                                                    </div>
                                                    <div className="etablissement-text">
                                                        <p>Plus de <span className="pink-text">{all_subscriptions_number}</span> de clients dans le monde ont déjà choisi Endesa !<br />
                                                            Afin de lancer la simulation du prix de votre offre cliquez sur “continuer”.</p>
                                                    </div>
                                                </div>
                                                {/* Hide promotion code
                                                <div className="form_group mt-5">
                                                    <p>Disposez-vous d’un code promo ou un code de parrainage?</p>
                                                    <input type="text" className="simple_input promo_input" onChange={this.handlePromotion} value={code} />
                                                    <input className="btn_appliquer" onClick={this.checkPromo} value="Appliquer" />
                                                    {
                                                        codePromoReduction > 0 ?
                                                            <p className="text-muted mt-2">Votre code promo est validée avec une réduction de {codePromoReduction}% </p>
                                                            : null
                                                    }

                                                </div>
                                                */}

                                                <div className="form_group mt-5"><p> </p>
                                                    <input type="submit" id="allow-navigation offre_continue" name="btn_submit" disabled={!is_autorise_info || !is_autorise_tech ? true : false } className="btn_next_continue btn_submit allow-navigation" onSubmit={this.handleSubmit} value="Continuer"  />
                                                    {
                                                        is_readonly ?
                                                            <label className="reset-data btn_reset" onClick={this.resetData}><i className="far fa-trash-alt"></i>Réinitialiser la souscription</label>
                                                            :
                                                            <label className="previous" id='offre_previous' onClick={this.go_back}><i className="fas fa-chevron-left allow-navigation" id="allow-navigation"></i>Étape précédente</label>
                                                    }
                                                </div>
                                            </form>
                                        </Col>
                                    </Row>
                                </div>
                                <ReCaptcha
                                    ref={ref => this.recaptcha = ref}
                                    sitekey={site_key}
                                    action={this.updateToken}
                                    verifyCallback={this.verifyCallback}
                                />
                            </Col>
                        </Row>
                    </Container>
                }
                {/*
                    (this.state.ShowBrochure ?
                        <React.Fragment>
                            <Modal dialogClassName="modal-90w" size="lg" centered show={this.state.show} onHide={this.handleClose}>
                                <BrochureModal
                                    downloadFile = {this.downloadFile}
                                    CloseBrochure = {this.CloseBrochure.bind(this)}
                                    fileURL = {this.state.fileURL}
                                />
                            </Modal>
                        </React.Fragment>
                        :
                        ''
                    )
                */}
                {
                    this.state.ShowBrochure && this.state.show ?
                        <React.Fragment>
                            <Modal dialogClassName="modal-90w" size="lg" centered show={this.state.show} onHide={this.handleClose}>
                                <span className="close-btn-brochure" onClick={this.toggleBrochure.bind(this)}>
                                    <img src={closeBtn} />
                                </span>
                                <DocumentBrochurePopup fileURL={this.state.fileURL} />
                            </Modal>
                        </React.Fragment>
                    : null
                }
            </React.Fragment>
        );
    }
}

function mapStateToProps({ my_offers, brochure_file, previous, code_promo, parametres, all_subs, reset_subscription }) {
    return { my_offers, brochure_file, previous, code_promo, parametres, all_subs, reset_subscription };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        liste_offres: liste_offres,
        get_brochure: get_brochure,
        get_previous: get_previous,
        get_reset_subscription: get_reset_subscription,
        check_promotion_code: check_promotion_code,
        get_numbers_subs: get_numbers_subs,
        dynamic_parameters: dynamic_parameters
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Offers);