export function get_pre_verified_address(payload) {
    return {
        type: 'PRE_VERIFIED_ADDRESS',
        payload,
    };
}
export function get_pre_verified_address_done(request) {
    return {
        type: 'PRE_VERIFIED_ADDRESS_DONE',
        payload: request,
    };
}