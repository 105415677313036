export function get_data(payload) {
    return {
        type: 'DATA',
        payload,
    };
}
export function get_data_done(request) {
    return {
        type: 'DATA_DONE',
        payload: request,
    };
}