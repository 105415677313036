/*import axios from 'axios';
import { apiUrl, url_contact } from '../services/constant';

export const contact = (data) => dispatch => {
    const request = axios.post(apiUrl + url_contact, data);
    dispatch({
        type: 'CONTACT',
        payload: request
    })
}
*/
export function contact(payload) {
    return {
        type: 'CONTACT',
        payload,
    };
}
export function contact_done(request) {
    return {
        type: 'CONTACT_DONE',
        payload: request,
    };
}