import React from "react";
import swal from 'sweetalert';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { identification } from '../../actions/identification';
import { current_step, subscription_id } from '../../services/status';
import { get_recalled } from '../../actions/get_recalled';
import './aide.css';
import phone from '../../assets/img/phone.svg';
import closeBtn from '../../assets/img/close-btn.svg';
import closeMobile from '../../assets/img/mobile_close.png';
import PhoneInput from 'react-phone-input-2'
import { get_numbers_subs } from '../../actions/all_subs';

class PopupRappel extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            frm_data: {},
            civilityPopup: 'Mr',
            etreReppele: this.props.checkRappelProp,
            show_list: false,
            show_list_heure: false,
            joursSemaine: ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi'],
            heuresDispo: [
                '9h-12h',
                '13h-14h',
                '14h-17h'
            ],
            last_name: '',
            first_name: '',
            telephone: '',
            email: '',
            type: '',
            days: [],
            hours: [],
            current_jour: '',
            current_heure: '',
            //alphanum: localStorage.getItem('next') ? JSON.parse(localStorage.getItem('next')).user_key : JSON.parse(localStorage.getItem('current_step_continue')).data.user_key ? JSON.parse(localStorage.getItem('previous')).user_key : '' ,
            alphanum: localStorage.getItem('next') ? JSON.parse(localStorage.getItem('next')).user_key : 
                        localStorage.getItem('current_step_continue') ? JSON.parse(localStorage.getItem('current_step_continue')).data.user_key :
                        localStorage.getItem('previous') ? JSON.parse(localStorage.getItem('previous')).user_key : '',
            errorEmail: [],
            showPopupPhone: false,
            showSecondPopup: this.props.checkReminderProp,
            assistant_phone_endesa: '',
            disponibiliteText: '',
            checkPhone: this.props.checkPhoneProp
        }
    }
    //COMPONENT WILLMOUNT
    componentWillMount(){
        const { get_numbers_subs } = this.props;
        get_numbers_subs();
    }
    //COMPONENT CHANGE
    componentDidUpdate(prevProps) {
        const {etreReppele, disponibiliteText} = this.state;
        const { etre_rappeller, client_identification, all_subs } = this.props;

        // PHONE NUMBER ENDESA
        if (prevProps.all_subs !== all_subs) {
            //console.log(all_subs)
            all_subs.result.data.map((itm, indx) => {
                if (itm.param === "ASSISTANT_PHONE_NUMBER") {
                    //console.log('YEES : ', itm.value)
                    this.setState({
                        assistant_phone_endesa: itm.value
                    })
                }
                if (itm.param === "CHECK_REMINDER") {
                    //console.log('YEES : ', itm.value)
                    this.setState({
                        showPopupPhone: itm.value,
                        //etreReppele: itm.value,
                        //showSecondPopup: !itm.value
                    })
                }
                if (itm.param === "JOURS_CRENEAU") {
                    console.log('JOURS_CRENEAU : ', itm.value)
                    this.setState({
                        joursSemaine: itm.value
                    })
                }
                if (itm.param === "PLAGES_CRENEAU") {
                    console.log('PLAGES_CRENEAU : ', itm.value)
                    this.setState({
                        heuresDispo: itm.value
                    })
                }
                if (itm.param === "PLAGE") {
                    //console.log('plage_disponibilité : ', itm.value)
                    this.setState({
                        disponibiliteText: itm.value
                    })
                }
            })
        }
        if (prevProps.etre_rappeller !== etre_rappeller) {
            if (etre_rappeller.result.status === 200) {
                if(this.state.etreReppele){
                    window.dataLayer.push({
                        event: 'gtm.click.Etrerappeleplustard  ',                
                    });
                }
                else{
                    window.dataLayer.push({
                        event: 'gtm.click.Etrerappelemaintenant ',                
                    });
                }
                if(etreReppele){
                    swal({
                        //title: "Nous avons bien enregistré votre demande. Un de nos conseillers prendra contact avec vous dans le créneau choisi.",
                        text: `Nous avons bien enregistré votre demande. Nos conseillers sont disponibles du lundi au vendredi de ${disponibiliteText}. Nous vous rappellerons dans le créneau choisi.`,
                        icon: "success",
                    }).then(() => {
                        this.props.closePopup()
                    });
                }else{
                    swal({
                        //title: "Nous avons bien enregistré votre demande. Un de nos conseillers prendra contact avec vous dans le créneau choisi.",
                        text: `Nous avons bien enregistré votre demande. Nos conseillers sont disponibles du lundi au vendredi de ${disponibiliteText}. Nous vous rappellerons dans le meilleur délai.`,
                        icon: "success",
                    }).then(() => {
                        this.props.closePopup()
                    });
                }
            } else {
                swal({
                    title: "Erreur dans le formulaire",
                    text: "Une ou plusieurs informations saisies ne sont pas correctes",
                    icon: "error",
                }).then(() => {
                    this.setState({
                        isLoading: false,
                        errorEmail: (etre_rappeller.result.response.data.email ? etre_rappeller.result.response.data.email : ''),
                    });
                });
            }
        }
    }
    // HANDLE CIVILITY
    handleCivility = (e) => {
        this.setState({
            civilityPopup: e.target.value
        });
        //console.log('e : ', e)
    };
    checkRappel = (e) => {
        const { etreReppele } = this.state;
        this.setState({
            etreReppele: !etreReppele
        });
    };
    trigger_list = () => {
        const { show_list } = this.state;
        this.setState({
            show_list: !show_list,
            show_list_heure: false
        })
        //console.log('click')
    };
    trigger_list_heures = () => {
        const { show_list_heure } = this.state;
        this.setState({
            show_list: false,
            show_list_heure: !show_list_heure
        })
    };
    addDay = (current_day) => {
        const { joursSemaine, days, show_list } = this.state;
        let rest_list_jour = joursSemaine.filter((jour) => jour !== current_day);
        let new_list = days;
        new_list.push(joursSemaine.filter((jour) => jour === current_day)[0]);
        this.setState({
            joursSemaine: rest_list_jour,
            days: new_list,
            show_list: !show_list
        })
    };
    addHeures = (current_heure) => {
        const { heuresDispo, hours, show_list_heure } = this.state;
        let rest_list_heure = heuresDispo.filter((heure) => heure !== current_heure);
        let new_list = hours;
        new_list.push(heuresDispo.filter((heure) => heure === current_heure)[0]);
        //console.log('List',new_list);
        this.setState({
            heuresDispo: rest_list_heure,
            hours: new_list,
            show_list_heure: !show_list_heure
        })
    };
    removeDay = (item) => {
        const { days, joursSemaine } = this.state;
        let rest_jour = days.filter((cp) => cp !== item);
        let rest_list_jour = joursSemaine;
        rest_list_jour.push(item);
        this.setState({
            days: rest_jour,
            joursSemaine: rest_list_jour
        });
    };
    removeHeures = (heures) => {
        const { hours, heuresDispo } = this.state;
        let rest_heure = hours.filter((heure) => heure !== heures);
        let rest_list_heure = heuresDispo;
        rest_list_heure.push(heures);
        this.setState({
            hours: rest_heure,
            heuresDispo: rest_list_heure
        });
    };
    // INPUT CHANGE
    handleInput = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value
        });
    }
    // SUBMIT DATA
    handleSubmit = (e) => {
        const { frm_data, civilityPopup, last_name, first_name, telephone, etreReppele, email, days, hours } = this.state;
        const { get_recalled } = this.props;
        let path = window.location.pathname.match(/([^/]*\/){2}/)[0]
        let path_remove_slash = path.replace(/^\/|\/$/g, '');
        e.preventDefault();
        //frm_data.last_name = last_name;
        //frm_data.first_name = first_name;
        frm_data.telephone = "+" + telephone;
        frm_data.email = email;
        //frm_data.civility = civilityPopup;
        frm_data.type = (!etreReppele ? 'now' : 'later');
        frm_data.days = days;
        frm_data.hours = hours;
        frm_data.subscription_id = path_remove_slash;
        // CALL WS BACKEND
        get_recalled(frm_data);
        //console.log('Data', frm_data);
    }
    // CLICK SHOW NUMBER POPUP
    rappelPopupNumber = (e) => {
        e.preventDefault()
        const {showSecondPopup} = this.state
        this.setState({
            showSecondPopup: !showSecondPopup
        })
        //console.log('CLICKED')
    }
    render() {
        const {
            assistant_phone_endesa,
            civilityPopup,
            etreReppele,
            joursSemaine,
            show_list,
            current_jour,
            days,
            show_list_heure,
            current_heure,
            heuresDispo,
            last_name,
            first_name,
            telephone,
            email,
            alphanum,
            type,
            errorEmail,
            hours,
            showPopupPhone,
            showSecondPopup,
            disponibiliteText,
            checkPhone } = this.state
        return (
            <div className={this.props.classFull ? "pop_up pop_up_facture facture class-full error_popup_rappel" : "popup-wrapper"}>
                {/*<div className={this.props.classFull ? "bg_pop_up" : "bg_pop_up2"} onClick={this.props.closePopup} />*/}
                <div className={this.props.classFull ? "pop_up_container popup_inner" : "popup"}>
                    {
                        this.props.showBackButton ?
                            <div className="wrapper-back-popup">
                                <span className="retour-popup" onClick={this.props.initPopup}>Retour</span>
                            </div>
                        : null
                    }
                    {
                        !showSecondPopup ?
                        <div>
                            <div className='popup_inner'>
                                <button className="btn-close" onClick={this.props.closePopup}>
                                    <img className="only_desktop" src={closeBtn} />
                                    <div className="only_mobile only-mobile-bloc">
                                        <img className="only_mobile mobile-close-btn" src={closeMobile} />
                                        <span>Fermer</span>
                                    </div>
                                </button>
                                <img src={phone} className="phone_img" alt="Endesa S.A." />
                                <h1>J'appelle un conseiller</h1>
                                <a href="#" style={{ textAlign: 'center', display: 'block', textDecoration: 'underline'}} onClick={(e) => this.rappelPopupNumber(e)} >Je préfère être rappelé</a>
                                <h2 style={{ marginTop: '10px'}}>Nous sommes joignables du lundi au vendredi de {disponibiliteText}</h2>
                                <a href={'tel:' + assistant_phone_endesa} className="btn_rappel mt-4">{assistant_phone_endesa}</a>
                            </div>
                        </div>
                        :
                        <div className='popup_inner'>
                            <button className="btn-close" onClick={this.props.closePopup}>
                                <img className="only_desktop" src={closeBtn} />
                                <div className="only_mobile only-mobile-bloc">
                                    <img className="only_mobile mobile-close-btn" src={closeMobile} />
                                    <span>Fermer</span>
                                </div>
                            </button>
                            <img src={phone} className="phone_img" alt="Endesa S.A." />
                            <h1>Je souhaite être recontacté</h1>
                            {/* commented, to show only 1st popup TODO ADD DYNAMIC PROP */}
                            {
                                checkPhone != 'indisponible' ?
                                    <a href="#" style={{ textAlign: 'center', display: 'block', textDecoration: 'underline'}} onClick={(e) => this.rappelPopupNumber(e)} >Je préfère appeler directement un conseiller</a>
                                : null
                            }
                            {/*<a href="#" style={{ textAlign: 'center', display: 'block', textDecoration: 'underline'}} onClick={(e) => this.rappelPopupNumber(e)} >Je préfère appeler directement un conseiller</a>*/}
                            <h2 style={{ marginTop: '10px'}}>Nous sommes joignables du lundi au vendredi.</h2>
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-group mt-lg-5">
                                    <Row>
                                        <Col xs={12} sm={6} md={6} className="noPadding">
                                            <div className="input-rappel">
                                                <label className="label_names">Téléphone <span className="red_star">*</span></label>
                                                {/*<input type="text" name="telephone" value={telephone} className="simple_input" onChange={this.handleInput} />*/}
                                                <PhoneInput
                                                    inputProps={{ name: "telephone", className: "simple_input_phone" }}
                                                    masks={{ ma: '. ..-..-..-..' }}
                                                    country={'fr'}
                                                    value={telephone}
                                                    countryCodeEditable={false}
                                                    onChange={telephone => this.setState({ telephone })}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={6} md={6} className="noPadding ">
                                            <div className="input-rappel">
                                                <label className="label_names">Email <span className="red_star">*</span></label>
                                                <input type="email" name="email" value={email} className="simple_input" onChange={this.handleInput} />
                                                {
                                                    errorEmail.length > 0 ?
                                                        errorEmail.map((message, idx) => {
                                                            return (
                                                                <p key={idx} className="text-danger small-text-help mt-2">{message}</p>
                                                            )
                                                        })
                                                        : null
                                                }
                                            </div>
                                        </Col>
                                        {
                                            etreReppele ?
                                                null
                                                :
                                                <Col xs={12} sm={{ span: 6, offset: 3}} md={{ span: 6, offset: 3}} className="noPadding mt-3" style={{display: 'flex', alignItems: 'end'}}>
                                                    <button disabled={ telephone !== '' && telephone.length > 10 && email !== '' ? false : true }  className="btn_rappel">Me rappeler maintenant</button>
                                                </Col>
                                        }
                                    </Row>
                                </div>
                                <div className="form-group mt-4">
                                    <Row>
                                        <Col xs={12} className="noPadding">
                                            <label className={(etreReppele ? 'mt-4 rappel_label active' : 'mt-4 rappel_label')}>
                                                <input
                                                    type="checkbox"
                                                    name="rappel"
                                                    id="rappel"
                                                    value="rappel"
                                                    onChange={this.checkRappel}
                                                    disabled= {showPopupPhone ? true : false}
                                                    checked={etreReppele || false}
                                                />
                                                Je souhaite être rappelé ultérieurement
                                        </label>
                                        </Col>
                                        {
                                            etreReppele ?
                                                <React.Fragment>
                                                    <Col xs={12} sm={6} md={6} className="noPadding">
                                                        <div className="wrapper-postal">
                                                            <label>Jour de la semaine <span className="red_star">*</span></label>
                                                            <div className="select dropdown-postal mt-2" title={current_jour} onClick={this.trigger_list}>
                                                                <input className="toggle" readonly type="text" />
                                                                <div className="arrow">
                                                                    {(show_list ?
                                                                        <i className="fas fa-chevron-up"></i>
                                                                    : <i className="fas fa-chevron-down"></i>)}
                                                                </div>
                                                                <div className="selected">
                                                                    <p className="value">{current_jour}</p>
                                                                </div>
                                                                <div className={`dropdown ${(show_list ? '' : 'hide-drop')}`}>
                                                                    {
                                                                        joursSemaine.map((item, index) => {
                                                                            return <p key={index} className="value" onClick={(e) => this.addDay(item)}>{item}</p>;
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="choosen_day pt-3">
                                                            {
                                                                days.map((item, index) => {
                                                                    return <p key={index} className="etab_list" onClick={() => this.removeDay(item)}>{item} <i className="fas fa-times"></i></p>;
                                                                })
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} sm={6} md={6} className="noPadding">
                                                        <div className="wrapper-postal">
                                                            <label>Créneaux horaires <span className="red_star">*</span></label>
                                                            <div className="select dropdown-postal mt-2" title={current_heure} onClick={this.trigger_list_heures}>
                                                                <input className="toggle" readonly type="text" />
                                                                <div className="arrow">
                                                                    {(show_list_heure ?
                                                                        <i className="fas fa-chevron-up"></i>
                                                                    : <i className="fas fa-chevron-down"></i>)}
                                                                </div>
                                                                <div className="selected">
                                                                    <p className="value">{current_heure}</p>
                                                                </div>
                                                                <div className={`dropdown ${(show_list_heure ? '' : 'hide-drop')}`}>
                                                                    {
                                                                        heuresDispo.map((item, index) => {
                                                                            return <p key={index} className="value" onClick={(e) => this.addHeures(item)}>{item}</p>;
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="choosen_day choosen_hours pt-3">
                                                            {
                                                                hours.map((item, index) => {
                                                                    return <p key={index} className="etab_list" onClick={() => this.removeHeures(item)}>{item} <i className="fas fa-times"></i></p>;
                                                                })
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} className="mt-5">
                                                        <button name="btn_submit" type="submit"
                                                        disabled={ etreReppele === true && telephone !== '' && telephone.length > 10 && hours.length > 0 && days.length > 0 && email !== '' ? false
                                                        : true }
                                                        className="btn_submit btn_rappel" onSubmit={this.handleSubmit}>Envoyer</button>
                                                    </Col>
                                                </React.Fragment>
                                                :
                                                null
                                        }
                                    </Row>
                                </div>
                            </form>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps({ etre_rappeller, client_identification, all_subs }) {
    return { etre_rappeller, client_identification, all_subs };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        get_recalled: get_recalled,
        identification: identification,
        get_numbers_subs: get_numbers_subs
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(PopupRappel);