import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/CallApi';
import { post } from '../services/request';
import { apiUrl, url_validate_pce } from '../services/constant';
import { valider_pce_done } from '../actions/valide_pce';


function* valider_pce_saga(action) {
	yield call(
		callApi,
		apiUrl + url_validate_pce,
		post,
        action.payload,
        valider_pce_done,
	);
}
export default function* watch_valider_pce_saga() {
    yield takeEvery('PCE_VALIDE', valider_pce_saga);
}

