import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class NoMatchPage extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        localStorage.setItem('step', '');
        localStorage.setItem('subscription_id', '');
    }
    render() {
        return (
            <React.Fragment>
                <Container>
                    <Row>
                        <Col xs={12} md={12} className="txt_center mt-6">
                            <h1 className="h1">404 - Not found</h1>
                            <p className="classic_txt mt-3 txt_welcome">
                                La page que vous cherchez n'existe pas!
                              </p>
                            <button className="btn_end">Retour au site</button>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}

export default NoMatchPage;